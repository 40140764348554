import { useState } from "react";
import { ModalFooterProps } from "@efilecabinet/nautilus-ui";
import { IUseFormObject, useForm } from "../../../../hooks/useForm";
import { AddTaskModalTKeys, TranslationFiles, useSafeTranslation } from "../../../../hooks/useSafeTranslation";
import { AddTaskModalProps } from "./AddTaskModal";
import { AtlantisTaskStatus } from "../../../../api/tasks/taskApiTypes";

export interface AddTaskFormData {
    modalElementId: string,
    form: IUseFormObject<{taskText: string}>,
    footerButtons: ModalFooterProps,
    onSubmit: () => void,
    isProcessingAction: boolean,
}

export const useAddTaskModalForm = ({ isHiddenState: [isHidden, setIsHidden], submitTask }: AddTaskModalProps): AddTaskFormData => {

    const modalElementId = 'addTaskModal';
    
    const { t } = useSafeTranslation(TranslationFiles.AddTaskModal);

    const form = useForm({ taskText: '' });
    
    const [isProcessingAction, setIsProcessingAction] = useState(false);

    function onSubmit() {
        setIsProcessingAction(true);
        submitTask({ taskId: '', text: form.model.taskText, status: AtlantisTaskStatus.Assigned })
            .then(() => {               
                setIsHidden(true);        
                setIsProcessingAction(false);
            });
    }
    
    const footerButtons: ModalFooterProps = {
        buttons: [
            {
                buttonProps: {                  
                    buttonType: 'textButton',
                    dataId: 'btnCancelAddTask',
                    buttonStyle: 'btn-outline-primary',
                    label: t(AddTaskModalTKeys.Cancel),
                    onClick: () => { /* do nothing */},
                }
            }, {
                buttonProps: {  
                    dataId: 'btnAddTask',                
                    buttonType: 'asyncButton',
                    buttonStyle: 'btn-primary',
                    label: t(AddTaskModalTKeys.Add),
                    icon: { icon: ['fas', 'spinner-third'], pulse: true },
                    isProcessingActionState: [isProcessingAction, setIsProcessingAction],
                    width: '100px',
                    options: {
                        disabled: !form.model.taskText
                    },
                    onClick: onSubmit,
                },
                dismissModalOnClick: false,
            },
        ]
    };

    return {
        modalElementId,
        form,
        footerButtons,
        onSubmit,
        isProcessingAction,
    };
}