export interface ProfileItemDTO {
    id: number;
    accountID: number;
    name: string;
    type: ProfileItemTypeEnum;
    value: any;
    required: boolean;
    joinID: number;
    valueID: number;
    include: boolean;
    position?: number;
    presetValueID?: number;
    presetValues: ProfileItemPresetValueDTO[];
    hiddenCharacterID?: number;
    hiddenCharacters: ProfileItemHiddenCharacterConfigurationDTO;
    isVisible: boolean;
    isDeleted: boolean;
    profileID?: number;
    visibleDecimalPlaces?: number;
}

export enum ProfileItemTypeEnum {
    Text = 0,
    Email = 1,
    Number = 2,
    Date = 3,
    PhoneNumber = 4,
    CheckBox = 5,
    Currency = 6,
    Memo = 7,
    PresetValues = 8,
    Percentage = 9,
    HiddenText = 10,
}

export interface ProfileItemPresetValueDTO {
    id: number;
    value: string;
    order: number;
}

export interface ProfileItemHiddenCharacterConfigurationDTO {
    id: number;
    hideSpecifiedCharacters: boolean;
    fromBeginning?: number;
    fromEnd?: number;
    characterRanges: ProfileItemHiddenCharacterRangeDTO[];
}

export interface ProfileItemHiddenCharacterRangeDTO {
    id: number;
    startingPosition: number;
    endingPosition: number;
}