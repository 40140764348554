import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { useLayerContext } from '../../../app/_context/LayerContext/LayerContext';
import { useSettingsAndFeatures } from '../../../app/_context/SettingFeaturesContext/SettingsFeaturesContext';
import { FeedbackModal } from './Help/Feedback/FeedbackModal';
import { useFeedbackApi } from '../../../api/feedback/useFeedbackApi';

export const PreviewerXFeedback = () => {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const { previewerXSessionCount, user, userFeedbackInfo } = useAuthContext();
    const { previewerXDocViewCount } = useLayerContext();
    const { settings } = useSettingsAndFeatures();
    const { acknowledgePreviewerFeedback } = useFeedbackApi();

    const shouldShowFeedbackModal = () => {
        if (!!previewerXDocViewCount && settings != undefined && !!userFeedbackInfo) {
            return getActualSessionCount() >= settings.feedbackPreviewerXSessionCount && previewerXDocViewCount >= settings.feedbackPreviewerXDocViewCount && !userFeedbackInfo.previewerFeedbackAcknowledged;
        }

        return false;
    };

    const getActualSessionCount = () => {
        const countLoginStored = localStorage.getItem(`previewerx-login-count-${user?.id}`) ?? '0';

        return Math.max(previewerXSessionCount, +countLoginStored);
    };

    const closeFeedbackModal = () => {
        if (!!userFeedbackInfo) {
            userFeedbackInfo.previewerFeedbackAcknowledged = true;
            acknowledgePreviewerFeedback(user?.id as number);
        }
        setShowFeedbackModal(false);
    };

    useEffect(() => {
        if (shouldShowFeedbackModal()) {
            setShowFeedbackModal(true);
        }
    }, [previewerXDocViewCount]);

    return <FeedbackModal showFeedbackModal={showFeedbackModal} toggleFeedback={() => closeFeedbackModal()} isPreviewerXFeedback={true} />;
};
