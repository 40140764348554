import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { AuthAccountDTO } from '../../api/auth/authApiTypes';
import { Modal, Text, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { AccountTile } from '../AccountTile';
import { useAuthContext } from '../../app/_context/AuthContext';
import { AccountSwitchRequiredModalTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import './AccountSwitchRequiredModal.css';

interface AccountSwitchRequiredModalProps {
    isOpen: boolean;
    closeModal: () => void;
    switchToAccountId?: number;
    onAccountSwitch: () => void;
}

export const AccountSwitchRequiredModal = (props: AccountSwitchRequiredModalProps) => {

    const { isOpen, closeModal, switchToAccountId, onAccountSwitch } = props;

    const { t } = useSafeTranslation(TranslationFiles.AccountSwitchRequiredModal);
    const { authUser, hasAuthUser, userAccounts, hasUserAccounts } = useAuthContext();

    const [activeAccount, setActiveAccount] = useState<AuthAccountDTO>();
    const [switchToAccount, setSwitchToAccount] = useState<AuthAccountDTO>();

    const onClickClose = () => {
        closeModal();
    };

    const ctas: EfcCallToActionInfo[] = [{ text: t(AccountSwitchRequiredModalTKeys.CtaText), emphasis: 'med', color: 'primary', onClick: onClickClose }];

    useEffect(() => {
        if (!!hasAuthUser && !!hasUserAccounts) {
            setActiveAccount((userAccounts as any)?.get(authUser?.accountID));
        }
    }, [hasAuthUser, hasUserAccounts]);

    useEffect(() => {
        if (!!hasUserAccounts && !!switchToAccountId) {
            setSwitchToAccount((userAccounts as any)?.get(switchToAccountId));
        }
    }, [hasUserAccounts, switchToAccountId]);

    return (
        <>
            {!!activeAccount && !!switchToAccount ?
                <Modal title={t(AccountSwitchRequiredModalTKeys.CtaTitle)} toggle={closeModal} size='lg' isOpen={isOpen} ctas={ctas} >
                    <Modal.Body>
                        <Text>{t(AccountSwitchRequiredModalTKeys.Text, { activeAccountName: activeAccount.accountName, switchAccountName: switchToAccount.accountName})}</Text>
                        <Row className='account-tile-row'>
                            <Col>
                                <Text muted>{t(AccountSwitchRequiredModalTKeys.ActiveAccount)}</Text>
                                <AccountTile account={activeAccount} />
                            </Col>
                            <Col>
                                <Text muted>{t(AccountSwitchRequiredModalTKeys.AdditionalAccounts)}</Text>
                                <AccountTile account={switchToAccount} onAccountSwitch={onAccountSwitch} switchTo />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                :
                <Modal title={t(AccountSwitchRequiredModalTKeys.NoAccessTitle)} toggle={closeModal} size='lg' isOpen={isOpen} ctas={ctas} >
                    <Modal.Body>
                        <Text>{t(AccountSwitchRequiredModalTKeys.NoAccessText, { activeAccountName: activeAccount?.accountName })}</Text>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};
