import React, { useEffect } from 'react';
import { useUserApi } from '../../../api/user/useUserApi';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import { SidesheetOnboardingModalLg } from './SidesheetOnboardingModalLg';
import { SidesheetOnboardingModalSm } from './SidesheetOnboardingModalSm';

interface SidesheetOnboardingModalProps {
    isOpen: boolean;
    destroyModal: () => void;
}

export const SidesheetOnboardingModal = ({ isOpen, destroyModal }: SidesheetOnboardingModalProps) => {

    const { authUser, hasAuthUser, hasSeenNodeSideSheetOnBoardingModalState } = useAuthContext();
    const setHasSeenNodeSideSheetOnBoardingModal = hasSeenNodeSideSheetOnBoardingModalState[1];
    const { updateHasSeenNodeSideSheetOnBoardingModal } = useUserApi();
    const { screenSize } = useBootstrapBreakpoints();

    useEffect(() => {
        if (!!hasAuthUser && !!authUser?.accountID) {
            setHasSeenNodeSideSheetOnBoardingModal(true);
            updateHasSeenNodeSideSheetOnBoardingModal(authUser.userID);
        }
    }, [hasAuthUser]);

    return (
        <>
            {screenSize >= ScreenSize.xl
                ? <SidesheetOnboardingModalLg isOpen={isOpen} destroyModal={destroyModal} />
                : <SidesheetOnboardingModalSm isOpen={isOpen} destroyModal={destroyModal} />
            }
        </>
    );
};
