import { EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface IToDo {
    id: string;
    icon: FontAwesomeIconProps;
    text: string;
    from: string;
    cta: EfcCallToActionInfo;
    createdDate?: string;
    completedDate?: string;
    dismissedDate?: string;
    sourceType: SourceTypeEnum;
}

export enum SourceTypeEnum {
    Atlantis = 0,
    Utopia = 1,
}