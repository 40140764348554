import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../hooks/useColors';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import './AnimatedCollapseIcon.css';

interface AnimatedCollapseIconProps {
    isOpen: boolean;
    size?: SizeProp;
    color?: string;
}

export const AnimatedCollapseIcon = (props: AnimatedCollapseIconProps) => {
    const { isOpen, size, color } = props;

    const { atlantisPrimary } = useColors();

    const [rotationClass, setRotationClass] = useState('right');

    const toggleRotation = () => {
        isOpen ? setRotationClass('down') : setRotationClass('right');
    };

    useEffect(() => {
        toggleRotation();
    }, [isOpen]);

    return (
        <FontAwesomeIcon icon='angle-right' size={size} className={`me-2 collapse-icon collapse-icon-${rotationClass}`} color={!!color ? color : atlantisPrimary} />
    );
};
