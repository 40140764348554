import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import { Button, Text } from '@efilecabinet/efc-atlantis-components';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { ConfirmationModal } from '../../../../features/__shared/_modals/ConfirmationModal/ConfirmationModal';
import { ConfirmDeleteNodeProfileModal, DeleteOptions } from './ConfirmDeleteNodeProfileModal/ConfirmDeleteNodeProfileModal';
import { EditOptions } from '../../../EditOptions/EditOptions';
import { ProfileSideSheetZeroState } from './ProfileSideSheetZeroState/ProfileSideSheetZeroState';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useNodeProfileApi } from '../../../../api/nodeProfiles/useNodeProfileApi';
import { useNodeDetailsContext } from '../../_context/NodeDetailsContext/NodeDetailsContext';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { NodeProfileItemForm } from './Forms/NodeProfileItemForm/NodeProfileItemForm';
import { NodeProfileForm } from './Forms/NodeProfileForm/NodeProfileForm';
import { ProfileSideSheetSkeletonLoader } from './ProfileSideSheetSkeletonLoader/ProfileSideSheetSkeletonLoader';
import { NodeProfileFields } from './Fields/NodeProfileFields/NodeProfileFields';
import { NodeProfileItemDisplay } from './NodeProfileDisplay/NodeProfileItemDisplay';
import { useProfileUtilities } from '../../../../hooks/useProfileUtilities';
import { AssociatedNodeProfiles } from './AssociatedNodeProfiles/AssociatedNodeProfiles';
import { NodeProfileDTO } from '../../../../api/nodeProfiles/nodeProfileApiTypes';
import { ProfileItemDTO, ProfileItemTypeEnum } from '../../../../api/profileItems/profileItemApiTypes';
import './ProfileSideSheetPage.css';

interface ProfileSideSheetPageProps {
    onClose?: () => void;
    nodeId: string;
}

export const ProfileSideSheetPage = ({ onClose, nodeId }: ProfileSideSheetPageProps) => {

    const { activeNode } = useNodeDetailsContext();
    const accountId = activeNode?.accountID as number;

    const { openModal } = useLayerContext();
    const { comparePositionFn, createBlankNodeProfile, hasAdditionalProfileItems, hasAppliedProfile, hasProfileData } = useProfileUtilities();
    const { getAllAsync, getUnmaskedProfileItemValueAsync, updateNodeProfileAsync } = useNodeProfileApi();
    const { t } = useSafeTranslation(TranslationFiles.Metadata);

    const [appliedNodeProfile, setAppliedNodeProfile] = useState<NodeProfileDTO>(createBlankNodeProfile(nodeId, accountId));
    const [associatedNodeProfiles, setAssociatedNodeProfiles] = useState<NodeProfileDTO[]>([]);
    const [editingAdditionalProfileItemId, setEditingAdditionalProfileItemId] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAddingProfile, setIsAddingProfile] = useState(false);
    const [isAddingProfileItem, setIsAddingProfileItem] = useState(false);
    const [isEditingProfile, setIsEditingProfile] = useState<boolean>(false);

    const confirmProfileDelete = () => {
        openModal((closeModalFn) =>
            <ConfirmDeleteNodeProfileModal
                closeModalFn={closeModalFn}
                onClose={onConfirmedDeleteProfile}
            />
        );
    };

    const onConfirmedDeleteProfile = async (hasConfirmed: boolean, deleteSelection?: DeleteOptions) => {
        if (hasConfirmed) {
            saveNodeProfile({
                ...appliedNodeProfile,
                profileName: '',
                profileID: undefined,
                profileItems: deleteSelection === DeleteOptions.DeleteAll
                    ? appliedNodeProfile.profileItems.filter(x => !x.profileID).map(x => ({ ...x }))
                    : appliedNodeProfile.profileItems.map(x => ({ ...x, profileID: undefined })),
            });
        }
    };

    const confirmProfileItemDelete = (profileItemId: number) => {
        openModal((closeModalFn) =>
            <ConfirmationModal
                closeModalFn={closeModalFn}
                onClose={(isConfirmed) => onConfirmedDeleteProfileItem(isConfirmed, profileItemId)}
                titleText={t(MetadataTKeys.ConfirmDeleteProfileItemTitle)}
                bodyText={t(MetadataTKeys.ConfirmDeleteText)}
                confirmCtaText={t(MetadataTKeys.ConfirmDeleteBtnText)}
                confirmCtaColor='danger'
                denyCtaText={t(MetadataTKeys.CancelButton)}
            />
        );
    };

    const onConfirmedDeleteProfileItem = async (hasConfirmed: boolean, profileItemId: number) => {
        if (hasConfirmed) {
            saveNodeProfile({
                ...appliedNodeProfile,
                profileItems: appliedNodeProfile.profileItems.filter(x => x.id != profileItemId).map(x => ({ ...x })),
            });
        }
    };

    const saveProfileItemAsync = async (profileItemToSave: ProfileItemDTO) => {

        setIsAddingProfileItem(false);

        const unmaskHiddenTextPromises: Promise<void>[] = [];

        const updatedNodeProfile = {
            ...appliedNodeProfile,
            profileItems: appliedNodeProfile.profileItems.map(existingProfileItem => {
                if (existingProfileItem.id === profileItemToSave.id) {
                    return { ...profileItemToSave };
                } else if (existingProfileItem.type == ProfileItemTypeEnum.HiddenText) { // need to unmask
                    unmaskHiddenTextPromises.push(getUnmaskedProfileItemValueAsync(nodeId, existingProfileItem.id).then(response => {
                        _.find(updatedNodeProfile.profileItems, { id: existingProfileItem.id })!.value = response.value;
                    }));
                    return { ...existingProfileItem };
                } else {
                    return { ...existingProfileItem };
                }
            }),
        };

        await Promise.all(unmaskHiddenTextPromises);

        if (!updatedNodeProfile.profileItems.some(x => x.id == profileItemToSave.id)) {
            updatedNodeProfile.profileItems.push(profileItemToSave);
        }

        saveNodeProfile(updatedNodeProfile);
    };

    function saveNodeProfile(nodeProfile: NodeProfileDTO) {
        setIsLoading(true);
        updateNodeProfileAsync(nodeProfile).then(nodeProfile => {
            setAppliedNodeProfile(nodeProfile);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const loadNodeProfiles = async () => {
        try {
            setIsLoading(true);

            const allNodeProfiles = await getAllAsync(parseInt(nodeId as string));

            if (!!allNodeProfiles.length && _.last(allNodeProfiles)?.nodeID?.toString() == nodeId) {
                const appliedNodeProfile = allNodeProfiles.pop() as NodeProfileDTO;
                setAppliedNodeProfile(appliedNodeProfile);
            } else {
                const blankNodeProfile = createBlankNodeProfile(nodeId, accountId);
                setAppliedNodeProfile(blankNodeProfile);
            }

            setAssociatedNodeProfiles(allNodeProfiles);

        } finally {
            setIsLoading(false);
        }
    };

    const someFormIsActive = !!editingAdditionalProfileItemId || !!isEditingProfile || !!isAddingProfile || !!isAddingProfileItem;

    useEffect(() => {

        setIsAddingProfile(false);
        setIsAddingProfileItem(false);
        setIsEditingProfile(false);

        loadNodeProfiles();

    }, [nodeId]);

    return (
        <SidesheetPage title={t(MetadataTKeys.ProfileTitle)} onClose={onClose} dataId='metadata-sideSheet-pane'>

            {!isLoading && !someFormIsActive && !!activeNode?.permission.write && (
                <Row className='pb-4 g-0'>
                    <Col>
                        <Button className='add-profile-button' color='primary' emphasis='med' onClick={() => { setIsAddingProfile(true); }} disabled={!!appliedNodeProfile.profileID || !!isLoading}>
                            {t(MetadataTKeys.AddProfileText)}
                        </Button>
                        <Button color='primary' emphasis='med' onClick={() => { setIsAddingProfileItem(true); }} disabled={!!isLoading}>{t(MetadataTKeys.AddProfileItemText)}</Button>
                    </Col>
                </Row>
            )}

            {!!isAddingProfile && (
                <NodeProfileForm
                    accountId={accountId as number}
                    nodeId={nodeId}
                    onCanceled={() => { setIsAddingProfile(false); }}
                    onSaved={(newNodeProfile) => { saveNodeProfile(newNodeProfile); setIsAddingProfile(false); }}
                    originalNodeProfile={appliedNodeProfile} />
            )}

            {!!isAddingProfileItem && (
                <NodeProfileItemForm
                    accountId={accountId as number}
                    disabledProfileItems={appliedNodeProfile.profileItems}
                    nodeId={nodeId}
                    onCanceled={() => { setIsAddingProfileItem(false); }}
                    onSaved={(newProfileItem) => saveProfileItemAsync(newProfileItem)} />
            )}

            {!!isLoading && (
                <ProfileSideSheetSkeletonLoader />
            )}

            {!someFormIsActive && !isLoading && !hasProfileData(appliedNodeProfile) && (
                <ProfileSideSheetZeroState />
            )}

            {!isLoading && (!!hasProfileData(appliedNodeProfile) || !!associatedNodeProfiles.length) && (
                <>
                    {!!isEditingProfile && (
                        <NodeProfileForm
                            accountId={accountId as number}
                            nodeId={nodeId}
                            onCanceled={() => { setIsEditingProfile(false); }}
                            onSaved={(updatedNodeProfile) => { setAppliedNodeProfile(updatedNodeProfile); setIsEditingProfile(false); }}
                            originalNodeProfile={appliedNodeProfile} />
                    )}

                    {!isEditingProfile && !!hasAppliedProfile(appliedNodeProfile) && (
                        <>
                            <Row>
                                <div className='d-flex'>
                                    <Text size='lg' className='d-flex align-items-center pb-2 pe-4'>{appliedNodeProfile.profileName}</Text>
                                    {!someFormIsActive && (
                                        <EditOptions
                                            className='pb-2'
                                            onEditClick={() => { setIsEditingProfile((prevState) => !prevState); }}
                                            options={[{ onClick: confirmProfileDelete, text: t(MetadataTKeys.Delete) }]} />
                                    )}

                                </div>
                            </Row>
                            <NodeProfileFields>
                                {appliedNodeProfile?.profileItems.filter(x => !!x.profileID).sort(comparePositionFn).map(nodeProfileItem => (
                                    <NodeProfileItemDisplay key={nodeProfileItem.id}
                                        hasWritePermission={!!activeNode?.permission.write}
                                        onDeleteClick={() => { /* do nothing, canEdit is false */ }}
                                        onEditClick={() => { /* do nothing, canEdit is false */ }}
                                        nodeId={nodeId}
                                        nodeProfileItem={nodeProfileItem}
                                        showEditOptions={false} />
                                ))}
                            </NodeProfileFields>
                        </>
                    )}

                    {!!hasAdditionalProfileItems(appliedNodeProfile) && (
                        <Row className='g-0'>
                            <Text size='lg' className='pt-3 pb-3'>{t(MetadataTKeys.AdditionalProfileItems)}</Text>
                            {appliedNodeProfile?.profileItems.filter(x => !x.profileID).map(nodeProfileItem => editingAdditionalProfileItemId == nodeProfileItem.id
                                ? (
                                    <NodeProfileItemForm
                                        accountId={accountId}
                                        nodeId={nodeId}
                                        onCanceled={() => setEditingAdditionalProfileItemId(undefined)}
                                        onSaved={(profileItem) => { saveProfileItemAsync(profileItem); setEditingAdditionalProfileItemId(undefined); }}
                                        originalNodeProfileItem={nodeProfileItem} />
                                )
                                : (
                                    <NodeProfileItemDisplay key={nodeProfileItem.id}
                                        hasWritePermission={!!activeNode?.permission.write}
                                        onDeleteClick={confirmProfileItemDelete}
                                        onEditClick={() => { setEditingAdditionalProfileItemId(nodeProfileItem.id); }}
                                        nodeId={nodeId}
                                        nodeProfileItem={nodeProfileItem}
                                        showEditOptions={!!activeNode?.permission.write && !someFormIsActive} />
                                )
                            )}
                        </Row>
                    )}

                    {!!associatedNodeProfiles.length && (
                        <AssociatedNodeProfiles associatedNodeProfiles={associatedNodeProfiles} nodeId={activeNode?.id.toString() ?? ''} />
                    )}
                </>
            )}
            <div className='pb-3'></div>
        </SidesheetPage>
    );
};
