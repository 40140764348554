import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NotificationOrderEnum, NotificationStatusEnum, UtopiaNotificationDTO, UtopiaTaskCountDTO } from './notificationApiTypes';

export const useNotificationsApi = () => {

    const getUserNotifications = async (notificationParams: { notificationStatuses: NotificationStatusEnum[], orderBy: NotificationOrderEnum, start: number, count: number, }): Promise<UtopiaNotificationDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/Notification?${notificationParams.notificationStatuses.map((notificationStatus) => `notificationStatuses=${notificationStatus}&`).join('')}orderBy=${notificationParams.orderBy}&start=${notificationParams.start}&count=${notificationParams.count}`);
        return resp.data;
    };

    const getUserNotificationsCount = async (notificationStatuses: NotificationStatusEnum[]): Promise<UtopiaTaskCountDTO> => {
        const resp: AxiosResponse = await Api.get(`api/Notification/Count?${notificationStatuses.map((notificationStatus) => `notificationStatuses=${notificationStatus}&`).join('')}`);
        return resp.data;
    };

    return {
        getUserNotifications,
        getUserNotificationsCount,
    };
};