import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Dropdown, EfcFeedItemInfo, Feed, useDropdown, Button } from '@efilecabinet/efc-atlantis-components';
import { NotificationMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useUtopiaNotificationsConverter } from '../../../../hooks/useUtopiaNotificationsConverter';
import { useBootstrapBreakpoints } from '../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../hooks/bootstrap/bootstrapTypes';
import { useNotificationsApi } from '../../../../api/notifications/useNotificationsApi';
import { NotificationStatusEnum, NotificationOrderEnum, UtopiaNotificationDTO } from '../../../../api/notifications/notificationApiTypes';
import './NotificationMenuItem.css';

export const NotificationMenuItem = () => {
    const [notificationsFeedItems, setNotificationsFeedItems] = useState<EfcFeedItemInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const { t } = useSafeTranslation(TranslationFiles.NotificationMenuItem);
    const { getUserNotifications } = useNotificationsApi();
    const { toFeedItem, eSignatureModal } = useUtopiaNotificationsConverter();
    const { toggleDropdown, dropdownIsOpen } = useDropdown();
    const { screenSize } = useBootstrapBreakpoints();

    const MAX_ROWS_TO_DISPLAY = 150;
    const INITIAL_PULL_COUNT = 10;
    const SKELETON_COUNT = 10;

    const utopiaNotificationsQueryParams = {
        notificationStatuses: [NotificationStatusEnum.Active],
        orderBy: NotificationOrderEnum.QueuedOnDesc,
        start: 0,
        count: INITIAL_PULL_COUNT,
    };

    const addNotifications = (moreNotifications: UtopiaNotificationDTO[]) => {
        const moreFeedItems = moreNotifications.map((notification: UtopiaNotificationDTO) => toFeedItem(notification));
        setNotificationsFeedItems((prevState) => [...prevState, ...moreFeedItems]);
    };

    const getNotifications = () => {
        setIsLoading(true);
        setErrorLoading(false);
        getUserNotifications(utopiaNotificationsQueryParams)
            .then((notifications: UtopiaNotificationDTO[]) => {
                setNotificationsFeedItems(notifications.map((notification) => toFeedItem(notification)));

                const rowsLeft = MAX_ROWS_TO_DISPLAY - INITIAL_PULL_COUNT;
                if (rowsLeft > 0) {
                    getUserNotifications({ ...utopiaNotificationsQueryParams, start: INITIAL_PULL_COUNT, count: rowsLeft }).then((moreNotifications) => {
                        addNotifications(moreNotifications);
                    });
                }
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (dropdownIsOpen) {
            getNotifications();
        }
    }, [dropdownIsOpen]);

    return (
        <>
            <Dropdown toggle={toggleDropdown} isOpen={dropdownIsOpen}>
                <Dropdown.Toggle className='nav-link d-flex align-items-center' tag='a'>
                    <FontAwesomeIcon icon='bell' className='mx-2' size='lg' title={t(NotificationMenuItemTKeys.NotificationTitle)} color={screenSize < ScreenSize.s ? 'white' : 'dark'} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={screenSize > ScreenSize.xs ? 'notifications-navmenu' : 'notifications-navmenu-mobile'}>
                    <div className='notifications-navmenu-div'>
                        <Card fluid borderless>
                            <Card.Body>
                                {screenSize < ScreenSize.s && (
                                    <div className='exit-button-mobile-notifications'>
                                        <Button close onClick={toggleDropdown} />
                                    </div>
                                )}

                                <Card.Title tag={Link} className='ms-1 no-decoration' size='lg' bold icon={{ icon: 'bell', pull: 'left', size: 'lg' }}>
                                    {t(NotificationMenuItemTKeys.NotificationTitle)}
                                </Card.Title>

                                {!!isLoading || !!errorLoading || notificationsFeedItems.length == 0 ? (
                                    <>
                                        {!!isLoading && (
                                            <SkeletonTheme enableAnimation height='24px' width='100%'>
                                                <Skeleton count={SKELETON_COUNT} className='mb-3 mx-auto' />
                                            </SkeletonTheme>
                                        )}
                                        {!!errorLoading && <Card.Text>{t(NotificationMenuItemTKeys.ErrorLoading)}</Card.Text>}
                                        {notificationsFeedItems.length == 0 && !errorLoading && !isLoading && (
                                            <Card.Text className='empty-notifications-navmenu-text'>
                                                {t(NotificationMenuItemTKeys.NoItems1)}
                                                <br />
                                                {t(NotificationMenuItemTKeys.NoItems2)}
                                                <br />
                                                {t(NotificationMenuItemTKeys.NoItems3)}
                                            </Card.Text>
                                        )}
                                    </>
                                ) : (
                                    <Feed small feedItems={notificationsFeedItems} maxRowsToDisplay={MAX_ROWS_TO_DISPLAY} tag={Link} />
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            {eSignatureModal}
        </>
    );
};
