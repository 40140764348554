import React from 'react';
import { SurveyRepromptTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { Row, Col } from 'reactstrap';
import { Toast, Button } from '@efilecabinet/efc-atlantis-components';
import surveyImage from '../../../../images/surveyImage.png';
import './SurveyReprompt.css';

interface SurveyModalProps {
    isOpen: boolean;
    toggle: () => void;
}

export const SurveyReprompt = ({ isOpen, toggle }: SurveyModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.SurveyReprompt);

    const openSurvey = () => {
        window.open('https://www.surveymonkey.com/r/Modal', '_blank');
        toggle();
    };

    return (

        <Toast className='survey-reprompt-toast-wrapper' isOpen={isOpen}>
            <Toast.Header className='survey-reprompt-toast-header' close={<button type='button' className='btn-close survey-reprompt-close-button' aria-label='Close' onClick={toggle}></button>}>
                <img src={surveyImage} className='survey-reprompt-image' alt='Take survey' />
                <span className='survey-reprompt-header-text'>{t(SurveyRepromptTKeys.Title)}</span>
            </Toast.Header>
            <Toast.Body className='survey-reprompt-toast-body'>
                <Row>
                    <div className='mt-2'>
                        {t(SurveyRepromptTKeys.BodyTop)}
                    </div>
                    <div className='mt-3'>
                        {t(SurveyRepromptTKeys.BodyBottom)}
                    </div>
                </Row>
                <Row className='mt-4 mb-3'>
                    <Col xs='8' />
                    <Col xs='4'>
                        <Button onClick={openSurvey} >
                            {t(SurveyRepromptTKeys.ButtonText)}
                        </Button>
                    </Col>
                </Row>
            </Toast.Body>
        </Toast>     
    );
};
