import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useUtopiaIFrameRoutes } from '../../../hooks/useUtopiaIFrameRoutes';
// import { ILinkedViewURLDetails } from '../../hooks/linkedView/linkedViewTypes';
import { IFrame } from '../../../components/IFrame/IFrame';
import { TextButton } from '@efilecabinet/nautilus-ui';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';

export const LinkedView = () => {
    const { linkedViewId, containerNodeId, detailsNodeIds, selectedDetail } = useParams();
    
    const location = useLocation();
    const iframe = useUtopiaIFrame();
    const { IFrameLinkedViewRoutes } = useUtopiaIFrameRoutes();

    //TODO: Wire up to get iframe URL
    return (
        <div className='h-50'>
            <TextButton onClick={() => console.log("Stuff Happens")} buttonType={'textButton'} label={'Blah Blah'} buttonStyle={'btn-primary'}>Just to see something</TextButton>
            {/* <IFrame name='linkedViewIframe' className='h-100 m-0' src={iframe.url} dataId='linkedViewIframe' /> */}
        </div>
    );
};

export default LinkedView;