import React from 'react';

export const useNodeSidesheetUtilities = () => {

    const SIDESHEET_HELP_ARTICLE_URL = 'https://support.revverdocs.com/en/articles/9940228-document-actions-section';

    function goToSidesheetHelpArticle() {
        window.open(SIDESHEET_HELP_ARTICLE_URL, '_blank');
    }
    
    return {
        goToSidesheetHelpArticle,
    };
};