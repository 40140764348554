import React from 'react';
import { Container, Row } from 'reactstrap';
import { NodeProfileItemField } from '../Fields/NodeProfileItemField/NodeProfileItemField';
import { NodeProfileItemValueDisplay } from './NodeProfileItemValueDisplay';
import { EditOptions } from '../../../../EditOptions/EditOptions';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { ProfileItemDTO } from '../../../../../api/profileItems/profileItemApiTypes';

interface NodeProfileItemDisplayProps {
    hasWritePermission: boolean;
    onDeleteClick: (profileItemId: number) => void;
    nodeId: string;
    nodeProfileItem: ProfileItemDTO;
    onEditClick: () => void;
    showEditOptions: boolean;
}

export const NodeProfileItemDisplay = ({ hasWritePermission, onDeleteClick, onEditClick, nodeId, nodeProfileItem, showEditOptions }: NodeProfileItemDisplayProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Metadata);

    return (
        <Container className='mb-3'>
            <Row className='g-0'>
                <div className='d-flex'>
                    <NodeProfileItemField nodeProfileItem={nodeProfileItem}>
                        {!!showEditOptions &&
                            <EditOptions
                                className='pb-2'
                                onEditClick={onEditClick}
                                options={[{ onClick: () => { onDeleteClick(nodeProfileItem.id); }, text: t(MetadataTKeys.Delete) }]} />
                        }
                    </NodeProfileItemField>
                </div>
            </Row>
            <Row className='g-0'>
                <NodeProfileItemValueDisplay canViewHiddenText={hasWritePermission} nodeId={nodeId} nodeProfileItem={nodeProfileItem} />
            </Row>
        </Container>
    );
};