import { AccountFeatureEnum } from "../accountFeatures/accountFeatureApiTypes";
import { OrderEnum } from "../sharedApiTypes";

export interface AccountRoleCountsDTO {
    userCount: number;
    guestCount: number;
    accountGroupData: AccountGroupDataDTO[];
}

export interface AccountGroupDataDTO {
    groupId: number;
    memberCount: number;
}

export interface RoleDTO {
    id: number;
    accountID: number;
    userID?: number;
    profileID?: number;
    roleType: RoleEnum;
    userName: string;
    name: string;
    license?: UserLicenseEnum;
    systemPermissions: SystemPermissionsDTO;
    memberOfRoles: RoleDTO[];
    roleMembers: RoleDTO[];
    securityPolicyID?: number;
    roleManagerID?: number;
    anonymous: boolean;
    includeInSalesforceMapping: boolean;
    ldapID: number;
}

export enum RoleEnum {
    Guest = 1, // OBSOLETE
    User = 2,
    Group = 3,
}

export enum UserLicenseEnum {
    Full = AccountFeatureEnum.FullUserLicense,
    Guest = AccountFeatureEnum.GuestUserLicense,
    Essentials = AccountFeatureEnum.EssentialsUserLicense,
    Compliance = AccountFeatureEnum.ComplianceUserLicense,
    Unassigned = -1
}

export interface SystemPermissionsDTO {
    permissionsList: SystemPermissionDTO[];
    accountID: number;
}

export interface SystemPermissionDTO {
    name: string;
    description: string;
    systemPermissionType: SystemPermissionEnum;
}

export enum SystemPermissionEnum {
    ItemPermissionsControl = 0,
    ItemPermissionsOverride = 1,
    SystemSettings = 2,
    SalesforceManagement = 3,
    AuditLogManagement = 4,
    ViewAuditLogs = 5,
    PersonalProviders = 6,
    DocumentRequests = 7,
    DocumentRequestManagement = 8,
    FormFill = 9,
    ESignature = 10,
    ESignatureManagement = 11,
    Profiles = 12,
    ProfileManagement = 13,
    FileManagement = 14,
    TemplateManagement = 15,
    FileVersioning = 16,
    FilePassword = 17,
    Search = 18,
    CheckInOut = 19,
    PreviewerTools = 20,
    CreateModifyStructure = 21,
    Governance = 22,
    GovernanceAdmin = 23,
    Sharing = 24,
    AccessLinks = 25,
    AccessLinkManagement = 26,
    UserManagement = 27,
    SessionManagement = 28,
    GroupManagement = 29,
    Workflow = 30,
    WorkflowApproval = 31,
    WorkflowManagement = 32,
    Automation = 33,
    RubexReports = 34,
    EnterpriseSecurityManagement = 35,
}