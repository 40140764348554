import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { AccountFeatureUsageDTO } from './accountFeatureUsageApiTypes';

export const useAccountFeatureUsageApi = () => {

    const getAccountFeatureUsageByAccountId = async (accountId:number): Promise<AccountFeatureUsageDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/accountFeatureUsage/${accountId}`);
        return resp.data;
    };

    return {
        getAccountFeatureUsageByAccountId,
    };
};
