import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Text } from '@efilecabinet/efc-atlantis-components';
import { useRouting } from '../../features/_routing';
import { useAuthContext } from '../../app/_context/AuthContext';
import { useRouteValidator } from '../../hooks/useRouteValidator';
import { useColors } from '../../hooks/useColors';
import { AccountTileTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import { AuthAccountDTO } from '../../api/auth/authApiTypes';
import { RoutePath } from '../../features/_routing/routingTypes';
import { DuoLetterIcon } from '../Icons/DuoLetterIcon';
import './AccountTile.css';

interface AccountTileProps {
    account: AuthAccountDTO;
    switchTo?: boolean;
    onAccountSwitch?: () => void;
}

export const AccountTile = (props: AccountTileProps) => {
    const { account, switchTo, onAccountSwitch } = props;

    const { t } = useSafeTranslation(TranslationFiles.AccountTile);
    const { handleSwitchAccounts } = useAuthContext();
    const { canGoToRoute } = useRouteValidator();
    const colors = useColors();
    const duotoneCircleOpacity = 1;
    const { getLinkToAccountSettings } = useRouting();

    const handleAccountClick = (event: React.MouseEvent<Element>) => {
        if (event.detail === 2) {
            console.log('AccountId: ', account.accountID, account.identifier);
        }
    };

    const handleSwitchAccount = () => {
        if (!!account) {
            handleSwitchAccounts(account.roleID, onAccountSwitch);
        }
    };

    const getCurrentAccountLetter = ():string => {
        return account?.accountName.charAt(0).toLowerCase();
    };

    return (
        <Card fluid borderless body className='account-tile my-3 pe-5'>
            <Card.Title onClick={handleAccountClick}>
                <DuoLetterIcon letter={getCurrentAccountLetter()} primaryColor='white' secondaryColor={colors.atlantisBrandPurple} secondaryOpacity={duotoneCircleOpacity} size='2x' />
                <Text className='pe-2 mt-0.9' bold>
                    {account?.accountName}
                </Text>
            </Card.Title>
            {!!switchTo ? (
                <Card.Link className='ms-2 my-2' onClick={handleSwitchAccount} icon={{ icon: ['far', 'arrow-right'], size: 'lg' }}>
                    {t(AccountTileTKeys.SwitchToThisAccount)}
                </Card.Link>
            ) : (
                canGoToRoute(RoutePath.AccountSettings) && (
                    <Card.Link className='ms-2 my-2' tag={Link} to={getLinkToAccountSettings()} icon={{ icon: 'gear', size: 'lg' }}>
                        {t(AccountTileTKeys.AccountSettings)}
                    </Card.Link>
                )
            )}
        </Card>
    );
};
