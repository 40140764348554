import { NodeDTO } from "../node/nodeApiTypes";

export interface NodeRecentDTO extends NodeDTO {
    auditLogDate: string;
}

export enum RecentNodeTypeEnum {
    Uploads = 0,
    Downloads = 1,
    All = 2,
}