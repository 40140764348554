import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useToDoListRouting = () => {

    const navigate = useNavigate();

    // To-do List Index

    const getLinkToToDoListIndex = () => {
        return RoutePath.ToDoListIndex;
    };

    const routeToToDoListIndex = () => {
        navigate(getLinkToToDoListIndex());
    };

    // Open To-dos

    const getLinkToOpenToDos = () => {
        return RoutePath.OpenToDos;
    };

    const routeToOpenToDos = () => {
        navigate(getLinkToOpenToDos());
    };

    // Completed

    const getLinkToCompletedToDos = () => {
        return RoutePath.CompletedToDos;
    };

    const routeToCompletedToDos = () => {
        navigate(getLinkToCompletedToDos());
    };

    // Dismissed

    const getLinkToDismissedToDos = () => {
        return RoutePath.DismissedToDos;
    };

    const routeToDismissedToDos = () => {
        navigate(getLinkToDismissedToDos());
    };

    return {
        getLinkToToDoListIndex,
        getLinkToOpenToDos,
        getLinkToCompletedToDos,
        getLinkToDismissedToDos,
        routeToToDoListIndex,
        routeToOpenToDos,
        routeToCompletedToDos,
        routeToDismissedToDos,
    };
};
