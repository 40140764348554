import { AxiosResponse } from 'axios';
import { AccessLinkDTO } from './accessLinksApiTypes';
import { Api } from '../axios';

export const useAccessLinksApi = () => {

    const getAccessLinksByNodeIdAsync = async (accountId: string, nodeId: string): Promise<AccessLinkDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/AccessLinks/${accountId}/${nodeId}`);
        return resp.data;
    };

    return {
        getAccessLinksByNodeIdAsync,
    };
};
