import i18n, { use, init } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

import { Api } from '../api/axios';
import { TranslationFiles } from '../hooks/useSafeTranslation';

// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
use(LanguageDetector);
use(initReactI18next);
use(
    resourcesToBackend((language, namespace, callback) => {
        import(`./${language}/${namespace}.json`)
            .then((resources) => {
                callback(null, resources);
            })
            .catch((error) => {
                callback(error, null);
            });
    })
);

// for all options read: https://www.i18next.com/overview/configuration-options
init({
    lng: 'en',
    fallbackLng: {
        'en-US': ['en'],
        default: ['en']
    },
    ns: Object.values(TranslationFiles),
    debug: false,
    saveMissing: true,
    saveMissingTo: 'all',
    saveMissingPlurals: true,
    missingKeyHandler: async (lngs, ns, key, fallbackValue, updateMissing, options) => {
        for (let lang of lngs) {
            if (lang == 'en') {
                let missingKeyReq = {
                    key,
                    namespace: ns,
                    lang,
                    fallbackValue,
                };

                try {
                    const resp = await Api.post('api/locale/AddMissingKey', missingKeyReq);
                    return resp.data;
                } catch (err) {
                    return err;
                }
            }
        }
    },

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
