import React, { ReactNode } from 'react';
import { Card } from '@efilecabinet/efc-atlantis-components';
import { Col, Row } from 'reactstrap';
import './SideSheet.css';

interface SideSheetProps {
    children?: [tabs: ReactNode, tabContent: ReactNode];
}

export const SideSheet = (props: SideSheetProps) => {
    const { children } = props;

    return (
        <Card id={'sidesheet'} body fluid className='h-100 py-0 ' style={{ paddingLeft: '.7rem', paddingRight: '.7rem' }}>
            <Row className='h-100'>
                <Col className='h-100 p-0' style={{ maxWidth: '2.5rem', fontSize: '.9rem', borderRight: '1px solid #ececec' }}>
                    { /* Tabs */ }
                    {children?.[0]}
                </Col>
                <Col className='px-0 h-100 sidesheet-pane-col'>
                    { /* Tab Content */ }
                    {children?.[1]}
                </Col>
            </Row>
        </Card>
    );
};
