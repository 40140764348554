import React, { useState } from 'react';
import { Tooltip } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { AccountRoleCountsDTO, RoleDTO, RoleEnum, UserLicenseEnum } from '../../../api/roles/roleApiTypes';
import './RoleChip.css';

export interface RoleChipProps {
    role: RoleDTO;
    accountRoleCounts: AccountRoleCountsDTO;
    removeRole: (role: RoleDTO) => void;
}

export const RoleChip = (props: RoleChipProps) => {
    const TRUNCATE_MAX_WIDTH = 16;

    const { role, accountRoleCounts, removeRole } = props;
    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const tooltipTarget = `role-name-chip-${role.id}`;
    const toggleToolTip = () => setIsToolTipOpen((prevState) => !prevState);

    const getRoleIcon = () => {
        if (role.roleType == RoleEnum.Group) {
            return (<FontAwesomeIcon icon='users' className='role-chip-icon' />);
        }
        else if (role.license == UserLicenseEnum.Guest) {
            return (
                <i className={'icon-efc-guest-user role-chip-icon role-chip-guest-icon'}></i>
            );
        }
        else {
            return (<FontAwesomeIcon icon='user' className='role-chip-icon' />);
        }
    };

    const truncateString = (str: string, isSmall: boolean) => {
        if (str.length > TRUNCATE_MAX_WIDTH) {
            return str.substring(0, TRUNCATE_MAX_WIDTH - 3) + '...';
        } else {
            return str;
        }
    };

    const getGroupCount = () => {
        let groupCount = '';

        if (role.roleType === RoleEnum.Group) {
            const filtered = accountRoleCounts.accountGroupData.filter(x => x.groupId == role.id);
            groupCount = ` (${filtered.length == 1 ? filtered[0].memberCount : 0})`;
        }

        return groupCount;
    };

    const getTileText = (str: string, isSmall: boolean) => {
        return truncateString(str, isSmall) + getGroupCount();
    };

    return (
        <div className='role-chip-wrapper' >
            {getRoleIcon()}
            <span id={tooltipTarget}>
                <Text size='md' >{getTileText(role.name, false)}</Text>
            </span>
            {!!role.name && role.name.length > TRUNCATE_MAX_WIDTH && (
                <Tooltip target={tooltipTarget} isOpen={isToolTipOpen} toggle={toggleToolTip} placement='bottom'>
                    {role.name}
                </Tooltip>
            )}
            <FontAwesomeIcon icon='xmark' className='role-chip-icon role-chip-close-icon' onClick={() => { removeRole(role); } } />
        </div>
    );
};