import { ESignatureExternalTypeEnum } from "../eSignatureTransactions/eSignatureTransactionApiTypes";
import { SystemActionEnum, SystemTypeEnum } from "../sharedApiTypes";

export interface UtopiaNotificationDTO {
    id: number;
    recipientRoleID: number;
    accountID: number;
    notificationType: UtopiaNotificationTypeEnum;
    actionType: SystemActionEnum;
    systemType: SystemTypeEnum;
    dateQueued: string;
    content: string;
    itemUrl?: string;
    requiresEmail: boolean;
    completedDate?: string;
    dismissedDate?: string;
    routeTo?: string;
}

export enum UtopiaNotificationTypeEnum {
    Alert = 0,
    Task = 1,
}

export enum NotificationStatusEnum {
    Active = 0,
    Dismissed = 1,
    Completed = 2,
}

export enum NotificationOrderEnum {
    QueuedOnAsc = 0,
    QueuedOnDesc = 1,
    DismissedOnAsc = 2,
    DismissedOnDesc = 3,
    CompletedOnAsc = 4,
    CompletedOnDesc = 5,
}

export interface UtopiaTaskCountDTO {
    count: number;
}

export interface IUtopiaAlert extends UtopiaNotificationDTO {
    supportText?: string;
}

export type UtopiaTaskNotificationDTO = UtopiaDocRequestTaskDTO | UtopiaESignatureTaskDTO | UtopiaWorkflowTaskDTO; 

export interface UtopiaWorkflowTaskDTO extends UtopiaNotificationDTO {
    workflowInstanceId: number;
    workflowStageInstanceId: number;
    workflowInstanceName: string;
}

export interface UtopiaDocRequestTaskDTO extends UtopiaNotificationDTO {
    documentRequestId: string;
    senderDisplayName: string;
    fileUploadCount: number;
    formFillCount: number;
}

export interface UtopiaESignatureTaskDTO extends UtopiaNotificationDTO {
    senderDisplayName: string;
    externalType: ESignatureExternalTypeEnum;
    transactionId: number;
}