import React, { useEffect } from 'react';
import { Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { RoutePath, UtilitiesRoutePath } from '../../_routing/routingTypes';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../_routing';
import { TranslationFiles, useSafeTranslation, UtilitiesTKeys } from '../../../hooks/useSafeTranslation';
import './UtilitiesPage.css';

enum Params {
    AccountId = ':accountId',
}

const IFrameRoutes: Map<UtilitiesRoutePath, string> = new Map([
    [RoutePath.UtilitiesIndex, `/admin/csvImport/import/${Params.AccountId}`],
    [RoutePath.CSVImport, `/admin/csvImport/import/${Params.AccountId}`],
    [RoutePath.CSVExport, '/admin/csvExport/'],
    [RoutePath.AccountTemplateExport, '/admin/accountTemplates/export'],
    [RoutePath.AccountTemplateImport, '/admin/accountTemplates/import'],
]);

export const UtilitiesPage = () => {

    const { routeToCSVImport, routeToCSVExport, routeToAccountTemplateExport, routeToAccountTemplateImport} = useRouting();
    const { t } = useSafeTranslation(TranslationFiles.Utilities);

    const { authUser } = useAuthContext();
    const { canGoToRoute } = useRouteValidator();

    const currentRoute = `${location.pathname}` as UtilitiesRoutePath;
    const iframe = useUtopiaIFrame(getIFrameUrl(currentRoute));

    function getIFrameUrl(route: UtilitiesRoutePath) {
        return IFrameRoutes.get(route)?.replace(Params.AccountId, authUser?.accountID?.toString() ?? '') ?? '';
    }

    useEffect(() => {
        if (currentRoute == RoutePath.UtilitiesIndex) {
            if (canGoToRoute(RoutePath.CSVImport)) {
                routeToCSVImport();
            } else if (canGoToRoute(RoutePath.CSVExport)) {
                routeToCSVExport();
            } else if (canGoToRoute(RoutePath.AccountTemplateExport)) {
                routeToAccountTemplateExport();
            } else if (canGoToRoute(RoutePath.AccountTemplateImport)) {
                routeToAccountTemplateImport();
            }
        } else {
            iframe.setUrl(getIFrameUrl(currentRoute));
        }
    }, [location.pathname]);

    return (
        <Page title={t(UtilitiesTKeys.UtilitiesTitle)} icon={{ icon: 'wrench' }}>

            {canGoToRoute(RoutePath.CSVImport) &&
                <Tab active={currentRoute === RoutePath.CSVImport} onClick={() => routeToCSVImport()}>
                    {t(UtilitiesTKeys.CSVImportTab)}
                </Tab>
            }
            {canGoToRoute(RoutePath.CSVExport) &&
                <Tab active={currentRoute === RoutePath.CSVExport} onClick={() => routeToCSVExport()}>
                    {t(UtilitiesTKeys.CSVExportTab)}
                </Tab>
            }           
            {canGoToRoute(RoutePath.AccountTemplateExport) &&
                <Tab active={currentRoute === RoutePath.AccountTemplateExport} onClick={() => routeToAccountTemplateExport()}>
                    {t(UtilitiesTKeys.AccountTemplateExportTab)}
                </Tab>
            }
            {canGoToRoute(RoutePath.AccountTemplateImport) &&
                <Tab active={currentRoute === RoutePath.AccountTemplateImport} onClick={() => routeToAccountTemplateImport()}>
                    {t(UtilitiesTKeys.AccountTemplateImportTab)}
                </Tab>
            }

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} />

            {/* Use the following Tab Panes once we make this page native */}

            {/*<Tab.Content activeTab={activeTab}>*/}
            {/*    <Tab.Pane tabId={0}>*/}
            {/*        Placeholder for Utilities*/}
            {/*    </Tab.Pane>*/}
            {/*    <Tab.Pane tabId={1}>*/}
            {/*        Placeholder for Other*/}
            {/*    </Tab.Pane>*/}
            {/*</Tab.Content>*/}
        </Page>
    );
};
