import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { DocumentRequestTypeCheckResponseDTO, UploadLinkResponseDTO } from './uploadLinkApiTypes';

export const useUploadLinkApi = () => {

    const createUploadLink = async (nodeId: string, subject: string, accountId: number): Promise<UploadLinkResponseDTO> => {
        const resp: AxiosResponse = await Api.post('api/UploadLink/Create', { nodeId, subject, accountId });
        return resp.data;
    };

    const getDocumentRequestType = async (documentRequestID: string): Promise<DocumentRequestTypeCheckResponseDTO> => {
        const resp: AxiosResponse = await Api.get(`api/UploadLink/CheckDocumentRequestType/${documentRequestID}`);
        return resp.data;
    };

    return {
        createUploadLink,
        getDocumentRequestType,
    };
};