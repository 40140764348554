import React, { useEffect, useState } from 'react';
import { Text, Toggle } from '@efilecabinet/efc-atlantis-components';
import { Spinner } from 'reactstrap';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import './FeatureToggles.css';

enum FeatureToggleType {
    SideSheet = 0,
    Metadata,
}

interface IFeatureToggles {
    showSideSheetToggle: boolean;
    showMetadataToggle: boolean;
}

export const FeatureToggles = ({ showSideSheetToggle, showMetadataToggle }: IFeatureToggles) => {
    const { optInInfo, getMetadataToggleStatus, handleNewSideSheetToggle, handleMetadataToggle } = useAuthContext();

    const [isSideSheetChecked, setIsSideSheetChecked] = useState(true);
    const [isMetadataChecked, setIsMetadataChecked] = useState(getMetadataToggleStatus());
    const [loading, setLoading] = useState(false);

    const toggleSideSheet = () => setIsSideSheetChecked((prevState) => !prevState);
    const toggleMetadata = () => setIsMetadataChecked((prevState) => !prevState);

    async function handleToggleClick(toggleType: FeatureToggleType) {
        setLoading(true);
        if (toggleType === FeatureToggleType.SideSheet) {

            try {
                const toggleValue = !isSideSheetChecked;
                toggleSideSheet();
                await handleNewSideSheetToggle(toggleValue);
            } catch (error) {
                console.error('error toggling side sheet', error);
            } finally {
                setLoading(false);
            }
        }
        if (toggleType === FeatureToggleType.Metadata) {
            try {
                const toggleValue = !isMetadataChecked;
                toggleMetadata();
                handleMetadataToggle(toggleValue);
            } catch (error) {
                console.error('error toggling metadata', error);
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (!!optInInfo) {
            optInInfo.optInAtlantisSideSheet ? setIsSideSheetChecked(true) : setIsSideSheetChecked(false);
        }
    }, [optInInfo]);

    return (
        <div className='d-flex justify-content-end toggle-row'>
            {showSideSheetToggle &&
                <span className='d-flex align-items-center justify-content-end'>
                    <Spinner size='sm' color='primary' className='me-2' hidden={!loading} />
                    <Text className='me-2'>New Side Sheet</Text>
                    <Toggle isChecked={isSideSheetChecked} onClick={() => { handleToggleClick(FeatureToggleType.SideSheet); }} className='me-3 opt-out-toggle' />
                </span>
            }

            {showMetadataToggle && <span className='d-flex align-items-center justify-content-end'>
                <Spinner size='sm' color='primary' className='me-2' hidden={!loading} />
                <Text className='me-2'>Use Metadata</Text>
                <Toggle isChecked={isMetadataChecked} onClick={() => { handleToggleClick(FeatureToggleType.Metadata); }} className='me-3 opt-out-toggle' />
            </span>}
        </div>
    );
};

