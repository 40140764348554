import React from 'react';
import { Card } from '@efilecabinet/efc-atlantis-components';
import { AccountsNavMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../../app/_context/AuthContext';

interface ILogoutNavMenuItemProps {
    showAnonymousElementsOnly?: boolean;
}

export const LogoutNavMenuItem = (props: ILogoutNavMenuItemProps) => {
    const { showAnonymousElementsOnly } = props;

    const { t } = useSafeTranslation(TranslationFiles.AccountsNavMenuItem);
    const { handleLogout } = useAuthContext();

    const logout = () => {
        handleLogout();
    };

    return (
        <>
            <Card.Link className={'ms-2' + (!!showAnonymousElementsOnly ? ' me-3' : ' mt-4')} onClick={logout} icon={{ icon: ['far', 'arrow-left'], size: 'lg' }}>
                {t(AccountsNavMenuItemTKeys.Logout)}
            </Card.Link>
        </>
    );
};
