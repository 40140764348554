import React, { FC, ReactNode } from 'react';
import { SidesheetPage } from '../../../../../components/SideSheet/SidesheetPage';
import { MDDisplayProperty } from '../MdDisplayProperty/MdDisplayProperty';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useMDSideSheet } from '../../../../../hooks/useMDSideSheet';
import { MetadataFieldDto, MDFieldTypes, NumberFormatTypes } from '../../../../../api/mdFields/mdFieldApiTypes';

interface MDFieldSideSheetContentProps {
    mdField: MetadataFieldDto;
    onClose?: () => void;
}

export const MDFieldSideSheetContent: FC<MDFieldSideSheetContentProps> = ({ mdField, onClose }) => {

    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);

    const {
        getAdvancedFormattingForNumberProps,
        getAdvancedFormattingForTextProps,
        getAdvancedFormattingForCheckbox,
        styleTextFormat,
        styleDropdownListFormat,
        styleDateFormat,
        styleAddressFormat
    } = useMDSideSheet();

    function getMDFieldDisplay(): ReactNode {
        switch (mdField?.type) {
            case MDFieldTypes.Text:
                return (
                    <>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.Format)} value={[styleTextFormat(mdField)]}></MDDisplayProperty>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.AdvancedFormatting)} value={getAdvancedFormattingForTextProps(mdField)}></MDDisplayProperty>
                    </>
                );
            case MDFieldTypes.DropdownList:
                return (
                    <>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.Format)} value={[styleDropdownListFormat(mdField)]}></MDDisplayProperty>
                        {!!mdField.dropdownListProps?.allowNewOptions &&
                            < MDDisplayProperty label={t(MDSideSheetInformationTKeys.AdvancedFormatting)} value={[t(MDSideSheetInformationTKeys.AllowAddNewOptions)]}></MDDisplayProperty >
                        }
                        {!!mdField.dropdownListProps?.options &&
                            <MDDisplayProperty label={t(MDSideSheetInformationTKeys.Options)} value={mdField.dropdownListProps?.options.map((x) => x.value)}></MDDisplayProperty>
                        }
                    </>
                );
            case MDFieldTypes.Number:
                return (
                    <>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.Format)} value={[NumberFormatTypes[mdField.numberProps?.numberFormat ?? 0]]} />
                        {mdField.numberProps?.numberFormat === NumberFormatTypes.Number &&
                            < MDDisplayProperty label={t(MDSideSheetInformationTKeys.AdvancedFormatting)} value={getAdvancedFormattingForNumberProps(mdField)}></MDDisplayProperty >
                        }
                    </>
                );
            case MDFieldTypes.Date:
                return (
                    <>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.Format)} value={[styleDateFormat(mdField)]}></MDDisplayProperty>
                    </>
                );
            case MDFieldTypes.Checkbox:
                return (
                    <>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.AdvancedFormatting)} value={[getAdvancedFormattingForCheckbox(mdField)]}></MDDisplayProperty>
                    </>
                );
            case MDFieldTypes.Address:
                return (
                    <>
                        <MDDisplayProperty label={t(MDSideSheetInformationTKeys.Format)} value={styleAddressFormat(mdField)}></MDDisplayProperty>
                    </>
                );
            case MDFieldTypes.Email:
            case MDFieldTypes.PhoneNumber:
                return <></>;
            default: {
                const _exhaustiveCheck: never = mdField?.type;
                return <></>;
            }
        }
    }

    return (
        <SidesheetPage title={t(MDSideSheetInformationTKeys.Title)} onClose={onClose}>
            <div className='pb-3'></div>
            <MDDisplayProperty label={t(MDSideSheetInformationTKeys.FieldName)} value={[mdField?.name ?? '']}></MDDisplayProperty>
            <MDDisplayProperty label={t(MDSideSheetInformationTKeys.FieldType)} value={[MDFieldTypes[mdField?.type ?? 0]]}></MDDisplayProperty>
            {getMDFieldDisplay()}
        </SidesheetPage>
    );
};