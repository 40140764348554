import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface PermissionsSkeletonLoaderProps {
    isAdminOnNode: boolean;
}

export const PermissionsSkeletonLoader = ({ isAdminOnNode }: PermissionsSkeletonLoaderProps) => {
    return (
        <>
            {isAdminOnNode && (
                <SkeletonTheme enableAnimation height='48px' width='170px'>
                    <Skeleton count={1} className='mt-2 mb-4 mx-auto' />
                </SkeletonTheme>
            )}
            <SkeletonTheme enableAnimation height='24px' width='100%'>
                <Skeleton count={1} className='mb-4 mx-auto' />
            </SkeletonTheme>
            <SkeletonTheme enableAnimation height='24px' width='124px'>
                <Skeleton count={1} className='mb-1 mx-auto' />
            </SkeletonTheme>
            <SkeletonTheme enableAnimation height='70px' width='100%'>
                <Skeleton count={2} className='mb-1 mx-auto' />
            </SkeletonTheme>
            <SkeletonTheme enableAnimation height='24px' width='185px'>
                <Skeleton count={1} className='mt-3 mb-1 mx-auto' />
            </SkeletonTheme>
            <SkeletonTheme enableAnimation height='70px' width='100%'>
                <Skeleton count={1} className='mb-1 mx-auto' />
            </SkeletonTheme>
            <SkeletonTheme enableAnimation height='24px' width='124px'>
                <Skeleton count={1} className='mb-1 mt-3 mx-auto' />
            </SkeletonTheme>
            <SkeletonTheme enableAnimation height='70px' width='100%'>
                <Skeleton count={3} className='mb-1 mx-auto' />
            </SkeletonTheme>
            <hr style={{ color: '#8e8e8e' }} />
        </>
    );
};
