import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useFileQueueRouting = () => {

    const navigate = useNavigate();

    const getLinkToFileProcessingQueue = () => {
        return RoutePath.FileProcessingQueue;
    };

    const routeToFileProcessingQueue = () => {
        navigate(getLinkToFileProcessingQueue());
    };

    return {
        getLinkToFileProcessingQueue,
        routeToFileProcessingQueue,
    };
};
