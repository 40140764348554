import React, { useEffect, useState } from 'react';
import { Page, Text, EfcFeedItemInfo, Feed } from '@efilecabinet/efc-atlantis-components';
import { useWorkYouCareAboutApi } from '../../../api/workYouCareAbout/useWorkYouCareAboutApi';
import { useUtopiaNotificationsConverter } from '../../../hooks/useUtopiaNotificationsConverter';
import { TranslationFiles, useSafeTranslation, WorkYouCareAboutTKeys } from '../../../hooks/useSafeTranslation';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ZeroState } from '../../../components/ZeroState';
import { NotificationOrderEnum, NotificationStatusEnum, UtopiaNotificationDTO } from '../../../api/notifications/notificationApiTypes';
import zeroStateImg from '../../../assets/images/workYouCareAboutZeroState.svg';
import './WorkYouCareAboutPage.css';

export const WorkYouCareAboutPage = () => {

    const [workYouCareAboutFeedItems, setWorkYouCareAboutFeedItems] = useState<EfcFeedItemInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const { t } = useSafeTranslation(TranslationFiles.WorkYouCareAbout);
    const { getUserWorkYouCareAbout } = useWorkYouCareAboutApi();
    const { toFeedItem, eSignatureModal } = useUtopiaNotificationsConverter();
    const { screenSize } = useBootstrapBreakpoints();

    // Need to replace this hard coded max with lazy loading at some point
    const MAX_ROWS_TO_DISPLAY = 4000;

    const ZERO_STATE_MAX_IMG_WIDTH = 200;

    const getUtopiaNotifications = () => {
        getUserWorkYouCareAbout([NotificationStatusEnum.Active], NotificationOrderEnum.QueuedOnDesc, 0, MAX_ROWS_TO_DISPLAY)
            .then((ImportantWork: UtopiaNotificationDTO[]) => {
                setWorkYouCareAboutFeedItems(ImportantWork.map((workItem) => toFeedItem(workItem)));
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getUtopiaNotifications();
    }, []);

    return (
        <>
            <Page title='Work You Care About' icon={{ icon: 'briefcase' }}>
                {!!isLoading || !!errorLoading ? (
                    <>
                        {!!errorLoading &&
                            <Text>{t(WorkYouCareAboutTKeys.ErrorLoading)}</Text>
                        }
                    </>
                ) : (
                    <>
                        {workYouCareAboutFeedItems.length == 0 ? (
                            <ZeroState
                                imageColContent={<img src={zeroStateImg} alt={t(WorkYouCareAboutTKeys.ZeroStateImgAltText)} />}
                                textColContent={
                                    <>
                                        <Text bold>
                                            {t(WorkYouCareAboutTKeys.NoItemsMainText)}
                                        </Text>
                                        <br />
                                        <Text>
                                            {t(WorkYouCareAboutTKeys.NoItemsSubText)}
                                        </Text>
                                    </>
                                }
                                stackColumns={screenSize < ScreenSize.xl}
                                maxImgWidth={ZERO_STATE_MAX_IMG_WIDTH} />
                        ) : (
                            <Feed feedItems={workYouCareAboutFeedItems} maxRowsToDisplay={MAX_ROWS_TO_DISPLAY} showDateColumn={screenSize > ScreenSize.m} />
                        )}
                    </>
                )}
            </Page>
            {eSignatureModal}
        </>
    );
};