import { Input } from '@efilecabinet/efc-atlantis-components';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper, { Point, Area, Size } from 'react-easy-crop';

interface BrandingImageEditorProps {
    url: string;
    setBase64Image: (logo: string) => void;
    setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    saved: boolean;
}

export const CompanyLogoConfig: Size = {
    width: 345,
    height: 135,
};

export const BrandingImageEditor = (props: BrandingImageEditorProps) => {
    const { url, setBase64Image, saved, setShowSpinner } = props;

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

    const MIN_ZOOM = 0.1;
    const MAX_ZOOM = 5;

    const onCropChange = useCallback((newCrop: Point) => {
        setCrop(newCrop);
    }, []);

    const onZoomChange = useCallback((newZoom: number) => {
        setZoom(newZoom);
    }, []);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const createImage = (url: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.setAttribute('crossOrigin', 'anonymous');
            image.src = url;

            const handleLoad = () => {
                image.removeEventListener('load', handleLoad);
                image.removeEventListener('error', handleError);
                setShowSpinner(false);
                resolve(image);
            };

            const handleError = (error: Event) => {
                image.removeEventListener('load', handleLoad);
                image.removeEventListener('error', handleError);
                setShowSpinner(false);
                reject(error);
            };

            image.addEventListener('load', handleLoad);
            image.addEventListener('error', handleError);
        });
    };

    async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return null;
        }

        // set canvas size to match the bounding box
        canvas.width = image.width;
        canvas.height = image.height;

        // draw image
        ctx.drawImage(image, 0, 0);

        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0);

        // As Base64 string
        return canvas.toDataURL('image/png');

        // As a blob
        /*return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                if (!!file)
                    resolve(URL.createObjectURL(file));
            }, 'image/jpeg');
        });*/
    }

    useEffect(() => {
        async function GetCroppedImage() {
            if (!!croppedAreaPixels) {
                setBase64Image((await getCroppedImg(url, croppedAreaPixels)) ?? '');
            }
        }

        if (!!saved) {
            GetCroppedImage();
        }
    }, [saved]);

    return (
        <div className='cropper-container mx-auto mt-4 mb-2'>
            <Cropper
                image={url}
                crop={crop}
                zoom={zoom}
                aspect={5/2}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropComplete}
                showGrid={false}
                cropShape='rect'
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
                restrictPosition={false}
                cropSize={CompanyLogoConfig}
                classes={{
                    containerClassName: 'crop-container',
                    cropAreaClassName: 'crop-area'
                }}
            />
            <Input
                className='zoom-range mx-auto'
                value={zoom}
                type='range'
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                step={0.1}
                onChange={(e) => onZoomChange(+e.target.value)}
            />
        </div>
    );
};
