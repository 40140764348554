import React, { useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, Input } from '@efilecabinet/efc-atlantis-components';
import { Container, Col, Row } from 'reactstrap';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GroupTile } from './GroupTile/GroupTile';
import { ItemTile } from './ItemTile/ItemTile';
import { Chip } from './Chip/Chip';
import './ListSelector.css';

export interface ListSelectorProps {
    items: ListSelectorItem[];
    placeholderText?: string;
    hideSelectorIfSelection?: boolean;
    behaveAsSingleSelect?: behaveAsSingleSelect;
    showDisabledReason?: boolean;
    searchCallback?: (searchValue: string) => ListSelectorItem[];
    loadNextBatchCallback?: () => ListSelectorItem[];
    selectionChangedCallback: (selections: any[]) => void;
}

export interface behaveAsSingleSelect {
    isSingleSelect: boolean;
    isSearchable: boolean;
}

export interface ListSelectorItem {
    icon?: string;
    name: string;
    isDisabled?: boolean;
    identifier: number;
    childrenItems?: ListSelectorItem[];
}

export const ListSelector: React.FC<ListSelectorProps> = ({ items, behaveAsSingleSelect, showDisabledReason = true, searchCallback, loadNextBatchCallback, selectionChangedCallback, ...otherProps }) => {

    const ref = useRef<HTMLDivElement>(null);

    const DEBOUNCE_TIMER = 500;

    const [showSelector, setShowSelector] = useState(true);

    const [results, setResults] = useState<ListSelectorItem[]>(items);
    const [resultsUI, setResultsUI] = useState<JSX.Element[]>([]);

    const [searchInput, setSearchInput] = useState<string>('');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isSearchUpdate, setIsSearchUpdate] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isSelectedChipsOpen, setIsSelectedChipsOpen] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const [showChildrenMap, setShowChildrenMap] = useState(() => {
        const initialMap: Record<number, boolean> = {};
        results.forEach(item => {
            initialMap[item.identifier] = false;
        });
        return initialMap;
    });

    const updateShowChildren = (itemId: number, show: boolean) => {
        setShowChildrenMap(prevState => ({
            ...prevState,
            [itemId]: show,
        }));
    };

    const addSelectedItem = (item: ListSelectorItem) => {
        if (!!behaveAsSingleSelect?.isSingleSelect) {
            setSelectedItems([item]);
        } else {
            setSelectedItems([...selectedItems, item]);
        }
        setIsDropdownOpen(false);
    };

    const removeSelectedItem = (item: ListSelectorItem) => {
        setSelectedItems([...selectedItems.filter(x => x.identifier != item.identifier)]);
    };

    const configureResultsUI = () => {
        const layerHasIcons: boolean[] = checkForItemIcons();

        const newResultsUI: JSX.Element[] = [];
        for (let i = 0; i < results.length; i++) {
            if (results[i].childrenItems) {

                const childArray = results[i].childrenItems;
                const hasMatchingChildren = childArray ? childArray.length > 0 : false;

                if (isSearchUpdate && hasMatchingChildren) {
                    newResultsUI.push(
                        <GroupTile
                            key={results[i].identifier}
                            item={results[i]}
                            showIconColumn={layerHasIcons[0]}
                            showChildren={true}
                            childrenShowIconColumn={layerHasIcons[1]}
                            selectedItems={selectedItems}
                            addItem={addSelectedItem}
                            updateShowChildren={updateShowChildren} />);
                }
                else {
                    newResultsUI.push(
                        <GroupTile
                            key={results[i].identifier}
                            item={results[i]}
                            showIconColumn={layerHasIcons[0]}
                            showChildren={showChildrenMap[results[i].identifier]}
                            childrenShowIconColumn={layerHasIcons[1]}
                            selectedItems={selectedItems}
                            addItem={addSelectedItem}
                            updateShowChildren={updateShowChildren} />);
                }
            }
            else {
                newResultsUI.push(
                    <ItemTile
                        key={results[i].identifier}
                        item={results[i]}
                        isWithinGroup={false}
                        topLevelHasIconColumn={layerHasIcons[0]}
                        showIconColumn={layerHasIcons[0]}
                        selectedItems={selectedItems}
                        isDisabled={results[i].isDisabled}
                        showDisabledReason={showDisabledReason}
                        addItem={addSelectedItem} />);
            }
        }
        setResultsUI(newResultsUI);
    };

    function filterItemsBySearch(itemsToFilter: ListSelectorItem[], searchValue: string): ListSelectorItem[] {
        return itemsToFilter.map((item) => {
            const prunedChildren = item.childrenItems
                ? filterItemsBySearch(item.childrenItems, searchValue)
                : undefined;

            if (prunedChildren || item.name.toLowerCase().includes(searchValue)) {
                return { ...item, childrenItems: prunedChildren };
            }

            return null;
        }).filter(Boolean) as ListSelectorItem[];
    }

    function checkForItemIcons(): boolean[] {
        let hasTopLayerIcon = false;
        let hasInnerLayerIcon = false;

        // Step 1: Check top layer items
        for (const item of items) {
            if (item.icon) {
                hasTopLayerIcon = true;
                break; // No need to continue checking
            }
        }

        // Step 2: Check inner layer items
        for (const item of items) {
            if (item.childrenItems) {
                for (const childItem of item.childrenItems) {
                    if (childItem.icon) {
                        hasInnerLayerIcon = true;
                        break; // No need to continue checking
                    }
                }
            }
        }

        return [hasTopLayerIcon, hasInnerLayerIcon];
    }

    const performSearch = async () => {
        setIsDropdownOpen(true);
        setIsSearching(true);

        if (!searchCallback) {
            const filteredItems = filterItemsBySearch(items, searchInput.toLowerCase());
            setIsSearchUpdate(true);
            setResults(filteredItems);
        }
        else {
            setIsSearchUpdate(true);
            setResults(searchCallback(searchInput));//TODO: make async
        }

        setIsSearching(false);
    };

    const handleScroll = () => {
        const container = document.getElementById('list-selector-infinite-scroll-target');
        if (container) {
            const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
            if (isAtBottom && !!loadNextBatchCallback && !isSearchUpdate) {
                const extraResult: ListSelectorItem[] = loadNextBatchCallback();
                setResults((current) => [...current, ...extraResult]);
            }
        }
    };

    const debouncedSearch = debounce(performSearch, DEBOUNCE_TIMER);

    //Detect clicks outside of the component
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setSearchInput('');
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        configureResultsUI();
    }, [showChildrenMap]);

    useEffect(() => {
        configureResultsUI();
    }, [results]);

    useEffect(() => {
        setResults(items);
    }, [items]);

    useEffect(() => {
        if (!!searchInput) {
            debouncedSearch();
        }
        else {
            setResults(items);
            setIsSearchUpdate(false);
        }

    }, [searchInput]);

    useEffect(() => {
        setShowSelector(true);
        if (!!selectedItems) {
            if (!behaveAsSingleSelect?.isSingleSelect) {
                setIsSelectedChipsOpen(selectedItems.length > 0);
                if (selectedItems.length > 0 && otherProps.hideSelectorIfSelection) {
                    setShowSelector(false);
                }
            }
        }
        const ids = selectedItems.map((item) => item.identifier);
        selectionChangedCallback(ids);
        configureResultsUI();
    }, [selectedItems]);

    return (
        <>
            <div className="list-selector-wrapper" ref={ref} >
                {!!showSelector && (
                    <Row className="g-0">
                        <Col className='input-area'>
                            {!behaveAsSingleSelect?.isSearchable
                                ? <Button emphasis='med' className='list-selector-not-input' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>{otherProps.placeholderText}</Button>
                                : (
                                    <Input
                                        name='listSelectorInput'
                                        className="list-selector-input"
                                        placeholder={otherProps.placeholderText}
                                        value={searchInput}
                                        onChange={e => setSearchInput(e.target.value)} />)
                            }
                        </Col>
                        <Col className='button-area'>
                            <Button
                                emphasis='med'
                                className="list-selector-button"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)} >
                                {!isDropdownOpen && <FontAwesomeIcon icon='caret-down' />}
                                {isDropdownOpen && <FontAwesomeIcon icon='caret-up' />}
                            </Button>
                        </Col>
                    </Row>
                )}

                <Container className="list-selector-container g-0">
                    {!!isSelectedChipsOpen && (
                        <div className="list-selector-container-row list-selector-chip-row mt-2">
                            {selectedItems.map(item => <Chip key={item.identifier} item={item} removeItem={removeSelectedItem} />)}
                        </div>
                    )}

                    {(!!isDropdownOpen && !!showSelector) && (
                        <Row className="list-selector-container-row g-0 list-selector-search-row">
                            <Col>
                                {isSearching && (
                                    <SkeletonTheme enableAnimation height='200px' width='100%'>
                                        <Skeleton count={1} className='mb-3 mx-auto' />
                                    </SkeletonTheme>
                                )}

                                {!isSearching && (
                                    <div id='list-selector-infinite-scroll-target' className="list-selector-results-container" onScroll={handleScroll}>
                                        {resultsUI}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                </Container>

                <Container className="list-selector-container mb-20 g-0">
                    {isSelectedChipsOpen && (
                        <div className="list-selector-container-row list-selector-chip-row mt-2">
                            {selectedItems.map(item => <Chip key={item.identifier} item={item} removeItem={removeSelectedItem} />)}
                        </div>
                    )}
                </Container>
            </div>
        </>
    );
};
