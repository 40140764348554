import { useEffect, useState } from 'react';
import { useAuthContext } from '../app/_context/AuthContext';
import { useSettingsAndFeatures } from '../app/_context/SettingFeaturesContext/SettingsFeaturesContext';
import { useUrlUtilities } from './useUrlUtilities';

interface UseIFrameState {
    setUrl: (url: string) => void;
    url: string;
}

export const useUtopiaIFrame = (defaultUrl?: string): UseIFrameState => {
    const [urlToSet, setUrlToSet] = useState<string>(defaultUrl ?? '');
    const [url, setUrlState] = useState<string>('');
    const [requiredUrlParams, setRequiredUrlParams] = useState<string>('hideChrome=true&rS=:randomValue');
    const [hasCheckedSettingsAndFeatureFlags, setHasCheckedSettingsAndFeatureFlags] = useState<boolean>(false);

    const { hasSettings, settings } = useSettingsAndFeatures();
    const { hasQueryParams, removeQueryParams } = useUrlUtilities();
    const { loginIframeComplete, atlantisSideSheetEnabled } = useAuthContext();

    const getRequiredParameters = () => requiredUrlParams.replace(':randomValue', Math.random().toString());

    const changeUrl = (urlPath: string) => {
        urlPath = removeQueryParams(urlPath, ['hideChrome', 'hideSidebar', 'rS']);
        const iframeUrl = `${settings?.utopiaUrl}/#${urlPath}`;
        if (hasQueryParams(urlPath)) {
            setUrlState(`${iframeUrl}&${getRequiredParameters()}`);
        } else {
            setUrlState(`${iframeUrl}?${getRequiredParameters()}`);
        }
        setUrlToSet('');
    };

    useEffect(() => {
        if (!!urlToSet && !!hasSettings && !!loginIframeComplete && !!hasCheckedSettingsAndFeatureFlags) {
            changeUrl(urlToSet);
        }
    }, [urlToSet, hasSettings, loginIframeComplete, hasCheckedSettingsAndFeatureFlags]);

    useEffect(() => {
        if (!!atlantisSideSheetEnabled) {
            setRequiredUrlParams('hideChrome=true&hideSidebar=1&rS=:randomValue');
        }
        setHasCheckedSettingsAndFeatureFlags(true);
    }, [atlantisSideSheetEnabled]);

    return {
        setUrl: setUrlToSet,
        url,
    };
};
