import { EfcModalProps } from '@efilecabinet/efc-atlantis-components';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

export interface IUseModalProps extends EfcModalProps {
    close?: () => void;
}

// don't use this, use openModal function from useLayerContext instead
export const useModal = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen((prevState) => !prevState);
    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    const render = (ChildModal: JSX.Element) => {
        return createPortal(React.cloneElement<IUseModalProps>(ChildModal, { isOpen, toggle, close }), document.getElementById('root') as HTMLElement);
    };

    return { open, close, render };
};