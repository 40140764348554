import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { MetadataFieldDto, MDGetFieldsRequest, MDCheckFieldNameRequest, MetadataFieldDecorDto } from './mdFieldApiTypes';
import { useSettingsAndFeatures } from '../../app/_context/SettingFeaturesContext/SettingsFeaturesContext';

export const useMDFieldApi = () => {

    const { settings } = useSettingsAndFeatures();

    const getMDField = async (mdFieldId: string): Promise<MetadataFieldDecorDto> => {
        const resp: AxiosResponse = await Api.get(`${settings?.utopiaUrl}/api/Metadata/Field/${mdFieldId}`);
        return resp.data as MetadataFieldDecorDto;
    };

    const getAccountMDFields = async (accountId: number, start: number, count: number): Promise<MetadataFieldDecorDto[]> => {
        const resp: AxiosResponse = await Api.get(`${settings?.utopiaUrl}/api/Metadata/Field/Account/${accountId}/Start/${start}/Count/${count}`);
        return resp.data as MetadataFieldDecorDto[];
    };

    const getMDFields = async (mdFieldRequest: MDGetFieldsRequest): Promise<MetadataFieldDecorDto[]> => {
        const resp: AxiosResponse = await Api.post(`${settings?.utopiaUrl}/api/Metadata/Field/Query`, mdFieldRequest);
        return resp.data as MetadataFieldDecorDto[];
    };

    const getMDFieldByName = async (mdFieldRequest: MDCheckFieldNameRequest): Promise<MetadataFieldDecorDto> => {
        const resp: AxiosResponse = await Api.post(`${settings?.utopiaUrl}/api/Metadata/Field/GetByName`, mdFieldRequest);
        return resp.data as MetadataFieldDecorDto;
    };

    const createMDField = async (mdField: MetadataFieldDto): Promise<MetadataFieldDecorDto> => {
        const resp: AxiosResponse = await Api.post(`${settings?.utopiaUrl}/api/Metadata/Field`, mdField);
        return resp.data as MetadataFieldDecorDto;
    };

    const updateMDField = async (mdField: MetadataFieldDto): Promise<MetadataFieldDecorDto> => {
        const resp: AxiosResponse = await Api.put(`${settings?.utopiaUrl}/api/Metadata/Field`, mdField);
        return resp.data as MetadataFieldDecorDto;
    };

    const deleteMDField = async (mdFieldId: string): Promise<void> => {
        const resp: AxiosResponse = await Api.delete(`${settings?.utopiaUrl}/api/Metadata/Field/${mdFieldId}`);
    };

    return {
        getMDField,
        getAccountMDFields,
        getMDFields,
        getMDFieldByName,
        createMDField,
        updateMDField,
        deleteMDField
    };
};