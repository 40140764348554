import { IAuthContext } from '../app/_context/AuthContext';
import { SelectedDetail } from '../features/_routing/routingTypes';
import { useRouting } from '../features/_routing';
import { useUrlUtilities } from './useUrlUtilities';

export const useUtopiaRedirect = () => {

    const { getQueryParams, getFragment } = useUrlUtilities();
    const routing = useRouting();

    const redirectUtopiaUrlToAtlantis = (redirect: string, authContext: IAuthContext) => {
        const subpaths = redirect.split('?')[0].split('/');
        const params = getQueryParams(redirect);
        const area = subpaths[1];

        switch (area) {
            case 'home':
                redirectToDocuments(subpaths, params);
                break;
            case 'admin':
                redirectToAdmin(subpaths);
                break;
            case 'DocumentRequests':
                redirectToDocumentRequests(subpaths);
                break;
            case 'workflows':
                redirectToWorkflowsArea(subpaths, params, authContext);
                break;
            case 'userSettings':
                redirectToMySettingsArea(subpaths);
                break;
            case 'search':
                redirectToSearch(subpaths, params);
                break;
            case 'checkedoutfiles':
                redirectToCheckedOutArea(subpaths);
                break;
            case 'eSignatureTransaction':
                redirectToESignatureArea(subpaths);
                break;
            case 'reporting':
                routing.routeToReports();
                break;
            case 'o365':
            default: routing.routeToHome();
        }
    };

    // Documents Area

    const redirectToDocuments = (subpaths: string[], params: URLSearchParams) => {
        const tab = subpaths[3];

        switch (tab) {
            case 'data':
                redirectToFolderView(subpaths, params);
                break;
            case 'collections':
                redirectToFavorites(subpaths, params);
                break;
            default:
                routing.routeToDocuments();
        }
    };

    const redirectToFolderView = (subpaths: string[], params: URLSearchParams) => {
        const nodeIds = subpaths[8]?.split(',');
        const selectedDetail = params.get('selectedDetail');
        const showFullPreview = params.get('showFullPreview');

        if (!!nodeIds) {
            if(!!showFullPreview && showFullPreview == 'true'){
                routing.routeToNodeDetail(nodeIds, getSelectedDetailEnum('fullPreview'));
            }
            else if (!!selectedDetail) {
                routing.routeToNodeDetail(nodeIds, getSelectedDetailEnum(selectedDetail));
            } else {
                routing.routeToNode(nodeIds);
            }
        } else {
            routing.routeToDocuments();
        }
    };

    const getSelectedDetailEnum = (selectedDetailString: string) => {
        switch (selectedDetailString) {
            case 'fullPreview':
                return SelectedDetail.FullPreview;
            case 'nodePreview':
                return SelectedDetail.Preview;
            case 'profile':
                return SelectedDetail.Metadata;
            case 'comment':
                return SelectedDetail.Comments;
            case 'permission':
                return SelectedDetail.Permissions;
            case 'timeTriggers':
                return SelectedDetail.Governance;
            case 'eventTriggers':
                return SelectedDetail.Automation;
            case 'auditLogs':
                return SelectedDetail.History;
            case 'password':
                return SelectedDetail.FilePassword;
            case 'fileVersions':
                return SelectedDetail.FileVersions;
            default:
                return '';
        }
    };

    const redirectToFavorites = (subpaths: string[], params: URLSearchParams) => {
        const collectionId = subpaths[5];
        const nodeIds = subpaths[9].split(',');
        const selectedDetail = params.get('selectedDetail');

        if (!!collectionId) {
            if (!!nodeIds) {
                routing.routeToCollectionNodeDetail(collectionId, nodeIds, getSelectedDetailEnum(selectedDetail ?? ''));
            } else {
                routing.routeToCollection(collectionId);
            }
        } else {
            routing.routeToCollections();
        }
    };

    // Admin Area

    const redirectToAdmin = (subpaths: string[]) => {
        const tab = subpaths[2];

        switch (tab) {
            case 'user':
            case 'group':
            case 'userSessions':
            case 'accessLinks':
            case 'userDirectories':
                redirectToUsersArea(subpaths);
                break;
            case 'recyclebin':
                routing.routeToRecycleBin();
                break;
            case 'filequeue':
                routing.routeToFileProcessingQueue();
                break;
            case 'profile':
            case 'profileItem':
                redirectToProfilesArea(subpaths);
                break;
            case 'template':
                redirectToFolderTemplates(subpaths);
                break;
            case 'governance':
                routing.routeToGovernance();
                break;
            case 'securitypolicies':
                redirectToSecurityPolicies(subpaths);
                break;
            case 'auditLog':
                routing.routeToAuditLogs();
                break;
            case 'settings':
            case 'samlsettings':
            case 'branding':
            case 'emailImportManagement':
                redirectToAccountSettingsArea(subpaths);
                break;
            case 'csvImport':
            case 'csvExport':
            case 'accountTemplates':
                redirectToUtilities(subpaths);
                break;
            case 'salesforceManagement':
                redirectToSalesforceManagement(subpaths);
                break;
            default:
                routing.routeToHome();
        }
    };

    const redirectToUsersArea = (subpaths: string[]) => {
        const mainTab = subpaths[2];
        const subTab = subpaths[3];
        const id = subpaths[4];

        switch (mainTab) {
            case 'user':
                redirectToUsers(subTab, id);
                break;
            case 'group':
                redirectToGroups(subTab, id);
                break;
            case 'userSessions':
                routing.routeToUserSessions();
                break;
            case 'accessLinks':
                redirectToAccessLinks(subTab, id);
                break;
            case 'userDirectories':
                routing.routeToUserDirectories();
                break;
            default:
                routing.routeToUserIndex();
        }
    };

    const redirectToUsers = (tab: string, userId: string) => {
        if (tab == 'edit' && !!userId) {
            routing.routeToUser(userId);
        } else {
            if (tab == 'add') {
                routing.routeToUserNew();
            } else {
                routing.routeToUsers();
            }
        }
    };

    const redirectToGroups = (tab: string, groupId: string) => {
        if (tab == 'edit' && !!groupId) {
            routing.routeToGroup(groupId);
        } else {
            if (tab == 'add') {
                routing.routeToGroupNew();
            } else {
                routing.routeToGroups();
            }
        }
    };

    const redirectToAccessLinks = (tab: string, linkId: string) => {
        if (tab == 'edit' && !!linkId) {
            routing.routeToAccessLink(linkId);
        } else {
            routing.routeToAccessLinks();
        }
    };

    const redirectToProfilesArea = (subpaths: string[]) => {
        const mainTab = subpaths[2];
        const subTab = subpaths[3];
        const id = subpaths[4] ?? '';

        switch (mainTab) {
            case 'profile':
                redirectToProfiles(subTab, id);
                break;
            case 'profileItem':
                redirectToProfileItems(subTab, id);
                break;
            default:
                routing.routeToProfilesIndex();
        }
    };

    const redirectToProfiles = (subTab: string, profileId: string) => {
        if (subTab == 'edit' && !!profileId) {
            routing.routeToProfile(profileId);
        } else {
            if (subTab == 'add') {
                routing.routeToProfileNew();
            } else {
                routing.routeToProfiles();
            }
        }
    };

    const redirectToProfileItems = (subTab: string, itemId: string) => {
        if (subTab == 'edit' && !!itemId) {
            routing.routeToProfileItem(itemId);
        } else {
            if (subTab == 'add') {
                routing.routeToProfileItemNew();
            } else {
                routing.routeToProfileItems();
            }
        }
    };

    const redirectToFolderTemplates = (subpaths: string[]) => {
        const libraryId = subpaths[4] ?? '';
        const templateId = subpaths[6] ?? '';

        if (!!libraryId) {
            if (!!templateId) {
                routing.routeToFolderTemplate(libraryId, templateId);
            }
            else {
                routing.routeToFolderTemplateLibrary(libraryId);
            }
        } else {
            routing.routeToFolderTemplatesIndex();
        }
    };

    const redirectToSecurityPolicies = (subpaths: string[]) => {
        const tab = subpaths[3];
        const policyId = subpaths[4] ?? '';

        if (tab == 'edit' && !!policyId) {
            routing.routeToSecurityPolicyEdit(policyId);
        } else {
            if (tab == 'add') {
                routing.routeToSecurityPolicyNew();
            } else {
                routing.routeToSecurityPolicies();
            }
        }
    };

    const redirectToAccountSettingsArea = (subpaths: string[]) => {
        const tab = subpaths[2];

        switch (tab) {
            case 'settings':
                routing.routeToAccountSettings();
                break;
            case 'samlsettings':
                redirectToSSOSettings(subpaths);
                break;
            case 'branding':
                routing.routeToBranding();
                break;
            case 'emailImportManagement':
                redirectToAccountEmailImports(subpaths);
                break;
            default:
                routing.routeToAccountSettingsIndex();
                break;
        }
    };

    const redirectToAccountEmailImports = (subpaths: string[]) => {
        const tab = subpaths[3];
        const mappingId = subpaths[4] ?? '';

        if (tab == 'edit') {
            if (!!mappingId) {
                routing.routeToAccountEmailImport(mappingId);
            } else {
                routing.routeToAccountEmailImportNew();
            }
        } else {
            routing.routeToAccountEmailImports();
        }
    };

    const redirectToSSOSettings = (subpaths: string[]) => {
        const tab = subpaths[3];
        const configurationId = subpaths[4] ?? '';

        switch(tab) {
            case 'add':
                routing.routeToSamlNew();
                break;
            case 'edit':
                routing.routeToSamlEdit(configurationId);
                break;
            case 'validate':
                routing.routeToSamlValidate();
                break;
            default:
                routing.routeToSingleSignOn();
                break;
        }
    };

    const redirectToUtilities = (subpaths: string[]) => {
        const tab = subpaths[2];

        switch (tab) {
            case 'csvImport':
                routing.routeToCSVImport();
                break;
            case 'csvExport':
                routing.routeToAccountTemplateExport();
                break;
            case 'accountTemplates':
                redirectToAccountTemplateUtilities(subpaths);
                break;
            default:
                routing.routeToUtilitiesIndex();
                break;
        }
    };

    const redirectToAccountTemplateUtilities = (subpaths: string[]) => {
        const tab = subpaths[3];

        switch (tab) {
            case 'export':
                routing.routeToAccountTemplateExport();
                break;
            case 'import':
                routing.routeToAccountTemplateImport();
                break;
            default:
                routing.routeToUtilitiesIndex();
        }
    };

    const redirectToSalesforceManagement = (subpaths: string[]) => {
        const tab = subpaths[3] ?? '';
        const mappingId = subpaths[4] ?? '';

        if (!!tab) {
            if (!!mappingId) {
                routing.routeToSalesforceMappingEdit(mappingId);
            }
            else {
                routing.routeToSalesforceMappingNew();
            }
        }
        else {
            routing.routeToSalesforce();
        }
    };

    // Document Requests Area

    const redirectToDocumentRequests = (subpaths: string[]) => {
        const mainTab = subpaths[2];
        const subTab = subpaths[3];
        const id = subpaths[4];

        switch (mainTab) {
            case 'inbox':
                if (subTab == 'add') {
                    routing.routeToDocRequestNew();
                } else if (subTab == 'view' && !!id) {
                    routing.routeToDocRequestInboxRequest(id);
                } else {
                    routing.routeToDocRequestInbox();
                }
                break;
            case 'sent':
                if (!!id) {
                    routing.routeToDocRequestSentRequest(id);
                } else {
                    routing.routeToDocRequestSent();
                }
                break;
            case 'all':
                if (!!id) {
                    routing.routeToDocRequestAccountRequest(id);
                } else {
                    routing.routeToDocRequestAccountRequests();
                }
                break;
            case 'templates':
                if (subTab == 'add') {
                    routing.routeToDocRequestTemplateNew();
                } else if (subTab == 'view' && !!id) {
                    routing.routeToDocRequestTemplate(id);
                } else {
                    routing.routeToDocRequestTemplates();
                }
                break;
            default:
                routing.routeToDocRequestIndex();
        }
    };

    // Workflows Area

    const redirectToWorkflowsArea = (subpaths: string[], params: URLSearchParams, authContext: IAuthContext) => {
        const tab = subpaths[2];
        const id = subpaths[3];
        const accountId = parseInt(params.get('accountId') ?? '');

        switch (tab) {
            case 'main':
                routing.routeToWorkflows();
                break;
            case 'workflow':
                routing.routeToWorkflow(accountId, id, authContext);
                break;
            case 'instances':
                routing.routeToWorkflowInstances();
                break;
            case 'Instance':
                routing.routeToWorkflowInstance(accountId, id, authContext);
                break;
            case 'dashboard':
                routing.routeToWorkflowDashboard();
                break;
            case 'history':
                routing.routeToWorkflowHistory();
                break;
            default:
                routing.routeToWorkflowIndex();
        }
    };

    // My Settings Area

    const redirectToMySettingsArea = (subpaths: string[]) => {
        const tab = subpaths[2];

        switch (tab) {
            case 'settings':
                routing.routeToMySettings();
                break;
            case 'emailImportManagement':
                redirectToUserEmailImports(subpaths);
                break;
            case 'accounts':
                routing.routeToMyAccounts();
                break;
            case 'externalIntegrations':
                routing.routeToIntegratedProducts();
                break;
            default:
                routing.routeToMySettingsIndex();
        }
    };

    const redirectToUserEmailImports = (subpaths: string[]) => {
        const tab = subpaths[3];
        const mappingId = subpaths[4] ?? '';

        if (tab == 'edit') {
            if (!!mappingId) {
                routing.routeToUserEmailImport(mappingId);
            } else {
                routing.routeToUserEmailImportNew();
            }
        } else {
            routing.routeToUserEmailImports();
        }
    };

    // Search Area

    const redirectToSearch = (subpaths: string[], params: URLSearchParams) => {
        const userSearchId = subpaths[2];
        const toggleAdvancedSearch = Boolean(params.get('toggleAdvancedSearch') ?? '');
        const sharedSearchId = params.get('sharedSearchId') ?? '';

        if (!!userSearchId) {
            routing.routeToUserSearchResults(userSearchId);
        } else if (toggleAdvancedSearch) {
            routing.routeToAdvancedSearch();
        } else if (!!sharedSearchId) {
            routing.routeToSharedSearchResults(sharedSearchId);
        } else {
            routing.routeToSearch();
        }
    };

    // Checked Out Area

    const redirectToCheckedOutArea = (subpaths: string[]) => {
        const tab = subpaths[2];

        switch (tab) {
            case 'mine':
                routing.routeToMyCheckedOut();
                break;
            case 'all':
                routing.routeToAccountCheckedOut();
                break;
            default:
                routing.routeToCheckedOutIndex();
        }
    };

    // ESignature Area

    const redirectToESignatureArea = (subpaths: string[]) => {
        const tab = subpaths[2];

        switch (tab) {
            case 'sent':
                routing.routeToSentESignatures();
                break;
            case 'all':
                routing.routeToAccountESignatures();
                break;
            case 'templates':
                routing.routeToESignatureTemplates();
                break;
            default:
                routing.routeToESignatureIndex();
        }
    };

    // Redirect Utopia Notification

    const redirectUtopiaNotificationToAtlantis = (itemUrl: string | undefined, authContext: IAuthContext) => {
        if (!!itemUrl) {
            const urlFragment = getFragment(itemUrl);
            redirectUtopiaUrlToAtlantis(urlFragment, authContext);
        }
    };

    return {
        redirectUtopiaUrlToAtlantis,
        redirectUtopiaNotificationToAtlantis,
        getSelectedDetailEnum
    };
};