import React from 'react';
import { List, ListInlineItem, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { AccountTile } from '../AccountTile';
import { useAuthContext } from '../../app/_context/AuthContext';
import { AccountModalTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import { AuthAccountDTO } from '../../api/auth/authApiTypes';
import './AccountsModal.css';

interface AccountsModalProps {
    isOpen: boolean;
    closeModal: () => void;
}
export const AccountsModal = (props: AccountsModalProps) => {
    const { isOpen, closeModal } = props;

    const { t } = useSafeTranslation(TranslationFiles.AccountModal);

    const { authUser, userAccounts } = useAuthContext();

    const getOtherAccountIds = () => {
        if (!!userAccounts) {
            return Array.from(userAccounts, ([key, value]) => {
                return key;
            }).filter((id) => {
                return id != (authUser as any).accountID;
            });
        } else return [];
    };

    return (
        <Modal title={t(AccountModalTKeys.AccountModalTitle)} toggle={closeModal} size='lg' isOpen={isOpen}>
            <Modal.Body>
                <Text muted>{t(AccountModalTKeys.ActiveAccount)}</Text>

                <div className='accounts-modal-main-tile'>
                    <AccountTile account={(userAccounts as any)?.get(authUser?.accountID)} />
                </div>

                {!!userAccounts && userAccounts?.size > 1 && (
                    <>
                        <Text muted>{t(AccountModalTKeys.AdditionalAccounts, 'Additional accounts')}</Text>
                        <List type='inline'>
                            {getOtherAccountIds().map((acctId, index) => (
                                <ListInlineItem key={index}>
                                    <AccountTile account={userAccounts?.get(acctId) as AuthAccountDTO} switchTo />
                                </ListInlineItem>
                            ))}
                        </List>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};
