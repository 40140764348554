export enum OSName {
    Windows = 'Windows',
    Mac = 'Mac',
    Unix = 'Unix',
    Linux = 'Linux',
    SunOS = 'SunOS',
}

enum SizeUnits {
    Bytes = 0,
    KB = 1,
    MB = 2,
    GB = 3,
    TB = 4,
}

export const useUtilities = () => {
    const getOperatingSystemName = (): OSName | null => {
        const platform = navigator.platform;
        if (platform.includes('Win')) {
            return OSName.Windows;
        } else if (platform.includes('Mac')) {
            return OSName.Mac;
        } else if (platform.includes('X11') || platform.includes('HP-UX')) {
            return OSName.Unix;
        } else if (platform.includes('Linux')) {
            return OSName.Linux;
        } else if (platform.includes('SunOS')) {
            return OSName.SunOS;
        } else {
            return null;
        }
    };

    const formatDateTime = (value: string | Date) => {
        const date = typeof value === 'string' ? new Date(value) : value;
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'numeric',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        })
            .format(date)
            .replace(',', ' |');
        return formattedDate; // Output Format: 1/1/2021 | 12:00 AM
    };

    const getSizeDisplay = (size: number, decimalPoints = 0) => {
        let count = 0;
        let actSize = size;

        while (actSize > 1000) {
            actSize /= 1024;
            count++;
        }

        return `${actSize.toFixed(decimalPoints)} ${SizeUnits[count]}`;
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email.toLowerCase());
    };

    const areObjectsEqual = (obj1: any, obj2: any, skipKeys: string[] = []): boolean => {
        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return false;
        }

        const keys1 = Object.keys(obj1).filter((key) => !skipKeys.includes(key));
        const keys2 = Object.keys(obj2).filter((key) => !skipKeys.includes(key));

        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
            if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key], skipKeys)) {
                return false;
            }
        }

        return true;
    };

    return {
        getOperatingSystemName,
        formatDateTime,
        getSizeDisplay,
        isEmailValid,
        areObjectsEqual,
    };
};
