export class UserSearchDTO {
    id: number;
    searchedOn: Date;
    filterItem: FilterItemDTO;
    saved: boolean;
    name: string;
    smartSearchNodeID: string;

    constructor(id?: number, searchedOn?: Date, filterItem?: FilterItemDTO, saved?: boolean, name?: string, smartSearchNodeID?: string) {
        this.id = id ?? 0;
        this.searchedOn = searchedOn ?? new Date();
        this.filterItem = filterItem ?? {
            filterCriteria: null,
            andCriteria: [],
            orCriteria: []
        };
        this.saved = saved ?? false;
        this.name = name ?? '';
        this.smartSearchNodeID = smartSearchNodeID ?? '';
    }
}

export class FilterItemDTO {
    filterCriteria: FilterValueDTO | null;
    andCriteria: FilterItemDTO[];
    orCriteria: FilterItemDTO[];

    constructor(type: FilterTypeEnum, value: string, comparator: FilterComparatorEnum) {
        this.filterCriteria = new FilterValueDTO(type, value, comparator);
        this.andCriteria = [];
        this.orCriteria = [];
    }
}

export class FilterValueDTO {
    type: FilterTypeEnum;
    value: string;
    comparator: FilterComparatorEnum;

    constructor(type: FilterTypeEnum, value: string, comparator: FilterComparatorEnum) {
        this.type = type;
        this.value = value;
        this.comparator = comparator;
    }
}
export enum FilterTypeEnum {
    Name = 0,
    ProfileValue = 1,
    CreatedByName = 2,
    Path = 3,
    DateCreated = 4,
    Type = 5,
    FullText = 6,
    DeletedOn = 7,
    Username = 8,
    Comment = 9,
    Container = 10,
    DateModified = 11,
    Profile = 12,
    ProfileItem = 13,
    CreatedByUserID = 14,
    ParentID = 15,
}

export enum FilterComparatorEnum {
    Contains = 0,
    Equals = 1,
    GreaterThan = 2,
    LessThan = 3,
    NotEqual = 4,
    NotContain = 5,
    GreaterThanOrEqualTo = 6,
    LessThanOrEqualTo = 7,
    EndsWith = 8,
    StartsWith = 9,
    WithInPast = 10,
    Matches = 11,
    Has = 12,
    ContainsWords = 13,
    MatchPhrase = 14,
    Like = 15,
}