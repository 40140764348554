import React from 'react';
import { useColors } from '../../../hooks/useColors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface BottomNavIconProps {
    iconName: IconProp;
    isActive: boolean;
    transformRight?: string;
}

export const BottomNavIcon = (props: BottomNavIconProps) => {
    const { iconName, isActive, transformRight } = props;
    const colors = useColors();

    return (
        <span className='fa-layers fa-fw mt-3'>
            {isActive &&
                <FontAwesomeIcon icon='circle' transform={`grow-25 ${transformRight}`} color={colors.atlantisLightFocused} />
            }

            {!!iconName &&
                <FontAwesomeIcon icon={iconName} color={`${isActive ? colors.atlantisEnabled : 'white'}`} />
            }
        </span>
    );
};
