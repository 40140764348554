import { useEffect, useState } from 'react';
import { useAccountFeatureUsageApi } from '../api/accountFeatureUsage/useAccountFeatureUsageApi';
import { AccountFeatureEnum } from '../api/accountFeatures/accountFeatureApiTypes';
import { AccountFeatureUsageDTO } from '../api/accountFeatureUsage/accountFeatureUsageApiTypes';

export const useAccountFeatureUsage = () => {
    const { getAccountFeatureUsageByAccountId } = useAccountFeatureUsageApi();

    const [accountFeatureUsageItems, setAccountFeatureUsageItems] = useState<Map<number, Map<AccountFeatureEnum, AccountFeatureUsageDTO>>>(new Map <number, Map<AccountFeatureEnum, AccountFeatureUsageDTO>>());

    const getAccountFeatureUsage = (accountId: number, accountFeatureEnum: AccountFeatureEnum) => {
        return accountFeatureUsageItems.get(accountId)?.get(accountFeatureEnum);
    };

    const getAccountFeatureUsageSize = (accountId: number) => {
        return accountFeatureUsageItems.get(accountId)?.size ?? 0;
    };

    const getAccountFeatureUsageCounts = async (accountId: number): Promise<{ [key: number]: AccountFeatureUsageDTO }> => {
        return await getAccountFeatureUsageByAccountId(accountId as number);
    };

    const handleMessage = (message: any) => {
        if (message.data.key == 'accountFeatureUsageNotification') {
            if (!!message.data.value) {
                const accountFeatureUsageByAccountLookup = new Map<number, Map<AccountFeatureEnum, AccountFeatureUsageDTO>>();
                for (const accountIdString in message.data.value) {
                    const accountID = parseInt(accountIdString);
                    accountFeatureUsageByAccountLookup.set(accountID, new Map());

                    for (const featureType in message.data.value[accountID]) {
                        const accountFeatureLookup = accountFeatureUsageByAccountLookup.get(accountID);

                        if (!!accountFeatureLookup) {
                            accountFeatureLookup.set(parseInt(featureType) as AccountFeatureEnum, message.data.value[accountID][featureType]);
                        }
                    }
                }
                setAccountFeatureUsageItems(accountFeatureUsageByAccountLookup);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [accountFeatureUsageItems]);

    return {
        getAccountFeatureUsage,
        getAccountFeatureUsageSize,
        getAccountFeatureUsageCounts,
    };

};