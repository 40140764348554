import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodeNoteDTO } from './nodeNoteApiTypes';

export const useNodeNotesApi = () => {

    const getNodeNotesAsync = async (nodeId: string): Promise<NodeNoteDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/NodeComment/NodeNote?id=${nodeId}`);
        return resp.data;
    };

    const addNodeNoteAsync = async (nodeNote: NodeNoteDTO): Promise<NodeNoteDTO> => {
        const resp: AxiosResponse = await Api.post('api/NodeComment/NodeNote', nodeNote);
        return resp.data;
    };

    const updateNodeNoteAsync = async (updatedNodeNote: NodeNoteDTO): Promise<NodeNoteDTO> => {
        const resp: AxiosResponse = await Api.put(`api/NodeComment/NodeNote?id=${updatedNodeNote.id}`, updatedNodeNote);
        return resp.data;
    };

    const deleteNodeNoteAsync = async (nodeId: number): Promise<void> => {
        await Api.delete(`api/NodeComment/NodeNote?id=${nodeId}`);
    };

    return {
        getNodeNotesAsync,
        addNodeNoteAsync,
        updateNodeNoteAsync,
        deleteNodeNoteAsync
    };
};
