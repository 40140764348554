import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { CollectionDTO } from './collectionApiTypes';

export const useCollectionsApi = () => {

    const getCollections = async (): Promise<CollectionDTO[]> => {
        const resp: AxiosResponse = await Api.get('api/collections');
        return resp.data;
    };

    const getCollectionById = async (id: number): Promise<CollectionDTO> => {
        const resp: AxiosResponse = await Api.get(`api/collections/${id}`);
        return resp.data;
    };

    return {
        getCollections,
        getCollectionById,
    };
};