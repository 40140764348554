import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { AtlantisTaskDTO } from './taskApiTypes';
import { UtopiaTaskNotificationDTO, NotificationOrderEnum, NotificationStatusEnum, UtopiaTaskCountDTO } from '../notifications/notificationApiTypes';

export const useTaskApi = () => {

    const addAtlantisTask = async (userId: number, task: AtlantisTaskDTO): Promise<AtlantisTaskDTO> => {
        const resp: AxiosResponse = await Api.post(`api/tasks/user/${userId}`, task);
        return resp.data;
    };

    const completeAtlantisTask = async (userId: number, taskId: string): Promise<AtlantisTaskDTO> => {
        const resp: AxiosResponse = await Api.put(`api/tasks/user/${userId}/task/${taskId}/complete`);
        return resp.data;
    };

    const getUserAtlantisTasks = async (userId: number, status: string, start: number, count: number): Promise<AtlantisTaskDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/tasks/user/${userId}/${status.toLowerCase()}?start=${start}&count=${count}`);
        return resp.data;
    };

    const getUserUtopiaTasks = async (taskStatuses: NotificationStatusEnum[], orderBy: NotificationOrderEnum, start: number, count: number): Promise<UtopiaTaskNotificationDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/Tasks?${taskStatuses.map((taskStatus) => `taskStatuses=${taskStatus}&`).join('')}orderBy=${orderBy}&start=${start}&count=${count}`);
        return resp.data;
    };

    const getUserUtopiaTasksCount = async (taskStatuses: NotificationStatusEnum[]): Promise<UtopiaTaskCountDTO> => {
        const resp: AxiosResponse = await Api.get(`api/Tasks/Count?${taskStatuses.map((taskStatus) => `taskStatuses=${taskStatus}&`).join('')}`);
        return resp.data;
    };

    const dismissAtlantisTask = async (userId: number, taskId: string): Promise<AtlantisTaskDTO> => {
        const resp: AxiosResponse = await Api.put(`api/tasks/user/${userId}/task/${taskId}/dismiss`);
        return resp.data;
    };

    const dismissUtopiaTask = async (taskId: string): Promise<void> => {
        await Api.post(`api/tasks/utopiaTask/${taskId}/dismiss`);
    };

    const restoreAtlantisTask = async (userId: number, taskId: string): Promise<void> => {
        await Api.put(`api/tasks/user/${userId}/task/${taskId}/restore`);
    };

    const restoreUtopiaTask = async (taskId: string): Promise<void> => {
        await Api.post(`api/tasks/restore/${taskId}`);
    };

    return {
        addAtlantisTask,
        completeAtlantisTask,
        getUserAtlantisTasks,
        getUserUtopiaTasks,
        getUserUtopiaTasksCount,
        dismissAtlantisTask,
        dismissUtopiaTask,
        restoreAtlantisTask,
        restoreUtopiaTask,
    };
};
