export const useZIndexManager = () => {

    const getOffCanvasZIndex = (): number => {
        return 1030;
    };

    const getBottomNavZIndex = (): number => {
        return 1035;
    };

    return {
        getOffCanvasZIndex,
        getBottomNavZIndex
    };
};