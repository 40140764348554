import { useEffect, useState } from 'react';
import { ScreenSize } from './bootstrap/bootstrapTypes';
import { useBootstrapBreakpoints } from './bootstrap/useBootstrapBreakpoints';
import { RoutePath } from '../features/_routing/routingTypes';

export const enum VertNavState {
    Open = 'open',
    Collapsed = 'collapsed',
    Hidden = 'hidden'
}

const AutoCollapseRoutes: RoutePath[] = [RoutePath.Documents, RoutePath.Search];

export const useVertNavState = () => {
    const { screenSize } = useBootstrapBreakpoints();
    const [vertNavState, setVertNavState] = useState<VertNavState>(getVertNavState());
    const [prevLocationPathname, setPrevLocationPathname] = useState<string>('');

    function getVertNavState(): VertNavState {
        switch(screenSize)
        {
            case ScreenSize.xxl:
            case ScreenSize.xl:
                return localStorage.getItem('vertNavCollapsedState') as VertNavState;   
            case ScreenSize.l:
            case ScreenSize.m:
            case ScreenSize.s:
                return VertNavState.Collapsed;
            case ScreenSize.xs:
                return VertNavState.Hidden;
        }
    }

    const saveVertNavState = (state: VertNavState) => {
        localStorage.setItem('vertNavCollapsedState', state);
        setVertNavState(getVertNavState());
    };

    function toggleAndSaveVertNavState() {
        if(screenSize > ScreenSize.m) {
            saveVertNavState(vertNavState == VertNavState.Open ? VertNavState.Collapsed : VertNavState.Open);
        }
    }

    function closeAndSaveVertNavState() {
        saveVertNavState(VertNavState.Collapsed);
    }

    useEffect(() => {
        const isAutoCollapseRoute = AutoCollapseRoutes.some((route) => location.pathname.startsWith(route));
        if (!isAutoCollapseRoute || screenSize <= ScreenSize.l) {
            setVertNavState(getVertNavState());
        }
    }, [screenSize]);
    
    useEffect(() => {
        const isAutoCollapseRoute = AutoCollapseRoutes.some((route) => location.pathname.startsWith(route));
        const fromAutoCollapsRoute = AutoCollapseRoutes.some((route) => prevLocationPathname.startsWith(route));
        const shouldAutoCollapseIfOpen = isAutoCollapseRoute && !fromAutoCollapsRoute && screenSize > ScreenSize.xs;

        if(shouldAutoCollapseIfOpen) {
            setVertNavState(VertNavState.Collapsed);
        } else if (!isAutoCollapseRoute) {
            setVertNavState(getVertNavState()); 
        }

        setPrevLocationPathname(location.pathname);
    }, [location.pathname]);

    return {
        vertNavState,
        toggleAndSaveVertNavState,
        closeAndSaveVertNavState,
    };
};