import { MDFieldTypes } from '../api/mdFields/mdFieldApiTypes';

export const useMetadataUtilities = () => {

    function createInitialMDField() {
        return { id: '', name: '', type: MDFieldTypes.Text, isArchived: false };
    }

    function createInitialMDGroup() {
        return { id: '', name: '', metadataFields: [] };
    }

    return {
        createInitialMDField,
        createInitialMDGroup
    };
};