import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Nav, Text } from '@efilecabinet/efc-atlantis-components';
import { useAllFeaturesStyle } from '../../../../hooks/useAllFeaturesStyle';
import { useAllFeaturesFlyoutHook } from '../../../../hooks/useAllFeaturesFlyoutHook';
import { useRouteValidator } from '../../../../hooks/useRouteValidator';
import { TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { NoAccessToFeatures } from './NoAccessToFeatures';
import './AllFeaturesPanel.css';

export interface AllFeaturesPanelProps {
    color?: string;
    collapseAllFeatures?: () => void;
}

export const AllFeaturesPanel = (props: AllFeaturesPanelProps) => {
    const { color, collapseAllFeatures } = props;
    const { t } = useSafeTranslation(TranslationFiles.VerticalNav);
    const { allFeaturesWidthStyle } = useAllFeaturesStyle();
    const { AllFeatureGroups } = useAllFeaturesFlyoutHook();
    const { canGoToRoute, canGoToSomeRoute } = useRouteValidator();

    return (
        <div className='px-2 pb-5'>
            <Row style={allFeaturesWidthStyle}>
                {AllFeatureGroups.map((featureGroup) =>
                    canGoToSomeRoute(...featureGroup.Features.map((feature) => feature.routePath)) && (
                        <Col key={featureGroup.TitleTranslationKey} className='pb-2 has-access-to-features-column'>
                            <Text style={{ color: color }} bold>
                                {t(featureGroup.TitleTranslationKey)}
                            </Text>
                            {/* closeTray on Nav.Link does not work if is put within component, it has to be passed as a child of SideNav.Link */}
                            {featureGroup.Features.map((feature) => (
                                <Nav.Link key={feature.routePath} dataId={`allFeatures${feature.routePath}`} show={canGoToRoute(feature.routePath)} tag={Link} to={feature.routePath} icon={feature.icon} className={`my-4 ${!!color ? 'no-hover' : ''}`} color={color} onClick={collapseAllFeatures} closeTray>
                                    {t(feature.translationKey)}
                                </Nav.Link>
                            ))}
                        </Col>)
                )}
            </Row>

            <NoAccessToFeatures />
        </div>
    );
};
