import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const NotesSkeletonLoader = () => {
    return (
        <>
            <div className='note-skeleton-loader-notes'>
                <SkeletonTheme enableAnimation height='24px' width='80%'>
                    <Skeleton count={1} className='mb-3 note-skeleton-loader note-skeleton-loader-right' />
                    <Skeleton count={1} className='mb-3 note-skeleton-loader note-skeleton-loader-left' />
                    <Skeleton count={1} className='mb-3 note-skeleton-loader note-skeleton-loader-right' />
                    <Skeleton count={1} className='mb-3 note-skeleton-loader note-skeleton-loader-left' />
                    <Skeleton count={1} className='mb-3 note-skeleton-loader note-skeleton-loader-right' />
                    <Skeleton count={1} className='mb-3 note-skeleton-loader note-skeleton-loader-left' />
                </SkeletonTheme>
            </div>
            <div className='note-skeleton-loader-note-box-container'>
                <div className='note-skeleton-loader-note-box'>
                    <SkeletonTheme enableAnimation height='38px' width='100%'>
                        <Skeleton count={1} className='note-skeleton-loader-left' />
                    </SkeletonTheme>
                </div>
                <div className='note-skeleton-loader-submit-btn'>
                    <SkeletonTheme enableAnimation height='16px' width='16px'>
                        <Skeleton count={1} className='note-skeleton-loader-right' />
                    </SkeletonTheme>
                </div>
            </div>
        </>
    );
};