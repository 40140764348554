import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { ProfileItemDTO } from './profileItemApiTypes';

export const useProfileItemApi = () => {
    
    const getAccountProfileItems = async (accountId: number): Promise<ProfileItemDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/profileitem/account/${accountId}`);
        return resp.data;
    };

    const deleteProfileItems = async (ids: number[]): Promise<void> => {
        await Api.post('api/profileitem/delete', ids);
    };

    return {
        getAccountProfileItems,
        deleteProfileItems,
    };
};