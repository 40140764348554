import React, { useEffect } from 'react';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { ThemeEnum } from '../../../../hooks/useColors';
import { useSettingsAndFeatures } from '../../../../app/_context/SettingFeaturesContext/SettingsFeaturesContext';
import { UtopiaAlertsTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import './UtopiaAlerts.css';

export const UtopiaAlerts = () => {
    const { t } = useSafeTranslation(TranslationFiles.UtopiaAlerts);

    const { settings } = useSettingsAndFeatures();
    const { showToastMessage, closeToastMessage } = useLayerContext();

    const MAX_FILENAME_LENGTH = 35;

    const handlePreviewerXAlerts = (obj: any) => {
        if (!!obj.name) {
            const shortenedFileName = getShortenedFileName(obj);
            if (obj.event === 'success') {
                if (!!obj.originalNodeId) {
                    closeToastMessage('previewerX-Save-Failure-' + obj.originalNodeId);
                }
                closeToastMessage('previewerX-Save-Failure-' + shortenedFileName);
                showToastMessage({
                    color: ThemeEnum.Success,
                    timeout: 4000,
                    identifier: 'previewerX-Save-Success',
                    message: t(UtopiaAlertsTKeys.PreviewerXSaveSuccessAlert, { fileName: shortenedFileName }),
                });
            }
            else if (obj.event === 'failure') {
                showToastMessage({
                    color: ThemeEnum.Danger,
                    identifier: 'previewerX-Save-Failure-' + (!!obj.originalNodeId ? obj.originalNodeId : shortenedFileName),
                    message: t(UtopiaAlertsTKeys.PreviewerXSaveFailureAlert, { fileName: shortenedFileName }),
                });
            }
        }
    };

    const handleAccusoftAlerts = (data: any) => {
        if (data.status === 'success' && data.action === 'savedAccusoftDoc') {
            showToastMessage({
                color: ThemeEnum.Success,
                timeout: 5000,
                identifier: 'accusoft-save-success',
                message: (
                    <>
                        {t(UtopiaAlertsTKeys.AccusoftSaveSuccessAlertBeforeBtn, { fileName: data?.fileName })}
                        <button className='form-tools-btn' onClick={handleFormToolsClick}>
                            {t(UtopiaAlertsTKeys.AccusoftSaveSuccessAlertBtn)}
                        </button>{' '}
                        {t(UtopiaAlertsTKeys.AccusoftSaveSuccessAlertAfterBtn)}
                    </>
                ),
            });
        }
    };

    const handleFormToolsClick = () => {
        const documentIframe = document.querySelector('[name="utopiaIframe"]') as HTMLIFrameElement;
        documentIframe?.contentWindow?.postMessage('openFormTools', '*');
    };

    const getShortenedFileName = (obj: any) => {
        const fileExtension = obj.name.split('.').pop();
        if (obj.name.length > MAX_FILENAME_LENGTH) {
            return obj.name.slice(0, MAX_FILENAME_LENGTH - fileExtension.length) + '...' + fileExtension;
        } else {
            return obj.name;
        }
    };

    useEffect(() => {
        const alertListener = async function (event: any) {
            try {
                const eventData = JSON.parse(event.data);
                if (eventData.type === 'previewerX') {
                    handlePreviewerXAlerts(eventData.data);
                }
                if (eventData.type === 'accusoftPreviewer') {
                    handleAccusoftAlerts(eventData.data);
                }
            } catch {
                // nom nom nom
            }
        };

        window.addEventListener('message', alertListener);

        return () => window.removeEventListener('message', alertListener);
    }, []);

    return null;
};
