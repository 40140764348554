export enum FeatureFlags {
    BrandingEnabled = 'AtlantisBrandingEnabled',
    LocalizationEnabled = 'AtlantisLocalizationEnabled',
    PDFTronPreviewerEnabled = 'AtlantisPDFTronPreviewerEnabled',
    PreviewerXEnabled = 'AtlantisPreviewerXEnabled',
    ProductUpdatesEnabled = 'AtlantisProductUpdatesEnabled',
    ShowInAppGuidance = 'AtlantisShowInAppGuidance',
    MetadataEnabled = 'AtlantisMetadataEnabled',
    MetadataToggleEnabled = 'AtlantisMetadataToggleEnabled',
    PublicOptOut = 'AtlantisPublicOptOut',
    AccountOnboarding = 'AtlantisAccountOnboarding',
    SideSheetEnabled = 'AtlantisSideSheetEnabled',
    SideSheetToggleEnabled = 'AtlantisSideSheetToggleEnabled',
}