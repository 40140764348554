import React from 'react';
import { Card } from '@efilecabinet/efc-atlantis-components';
import { ZeroState } from '../../../ZeroState';
import { NotesTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import emptyMailboxImg from './EmptyMailbox.png';

export const NotesZeroStateComponent = () => {

    const { t } = useSafeTranslation(TranslationFiles.Notes);
    return (
        <div className='no-notes-container'>
            <ZeroState
                maxImgWidth={175}
                imageColContent={
                    <div className='mb-3'>
                        <img className='no-notes-mailbox' src={emptyMailboxImg} alt={t(NotesTKeys.EmptyMailboxImgAltText)} />
                    </div>
                }
                textColContent={
                    <>
                        <div className='mb-3'>
                            <p className='mb-0'>{t(NotesTKeys.NoNotesYet)}<br />{t(NotesTKeys.WriteFirstNoteInvitation)}</p>
                        </div>
                        <div className='mb-3'>
                            <Card className='no-notes-pro-tip'>
                                <Card.Body className='d-flex align-items-start justify-content-center flex-column'>
                                    <Card.Title className='mb-0' icon={{ icon: ['fal', 'lightbulb'], color: 'primary', size: 'lg' }}>
                                        {t(NotesTKeys.ProTip)}
                                    </Card.Title>
                                    <Card.Text className='no-notes-pro-tip-body'>
                                        <p className='mb-0'>
                                            {t(NotesTKeys.Use)}
                                            <span className='no-notes-code-font'>{t(NotesTKeys.Shift)}</span>
                                            {t(NotesTKeys.Plus)}
                                            <span className='no-notes-code-font'>{t(NotesTKeys.Enter)}</span>
                                            {t(NotesTKeys.ToMakeALineBreak)}
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </>
                }
                stackColumns={true}
            ></ZeroState>
        </div>
    );
};