import React, { useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { AccountRoleCountsDTO, RoleDTO, RoleEnum, UserLicenseEnum } from '../../../api/roles/roleApiTypes';
import { useSafeTranslation, TranslationFiles, RoleSelectorTKeys } from '../../../hooks/useSafeTranslation';
import './RoleTile.css';

export interface RoleTileProps {
    role: RoleDTO;
    accountRoleCounts: AccountRoleCountsDTO;
    addRole: (role: RoleDTO) => void;
    selectedRoles: RoleDTO[];
    searchInput: string;
    isDefaultSelection?: boolean;
}

export const RoleTile = (props: RoleTileProps) => {
    const TRUNCATE_MAX_WIDTH = 25;
    const TRUNCATE_SMALL_MAX_WIDTH = 30;

    const { role, accountRoleCounts, addRole, selectedRoles, searchInput, isDefaultSelection } = props;
    const { t } = useSafeTranslation(TranslationFiles.RoleSelector);

    const [showExpandedView, setShowExpandedView] = useState<boolean>();
    const [canAddRole, setCanAddRole] = useState<boolean>(false);
    const [finishedCheckingCanAddRole, setFinishedCheckingCanAddRole] = useState<boolean>(false);

    const [isNameTooltipOpen, setIsNameTooltipOpen] = useState<boolean>(false);
    const nameTooltipTarget = `role-name-${role.id}`;
    const nameToggleTooltip = () => setIsNameTooltipOpen((prevState) => !prevState);
    let nameWasTruncated = false;
    const setNameWasTruncated = (wasTruncated: boolean) => { nameWasTruncated = wasTruncated; };
    const [isUsernameTooltipOpen, setIsUsernameTooltipOpen] = useState<boolean>(false);
    const usernameTooltipTarget = `role-username-${role.id}`;
    const usernameToggleTooltip = () => setIsUsernameTooltipOpen((prevState) => !prevState);
    let usernameWasTruncated = false;
    const setUsernameWasTruncated = (wasTruncated: boolean) => { usernameWasTruncated = wasTruncated; };
    const [isAlreadyAddedTooltipOpen, setIsAlreadyAddedTooltipOpen] = useState<boolean>(false);
    const alreadyAddedTooltipTarget = `role-already-added-${role.id}`;
    const alreadyAddedToggleTooltip = () => setIsAlreadyAddedTooltipOpen((prevState) => !prevState);

    const getRoleIcon = () => {
        if (role.roleType == RoleEnum.Group) {
            return (<FontAwesomeIcon icon='users' size='lg' className={'role-icon' + (roleAlreadyAdded() ? ' role-already-added' : '')} />);
        }
        else if (role.license == UserLicenseEnum.Guest) {
            return (
                <i className={'icon-efc-guest-user role-icon role-custom-icon' + (roleAlreadyAdded() ? ' role-already-added' : '')}></i>
            );
        }
        else {
            return (<FontAwesomeIcon icon='user-alt' size='lg' className={'role-icon' + (roleAlreadyAdded() ? ' role-already-added' : '')} />);
        }
    };

    const truncateString = (str: string, isSmall: boolean, setItemWasTruncated: (value: boolean) => void) => {
        const truncateWidth = isSmall ? TRUNCATE_SMALL_MAX_WIDTH : TRUNCATE_MAX_WIDTH;

        if (str.length > truncateWidth) {
            setItemWasTruncated(true);
            return str.substring(0, truncateWidth - 3) + '...';
        } else {
            return str;
        }
    };

    const getGroupCount = () => {
        let groupCount = '';

        if (role.roleType === RoleEnum.Group) {
            const filtered = accountRoleCounts.accountGroupData.filter(x => x.groupId == role.id);
            groupCount = ` (${filtered.length == 1 ? filtered[0].memberCount : 0})`;
        }

        return groupCount;
    };

    const getTileText = (str: string, isSmall: boolean, setItemWasTruncated: (value: boolean) => void) => {
        return truncateString(str, isSmall, setItemWasTruncated) + getGroupCount();
    };

    const highlightedMatch = (str: string) => {
        const parts = !searchInput ? [str] : str.split(new RegExp(`(${searchInput})`, 'gi'));
        return parts.map((part, index) =>
            !!searchInput && part.toLowerCase() === searchInput.toLowerCase() ?
                <span key={index} style={{ backgroundColor: '#E5D6F0' }}>{part}</span> :
                part
        );
    };

    const tryAddRole = () => {
        if (canAddRole) {
            addRole(role);
        }
    };

    const getRoleTileStyling = () => {
        let styling = 'g-0 role-row-container';
        if (showExpandedView) {
            styling += ' role-row-expanded';
        }
        if (roleAlreadyAdded()) {
            styling += ' role-already-added';
        }
        return styling;
    };

    const roleAlreadyAdded = () => {
        return !canAddRole && finishedCheckingCanAddRole;
    };

    useEffect(() => {
        if (!!role) {
            setShowExpandedView(role.name.toLowerCase() != role.userName.toLowerCase() && role.roleType != RoleEnum.Group);
        }

    }, [role]);

    useEffect(() => {
        if (!!selectedRoles) {
            const foundRole = selectedRoles.filter(x => x.id == role.id);
            setCanAddRole(foundRole.length == 0);
            setFinishedCheckingCanAddRole(true);
        }

    }, [selectedRoles]);

    return (
        <Row className={getRoleTileStyling()} onClick={tryAddRole} >
            <Col>
                <Row className={'g-0 role-row' + (!!isDefaultSelection ? ' role-search-default-selection' : '')}>
                    <Col xs='1' />
                    <Col xs='1'>
                        <div className='role-icon-wrapper'>
                            {getRoleIcon()}
                        </div>
                    </Col>
                    <Col xs={!roleAlreadyAdded() ? '10' : '9'}>
                        <div className='role-text-wrapper' >
                            <span id={nameTooltipTarget}>
                                <Text size='lg' className={roleAlreadyAdded() ? 'role-already-added' : ''} >{highlightedMatch(getTileText(role.name, false, setNameWasTruncated))}</Text>
                            </span>
                            {nameWasTruncated && (
                                <Tooltip target={nameTooltipTarget} isOpen={isNameTooltipOpen} toggle={nameToggleTooltip} placement='bottom'>
                                    {role.name}
                                </Tooltip>
                            )}
                        </div>
                    </Col>
                    {roleAlreadyAdded() && (
                        <>
                            <Col xs='1' id={alreadyAddedTooltipTarget}>
                                <FontAwesomeIcon icon='check-circle' size='lg' />
                            </Col>
                            <Tooltip target={alreadyAddedTooltipTarget} isOpen={isAlreadyAddedTooltipOpen} toggle={alreadyAddedToggleTooltip} placement='bottom'>
                                {t(RoleSelectorTKeys.AlreadySelectedMessage)}
                            </Tooltip>
                        </>
                    )}
                </Row>

                {showExpandedView && (
                    <Row className={'g-0' + (!!isDefaultSelection ? ' role-search-default-selection' : '')}>
                        <Col xs='2' />
                        <Col xs='10'>
                            <div className='role-text-wrapper' >
                                <span id={usernameTooltipTarget}>
                                    <Text size='md' className={roleAlreadyAdded() ? 'role-already-added' : ''} >{highlightedMatch(getTileText(role.userName, true, setUsernameWasTruncated))}</Text>
                                </span>
                                {usernameWasTruncated && (
                                    <Tooltip target={usernameTooltipTarget} isOpen={isUsernameTooltipOpen} toggle={usernameToggleTooltip} placement='bottom'>
                                        {role.userName}
                                    </Tooltip>
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};