import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { useAuthContext } from '../../app/_context/AuthContext';
import { TranslationFiles, useSafeTranslation, AccountFeatureUsageModalTKeys } from '../../hooks/useSafeTranslation';
import { useAccountFeatureUsage } from '../../hooks/useAccountFeatureUsage';
import { AccountFeatureEnum } from '../../api/accountFeatures/accountFeatureApiTypes';
import './AccountFeatureUsageHeader.css';

export interface AccountFeatureUsageHeaderProps {
    accountFeatureTypes: AccountFeatureEnum[];
}

export const AccountFeatureUsageHeader = (props: AccountFeatureUsageHeaderProps) => {
    const { accountFeatureTypes } = props;

    const { authUser } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.AccountFeatureUsage);
    const { getAccountFeatureUsage, getAccountFeatureUsageSize } = useAccountFeatureUsage();

    const [tooltipIsOpen , setTooltipIsOpen ] = useState(false);
    const toggleTooltip = () => setTooltipIsOpen ((preState) => !preState);

    const AccountFeatureNames: Map<AccountFeatureEnum, { name: string, detail: string }> = new Map([
        [AccountFeatureEnum.FullUserLicense, { name: t(AccountFeatureUsageModalTKeys.Full), detail: t(AccountFeatureUsageModalTKeys.FullDetail) }],
        [AccountFeatureEnum.EssentialsUserLicense, { name: t(AccountFeatureUsageModalTKeys.Essential), detail: t(AccountFeatureUsageModalTKeys.EssentialDetail) }],
        [AccountFeatureEnum.Templates, { name: t(AccountFeatureUsageModalTKeys.FolderTemplates), detail: t(AccountFeatureUsageModalTKeys.FolderTemplatesDetail) }],
        [AccountFeatureEnum.Workflow, { name: t(AccountFeatureUsageModalTKeys.Workflows), detail: t(AccountFeatureUsageModalTKeys.WorkflowsDetail) }],
        [AccountFeatureEnum.ESignature, { name: t(AccountFeatureUsageModalTKeys.Signatures), detail: t(AccountFeatureUsageModalTKeys.SignaturesDetail) }],
        [AccountFeatureEnum.ESignatureKBA, { name: t(AccountFeatureUsageModalTKeys.KBA), detail: t(AccountFeatureUsageModalTKeys.KBADetail) }],
        [AccountFeatureEnum.ESignatureOTP, { name: t(AccountFeatureUsageModalTKeys.OneTimePasscode), detail: t(AccountFeatureUsageModalTKeys.OneTimePasscodeDetail) }],
        [AccountFeatureEnum.FormFill, { name: t(AccountFeatureUsageModalTKeys.FormFillTemplates), detail: t(AccountFeatureUsageModalTKeys.FormFillTemplatesDetail) }],
        [AccountFeatureEnum.DocumentRequests, { name: t(AccountFeatureUsageModalTKeys.OpenDocumentRequests), detail: t(AccountFeatureUsageModalTKeys.OpenDocumentRequestsDetail) }],
        [AccountFeatureEnum.ComplianceUserLicense, { name: t(AccountFeatureUsageModalTKeys.Compliance), detail: t(AccountFeatureUsageModalTKeys.ComplianceDetail) }],
    ]);

    const featuresToShow = () => {
        let showFeatures = false;
        accountFeatureTypes.map((featureEnum) => {
            if (!!getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.maxCount) {
                showFeatures = true;
            }
        });
        return showFeatures;
    };

    const showDivider = (index: number) => {
        if (accountFeatureTypes.length > 1 && index + 1 < accountFeatureTypes.length) {
            return !!getAccountFeatureUsage(authUser?.accountID as number, accountFeatureTypes[index + 1])?.maxCount;
        }
        return false;
    };

    return (
        <div className='usage-counts'>
            {getAccountFeatureUsageSize(authUser?.accountID as number) > 0 && featuresToShow() &&
                <>
                    {accountFeatureTypes.map((featureEnum: AccountFeatureEnum, index) => (
                        <>
                            {(getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.maxCount ?? 0 > 0) &&
                                <>
                                    <Text className='usage-spacing' key={getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.featureType}>
                                        {t(AccountFeatureUsageModalTKeys.DisplayHeader,
                                            {
                                                numberUsed: getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.numberUsed,
                                                maxCount: getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.maxCount ?? '?',
                                                name: AccountFeatureNames.get(featureEnum)?.name
                                            })}
                                    </Text>
                                    {showDivider(index) &&
                                        <Text key={index} className='usage-spacing'> | </Text>
                                    }
                                </>
                            }
                        </>
                    ))}
                    <>
                        <span id={'info-bubble-tooltip'}>
                            <FontAwesomeIcon icon={['far', 'circle-question']} className='ms-1' color='var(--atl-primary)' />
                        </span>
                        <Tooltip placement='left' target={'info-bubble-tooltip'} isOpen={tooltipIsOpen } toggle={toggleTooltip} className='usage-info-tooltip'>
                            {accountFeatureTypes.length == 1 ? (
                                <>
                                    {t(AccountFeatureUsageModalTKeys.SingleTooltip,
                                        {
                                            numberUsed: getAccountFeatureUsage(authUser?.accountID as number, accountFeatureTypes[0])?.numberUsed,
                                            maxCount: getAccountFeatureUsage(authUser?.accountID as number, accountFeatureTypes[0])?.maxCount ?? '?',
                                            detail: AccountFeatureNames.get(accountFeatureTypes[0])?.detail
                                        })}
                                </>
                            ) : (
                                <>
                                    <div>{t(AccountFeatureUsageModalTKeys.MultiTooltipHeader)}</div>
                                    <ul className='usage-info-list-tooltip'>
                                        {accountFeatureTypes.map((featureEnum, index) => (
                                            <>
                                                {(getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.maxCount ?? 0 > 0) &&
                                                    <li className='usage-info-list-tooltip' key={index}>
                                                        {t(AccountFeatureUsageModalTKeys.MutliTooltip,
                                                            {
                                                                numberUsed: getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.numberUsed,
                                                                maxCount: getAccountFeatureUsage(authUser?.accountID as number, featureEnum)?.maxCount ?? '?',
                                                                detail: AccountFeatureNames.get(featureEnum)?.detail
                                                            })}
                                                    </li>
                                                }
                                            </>
                                        ))}
                                    </ul>
                                    <div>{t(AccountFeatureUsageModalTKeys.MultiTooltipFooter)}</div>
                                </>
                            )}
                        </Tooltip>
                    </>
                </>
            }
        </div>
    );
};
