import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { ProductUpdateCardDTO } from './productUpdateApiTypes';

export const useProductUpdatesApi = () => {

    const getProductUpdateCards = async (): Promise<ProductUpdateCardDTO[]> => {
        const resp: AxiosResponse = await Api.get('ProductUpdateCards.json');
        return resp.data;
    };
    
    const getToken = async (): Promise<string> => {
        const resp: AxiosResponse = await Api.get('api/ProductUpdates/GetToken');
        return resp.data;
    };

    return {
        getProductUpdateCards,
        getToken,
    };
};
