import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { FileVersionDTO } from './fileVersionApiTypes';

export const useFileVersionApi = () => {

    const getFileVersions = async (nodeId: string): Promise<FileVersionDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/FileVersion/${nodeId}`);
        return resp.data;
    };

    const deleteFileVersion = async (versionId: string): Promise<void> => {
        await Api.delete(`api/FileVersion/${versionId}`);
    };

    const lockVersion = async (fileVersion: FileVersionDTO): Promise<void> => {
        await Api.put(`api/FileVersion/LockVersion/${fileVersion.nodeId}`, fileVersion);
    };

    const makePrimary = async (fileVersion: FileVersionDTO): Promise<void> => {
        await Api.put(`api/FileVersion/${fileVersion.nodeId}`, fileVersion);
    };

    return {
        getFileVersions,
        deleteFileVersion,
        lockVersion,
        makePrimary,
    };
};
