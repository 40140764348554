export interface ProductUpdateCardDTO {
    bulletPointText: string[] | null;
    buttonInfo: ProductUpdateCardButtonInfoDTO;
    externalButton?: ProductUpdateExternalButtonInfoDTO;
    image: string;
    linkText: string;
    linkUrl: string;
    templateType: ProductUpdateTemplateTypes;
    text: string;
    textHeader: string;
}

export interface ProductUpdateCardButtonInfoDTO {
    buttonText: string | null;
    buttonNavigateArea: string | null;
}

export interface ProductUpdateExternalButtonInfoDTO {
    buttonText: string | null;
    buttonUrl: string | null;
}

export enum ProductUpdateTemplateTypes {
    General = 1,
    PreviewerX = 2,
    Branding = 3,
}