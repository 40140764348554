import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodeDTO } from '../node/nodeApiTypes';

export const useFormsApi = () => {
    const getFormFillTemplates = async (accountId: number): Promise<NodeDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/FormFill/Templates/${accountId}`);
        return resp.data;
    };

    return {
        getFormFillTemplates,
    };
};
