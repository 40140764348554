import React from 'react';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { Text, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../../../../hooks/useColors';


interface PermissionDetailsTableHeaderProps {
    itemType: string;
    isContainer: boolean;
}

export const PermissionDetailsTableHeader = ({ itemType, isContainer }: PermissionDetailsTableHeaderProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { atlantisPrimary } = useColors();

    const ICON_COL_WIDTH = '4%';
    const PERMISSION_COL_WIDTH = '9%';

    return (
        <tr>
            <th scope='col'>
                <Text>{t(PermissionsTKeys.RoleColumnHeader)}</Text>
            </th>
            <th scope='col' style={{ width: ICON_COL_WIDTH }}></th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text>{t(PermissionsTKeys.ViewColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-view-column-header' message={!isContainer ? t(PermissionsTKeys.ViewFileTooltip) : t(PermissionsTKeys.ViewTooltip, { itemType })} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text>{t(PermissionsTKeys.DownloadColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-download-column-header' message={!isContainer ? t(PermissionsTKeys.DownloadFileDesc) : t(PermissionsTKeys.DownloadTooltip, { itemType })} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            {isContainer && (
                <>
                    <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                        <div className='d-flex align-items-center'>
                            <Text>{t(PermissionsTKeys.UploadColumnHeader)}</Text>
                            <TooltipItem delayShow={200} id='permission-details-upload-column-header' message={t(PermissionsTKeys.UploadTooltip, { itemType })} innerClassName='d-flex align-items-center'>
                                <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                            </TooltipItem>
                        </div>
                    </th>
                    <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                        <div className='d-flex align-items-center'>
                            <Text className='me-1'>{t(PermissionsTKeys.CreateColumnHeader)}</Text>
                            <TooltipItem delayShow={200} id='permission-details-create-column-header' message={t(PermissionsTKeys.CreateTooltip, { itemType })} innerClassName='d-flex align-items-center'>
                                <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                            </TooltipItem>
                        </div>
                    </th>
                </>
            )}
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.EditColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-edit-column-header' message={!isContainer ? t(PermissionsTKeys.EditFileDesc) : t(PermissionsTKeys.EditDesc, { itemType })} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.DeleteColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-delete-column-header' message={!isContainer ? t(PermissionsTKeys.DeleteFileDesc) : t(PermissionsTKeys.DeleteDesc, { itemType })} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.AdminColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-admin-column-header' message={!isContainer ? t(PermissionsTKeys.AdminFileDesc) : t(PermissionsTKeys.AdminDesc)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.LockColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-lock-column-header' message={t(PermissionsTKeys.LockAccessDesc)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: PERMISSION_COL_WIDTH }}>
                <div className='d-flex align-items-center'>
                    <Text className='me-1'>{t(PermissionsTKeys.BlockColumnHeader)}</Text>
                    <TooltipItem delayShow={200} id='permission-details-block-column-header' message={t(PermissionsTKeys.BlockAccessDesc)} innerClassName='d-flex align-items-center'>
                        <FontAwesomeIcon icon={['far', 'question-circle']} color={atlantisPrimary} />
                    </TooltipItem>
                </div>
            </th>
            <th scope='col' style={{ width: ICON_COL_WIDTH }}></th>
        </tr>
    );
};
