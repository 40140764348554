import React, { ReactNode, useState } from 'react';
import { FormGroup, Input, Label, Text, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { NestedChecklistTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColors } from '../../hooks/useColors';
import './NestedChecklist.css';

interface NestedChecklistProps {
    checked?: boolean;
    children?: ReactNode;
    className?: string;
    dataId?: string;
    id?: string;
    label?: string;
    name?: string;
    onClick?: (args?: any) => void;
    optional?: boolean;
    tooltip?: string;
}

export const NestedChecklist = (props: NestedChecklistProps) => {
    const { checked = false, children, className, dataId, id, label, name, onClick, optional, tooltip } = props;

    const { atlantisPrimary } = useColors();

    const [isOpen, setIsOpen] = useState(false);

    const { t } = useSafeTranslation(TranslationFiles.NestedChecklist);

    const toggle = () => onClick && onClick();
    const toggleTooltip = () => setIsOpen((preState) => !preState);

    return (
        <FormGroup check className={`${className}`}>
            <Input type='checkbox' id={id} name={name} dataId={dataId} checked={checked} className='pointer' onChange={toggle} />
            <Label check for={id} className='pointer d-flex'>
                {label}
                {optional && <Text className='text-secondary ms-1'>({t(NestedChecklistTKeys.Optional)})</Text>}
                {tooltip && (
                    <>
                        <span id={`${id}-tooltip`}>
                            <FontAwesomeIcon icon={['far', 'circle-question']} className='ms-1' color={atlantisPrimary} />
                        </span>
                        <Tooltip placement='right' target={`${id}-tooltip`} isOpen={isOpen} toggle={toggleTooltip} className='checklist-tooltip'>
                            {tooltip}
                        </Tooltip>
                    </>
                )}
            </Label>
            <div className='border-start border-2 border-primary ps-3 mt-2' style={{ marginLeft: '-15px' }}>
                {children}
            </div>
        </FormGroup>
    );
};
