import React, { useState } from 'react';
import './ShareableLink.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Label, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { Row } from 'reactstrap';
import { ShareableLinkTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import { useColors } from '../../hooks/useColors';

interface IShareableLinkProps {
    link: string;
    label?: string;
}

const ShareableLink = (props: IShareableLinkProps) => {
    const [canClickCopy, setCanClickCopy] = useState(true);

    const { t } = useSafeTranslation(TranslationFiles.ShareableLink);
    const { atlantisPrimary } = useColors();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.link);
    };

    const clickCopy = () => {
        if (canClickCopy) {
            copyToClipboard();
            setCanClickCopy(false);
            const timeout = setTimeout(() => {
                setCanClickCopy(true);
            }, 2500);
            return () => clearTimeout(timeout);
        }
    };

    return (
        <>
            <div className='shareable-link' data-id='shareable-link'>
                {props.label && (
                    <Row>
                        <Label>{props.label}</Label>
                    </Row>
                )}
                <Row className='link'>
                    <div>
                        <TooltipItem position='top' message={props.link} id='shareable-link-input'>
                            <Input className='input text-truncate' data-id='shareable-link-input' type='text' value={props.link} disabled />
                        </TooltipItem>
                    </div>
                    <div onClick={clickCopy} className='copy-button'>
                        <FontAwesomeIcon color={atlantisPrimary} className='icon' icon={['far', 'copy']} />
                        {canClickCopy && t(ShareableLinkTKeys.CopyLinkText)}
                        {!canClickCopy && t(ShareableLinkTKeys.CopiedText)}
                    </div>
                </Row>
            </div>
        </>
    );
};

export default ShareableLink;
