import React, { useState } from 'react';
import { RoleSelector } from '../../../../RoleSelector/RoleSelector';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { EfcCallToActionInfo, Text, Modal, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { PermissionsCheckboxList } from '../PermissionsCheckboxList/PermissionsCheckboxList';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { useLayerContext } from '../../../../../app/_context/LayerContext/LayerContext';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';
import { useFileUtilities } from '../../../../../hooks/useFileUtilities';
import { useIcons } from '../../../../../hooks/useIcons';
import { ThemeEnum } from '../../../../../hooks/useColors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NodeDTO } from '../../../../../api/node/nodeApiTypes';
import { RoleDTO } from '../../../../../api/roles/roleApiTypes';
import './PermissionGrantAccessModal.css';

interface PermissionGrantAccessModalProps {
    isOpen: boolean;
    onClose: () => void;
    activeNode?: NodeDTO;
    grantAccessSuccessCallback?: (showSkeletonLoader?: boolean) => Promise<void>;
}

export const PermissionGrantAccessModal = (props: PermissionGrantAccessModalProps) => {
    const { isOpen, onClose, activeNode, grantAccessSuccessCallback } = props;

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { updateAndCreatePermissions } = useNodePermissionsUtilities();
    const { showToastMessage, closeToastMessage } = useLayerContext();
    const { spinnerIconProps } = useIcons();
    const { permissions: existingPermissions, editedPermission: permission } = useNodePermissionsContext();
    const { getNodeTypeText } = useFileUtilities();

    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [selectedRoles, setSelectedRoles] = useState<RoleDTO[]>([]);
    const toggleToolTip = () => setIsToolTipOpen((prevState) => !prevState);

    const grantAccess = async () => {
        if (selectedRoles.length == 0 || !permission?.nodeID) {
            return;
        }

        const distinctRolesAttachedToPermission: NodePermissionDTO[] = selectedRoles.map((role) => ({
            ...permission,
            roleID: role.id
        } as NodePermissionDTO));

        const permissionsToUpdate: NodePermissionDTO[] = [];
        const permissionsToCreate: NodePermissionDTO[] = [];

        for (const permissionWithRole of distinctRolesAttachedToPermission) {
            if (!!permissionWithRole.roleID) {
                permissionWithRole.id = getExistingPermissionId(permissionWithRole.roleID, permissionWithRole.nodeID);
                if (!!permissionWithRole.id) {
                    permissionsToUpdate.push(permissionWithRole);
                } else {
                    permissionsToCreate.push(permissionWithRole);
                }
            }
        }
        try {
            setShowSpinner(true);
            closeToastMessage('edit-permissions-error-toast');
            await updateAndCreatePermissions(permissionsToUpdate, permissionsToCreate);
            showToastMessage({
                color: ThemeEnum.Success,
                identifier: 'edit-permissions-success-toast',
                message: t(PermissionsTKeys.EditSuccess),
                timeout: 2500,
            });
            !!grantAccessSuccessCallback && await grantAccessSuccessCallback();
            onClose();
        } catch (error) {
            showToastMessage({
                color: ThemeEnum.Danger,
                identifier: 'edit-permissions-error-toast',
                message: t(PermissionsTKeys.EditError, { error: error }),
            });
            console.error('There was an error granting access:', error);
        } finally {
            setShowSpinner(false);
        }
    };

    const getExistingPermissionId = (roleId: number, nodeId?: number) => {
        const existingPermission = existingPermissions.find((permission) => permission.roleID === roleId && permission.nodeID === nodeId && !permission.remove);
        return existingPermission ? existingPermission.id : 0;
    };

    const canGrantAccess = () => {
        if (selectedRoles.length == 0) {
            return false;
        }
        if (!permission) {
            return false;
        }
        if (permission.view || permission.restrict) {
            return true;
        }
        return false;
    };

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(PermissionsTKeys.CancelButton),
            emphasis: 'med',
            color: 'primary',
            onClick: onClose
        },
        {
            text: t(PermissionsTKeys.GrantAccessButton),
            emphasis: 'high',
            onClick: grantAccess,
            disabled: !canGrantAccess() || showSpinner,
            icon: showSpinner ? spinnerIconProps : undefined
        },
    ];

    return (
        <>
            <Modal size='lg' isOpen={isOpen} toggle={onClose} title={t(PermissionsTKeys.GrantAccessModalTitle)} ctas={ctas} >
                <Tooltip target='permission-grant-access-to-whom-tooltip' placement='bottom' isOpen={isToolTipOpen} toggle={toggleToolTip} className='permissions-grant-access-tooltip'>
                    {t(PermissionsTKeys.GrantAccessModalGuestToolTip)}
                </Tooltip>
                <Modal.Body>
                    <>
                        <Text>{t(PermissionsTKeys.GrantAccessModalHelperText, { itemType: (!!activeNode ? getNodeTypeText(activeNode.systemType) : t(PermissionsTKeys.GrantAccessModalHelperTextItem)) })}</Text>
                        <FontAwesomeIcon icon={['far', 'circle-question']} className='my-0 mx-2' color='var(--atl-primary)' id='permission-grant-access-to-whom-tooltip' />
                    </>
                    <RoleSelector selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />
                    <PermissionsCheckboxList activeNode={activeNode} isInherited={false} />
                </Modal.Body>
            </Modal>
        </>
    );
};