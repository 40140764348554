import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EfcLink, Text } from '@efilecabinet/efc-atlantis-components';
import { useTranslation } from 'react-i18next';
import { useRouting } from '../../../features/_routing';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { useLayerContext } from '../../../app/_context/LayerContext/LayerContext';
import { ThemeEnum } from '../../../hooks/useColors';

const enum TranslationKeys {
    SessionTimeoutChanged = 'sessionTimeoutChanged',
    GoToUserSettings = 'goToUserSettings',
}

export const SessionMismatchAlert = () => {
    const { t } = useTranslation('SessionMismatchAlert');
    const { getLinkToMySettings } = useRouting();

    const [hasShownAlert, setHasShownAlert] = useState(false);

    const { authUser, hasAuthUser, loginCount } = useAuthContext();
    const { showToastMessage, closeToastMessage } = useLayerContext();

    useEffect(() => {
        const checkShouldShowSessionMismatchAlert = () => {
            if (!!authUser?.sessionExpirationMismatch && loginCount === 1 && !hasShownAlert) {
                showSessionMismatchAlert();
                setHasShownAlert(true);
            }
        };

        const showSessionMismatchAlert = () => {
            showToastMessage({
                identifier: 'session-timeout-conflict-toast',
                color: ThemeEnum.Warning,
                message: (
                    <Text>
                        {t(TranslationKeys.SessionTimeoutChanged)}
                        <EfcLink color='primary' to={getLinkToMySettings()} tag={Link} onClick={() => closeToastMessage('session-timeout-conflict-toast')}>
                            {t(TranslationKeys.GoToUserSettings)}
                        </EfcLink>
                    </Text>
                ),
            });
        };

        if (!!hasAuthUser && !!loginCount) {
            checkShouldShowSessionMismatchAlert();
        }
    }, [hasAuthUser, loginCount]);

    return null;
};
