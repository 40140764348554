import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EfcCallToActionInfo, Text, Modal, EfcLink, Label, Input, FormGroup } from '@efilecabinet/efc-atlantis-components';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { TranslationFiles, WelcomeModalTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { useSettingsAndFeatures } from '../../../../app/_context/SettingFeaturesContext/SettingsFeaturesContext';
import { useOptInApi } from '../../../../api/optIn/useOptInApi';
import { useBootstrapBreakpoints } from '../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../hooks/bootstrap/bootstrapTypes';
import { WelcomeExperienceLogo } from '../AccountOnboardingModalComponents/WelcomeExperienceLogo';
import { IAccountOnboardingModalProps } from '../AccountOnboardingTypes';
import { TourType } from '../../../Tours/tourTypes';
import './NewExperienceWelcomeModal.css';

export const NewExperienceWelcomeModal = (props: IAccountOnboardingModalProps) => {
    const { toggleWelcome, showWelcomeModal } = props;

    const [showWelcomeAgain, setShowWelcomeAgain] = useState(true);
    const { authUser } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { toggleTour } = useLayerContext();
    const { settings } = useSettingsAndFeatures();
    const { screenSize } = useBootstrapBreakpoints();

    const { hideWelcomeModal } = useOptInApi();

    const takeATour = () => {
        toggleWelcome();
        toggleTour(TourType.Welcome);
    };

    const handleShowAgainClick = () => {
        setShowWelcomeAgain((currentState) => !currentState);
    };

    const onModalClosed = async () => {
        if (!showWelcomeAgain && authUser?.userID !== undefined) {
            await hideWelcomeModal(authUser.userID);
        }
    };

    const welcomeCtas: EfcCallToActionInfo[] = [{ text: t(WelcomeModalTKeys.GetStartedCta), emphasis: 'high', onClick: takeATour }];

    return (
        <Modal onClosed={onModalClosed} title={t(WelcomeModalTKeys.WelcomeTitle)} toggle={toggleWelcome} isOpen={showWelcomeModal} ctas={welcomeCtas} backdrop='static' className={`${screenSize < ScreenSize.s ? 'new-experience-mobile-modal-spacing' : ''}`} size='lg'>
            <Modal.Body className='mb-2'>
                <Row>
                    <Col xs='auto' className='position-relative'>
                        <WelcomeExperienceLogo />
                    </Col>
                    <Col className='jump-in-col'>
                        <Text semibold size='xl'>
                            {t(WelcomeModalTKeys.JumpInAndExplore)}
                        </Text>
                    </Col>
                </Row>

                {!!settings?.guideToNewExperienceUrl && (
                    <div className='view-guide-divider'>
                        <EfcLink href={settings?.guideToNewExperienceUrl} newWindow className='my-auto' color='primary'>
                            {t(WelcomeModalTKeys.GuideToNewExperienceLinkText)}
                            <FontAwesomeIcon icon='external-link' className='my-auto ms-2' />
                        </EfcLink>
                    </div>
                )}

                <FormGroup check className='show-welcome-again-checkbox'>
                    <Input id='show-again-checkbox' type='checkbox' onClick={handleShowAgainClick} />
                    <Label for='show-again-checkbox'>{t(WelcomeModalTKeys.DontShowLabel)}</Label>
                </FormGroup>
            </Modal.Body>
        </Modal>
    );
};
