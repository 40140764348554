import React, { useRef, useState } from 'react';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { ThemeEnum } from '../../../../hooks/useColors';
import { NotesTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { NodeNoteDTO } from '../../../../api/nodeNotes/nodeNoteApiTypes';
import { useNodeNotesApi } from '../../../../api/nodeNotes/useNodeNotesApi';

interface NewNoteProps {
    nodeId: string;
    onNewNoteCreated: (note: NodeNoteDTO) => void;
}

export const NewNote = ({ nodeId, onNewNoteCreated }: NewNoteProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Notes);

    const [canSubmit, setCanSubmit] = useState(false);

    const { showToastMessage, closeToastMessage } = useLayerContext();
    const { addNodeNoteAsync } = useNodeNotesApi();

    const noteTextRef = useRef<HTMLDivElement>(null);

    const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCanSubmit(!!(e.target.innerText.trim().length === 0));
    };

    const submitMessage = async () => {
        try {
            closeToastMessage('error-sending-note-toast');
            const noteText = noteTextRef.current?.innerText ?? '';
            noteTextRef.current!.innerText = '';
            const newNote = await addNodeNoteAsync({ nodeID: +nodeId, text: noteText });
            onNewNoteCreated(newNote);
        }
        catch (error) {
            console.error('Error sending note:', error);
            showToastMessage({
                color: ThemeEnum.Danger,
                identifier: 'error-sending-note-toast',
                message: t(NotesTKeys.ErrorSendingNote),
            });
        }
    };

    const submitMessageIfEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submitMessage();
        }
    };

    return (
        <div className='note-box-container'>
            <div
                id='userMessage'
                contentEditable={true}
                className='note-box'
                data-placeholdertext={t(NotesTKeys.InputBoxPlaceholderText)}
                onInput={handleUserInput}
                onKeyDown={submitMessageIfEnter}
                ref={noteTextRef}
            />
            <Button className='note-submit-button' color='primary' emphasis='low' icon={{ icon: ['far', 'circle-arrow-up'] }} onClick={submitMessage} disabled={canSubmit}></Button>
        </div>
    );
};