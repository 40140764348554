import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, Text, FormGroup, Label, Input } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, WelcomeModalTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { AccountOnboardingSteps, IAccountOnboardingReviewProps } from '../AccountOnboardingTypes';
import newBranding from './Images/newBranding.gif';
import { useRouting } from '../../../../features/_routing';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { CheckMark } from './CheckMark';
import './AccountOnboardingModalComponents.css';

export const AccountOnboardingReview = (props: IAccountOnboardingReviewProps) => {
    const { currentStep, toggleWelcome, dontShowWelcomeModal } = props;

    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { routeToBranding } = useRouting();
    const { authUser } = useAuthContext();

    const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

    const [smallerScreenSize, setSmallerScreenSize] = useState<boolean>(false);

    const SMALLER_WINDOW_WIDTH_SIZE = 991;

    const handleRemindMeClick = () => {
        const userLoginCount = localStorage.getItem(`login-count-${authUser?.userID}`) ?? '0';
        localStorage.setItem(`admin-welcome-remind-me-${authUser?.userID}`, userLoginCount);
        toggleWelcome();
    };

    const handleGoToBrandingClick = async () => {
        dontShowWelcomeModal();
        routeToBranding();
        toggleWelcome();
    };

    const handleResize = () => {
        setSmallerScreenSize(window.innerWidth <= SMALLER_WINDOW_WIDTH_SIZE);
    };

    useEffect(() => {
        if (currentStep === AccountOnboardingSteps.ShowReviewView) {
            window.addEventListener('resize', handleResize);
        } else {
            window.removeEventListener('resize', handleResize);
        }
    }, [currentStep]);

    return (
        <>
            {currentStep === AccountOnboardingSteps.ShowReviewView && (
                <>
                    <Row>
                        <Col sm='auto' className='empty-padding-left'>
                            <CheckMark isFinished={false} showLine={false} />
                        </Col>
                        <Col className='pt-2'>
                            <Row className='mb-3'>
                                <Text size='xl'>{t(WelcomeModalTKeys.AccountOnboardingUpNextTitle)}</Text>
                            </Row>

                            <Row className='mb-3'>
                                <Text>{t(WelcomeModalTKeys.AccountOnboardingUpNextSubTitle)}</Text>
                            </Row>

                            {!smallerScreenSize && (
                                <Row className='mb-5'>
                                    <img src={newBranding} alt='newBranding gif' />
                                </Row>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col sm='auto'>
                            {/* DO NOTHING - This is as designed, this is here just to be reassuring */}
                            <FormGroup check className='pt-3'>
                                <Input id='show-again-checkbox' type='checkbox' className='modal-pointer' onClick={() => setDontShowAgain(!dontShowAgain)} />
                                <Label for='show-again-checkbox' className='modal-pointer'>
                                    {t(WelcomeModalTKeys.DontShowLabel)}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <div className='float-end'>
                                <Button color='primary' emphasis='med' onClick={handleRemindMeClick} className={`btn-lg remind-button ${smallerScreenSize ? 'smaller-view-buttons' : ''}`} disabled={dontShowAgain}>
                                    Remind Me Later
                                </Button>

                                <Button color='primary' onClick={handleGoToBrandingClick} className={`btn-lg ${smallerScreenSize ? 'smaller-view-buttons' : ''}`}>
                                    Go To Branding
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
