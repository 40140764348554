import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { AuthUserDTO, IsAuthenticatedResponseDTO, SelectAuthAccountsDTO, UserDTO } from './authApiTypes';

export const useAuthApi = () => {

    const getAuthenticatedUser = async (): Promise<AuthUserDTO> => {
        const resp: AxiosResponse = await Api.get('api/authentication/SignedInUser');
        return resp.data;
    };

    const getCurrentUser = async (isSystemCall = false): Promise<UserDTO> => {
        const headers = {
            'Is-System-Call': isSystemCall
        };
        const resp: AxiosResponse = await Api.get('api/authentication/GetCurrentUser', { headers: headers });
        return resp.data;
    };

    const getIsAuthenticatedAsync = async (): Promise<IsAuthenticatedResponseDTO> => {
        const resp: AxiosResponse = await Api.get('api/authentication/IsAuthenticated');
        return resp.data;
    };

    const getUserAccounts = async (): Promise<SelectAuthAccountsDTO> => {
        const resp: AxiosResponse = await Api.get('api/authentication/GetUserAccounts');
        return resp.data;
    };

    const getOneTimeToken = async (): Promise<string> => {
        const resp: AxiosResponse = await Api.get('api/authentication/RubexOneTimeToken');
        return resp.data;
    };

    const loginWithToken = async (token: string): Promise<AuthUserDTO> => {
        const resp: AxiosResponse = await Api.get(`api/authentication/Login/${token}`);
        return resp.data;
    };

    const logout = async (): Promise<void> => {
        const resp: AxiosResponse = await Api.delete('api/authentication/Logout');
        return resp.data;
    };

    const switchToAccount = async (roleId: number): Promise<AuthUserDTO> => {
        const resp: AxiosResponse = await Api.post(`api/authentication/SwitchAccount/${roleId}`);
        return resp.data;
    };

    return {
        getAuthenticatedUser,
        getCurrentUser,
        getIsAuthenticatedAsync,
        loginWithToken,
        getOneTimeToken,
        logout,
        getUserAccounts,
        switchToAccount
    };
};