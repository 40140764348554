import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { useAncestorPathUtility } from '../../../../../hooks/useAncestorPathUtility';
import { useColors } from '../../../../../hooks/useColors';
import { NodeProfileFields } from '../Fields/NodeProfileFields/NodeProfileFields';
import { NodeProfileItemDisplay } from '../NodeProfileDisplay/NodeProfileItemDisplay';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useProfileUtilities } from '../../../../../hooks/useProfileUtilities';
import { NodeIcon } from '../../../../Icons/NodeIcon/NodeIcon';
import { NodeProfileDTO } from '../../../../../api/nodeProfiles/nodeProfileApiTypes';

export interface AssociatedNodeProfilesProps {
    associatedNodeProfiles: NodeProfileDTO[];
    nodeId: string;
}

export const AssociatedNodeProfiles = ({ associatedNodeProfiles, nodeId }: AssociatedNodeProfilesProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Metadata);
    const colors = useColors();
    const { loadAncestorsAsync, getAncestorPath } = useAncestorPathUtility();
    const { hasAdditionalProfileItems, hasAppliedProfile } = useProfileUtilities();

    useEffect(() => {
        loadAncestorsAsync(nodeId);
    }, [nodeId]);

    return (
        <>
            <hr className='mt-4'></hr>
            <Text semibold tag='h5' className='mt-2'>Associated Profiles</Text>
            {associatedNodeProfiles.map((associatedNodeProfile, index) => {
                const ancestorPath = getAncestorPath(associatedNodeProfile.nodeID?.toString() ?? '');
                return (
                    <React.Fragment key={index}>
                        <Row className='mt-3'>
                            <Col className='d-flex align-items-center'>
                                <NodeIcon nodeType={ancestorPath.type} fileExtension='' />
                                <Text semibold className='ms-1' size='lg'>
                                    {ancestorPath.name}
                                </Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Text className='ms-1' size='lg' color={colors.atlantisDisabled}>
                                    {ancestorPath.path}
                                </Text>
                            </Col>
                        </Row>

                        {!!hasAppliedProfile(associatedNodeProfile) && (
                            <>
                                <Row className='mt-3'>
                                    <div className='d-flex'>
                                        <Text size='lg' className='pe-4'>{associatedNodeProfile.profileName}</Text>
                                    </div>
                                </Row>
                                <NodeProfileFields>
                                    {associatedNodeProfile.profileItems.filter(x => !!x.profileID).map(nodeProfileItem => (
                                        <NodeProfileItemDisplay key={nodeProfileItem.id}
                                            showEditOptions={false}
                                            onDeleteClick={() => { /* do nothing, canEdit is false */ }}
                                            onEditClick={() => { /* do nothing, canEdit is false */ }}
                                            nodeId={nodeId}
                                            nodeProfileItem={nodeProfileItem}
                                            hasWritePermission={false} />
                                    ))}
                                </NodeProfileFields>
                            </>
                        )}

                        {!!hasAdditionalProfileItems(associatedNodeProfile) && (
                            <Row className='g-0'>
                                <Text size='lg' className='pt-3 pb-3'>{t(MetadataTKeys.AdditionalProfileItems)}</Text>
                                {associatedNodeProfile.profileItems.filter(x => !x.profileID).map(nodeProfileItem => (
                                    <NodeProfileItemDisplay key={nodeProfileItem.id}
                                        hasWritePermission={false}
                                        onDeleteClick={() => { /* do nothing, canEdit is false */ }}
                                        onEditClick={() => { /* do nothing, canEdit is false */ }}
                                        nodeId={nodeId}
                                        nodeProfileItem={nodeProfileItem}
                                        showEditOptions={false} />
                                ))}
                            </Row>
                        )}

                    </React.Fragment>
                );
            })}
        </>
    );
};
