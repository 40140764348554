import React, { useEffect, useRef, useState } from 'react';
import { Container, Col, Row, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Label, FormGroup, Tooltip, INode, SelectorNodeType } from '@efilecabinet/efc-atlantis-components';
import { IUseModalProps } from '../../../../hooks/useModal';
import { useIcons } from '../../../../hooks/useIcons';
import { useFileUtilities } from '../../../../hooks/useFileUtilities';
import { PreviewTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useColors } from '../../../../hooks/useColors';
import { useVertNavState } from '../../../../hooks/useVertNavState';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { useFileVersionApi } from '../../../../api/fileVersions/useFileVersionApi';
import { Previewer } from '../../Previewer';
import { NodeSelectorModal } from '../../../Selectors/NodeSelectorModal';
import { PreviewerLoadingCard } from '../../PreviewerLoadingCard';
import { NodeDetailsProvider } from '../../../NodeSideSheet/_context/NodeDetailsContext/NodeDetailsProvider';
import { NodeSideSheet } from '../../../NodeSideSheet/NodeSideSheet';
import { useNavStateStyling } from '../../../../hooks/useNavStateStyling';
import { useResizableColUtilities } from '../../../../hooks/useResizableColUtilities';
import { useAccountFeatureValidator } from '../../../../hooks/useAccountFeatureValidator';
import { useSystemPermissionsValidator } from '../../../../hooks/useSystemPermissionsValidators';
import { NodeDTO, NodeType } from '../../../../api/node/nodeApiTypes';
import { FileVersionDTO } from '../../../../api/fileVersions/fileVersionApiTypes';
import { AccountFeatureEnum } from '../../../../api/accountFeatures/accountFeatureApiTypes';
import { SystemPermissionEnum } from '../../../../api/roles/roleApiTypes';
import './FullScreenPreviewerModal.css';

interface FullScreenPreviewerModalProps extends IUseModalProps {
    node: NodeDTO;
    fileVersionId?: number;
    onClose: (isConfirmed: boolean) => void;
}

export const FullScreenPreviewerModal = (props: FullScreenPreviewerModalProps) => {
    const { node, fileVersionId, onClose, ...otherProps } = props;

    const { t } = useSafeTranslation(TranslationFiles.Preview);
    const { getNodeIconProps } = useIcons();
    const { vertNavState } = useVertNavState();
    const { getFileExtension, convertFileVersionDateTime } = useFileUtilities();
    const { getPagesSubClasses, getPageContainerSubClasses } = useNavStateStyling();
    const { getRowSubClasses } = useResizableColUtilities();
    const { atlantisPrimary } = useColors();
    const { openModal } = useLayerContext();
    const { authUser, hasAuthUser } = useAuthContext();
    const { getFileVersions } = useFileVersionApi();
    const { hasFeatureOnAccount } = useAccountFeatureValidator();
    const { hasPermissionOnAccount } = useSystemPermissionsValidator();

    const fullScreenPreviewerDiv = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedNode, setSelectedNode] = useState<NodeDTO>(node as NodeDTO);
    const [primaryFileVersionId, setPrimaryFileVersionId] = useState<number>();
    const [selectedNodeFileVersions, setSelectedNodeFileVersions] = useState<FileVersionDTO[]>();
    const [selectedVersionId, setSelectedVersionId] = useState<number | undefined>(fileVersionId ?? node?.fileInfo?.id);
    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [showDualScreen, setShowDualScreen] = useState<boolean>(canShowDualScreen(authUser?.accountID ?? 0));
    const tooltipTarget = `fullscreen-previewer-node-selector-${node?.id ?? 0}`;

    const toggleTooltip = () => setIsToolTipOpen((prevState) => !prevState);

    function canShowDualScreen(accountId: number) {
        return hasFeatureOnAccount(accountId, AccountFeatureEnum.DualScreenPreview) && hasPermissionOnAccount(accountId, SystemPermissionEnum.PreviewerTools);
    }

    const onPropChanged = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedVersionId(parseInt(value));
    };

    const handleClose = () => {
        setIsOpen(false);
        onClose(false);
    };

    const handleNodeSelectorChange = (selectedNodes: INode[]) => {
        setIsLoading(true);
        setSelectedVersionId(undefined);
        setSelectedNode(selectedNodes[0] as NodeDTO);
    };

    const fetchFileVersions = (nodeId: string, fileVersionId?: number) => {
        try {
            setIsLoading(true);
            getFileVersions(nodeId).then((versions: FileVersionDTO[]) => {
                const primaryFileVersion = versions.find((v: FileVersionDTO) => v.isActive);
                setPrimaryFileVersionId(primaryFileVersion?.id ?? 0);
                setSelectedNodeFileVersions(versions);
                if (!fileVersionId) {
                    setSelectedVersionId(versions.find((v: FileVersionDTO) => v.isActive)?.id ?? 0);
                }
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onFolderClicked = () => {
        openModal((closeModalFn) =>
            <NodeSelectorModal closeModalFn={closeModalFn} activeAccountId={authUser?.accountID ?? node.accountID} onSelect={handleNodeSelectorChange} selectorNodeType={SelectorNodeType.File} />
        );
    };

    useEffect(() => {
        if (!!selectedNode && selectedNode !== node && !!selectedNode.id) {
            fetchFileVersions(selectedNode.id);
        }
    }, [selectedNode]);

    useEffect(() => {
        if (!!selectedNode && !!selectedNode.id) {
            fetchFileVersions(selectedNode.id, selectedVersionId);
        }
    }, []);

    useEffect(() => {
        if (!!hasAuthUser && !!authUser) {
            setShowDualScreen(canShowDualScreen(authUser.accountID));
        }
    }, [hasAuthUser]);

    //TODO: Update to Nautilus UI Component.
    // Please note that because of an issue with how the component library handles the fullscreen prop, this uses Reactstrap for the modal and the header is bootstrap. This will be fixed when we move things over to Nautilus UI.
    return (
        <Modal className='full-screen-previewer-modal' toggle={handleClose} isOpen={isOpen} fullscreen {...otherProps}>
            <div className='modal-header px-4 full-screen-previewer-header'>
                <div className='d-flex flex-wrap align-items-center justify-content-start w-75'>
                    <div className='d-flex align-items-center mx-3'>
                        <FontAwesomeIcon icon={getNodeIconProps(NodeType.File, getFileExtension(node.name)).icon} size='lg' />
                        <h1 className='full-screen-previewer-title'>{node.name}</h1>
                    </div>
                    {!isLoading && !!selectedNode && !!selectedNodeFileVersions && !!selectedNodeFileVersions.length && (
                        <div className='d-flex align-items-center mx-3'>
                            <Label for='full-screen-previewer-versions' className='full-screen-previewer-version-label me-2'>{t(PreviewTKeys.FullScreenVersionLabel)}:</Label>
                            <FormGroup className='m-0 '>
                                <Input id='full-screen-previewer-versions' title='Document Full Previewer Version Selector' type='select' defaultValue={selectedVersionId} onChange={onPropChanged} className='full-screen-previewer-versions-selector mt-3'>
                                    {selectedNodeFileVersions.map((version: FileVersionDTO) => (
                                        <option key={version.id} value={version.id}>
                                            {version.id === primaryFileVersionId ? `${convertFileVersionDateTime(version.createdOn)} (Primary)` : convertFileVersionDateTime(version.createdOn)}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    )}
                    <div className='mx-3'>
                        <FontAwesomeIcon id={tooltipTarget} icon={['far', 'folder-open']} size='lg' color={atlantisPrimary} onClick={onFolderClicked} />
                        <Tooltip target={tooltipTarget} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>{t(PreviewTKeys.FullScreenNodeSelectorTooltip)}</Tooltip>
                    </div>
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <ModalBody>
                {!!selectedNode && !!selectedVersionId && !isLoading
                    ? <div className={`w-100 h-100 ${getPagesSubClasses(vertNavState)}`}>
                        <Container fluid className={`page-container ${getPageContainerSubClasses(vertNavState)}`}>
                            <Row className={getRowSubClasses('layout')}>
                                <Col className='h-100 full-screen-previewer-col'>
                                    <Previewer previewerNode={selectedNode} fileVersionId={selectedVersionId} previewerContainer={fullScreenPreviewerDiv} />
                                </Col>
                                <NodeDetailsProvider>
                                    <NodeSideSheet showFullScreenButton={false} enablePreviewer={showDualScreen} />
                                </NodeDetailsProvider>
                            </Row>
                        </Container>
                    </div>
                    : <PreviewerLoadingCard />
                }
            </ModalBody>
        </Modal>
    );
};