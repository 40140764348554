import React, { useRef, useState, useEffect } from 'react';
import { Button, Alert } from '@efilecabinet/efc-atlantis-components';
import { PreviewTKeys, FilePasswordTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useNodeDetailsContext } from '../../_context/NodeDetailsContext/NodeDetailsContext';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { FullScreenPreviewerModal } from '../../../Previewer/PreviewerModals/FullScreenPreviewerModal/FullScreenPreviewerModal';
import { Previewer } from '../../../Previewer/Previewer';
import { FilePasswordModal, FilePasswordModalUseEnum } from '../../../FilePassword/FilePasswordModals/FilePasswordModal/FilePasswordModal';
import { NodeType } from '../../../../api/node/nodeApiTypes';

interface PreviewProps {
    onClose?: () => void;
    active: boolean;
    showFullScreenButton: boolean;
}

export const Preview = (props: PreviewProps) => {
    const { onClose, active, showFullScreenButton } = props;

    const { t } = useSafeTranslation(TranslationFiles.Preview);
    const { t: tFilePassword } = useSafeTranslation(TranslationFiles.FilePassword);

    const { activeNode, activeFileVersion, fetchFileVersions, isLoadingNode, hasFilePassword } = useNodeDetailsContext();
    const { openModal } = useLayerContext();

    const [hasEnteredCorrectPassword, setHasEnteredCorrectPassword] = useState<boolean>(false);
    const [showFilePasswordModal, setShowFilePasswordModal] = useState(false);

    const sideSheetPreviewerDiv = useRef<HTMLDivElement>(null);

    const onFullScreenButtonClicked = () => {
        if (!!activeNode && !isLoadingNode && activeNode.systemType == NodeType.File) {
            openModal((closeModalFn) =>
                <FullScreenPreviewerModal node={activeNode} fileVersionId={activeFileVersion?.id} onClose={closeModalFn} />);
        } else {
            console.log('There was an error opening the full screen previewer modal. Invalid node or node type.');
        }
    };
    
    const readyToLoadPreviewer = !!activeNode && activeNode?.fileInfo?.id && !isLoadingNode;

    useEffect(() => {
        if (active && hasFilePassword && !hasEnteredCorrectPassword) {
            setShowFilePasswordModal(true);
        }
    }, [active, hasFilePassword, hasEnteredCorrectPassword]);

    useEffect(() => {
        if (!!activeNode && hasEnteredCorrectPassword) {
            setHasEnteredCorrectPassword(false);
        }
    }, [activeNode]);

    useEffect(() => {
        const fileVersionListener = async function (event: any) {
            try {
                const eventData = JSON.parse(event.data);
                if (eventData.type === 'previewerX' && !!activeNode) {
                    fetchFileVersions(activeNode.id);
                }
            } catch {
                // There will be a lot of messages that are not meant for us, so we just ignore them
            }
        };

        window.addEventListener('message', fileVersionListener);

        return () => window.removeEventListener('message', fileVersionListener);
    }, []);

    return (
        <>
            <SidesheetPage
                title={t(PreviewTKeys.Title)}
                onClose={onClose}
                dataId='preview-sideSheet-pane'
                hidden={!active}
                contentNeedsFullWidth={true}
                secondaryButton={<Button color='primary' emphasis='med' onClick={onFullScreenButtonClicked} icon={{ icon: 'expand' }} hidden={!showFullScreenButton} disabled={hasFilePassword && !hasEnteredCorrectPassword}>{t(PreviewTKeys.GoFullScreen)}</Button>}>

                {hasFilePassword && !hasEnteredCorrectPassword
                    ? <Alert color='danger'>{tFilePassword(FilePasswordTKeys.HasFilePasswordMessage)}</Alert>
                    : <Previewer previewerNode={activeNode} fileVersionId={activeNode?.fileInfo?.id} isLoading={!readyToLoadPreviewer} previewerContainer={sideSheetPreviewerDiv} />}
            </SidesheetPage>

            <FilePasswordModal show={showFilePasswordModal} toggle={() => setShowFilePasswordModal((prevState) => !prevState)} setHasEnteredCorrectPassword={setHasEnteredCorrectPassword} modalUse={FilePasswordModalUseEnum.Preview} nodeId={activeNode?.id} />
        </>
            );
};
