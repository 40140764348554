import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { FilePasswordOperationDTO, FilePasswordOperationResponseDTO } from './filePasswordApiTypes';

export const useFilePasswordApi = () => {

    const nodeHasFilePasswordAsync = async (nodeId: string): Promise<boolean> => {
        const resp: AxiosResponse = await Api.get(`api/FilePassword/${nodeId}`);
        return resp.data;
    };

    const performFilePasswordOperationAsync = async (filePasswordOperation: FilePasswordOperationDTO): Promise<FilePasswordOperationResponseDTO> => {
        const resp: AxiosResponse = await Api.post('api/FilePassword', filePasswordOperation);
        return resp.data;
    };

    return {
        nodeHasFilePasswordAsync,
        performFilePasswordOperationAsync,
    };
};
