import React from 'react';
import { ApplyAFolderTemplate } from './TourTypes/Documents/ApplyAFolderTemplate';
import { CreateACabinet } from './TourTypes/Documents/CreateACabinet';
import { RestoreDeletedFile } from './TourTypes/Documents/RestoreDeletedFile';
import { WelcomeTour } from './TourTypes/Home/WelcomeTour';
import { CreateNewUser } from './TourTypes/People/CreateNewUser';
import { HowToUseESignature } from './TourTypes/Work/HowToUseESignature';

export const Tours = () => {

    return (
        <>
            {/*Home*/}
            <WelcomeTour />

            {/*People*/}
            <CreateNewUser />

            {/*Documents*/}
            <RestoreDeletedFile />
            <CreateACabinet />
            <ApplyAFolderTemplate />

            {/*Work*/}
            <HowToUseESignature />

            {/*Account*/}
        </>
    );
};
