import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useGovernanceRouting = () => {

    const navigate = useNavigate();

    const getLinkToGovernance = () => {
        return RoutePath.Governance;
    };

    const routeToGovernance = () => {
        navigate(getLinkToGovernance());
    };

    return {
        getLinkToGovernance,
        routeToGovernance,
    };
};
