export enum SingleRoute {
    AuditLogs = '/auditlogs',
    FileProcessingQueue = '/fileprocqueue',
    Forms = '/forms',
    Governance = '/governance',
    Home = '/',
    Login = '/login',
    LoginOneTimeToken = '/login/:token',
    Recents = '/recents',
    RecycleBin = '/recyclebin',
    Reports = '/reports',
    WorkYouCareAbout = '/workyoucareabout',
}

export enum AccountSettingsRouteParams {
    MappingId = ':mappingId',
    ConfigurationId = ':configurationId',
}

export enum AccountSettingsRoutePath {
    AccountSettingsIndex = '/accountsettings',
    AccountSettings = '/accountsettings/setting',
    SingleSignOn = '/accountsettings/sso',
    SamlValidate = '/accountsettings/sso/validate',
    SamlNew = '/accountsettings/sso/new',
    SamlEdit = '/accountsettings/sso/:configurationId',
    Branding = '/accountsettings/branding',
    AccountEmailImports = '/accountsettings/emailimports',
    AccountEmailImportNew = '/accountsettings/emailimports/new',
    AccountEmailImportEdit = 'accountsettings/emailimports/:mappingId',
}

export enum CheckedOutRoutePath {
    CheckedOutIndex = '/checkedout',
    CheckedOutPersonal = '/checkedout/personal',
    CheckedOutAccount = '/checkedout/account'
}

export enum DocumentRouteParams {
    CollectionId = ':collectionId',
    ContainerNodeId = ':containerNodeId',
    DefaultCollectionId = ':defaultCollectionId',
    NodeIds = ':nodeIds', // comma seperated list of node ids, can also be a single node id
    RootNodeId = ':rootNodeId',
    SelectedDetail = ':selectedDetail',
    ShowFullPreview = ':showFullPreview',
    ParentId = ':parentId',
}

export enum DocumentRoutePath {
    Documents = '/documents',
    Node = '/documents/node/:nodeIds',
    NodeDetail = '/documents/node/:nodeIds/:selectedDetail',
    NodeContainer = '/documents/node/:containerNodeId/:nodeIds/:selectedDetail',
    Collections = '/documents/collections',
    Collection = '/documents/collections/:collectionId',
    CollectionNodeDetail = '/documents/collections/:collectionId/:nodeIds/:selectedDetail',
}

export enum SelectedDetail {
    Automation = 'eventtriggers',
    Comments = 'comment',
    Details = 'details',
    FilePassword = 'password',
    FileVersions = 'fileVersions',
    Governance = 'timeTriggers',
    History = 'auditlogs',
    Metadata = 'profile',
    Permissions = 'permission',
    Preview = 'nodePreview',
    FullPreview = 'fullPreview'
}

export enum DocumentRequestRouteParams {
    InboxRequestId = ':inboxRequestId',
    SentRequestId = ':sentRequestId',
    AccountRequestId = ':accountRequestId',
    TemplateRequestId = ':templateRequestId',
}

export enum DocumentRequestRoutePath {
    DocumentRequestIndex = '/docrequests',
    DocumentRequestInbox = '/docrequests/inbox',
    DocumentRequestNew = '/docrequests/new',
    DocumentRequestInboxEdit = '/docrequests/inbox/:inboxRequestId',
    DocumentRequestTemplates = '/docrequests/templates',
    DocumentRequestTemplateNew = '/docrequests/newTemplate',
    DocumentRequestTemplateEdit = '/docrequests/templates/:templateRequestId',
    DocumentRequestSent = '/docrequests/sent',
    DocumentRequestSentEdit = '/docrequests/sent/:sentRequestId',
    DocumentRequestAccountRequests = '/docrequests/all',
    DocumentRequestAccountRequestEdit = '/docrequests/all/:accountRequestId',
}

export enum ErrorRoutePath {
    PageNotFound = '/pagenotfound',
}

export enum ESignatureRoutePath {
    ESignatureIndex = '/esignature',
    ESignatureSent = '/esignature/sent',
    ESignatureAll = '/esignature/all',
    ESignatureTemplates = '/esignature/templates',
}

export enum FolderTemplateRouteParams {
    LibraryId = ':libraryId',
    TemplateId = ':templateId',
}

export enum FolderTemplateRoutePath {
    FolderTemplatesIndex = '/foldertemplates',
    FolderTemplateLibrary = '/foldertemplates/:libraryId',
    FolderTemplateEdit = '/foldertemplates/:libraryId/template/:templateId',
}
export enum LinkedViewRouteParams {
    LinkedNodeId = ':linkedNodeId',
    ContainerNodeId = ':containerNodeId',
    DetailsNodeIds = ':detailsNodeIds',
    SelectedDetail = ':selectedDetail',
    ShowFullPreview = ':showFullPreview',
}

export enum LinkedViewRoutePath {
    LinkedView = '/linkedview', //remove after setup
    LinkedNode = '/linkedview/linkedNode/:linkedNodeId',
    LinkedNodeContainer = '/linkedview/linkedNode/:linkedNodeId/container/:containerNodeId',
    LinkedNodeDetail = '/linkedview/linkedNode/:linkedNodeId/detailsNodeIds/:detailsNodeIds/:selectedDetail'
}

export enum MetadataRoutePath {
    MetadataIndex = '/metadata',
    MDFields = '/metadata/fields',
    MDGroups = '/metadata/groups',
}

export enum ProfileRouteParams {
    ProfileId = ':profileId',
    ProfileItemId = ':profileItemId',
}

export enum ProfileRoutePath {
    ProfilesIndex = '/profiles',
    Profiles = '/profiles/profiles',
    ProfileNew = '/profiles/profiles/new',
    ProfileEdit = '/profiles/profiles/:profileId',
    ProfileItems = '/profiles/profileItems',
    ProfileItemNew = '/profiles/profileItems/new',
    ProfileItemEdit = '/profiles/profileItems/:profileItemId',
}

export enum SalesforceRouteParams {
    MappingId = ':mappingId',
}

export enum SalesforceRoutePath {
    Salesforce = '/salesforce',
    SalesforceMappingNew = '/salesforce/new',
    SalesforceMappingEdit = '/salesforce/:mappingId',
}

export enum SearchRouteParams {
    UserSearchId = ':userSearchId',
    SharedSearchId = ':sharedSearchId',
}

export enum SearchRoutePath {
    Search = '/search',
    UserSearch = '/search/userSearch/:userSearchId',
    SharedSearch = '/search/sharedSearch/:sharedSearchId',
}

export enum SecurityPoliciesRouteParams {
    PolicyId = ':policyId',
}

export enum SecurityPoliciesRoutePath {
    SecurityPolicies = '/securitypolicies',
    SecurityPolicyNew = '/securitypolicies/new',
    SecurityPolicyEdit = '/securitypolicies/:policyId',
}

export enum SystemLogsRoutePath {
    SystemLogsServer = '/systemlogs/server',
    SystemLogsClient = '/systemlogs/client',
}

export enum ToDoListRoutePath {
    ToDoListIndex = '/todolist/open',
    OpenToDos = '/todolist/open',
    CompletedToDos = '/todolist/completed',
    DismissedToDos = '/todolist/dismissed',
}

export enum UserSettingsRouteParams {
    MappingId = ':mappingId',
}

export enum UserSettingsRoute {
    UserSettingsIndex = '/usersettings',
    UserSettings = '/usersettings/mysettings',
    MyAccounts = '/usersettings/myaccounts',
    IntegratedProducts = '/usersettings/integratedproducts',
    UserEmailImports = '/usersettings/emailimports',
    UserEmailImportNew = '/usersettings/emailimports/new',
    UserEmailImportEdit = '/usersettings/emailimports/:mappingId',
}

export enum UserRouteParams {
    UserId = ':userId',
    GroupId = ':groupId',
    AccessLinkId = ':accessLinkId',
}

export enum UserRoutePath {
    UserIndex = '/user',
    Users = '/user/users',
    UserNew = '/user/users/new',
    UserEdit = '/user/users/:userId',
    Groups = '/user/groups',
    GroupNew = '/user/groups/new',
    GroupEdit = '/user/groups/:groupId',
    UserSessions = '/user/usersessions',
    AccessLinks = '/user/accesslinks',
    AccessLinkEdit = '/user/accesslinks/:accessLinkId',
    UserDirectories = '/user/directories',
}

export enum UtilitiesRoutePath {
    UtilitiesIndex = '/utilities',
    CSVImport = '/utilities/csv/import',
    CSVExport = '/utilities/csv/export',
    AccountTemplateExport = '/utilities/accounttemplate/export',
    AccountTemplateImport = '/utilities/accounttemplate/import',
}

export enum WorkflowRouteParams {
    WorkflowId = ':workflowId',
    InstanceId = ':instanceId',
}

export enum WorkflowRoutePath {
    WorkflowIndex = '/workflow',
    Workflows = '/workflow/workflows',
    WorkflowEdit = '/workflow/workflows/:workflowId',
    WorkflowInstances = '/workflow/instances',
    WorkflowInstanceEdit = '/workflow/instances/:instanceId',
    WorkflowDashboard = '/workflow/dashboard',
    WorkflowHistory = '/workflow/history',
}

export type RoutePath = SingleRoute | AccountSettingsRoutePath | CheckedOutRoutePath | DocumentRoutePath | DocumentRequestRoutePath | ESignatureRoutePath | ErrorRoutePath | FolderTemplateRoutePath | LinkedViewRoutePath | MetadataRoutePath | ProfileRoutePath | SalesforceRoutePath | SearchRoutePath | SecurityPoliciesRoutePath | SystemLogsRoutePath | ToDoListRoutePath | UserRoutePath | UserSettingsRoute | UtilitiesRoutePath | WorkflowRoutePath;
export const RoutePath = { ...SingleRoute, ...AccountSettingsRoutePath, ...CheckedOutRoutePath, ...DocumentRoutePath, ...DocumentRequestRoutePath, ...ESignatureRoutePath, ...ErrorRoutePath, ...FolderTemplateRoutePath, ...LinkedViewRoutePath, ...MetadataRoutePath, ...ProfileRoutePath, ...SalesforceRoutePath, ...SearchRoutePath, ...SecurityPoliciesRoutePath, ...SystemLogsRoutePath, ...ToDoListRoutePath, ...UserRoutePath, ...UserSettingsRoute, ...UtilitiesRoutePath, ...WorkflowRoutePath };
