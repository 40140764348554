import { NodeDTO, NodeType } from '../api/node/nodeApiTypes';
import { FileTypeTKeys, TranslationFiles, useSafeTranslation } from './useSafeTranslation';

export const useFileUtilities = () => {
    const { t } = useSafeTranslation(TranslationFiles.FileTypes);

    const getFileExtension = (filename: string) => {
        return '.' + filename?.split('.').pop()?.toLowerCase();
    };

    const convertFileVersionDateTime = (input: string) => {
        const date = new Date(input);
        return date.toLocaleDateString() + ' | ' + date.toLocaleTimeString([], { timeStyle: 'short' });
    };

    const nodeTypeIsNotFile = (node: NodeDTO | undefined) => {
        // prettier-ignore
        return (
            node?.systemType === NodeType.Account ||
            node?.systemType === NodeType.Cabinet ||
            node?.systemType === NodeType.Drawer ||
            node?.systemType === NodeType.Folder ||
            node?.systemType === NodeType.TemplateFolder ||
            node?.systemType === NodeType.Template
        );
    };

    const nodeIsContainer = (systemType: NodeType) => {
        switch (systemType) {
            case NodeType.Account:
            case NodeType.Cabinet:
            case NodeType.Drawer:
            case NodeType.Folder:
                return true;
            case NodeType.File:
            case NodeType.TemplateFile:
            default:
                return false;
        }
    };

    const getNodeTypeText = (systemType: NodeType) => {
        switch (systemType) {
            case NodeType.Account:
                return t(FileTypeTKeys.Account);
            case NodeType.Cabinet:
                return t(FileTypeTKeys.Cabinet);
            case NodeType.Drawer:
                return t(FileTypeTKeys.Drawer);
            case NodeType.Folder:
                return t(FileTypeTKeys.Folder);
            case NodeType.TemplateFolder:
                return t(FileTypeTKeys.TemplateFolder);
            case NodeType.Template:
                return t(FileTypeTKeys.Template);
            case NodeType.File:
            default:
                return t(FileTypeTKeys.File);
        }
    };

    return {
        getFileExtension,
        convertFileVersionDateTime,
        nodeTypeIsNotFile,
        getNodeTypeText,
        nodeIsContainer,
    };
};
