import React from 'react';
import { SurveyModalTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { EfcCallToActionInfo, Modal } from '@efilecabinet/efc-atlantis-components';

interface SurveyModalProps {
    isOpen: boolean;
    toggle: () => void;
}

export const SurveyModal = ({ isOpen, toggle }: SurveyModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.SurveyModal);

    const openSurvey = () => {
        window.open('https://www.surveymonkey.com/r/Modal', '_blank');
        toggle();
    };

    const ctas: EfcCallToActionInfo[] = [
        { text: t(SurveyModalTKeys.NoThanks), emphasis: 'med', color: 'primary', onClick: toggle },
        { text: t(SurveyModalTKeys.TakeSurvey), emphasis: 'high', onClick: openSurvey },
    ];

    return (
        <Modal title={t(SurveyModalTKeys.Title)} isOpen={isOpen} ctas={ctas} toggle={toggle} centered={true} backdrop='static'>
            <Modal.Body className='mb-3'>{t(SurveyModalTKeys.Body)}</Modal.Body>
        </Modal>
    );
};
