import '@efilecabinet/nautilus-ui/dist/assets/index.css'; // This imports bootstrap as well
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './locales/i18n';
import { IAtlantisHtmlDocument } from './IAtlantisHtmlDocument';

import './assets/fonts/efc-icons-font.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
(document as IAtlantisHtmlDocument).platform = 'atlantis';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <React.Suspense>
            <BrowserRouter basename={baseUrl as string}>
                <App />  
            </BrowserRouter>
        </React.Suspense>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

