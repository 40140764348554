import { NodePermissionDTO, NodePermissionInheritanceEnum } from '../../api/nodePermissions/nodePermissionApiTypes';
import { RoleDTO } from '../../api/roles/roleApiTypes';

export enum NodePermissionLevelEnum {
    Default,
    View,
    Download,
    Create,
    UploadFiles,
    UploadDelete,
    CreateDirectories,
    CreateDirectoriesDelete,
    Write,
    Delete,
    Admin,
    Override,
    RestrictAll,
    Remove,
}

export enum PermissionTypes {
    View = 'view',
    Download = 'download',
    Upload = 'upload',
    CreateSubitems = 'createSubitems',
    Edit = 'edit',
    Delete = 'delete',
    Admin = 'admin',
    Lock = 'lock',
    Block = 'block',
}

export const initialPermissionState: NodePermissionDTO = {
    admin: false,
    childPermission: {
        view: false,
        download: false,
        uploadFiles: false,
        createDirectories: false,
        write: false,
        delete: false,
        inheritanceBehavior: NodePermissionInheritanceEnum.Default,
    } as NodePermissionDTO,
    createDirectories: false,
    createdBy: '',
    createdByUsername: '',
    createdOn: new Date(),
    delete: false,
    download: false,
    enforce: false,
    id: 0,
    inheritanceBehavior: NodePermissionInheritanceEnum.Default,
    name: '',
    preview: false,
    read: false,
    remove: false,
    restrict: false,
    role: '',
    roleData: {} as RoleDTO,
    systemType: 0,
    uploadFiles: false,
    view: true,
    write: false,
};
