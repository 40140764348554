import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { QueryActiveSessionsDTO, ActiveSessionDTO } from './userSessionApiTypes';

export const useUserSessionsApi = () => {

    const getUserSessions = async (accountID: number, queryParams: QueryActiveSessionsDTO): Promise<ActiveSessionDTO[]> => {
        const resp: AxiosResponse = await Api.post(`api/userSessions/${accountID}`, queryParams);
        return resp.data;
    };

    const endUserSessions = async (roleIDs: number[]): Promise<void> => {
        await Api.post('api/userSessions/remove', roleIDs);
    };

    return {
        getUserSessions,
        endUserSessions
    };
};
