import { useEffect, useState } from 'react';

export const useWindowWidth = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const onScreenResized = () => {
        setTimeout(() => { // on maximize, window.innerWidth is not updated immediately
            setWindowWidth(window.innerWidth);
        });
    };

    useEffect(() => {
        window.addEventListener('resize', onScreenResized);
        return () => {
            return window.removeEventListener('resize', onScreenResized);
        };
    }, []);

    return {
        windowWidth,
    };
};