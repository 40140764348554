import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useESignatureRouting = () => {

    const navigate = useNavigate();

    // ESignature Index

    const getLinkToESignatureIndex = () => {
        return RoutePath.ESignatureIndex;
    };

    const routeToESignatureIndex = () => {
        navigate(getLinkToESignatureIndex());
    };

    // Sent Transactions

    const getLinkToSentESignatures = () => {
        return RoutePath.ESignatureSent;
    };

    const routeToSentESignatures = () => {
        navigate(getLinkToSentESignatures());
    };

    // Account Transactions

    const getLinkToAccountESignatures = () => {
        return RoutePath.ESignatureAll;
    };

    const routeToAccountESignatures = () => {
        navigate(getLinkToAccountESignatures());
    };

    // Templates

    const getLinkToESignatureTemplates = () => {
        return RoutePath.ESignatureTemplates;
    };

    const routeToESignatureTemplates = () => {
        navigate(getLinkToESignatureTemplates());
    };

    return {
        getLinkToESignatureIndex,
        getLinkToSentESignatures,
        getLinkToAccountESignatures,
        getLinkToESignatureTemplates,
        routeToESignatureIndex,
        routeToSentESignatures,
        routeToAccountESignatures,
        routeToESignatureTemplates,
    };
};
