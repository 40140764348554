import { SystemPermissionDTO, SystemPermissionEnum } from '../api/roles/roleApiTypes';
import { useAuthContext } from '../app/_context/AuthContext';

export const useSystemPermissionsValidator = () => {

    const { authUser } = useAuthContext();

    const systemPermissionsByAccountLookup = createSystemPermissionsLookup();

    function createSystemPermissionsLookup(): Map<number, Map<SystemPermissionEnum, SystemPermissionDTO>> {
        const systemPermissionsLookup = new Map<number, Map<SystemPermissionEnum, SystemPermissionDTO>>();
        authUser?.systemPermissionsToAccounts.forEach(i => {
            systemPermissionsLookup.set(i.accountID, new Map<SystemPermissionEnum, SystemPermissionDTO>(i.permissionsList.map(systemPermission => [systemPermission.systemPermissionType, systemPermission])));
        });
        return systemPermissionsLookup;
    }

    const hasPermissionOnAccount = (accountId: number, ...systemPermissionTypes: SystemPermissionEnum[]): boolean => {
        return systemPermissionTypes.every(i => systemPermissionsByAccountLookup.get(accountId)?.has(i)) ?? false;
    };

    const hasPermissionOnSomeAccount = (...systemPermissionTypes: SystemPermissionEnum[]): boolean => {
        return systemPermissionTypes.every(i => Array.from(systemPermissionsByAccountLookup.values()).some(accountPermissions => accountPermissions.has(i)));
    };

    const hasSomePermissionOnAccount = (accountId: number, ...systemPermissionTypes: SystemPermissionEnum[]): boolean => {
        return systemPermissionTypes.some(i => systemPermissionsByAccountLookup.get(accountId)?.has(i));
    };

    const systemPermissionsHaveLoaded = (): boolean => {
        return authUser?.systemPermissionsToAccounts?.some(() => true) ?? false;
    };

    return {
        hasPermissionOnAccount,
        hasSomePermissionOnAccount,
        hasPermissionOnSomeAccount,
        systemPermissionsHaveLoaded,
    };
};