import React, { useState } from 'react';
import { Alert, Button, Modal, Text, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { PermissionsCheckboxList } from '../PermissionsCheckboxList/PermissionsCheckboxList';
import { NodeDTO, NodeType } from '../../../../../api/node/nodeApiTypes';
import { EditPermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIcons } from '../../../../../hooks/useIcons';
import { useLayerContext } from '../../../../../app/_context/LayerContext/LayerContext';
import { ThemeEnum, useColors } from '../../../../../hooks/useColors';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';
import { NodeIcon } from '../../../../Icons/NodeIcon/NodeIcon';
import { useFileUtilities } from '../../../../../hooks/useFileUtilities';

export interface IEditPermissionModalProps {
    isOpen: boolean;
    closeModal: () => void;
    activeNode: NodeDTO | undefined;
    editCallback?: () => Promise<void>;
}

export function EditPermissionModal(props: IEditPermissionModalProps) {
    const { isOpen, closeModal, activeNode, editCallback } = props;

    const { t } = useSafeTranslation(TranslationFiles.EditPermissions);
    const { categorizePermissionChanges, activePermission: permission } = useNodePermissionsContext();
    const { spinnerIconProps } = useIcons();
    const { showToastMessage } = useLayerContext();
    const { updateAndCreatePermissions } = useNodePermissionsUtilities();
    const { getFileExtension } = useFileUtilities();
    const { atlantisDefault } = useColors();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const isInherited = permission?.nodeID?.toString() !== activeNode?.id;
    const nodeName = isInherited ? activeNode?.name : permission?.name;

    const style =
        permission?.systemType === NodeType.Cabinet
            ? 'cabinet-dark-node-icon'
            : permission?.systemType === NodeType.Drawer
            ? 'drawer-dark-node-icon'
            : '';

    const handleSaveEdits = async () => {
        setIsLoading(true);
        setHasError(false);

        const isIndividualPermission = true;
        const { permissionsToCreate, permissionsToUpdate } = categorizePermissionChanges(activeNode?.id, isIndividualPermission);

        try {
            await updateAndCreatePermissions(permissionsToUpdate, permissionsToCreate);
            showToastMessage({
                color: ThemeEnum.Success,
                identifier: 'edit-permissions-success-toast',
                message: t(EditPermissionsTKeys.EditSuccess, { nodeName, roleName: permission?.roleData?.name }),
                timeout: 2500,
            });
            closeModal();
            editCallback && (await editCallback());
        } catch (error: any) {
            console.log('Error updating permissions', error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {permission && (
                <Modal isOpen={isOpen} size='lg'>
                    <Modal.Header toggle={closeModal}>
                        <div className='d-flex align-items-center'>
                            <Text semibold size='xxl'>
                                {t(EditPermissionsTKeys.ModalTitle)}
                                &nbsp;
                            </Text>
                            <TooltipItem delayShow={200} id='node-name-tooltip' message={nodeName ?? ''}>
                                <Text
                                    semibold
                                    size='xxl'
                                    className='d-block text-truncate'
                                    style={{ maxWidth: '200px', cursor: 'pointer' }}>
                                    {nodeName}
                                    &nbsp;
                                </Text>
                            </TooltipItem>
                            <Text semibold size='xxl'>
                                {t(EditPermissionsTKeys.ModalTitleAfter)}
                                &nbsp;
                            </Text>
                            <TooltipItem delayShow={200} id='role-name-tooltip' message={permission?.role ?? ''}>
                                <Text semibold size='xxl' className='d-block text-truncate' style={{ maxWidth: '375px' }}>
                                    {permission?.role}
                                </Text>
                            </TooltipItem>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='mt-2'>
                        {hasError && (
                            <Alert color={ThemeEnum.Danger} className='mb-4'>
                                {t(EditPermissionsTKeys.EditError)}
                            </Alert>
                        )}
                        {isInherited && (
                            <div className='d-flex align-items-center mb-4'>
                                <FontAwesomeIcon icon='sitemap' className='mr-2' />
                                <Text>{t(EditPermissionsTKeys.Inherited)}</Text>
                                <NodeIcon
                                    fileExtension={getFileExtension(nodeName ?? '')}
                                    nodeType={permission.systemType}
                                    customIconClassName={style}
                                    fontAwesomeColor={atlantisDefault}
                                />
                                <Text>{permission?.name}.</Text>
                            </div>
                        )}
                        <PermissionsCheckboxList activeNode={activeNode} isInherited={isInherited} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button emphasis='med' onClick={closeModal}>
                            {t(EditPermissionsTKeys.CancelButton)}
                        </Button>
                        <Button emphasis='high' onClick={handleSaveEdits} icon={isLoading ? spinnerIconProps : undefined}>
                            {t(EditPermissionsTKeys.SaveButton)}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}
