import React from 'react';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { RoutePath } from '../../../../features/_routing/routingTypes';
import { AtlantisTour, AtlantisTourStep } from '../../AtlantisTour/AtlantisTour';
import { TourType } from '../../tourTypes';

export const RestoreDeletedFile = () => {

    //const { t } = useSafeTranslation(TranslationFiles.WelcomeTour);

    const { isTourOpen } = useLayerContext();

    const tourType = TourType.RestoreDeletedFile;

    const steps: AtlantisTourStep[] = [
        {
            selector: '[data-id=sideNavAllFeatures] > li > span',
            content: 'Open all features panel',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.RecycleBin && !document.querySelector(`[data-id="allFeatures${RoutePath.RecycleBin}"]`)
        },
        {
            selector: `[data-id="allFeatures${RoutePath.RecycleBin}"]`,
            content: 'Select Recycle Bin',
            isNavigationStep: true,
            includeWhen: () => window.location.pathname !== RoutePath.RecycleBin,
            mutationObservables: [`[data-id="allFeatures${RoutePath.RecycleBin}"]`]
        },
        {
            selector: '[data-id=recycleBinArea]',
            content: 'You can find all of your deleted files on this page. If you are having trouble finding the file you\'d like to restore, try searching for it or adding some filters',
            stepInteraction: true,
            resizeObservables: ['[data-id=recycleBinArea]']
        },
        {
            selector: '[data-id=recycleBinSearch]',
            content: 'Once you\'ve found the file you would like to restore, right click on it and select \'Restore\'. You can also select multiple files at once and Restore them all at the same time the same way, or by using the buttons that appear at the top of the table.',
            stepInteraction: true,
            resizeObservables: ['[data-id=recycleBinArea]'],
            highlightedSelectors: ['[data-id=recycleBinArea]']
        }
    ];

    return (
        <AtlantisTour steps={steps} isOpen={isTourOpen(tourType)} tourType={tourType} />
    );
};
