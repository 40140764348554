import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


export const PreviewerLoadingCard = () => {

    return (
        <span className='h-100'>
            <SkeletonTheme enableAnimation height='100%' width='100%'>
                <Skeleton count={1} className='mb-3 mx-auto' />
            </SkeletonTheme>
        </span>
    );
};