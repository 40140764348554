import { DateFormatTypes, NumberFormatTypes, TextFormatTypes, DropdownListFormatTypes } from "../../api/mdFields/mdFieldApiTypes";

export type DateFormatTypeKeyStrings = keyof typeof DateFormatTypes;

export type NumberFormatTypeKeyStrings = keyof typeof NumberFormatTypes;

export type TextFormatTypeKeyStrings = keyof typeof TextFormatTypes;

export type DropdownListFormatTypeKeyStrings = keyof typeof DropdownListFormatTypes;

export enum MDModalSteps {
    NewField = 0,
    DropdownListOptions = 3,
}

export enum MDModalAddLink {
    AddInBulk = 0,
    AddIndividually = 1,
}

export enum MDModalSorting {
    Ascending = 0,
    Descending = 1,
}

// When we are ready to declare interfaces for Applied Metadata, we decided on this naming convention:
// IMetadataAppliedItem
// IAddressMetadataAppliedItem