import React, { useEffect, useState } from 'react';
import { Text, Modal, SearchInput, Button } from '@efilecabinet/efc-atlantis-components';
import { useLayerContext } from '../../../app/_context/LayerContext/LayerContext';
import { useAtlantisTours } from '../../../hooks/useAtlantisTours';
import { TourType } from '../tourTypes';
import './TourMenu.css';

export const TourMenu = () => {

    //const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { isTourMenuOpen, toggleTourMenu, toggleTour } = useLayerContext();    

    const { getTourGroups } = useAtlantisTours();

    const allTourGroups = getTourGroups();
    const [currentTourGroups, setCurrentTourGroups] = useState(allTourGroups);
    const [searchInput, setSearchInput] = useState('');

    const handleClick = (type: TourType) => {
        toggleTourMenu();
        toggleTour(type);
    };

    useEffect(() => {
        if (searchInput === '') {
            setCurrentTourGroups(allTourGroups);
        }
        else {
            setCurrentTourGroups(
                allTourGroups.filter(tg => tg.displayName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    tg.tours.map(t => t.displayName.toLowerCase()).some(t => t.includes(searchInput.toLowerCase())))
            );
        }
    }, [searchInput]);

    return (
        <Modal title='Guided Tours' toggle={toggleTourMenu} isOpen={isTourMenuOpen} >
            <Modal.Body>

                <SearchInput value={searchInput} onChange={(event) => setSearchInput(event.target.value)} />

                <div className='my-3'>
                    {currentTourGroups.length > 0 && currentTourGroups.map(tourGroup => {
                        return (
                            <div key={tourGroup.displayName} className='my-2'>
                                <div>
                                    <Text>{tourGroup.displayName}</Text>
                                </div>
                                {tourGroup.tours.map(tour => {
                                    return (
                                        <Button key={tour.type} emphasis='low' onClick={() => handleClick(tour.type)}>{tour.displayName}</Button>
                                    );
                                })}
                            </div>
                        );
                    })}

                    {currentTourGroups.length == 0 && 
                        <Text>No Results</Text>    
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};
