import React, { useEffect, useState } from 'react';
import { EfcCallToActionInfo, EfcFeedItemInfo, Feed, Modal, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { SystemLogsTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useLayerContext } from '../../../app/_context/LayerContext/LayerContext';
import { IUseModalProps } from '../../../hooks/useModal';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../_routing';
import { RoutePath, SystemLogsRoutePath } from '../../_routing/routingTypes';
import './SystemLogsPage.css';

export const SystemLogsPage = () => {

    const { t } = useSafeTranslation(TranslationFiles.SystemLogs);
    const { routeToSystemLogsServer, routeToSystemLogsClient } = useRouting();
    const { canGoToRoute } = useRouteValidator();

    const [ systemLogs, setSystemLogs] = useState<EfcFeedItemInfo[]>([]);

    const { openModal } = useLayerContext();
    const iframe = useUtopiaIFrame();

    const currentRoute = `${location.pathname}` as SystemLogsRoutePath;

    //Download code was pulled from Utopia
    const handleDownloadLogs = () => {
        const fileContent = getAllSystemLogsAsString();
        const blob = new Blob([fileContent], { type: 'application/text;' });
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', 'SystemLogs.txt');
        downloadLink.click();
    };

    function getAllSystemLogsAsString() {
        return 'SERVER SYSTEM LOGS\r\n\r\n' +
            (JSON.parse(localStorage.getItem('utopia-serverSystemLogs') as string) || [])?.map(stringify)?.join('\r\n\r\n') +
            (JSON.parse(localStorage.getItem('serverSystemLogs') as string) || [])?.map(stringify)?.join('\r\n\r\n') +
            '\r\n\r\nCLIENT SYSTEM LOGS\r\n\r\n' +
            (JSON.parse(localStorage.getItem('utopia-clientSystemLogs') as string) || [])?.map(stringify)?.join('\r\n\r\n') +
            (JSON.parse(localStorage.getItem('clientSystemLogs') as string) || [])?.map(stringify)?.join('\r\n\r\n');
    }

    function stringify(log: any) {
        return JSON.stringify(log, null, 5).replace(/\r?\n/g, '\r\n');
    }

    const handleClearLogs = () => {
        openModal((closeModalFn) =>
            <ConfirmClearLogsModal ctas={getConfirmationCtas(closeModalFn)} text={t(SystemLogsTKeys.ClearLogsConfirmMsg, { count: systemLogs.length })} />);      
    };

    const confirmClearLogs = (closeModalFn: () => void, confirmed: boolean) => {
        closeModalFn();

        if (!!confirmed) {
            setSystemLogs([]);
            localStorage.removeItem('utopia-serverSystemLogs');
            localStorage.removeItem('utopia-clientSystemLogs');
            localStorage.removeItem('serverSystemLogs');
            localStorage.removeItem('clientSystemLogs');
        }
    };

    const convertLogToFeedItem = (index: number, log: any): EfcFeedItemInfo => {
        const feedItem: EfcFeedItemInfo = {
            id: index.toString(),
            title: !!log?.status ? 'Status: ' + log?.status : 'Unknown Error', 
            feedDate: !!log?.date ? new Date(log.date) : new Date(),
        };

        if(log.description !== '{}' && Object.keys(log.description).length !== 0){
            feedItem.blockText = 'Description: ' + log.description + 
                (!!log?.data?.filteredUrl ? '\nRequest: ' + log.data.method + ' ' + log.data.filteredUrl : '') + 
                (!!log?.data?.url ? '\nUrl: ' + log.data.url : '') + 
                (!!log?.data?.operationId ? '\nOperationID: ' + log.data.operationId : '');
        }

        return feedItem;
    };

    const processLogs = (logType: string) => {
        const utopiaItems: object[] = JSON.parse(localStorage.getItem('utopia-' + logType + 'SystemLogs') as string);
        const atlantisItems: object[] = JSON.parse(localStorage.getItem(logType + 'SystemLogs') as string);
        const allItems = [...utopiaItems||[], ...atlantisItems||[]];
        if (!!allItems) {
            const moreFeedItems = allItems.map((log, index) => convertLogToFeedItem(index, log));
            setSystemLogs((prevState) => [...prevState, ...moreFeedItems]);
        }
    };

    const getConfirmationCtas = (closeModalFn: () => void): EfcCallToActionInfo[] =>
    [
        { text: t(SystemLogsTKeys.ClearLogsCancelButton), color: 'primary', emphasis: 'med', onClick: () => confirmClearLogs(closeModalFn, false) },
        { text: t(SystemLogsTKeys.ClearLogsConfirmButton), color: 'primary', emphasis: 'high', onClick: () => confirmClearLogs(closeModalFn, true) },
    ];

    const systemLogsCtas: EfcCallToActionInfo[] = [
        { text: t(SystemLogsTKeys.ClearLogsButton), emphasis: 'med', color: 'primary', icon: { icon: 'trash-can'}, onClick: () => handleClearLogs(), },
        { text: t(SystemLogsTKeys.DownloadLogsButton), emphasis: 'med', color: 'primary', icon: { icon: 'download' }, onClick: () => handleDownloadLogs(), },
    ];

    useEffect(() => {
        setSystemLogs([]);
        if(currentRoute == RoutePath.SystemLogsClient) {
            processLogs('client');
        } else {
            processLogs('server');
        }
    }, [currentRoute]);

    return (
        <>
            <Page title={t(SystemLogsTKeys.SystemLogsTitle, 'System Logs')} icon={{ icon: ['far', 'clipboard-list'] }} callsToAction={systemLogsCtas}>
                {canGoToRoute(RoutePath.SystemLogsServer) && 
                    <Tab active={currentRoute === RoutePath.SystemLogsServer} onClick={ () => routeToSystemLogsServer() }>
                        {t(SystemLogsTKeys.ServerTab)}
                    </Tab>
                }
                {canGoToRoute(RoutePath.SystemLogsClient) &&
                    <Tab active={currentRoute === RoutePath.SystemLogsClient} onClick={ () => routeToSystemLogsClient() }>
                        {t(SystemLogsTKeys.ClientTab)}
                    </Tab>
                }
                
                <Feed feedItems={systemLogs} />
            </Page>
            
            <IFrame name='utopiaIframe' className='iframe' src={iframe.url} dataId='systemLogsIframe' hidden />
        </>
    );
};

interface IConfirmClearLogsProps extends IUseModalProps {
    text: string;
}

const ConfirmClearLogsModal = (props: IConfirmClearLogsProps) => {

    const { text, ...otherProps } = props;

    const { t } = useSafeTranslation(TranslationFiles.SystemLogs);

    return (
        <Modal title={t(SystemLogsTKeys.AreYouSure)} {...otherProps }>
            <Modal.Body>
                {text}
            </Modal.Body>
        </Modal>
    );
};

