import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';
import { Virtuoso } from 'react-virtuoso';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoleSelectorTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { RoleTile } from '../RoleTile/RoleTile';
import { AccountRoleCountsDTO, RoleDTO, RoleEnum, UserLicenseEnum } from '../../../api/roles/roleApiTypes';
import { useRoleApi } from '../../../api/roles/useRoleApi';
import './RoleTypeTile.css';

export interface RoleTypeTileProps {
    accountId: number;
    roleType: RoleEnum;
    accountRoleCounts: AccountRoleCountsDTO;
    rolesFromSearchQuery: RoleDTO[];
    addRole: (role: RoleDTO) => void;
    selectedRoles: RoleDTO[];
    searchInput: string;
    defaultSelectedRole?: RoleDTO | null;
}

export const RoleTypeTile = (props: RoleTypeTileProps) => {
    const ROLE_QUERY_BATCH_SIZE = 5;

    const { accountId, roleType, accountRoleCounts, rolesFromSearchQuery, addRole, selectedRoles, searchInput, defaultSelectedRole } = props;
    const { t } = useSafeTranslation(TranslationFiles.RoleSelector);
    const { queryRolesByAccountId } = useRoleApi();

    const [isShowRoles, setIsShowRoles] = useState<boolean>(false);
    const [isSearchView, setIsSetSearchView] = useState<boolean>(false);

    const [roleCount, setRoleCount] = useState<number | null>(null);

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [loadedCount, setLoadedCount] = useState<number>(0);
    const [loadedRoles, setLoadedRoles] = useState<RoleDTO[]>([]);
    const [canLoadMoreRoles, setCanLoadMoreRoles] = useState<boolean>(true);
    const [isLoadingMoreRoles, setIsLoadingMoreRoles] = useState<boolean>(false);

    const [customScrollParent, setCustomScrollParent] = useState<HTMLElement>();

    const handleTileClick = () => {
        setIsShowRoles(!isShowRoles);
    };

    const getRoleTypeIcon = () => {
        switch (roleType) {
            case RoleEnum.User:
                return (<FontAwesomeIcon icon='user-alt' size='lg' className={'role-type-icon ' + grayRoleIfNoResults()} />);
            case RoleEnum.Guest:
                return (
                    <i className={'icon-efc-guest-user role-type-icon ' + grayRoleIfNoResults() + ' role-type-custom-icon'}></i>
                );
            case RoleEnum.Group:
            default:
                return (<FontAwesomeIcon icon='users' size='lg' className={'role-type-icon ' + grayRoleIfNoResults()} />);
        }
    };

    const getRoleTypeText = () => {
        switch (roleType) {
            case RoleEnum.User:
                return t(RoleSelectorTKeys.RoleTypeUserText);
            case RoleEnum.Guest:
                return t(RoleSelectorTKeys.RoleTypeGuestText);
            case RoleEnum.Group:
                return t(RoleSelectorTKeys.RoleTypeGroupText);
            default:
                return t(RoleSelectorTKeys.RoleTypeDefaultText);
        }
    };

    const grayRoleIfNoResults = () => {
        if (!isSearchView && roleCount != null && roleCount == 0) {
            return 'role-selector-no-matching-results';
        }
        if (isSearchView && !!rolesFromSearchQuery && rolesFromSearchQuery.length == 0) {
            return 'role-selector-no-matching-results';
        }
        return '';
    };

    const getRoleCount = () => {
        switch (roleType) {
            case RoleEnum.User:
                return accountRoleCounts?.userCount;
            case RoleEnum.Guest:
                return accountRoleCounts?.guestCount;
            case RoleEnum.Group:
                return accountRoleCounts?.accountGroupData.length;
            default:
                return 0;
        }
    };

    const loadMoreRoles = async () => {
        if (canLoadMoreRoles && !isLoadingMoreRoles) {
            try {
                setIsLoadingMoreRoles(true);

                const results = await queryRolesByAccountId({ 
                    accountId: accountId, 
                    roleTypes: [roleType == RoleEnum.Guest ? RoleEnum.User : roleType], 
                    userLicenseTypes: getUserLicenseTypesForQuery(), 
                    start: loadedCount, 
                    count: ROLE_QUERY_BATCH_SIZE 
                });
                setLoadedCount(loadedCount + results.length);

                const distinctRoles = [...loadedRoles, ...results].filter((value, index, self) => {
                    return self.findIndex(x => x.accountID == value.accountID && x.id == value.id) == index;
                });

                setCanLoadMoreRoles(distinctRoles.length < (roleCount as number));
                setLoadedRoles(distinctRoles);
            } catch (error) {
                console.error('Error loading more roles', error);
            } finally {
                setIsLoadingMoreRoles(false);
            }
        }
    };

    const getUserLicenseTypesForQuery = () => {
        switch (roleType) {
            case RoleEnum.User:
                return [UserLicenseEnum.Full, UserLicenseEnum.Essentials, UserLicenseEnum.Compliance];
            case RoleEnum.Guest:
                return [UserLicenseEnum.Guest];
            default:
                return undefined;
        }
    }

    const getVirtuosoComponentsConfig = () => {
        return {
            Footer: () => <RoleTypeFooter loadingMoreRoles={isLoadingMoreRoles} totalRolesCount={roleCount as number} loadedRolesCount={loadedRoles.length} roleQueryBatchSize={ROLE_QUERY_BATCH_SIZE} ></RoleTypeFooter>
        };
    };

    const findCustomScrollParent = () => document.getElementById('role-selector-infinite-scroll-target');

    useEffect(() => {
        if (isInitialLoad && !!roleCount) {
            loadMoreRoles();
            setIsInitialLoad(false);
        }

    }, [isInitialLoad, roleCount]);

    useEffect(() => {
        const foundCustomScrollParent = findCustomScrollParent();
        if (!!foundCustomScrollParent) {
            setCustomScrollParent(foundCustomScrollParent);
        }

    }, [!!findCustomScrollParent()]);

    useEffect(() => {
        if (!roleCount) {
            setRoleCount(getRoleCount());
        }

    }, [roleCount]);

    useEffect(() => {
        setIsSetSearchView(!!searchInput);
        setIsShowRoles(!!searchInput);
    }, [rolesFromSearchQuery]);

    return (
        <>
            <Row className='g-0 role-type-row-container role-type-row' onClick={handleTileClick} >
                <Col>
                    <Row className='g-0 role-type-row'>
                        <Col xs='1'>
                            <div className='role-type-icon-wrapper'>
                                {getRoleTypeIcon()}
                            </div>
                        </Col>
                        <Col xs='11'>
                            <div className='role-type-text-wrapper' >
                                {isSearchView && (
                                    <>
                                        <Text className={grayRoleIfNoResults()} size='lg' >{getRoleTypeText()} ({rolesFromSearchQuery.length} results)</Text>
                                        {(rolesFromSearchQuery.length == 0 || !isShowRoles) &&
                                            <FontAwesomeIcon icon='chevron-right' size='lg' className='role-type-dropdown-icon role-type-dropdown-icon-right' />
                                        }
                                        {rolesFromSearchQuery.length > 0 && isShowRoles &&
                                            <FontAwesomeIcon icon='chevron-down' size='lg' className='role-type-dropdown-icon' />
                                        }
                                    </>
                                )}

                                {!isSearchView && (
                                    <>
                                        <Text className={grayRoleIfNoResults()} size='lg' >{getRoleTypeText()} ({roleCount})</Text>
                                        {!isShowRoles &&
                                            <FontAwesomeIcon icon='chevron-right' size='lg' className='role-type-dropdown-icon role-type-dropdown-icon-right' />
                                        }
                                        {isShowRoles &&
                                            <FontAwesomeIcon icon='chevron-down' size='lg' className='role-type-dropdown-icon' />
                                        }
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {!!roleCount && roleCount > 0 && (
                <>
                    {isSearchView && isShowRoles && rolesFromSearchQuery.map(role =>
                        <RoleTile
                            key={role.id}
                            role={role}
                            accountRoleCounts={accountRoleCounts}
                            addRole={addRole}
                            selectedRoles={selectedRoles}
                            searchInput={searchInput}
                            isDefaultSelection={!!defaultSelectedRole && role === defaultSelectedRole}
                        />)}

                    {!isSearchView && isShowRoles && (
                        <Virtuoso
                            data={loadedRoles}
                            endReached={loadMoreRoles}
                            customScrollParent={customScrollParent}
                            components={getVirtuosoComponentsConfig()}
                            itemContent={(index, role) => { return <RoleTile key={role.id} role={role} accountRoleCounts={accountRoleCounts} addRole={addRole} selectedRoles={selectedRoles} searchInput={searchInput} />; }}>
                        </Virtuoso>
                    )}
                </>
            )}
        </>
    );
};

interface RoleTypeFooterProps {
    loadingMoreRoles: boolean;
    totalRolesCount: number;
    loadedRolesCount: number;
    roleQueryBatchSize: number;
}

const RoleTypeFooter = (props: RoleTypeFooterProps) => {

    const { loadingMoreRoles, totalRolesCount, loadedRolesCount, roleQueryBatchSize } = props;

    const [numSkeletonsToShow, setNumSkeletonsToShow] = useState<number>(0);

    const calculateRemainingRolesToSkeleton = () => {
        const remaining = totalRolesCount - loadedRolesCount;
        return remaining > roleQueryBatchSize ? roleQueryBatchSize : remaining;
    };

    useEffect(() => {
        if (loadingMoreRoles) {
            setNumSkeletonsToShow(calculateRemainingRolesToSkeleton());
        }
        else {
            setNumSkeletonsToShow(0);
        }
    }, [totalRolesCount, loadedRolesCount]);

    return loadingMoreRoles ?
        <SkeletonTheme enableAnimation height='60px' width='100%'>
            <Skeleton count={numSkeletonsToShow} />
        </SkeletonTheme>
        :
        <div style={{ height: '15px' }}></div>; // if you don't have a footer w/ a little bit of height, scrolling is jittery when you reach the end of the list
};