import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useAuditLogsRouting = () => {

    const navigate = useNavigate();

    const getLinkToAuditLogs = () => {
        return RoutePath.AuditLogs;
    };

    const routeToAuditLogs = () => {
        navigate(getLinkToAuditLogs());
    };

    return {
        getLinkToAuditLogs,
        routeToAuditLogs,
    };
};
