import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const ProfileSideSheetSkeletonLoader = () => {
    return (<>
        <SkeletonTheme enableAnimation height='156px' width='100%'>
            <Skeleton count={1} className='mb-3 mx-auto' />
        </SkeletonTheme>
        <SkeletonTheme enableAnimation height='24px' width='100%'>
            <Skeleton count={5} className='mb-3 mx-auto' />
        </SkeletonTheme>
        <hr style={{ color: '#8e8e8e' }} />
        <SkeletonTheme enableAnimation height='24px' width='100%'>
            <Skeleton count={3} className='mb-3 mx-auto' />
        </SkeletonTheme>
    </>);
};
