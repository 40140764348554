import React, { useState } from 'react';
import { ButtonToolbar, CallToAction, EfcCallToActionInfo, Text } from '@efilecabinet/efc-atlantis-components';
import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNodeSidesheetUtilities } from '../useNodeSidesheetUtilities';
import { useColors } from '../../../hooks/useColors';
import './SidesheetOnboardingModal.css';
import './SidesheetOnboardingModalLg.css';

interface SidesheetOnboardingModalLgProps {
    isOpen: boolean;
    destroyModal: () => void;
}

export const SidesheetOnboardingModalLg = ({ isOpen, destroyModal }: SidesheetOnboardingModalLgProps) => {

    const { atlantisPrimary } = useColors();
    const { goToSidesheetHelpArticle } = useNodeSidesheetUtilities();

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

    function getLinkColor() {
        return currentPageNumber == 1 ? 'white' : atlantisPrimary;
    }

    function getCtas(): EfcCallToActionInfo[] {
        const previousCta: EfcCallToActionInfo = {
            text: 'Previous',
            color: 'primaryDark',
            emphasis: 'med',
            onClick: () => setCurrentPageNumber(currentPageNumber - 1)
        };
    
        const nextCta: EfcCallToActionInfo = {
            text: 'Next',
            color: 'primaryDark',
            emphasis: 'high',
            icon: { icon: 'arrow-right', pull: 'right' },
            onClick: () => setCurrentPageNumber(currentPageNumber + 1)
        };
    
        const closeCta: EfcCallToActionInfo = {
            text: 'Close',
            color: 'primaryDark',
            emphasis: 'high',
            onClick: destroyModal,
        };

        switch (currentPageNumber) {
            case 1:
                return [nextCta];
            case 2:
                return [previousCta, nextCta];
            case 3:
                return [previousCta, closeCta];
            default:
                return [];
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={destroyModal}
            size='lg'
            contentClassName={`sidesheet-onboarding-modal sidesheet-onboarding-modal-lg-${currentPageNumber}`}>

            <div className='modal-header sidesheet-onboarding-modal-header'>
                <div className='w-100 d-flex justify-content-end'>
                    <FontAwesomeIcon icon='close' color='white' onClick={destroyModal} type='button' data-bs-dismiss='modal' aria-label='Close' />
                </div>
            </div>

            <div className='modal-body sidesheet-onboarding-modal-lg-body'/>

            <div className='modal-footer sidesheet-onboarding-modal-footer'>
                <div className='ms-4'>
                    <div className='d-flex align-items-center pointer' onClick={goToSidesheetHelpArticle}>
                        <Text className='me-1' size='lg' color={getLinkColor()} >
                            View the Guide to Document Actions
                        </Text>
                        <FontAwesomeIcon icon='arrow-up-right-from-square' color={getLinkColor()} />
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <Text className='me-2' color='white'>
                        {currentPageNumber}/3
                    </Text>
                    <ButtonToolbar>
                        {getCtas().map((cta: EfcCallToActionInfo, index: number) => (
                            <CallToAction key={index} {...cta} />
                        ))}
                    </ButtonToolbar>
                </div>
            </div>

        </Modal>
    );
};
