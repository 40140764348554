import React from 'react';
import productLaunchImage from '../AccountOnboardingModalComponents/Images/Product-Launch.svg';
import airplane from '../AccountOnboardingModalComponents/Images/Airplane.svg';

export const WelcomeExperienceLogo = () => {
    return (
        <>
            <img src={productLaunchImage} className='ms-3 position-relative' style={{ right: '-20px' }} height='203px' width='234px' alt='jump-in' />
            <img src={airplane} className='airplane' alt='airplane' />
        </>
    );
};
