import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { UtopiaUploadFileResponseDTO } from './fileUploadApiTypes';

export const useFileUploadApi = () => {

    const deleteAllFailedFileUploadsAsync = async (): Promise<UtopiaUploadFileResponseDTO[]> => {
        const resp: AxiosResponse = await Api.delete('api/FileUpload/Failed/All');
        return resp.data;
    };

    const deleteFailedFileUploadAsync = async (nodeId: string, uploadIdentifier: string): Promise<UtopiaUploadFileResponseDTO[]> => {
        const resp: AxiosResponse = await Api.delete(`api/FileUpload/Failed?nodeId=${nodeId}&uploadIdentifier=${uploadIdentifier}`);
        return resp.data;
    };

    const queryFailedFileUploadsAsync = async (start: number, count: number): Promise<UtopiaUploadFileResponseDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/FileUpload/Failed?start=${start}&count=${count}`);
        return resp.data;
    };

    return {
        deleteAllFailedFileUploadsAsync,
        deleteFailedFileUploadAsync,
        queryFailedFileUploadsAsync
    };
};