import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { PermissionDetailsTable } from './PermissionDetailsTable';
import { PermissionDetailsModalHeader } from './PermissionDetailsModalHeader';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { useLayerContext } from '../../../../../app/_context/LayerContext/LayerContext';
import { DeletePermissionsModal } from '../DeletePermissionsModal/DeletePermissionsModal';
import { PermissionGrantAccessModal } from '../GrantAccessModal/PermissionGrantAccessModal';
import { initialPermissionState } from '../../../../../hooks/nodePermissions/nodePermissionTypes';
import { DockedBar } from '../../../../DockedBar';
import { ThemeEnum } from '../../../../../hooks/useColors';
import { ToastMessages } from '../../../../Nav/NavMenu/ToastMessages';
import { useIcons } from '../../../../../hooks/useIcons';
import { FullScreenOverlay } from '../../../../FullScreenOverlay/FullScreenOverlay';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { NodeDTO } from '../../../../../api/node/nodeApiTypes';
import { useFileUtilities } from '../../../../../hooks/useFileUtilities';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';

interface PermissionDetailsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    editCallback?: (showSkeletonLoader?: boolean) => Promise<void>;
    activeNode: NodeDTO | undefined;
}

export const PermissionDetailsModal = (props: PermissionDetailsModalProps) => {
    const { isOpen, closeModal, editCallback, activeNode } = props;

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { categorizePermissionChanges, setActivePermission, setEditedPermission } = useNodePermissionsContext();
    const { showToastMessage } = useLayerContext();
    const { spinnerIconProps } = useIcons();
    const { getNodeTypeText, nodeIsContainer } = useFileUtilities();
    const { updateAndCreatePermissions } = useNodePermissionsUtilities();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isGrantAccessModalOpen, setIsGrantAccessModalOpen] = useState<boolean>(false);

    const systemType = activeNode?.systemType;
    const itemType = !!systemType ? getNodeTypeText(systemType).toLowerCase() : '';
    const isContainer = !!systemType ? nodeIsContainer(systemType) : false;

    const openDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    function onEdit() {
        setIsEditMode(true);
    }

    function onCancel() {
        setIsEditMode(false);
    }

    const onSuccessfulEdit = async () => {
        showToastMessage({
            color: ThemeEnum.Success,
            identifier: 'edit-permissions-success-toast',
            message: t(PermissionsTKeys.EditSuccess),
            timeout: 2500,
        });

        setIsEditMode(false);
        editCallback && (await editCallback(false));
    };

    const handleClickSave = async () => {
        const { permissionsToRemove, permissionsToCreate, permissionsToUpdate } = categorizePermissionChanges(activeNode?.id);

        if (permissionsToRemove.length) {
            openDeleteModal();
        } else {
            updateNodePermissions(permissionsToUpdate, permissionsToCreate);
        }
    };

    const updateNodePermissions = async (permissionsToUpdate: NodePermissionDTO[], permissionsToCreate: NodePermissionDTO[]) => {
        setIsLoading(true);
        try {
            await updateAndCreatePermissions(permissionsToUpdate, permissionsToCreate);
            onSuccessfulEdit();
        } catch (error: any) {
            console.log('Error updating permissions', error);
            showToastMessage({
                color: ThemeEnum.Danger,
                identifier: 'edit-permissions-error-toast',
                message: t(PermissionsTKeys.EditError, { error: error?.message }),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const openGrantAccessModal = () => {
        const permission = { ...initialPermissionState, nodeID: activeNode?.id ? parseInt(activeNode.id) : 0 };
        setActivePermission(permission);
        setEditedPermission(permission);
        setIsGrantAccessModalOpen(true);
    };
    const closeGrantAccessModal = () => {
        setIsGrantAccessModalOpen(false);
        setActivePermission(undefined);
        setEditedPermission(undefined);
    };

    return (
        <>
            <Modal fullscreen isOpen={isOpen}>
                <div className='modal-header'>
                    <PermissionDetailsModalHeader isEditMode={isEditMode} activeNode={activeNode} />
                    <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={closeModal}
                        data-id='close-permissions-details'></button>
                </div>

                <div className='modal-body m-3'>
                    {isEditMode ? (
                        <PermissionDetailsTable isEditMode={isEditMode} itemType={itemType} isContainer={isContainer} activeNode={activeNode} />
                    ) : (
                        <PermissionDetailsTable
                            isEditMode={isEditMode}
                            editCallback={onEdit}
                            itemType={itemType}
                            isContainer={isContainer}
                            activeNode={activeNode}
                            onGrantAccess={openGrantAccessModal}
                        />
                    )}
                </div>

                {isEditMode && (
                    <DockedBar isDocked={true}>
                        <Button emphasis='med' onClick={onCancel} dataId='permissions-details-cancel' disabled={isLoading}>
                            {t(PermissionsTKeys.CancelButton)}
                        </Button>
                        <Button
                            emphasis='high'
                            color={ThemeEnum.Primary}
                            onClick={handleClickSave}
                            className='ms-2'
                            dataId='permissions-details-save'
                            icon={isLoading ? spinnerIconProps : undefined}
                            disabled={isLoading}>
                            {t(PermissionsTKeys.SaveEditsButton)}
                        </Button>
                    </DockedBar>
                )}

                <FullScreenOverlay isVisible={isLoading} />
                <ToastMessages />
            </Modal>
            {isDeleteModalOpen && <DeletePermissionsModal
                isOpen={isDeleteModalOpen}
                closeModal={closeDeleteModal}
                onCancel={onCancel}
                successCallback={onSuccessfulEdit}
                activeNode={activeNode}
            />}
            {isGrantAccessModalOpen && (
                <PermissionGrantAccessModal
                    isOpen={isGrantAccessModalOpen}
                    onClose={closeGrantAccessModal}
                    activeNode={activeNode}
                    grantAccessSuccessCallback={editCallback} />
            )}
        </>
    );
};
