import { RoleDTO } from "../roles/roleApiTypes";

export interface NodePermissionDTO {
    admin: boolean;
    childPermission:  {
        view?: boolean;
        download?: boolean;
        uploadFiles?: boolean;
        createDirectories?: boolean;
        write?: boolean;
        delete?: boolean;
        inheritanceBehavior?: NodePermissionInheritanceEnum;
    } | null;
    createDirectories: boolean;
    createdBy: string;
    createdByUserID?: number;
    createdByUsername: string;
    createdOn: Date;
    delete: boolean;
    download: boolean;
    enforce: boolean;
    id: number;
    inheritanceBehavior: NodePermissionInheritanceEnum;
    name: string;
    nodeID?: number;
    preview: boolean;
    read: boolean;
    remove: boolean;
    restrict: boolean;
    role: string;
    roleData: RoleDTO;
    roleID?: number;
    systemType: number;
    uploadFiles: boolean;
    view: boolean;
    write: boolean;
}

export enum NodePermissionInheritanceEnum {
    Default = 0,
    AppliesToSelfOnly = 20,
}