import React, { Suspense, useEffect, useState } from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { EfcTableColumnInfo, FullTable, EfcCallToActionInfo, Text, Page, NodeType } from '@efilecabinet/efc-atlantis-components';
import { useIcons } from '../../../hooks/useIcons';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { FileProcessingQueueTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { FileQueueItemDTO, FileQueueStatusEnum } from '../../../api/fileProcessingQueue/fileProcessingQueueApiTypes';
import { useFileProcessingQueueApi } from '../../../api/fileProcessingQueue/useFileProcessingQueueApi';
import './FileProcessingQueuePage.css';

export const FileProcessingQueuePage = () => {

    const { authUser, hasAuthUser } = useAuthContext();

    const [fileQueueItems, setFileQueueItems] = useState<FileQueueItemDTO[]>([]);
    const [selectedItems, setSelectedItems] = useState<FileQueueItemDTO[]>([]);

    const { getNodeIconProps } = useIcons();
    const { getFileProcessingQueue, deleteFileQueueItems, retryFileQueueItems } = useFileProcessingQueueApi();


    const { t } = useSafeTranslation(TranslationFiles.FileProcessingQueue);

    const getFileQueueItems = () => {

        if (!!authUser) {
            const fileProcessingQueryParams = {
                accountId: authUser.accountID,
                start: 0,
                count: 100
            };

            getFileProcessingQueue(fileProcessingQueryParams).then((items: FileQueueItemDTO[]) => {
                setFileQueueItems(items);
            });
        }
    };

    const onSelectItems = (items: FileQueueItemDTO[]) => {
        setSelectedItems(items);
    };

    const getIcon = (item: FileQueueItemDTO): FontAwesomeIconProps => {
        const fileExt = item.fileName.slice(item.fileName.lastIndexOf('.'));
        return getNodeIconProps(NodeType.File, fileExt);
    };

    const retryItems = async (item?: FileQueueItemDTO) => {
        if (!!item) {
            await retryFileQueueItems([item.id]);
        }
        else {
            await retryFileQueueItems(selectedItems.map((item) => item.id));
        }

        refreshList();
    };

    const deleteItems = async (item?: FileQueueItemDTO) => {
        if (!!item && !!item.nodeID) {
            await deleteFileQueueItems([item.id]);
        }
        else {
            const ids = selectedItems.map((item) => (item.id));
            await deleteFileQueueItems(ids);
        }

        refreshList();
    };

    const refreshList = () => {
        getFileQueueItems();
        setSelectedItems([]);
    };

    const columns: (string | EfcTableColumnInfo)[] = [
        { name: 'fileName', searchBy: true, displayName: t(FileProcessingQueueTKeys.FileNameColumnHeader), useIcon: getIcon },
        { name: 'currentStatus', displayName: t(FileProcessingQueueTKeys.CurrentStatusColumnHeader), transform: (colVal) => FileQueueStatusEnum[colVal] },
        { name: 'tryCountRemaining', displayName: t(FileProcessingQueueTKeys.TryCountRemainingColumnHeader) },
        { name: 'queuedOn', searchBy: true, displayName: t(FileProcessingQueueTKeys.QueueDateColumnHeader), isDate: true },
        { name: 'errorMessage', displayName: t(FileProcessingQueueTKeys.ErrorMessageColumnHeader) },
    ];

    const itemActions: EfcCallToActionInfo[] = [
        { text: t(FileProcessingQueueTKeys.Retry), icon: { icon: ['far', 'repeat'] }, onClick: retryItems },
        { text: t(FileProcessingQueueTKeys.Delete), color: 'primary', icon: { icon: 'trash-can' }, onClick: deleteItems },
    ];

    const pageActions: EfcCallToActionInfo[] = [
        { text: t(FileProcessingQueueTKeys.Reload), icon: { icon: ['far', 'repeat'] }, emphasis: 'high', onClick: refreshList },
    ];

    useEffect(() => {
        getFileQueueItems();
    }, [hasAuthUser]);

    return (
        <Page title={t(FileProcessingQueueTKeys.PageTitle)} icon={{ icon: ['far', 'repeat'] }} callsToAction={pageActions}>
            <Suspense>
                <FullTable data={fileQueueItems} columns={columns} bulkActions={itemActions} contextActions={itemActions} onSelect={onSelectItems} />
                {!fileQueueItems.length &&
                    <Text>
                        {t(FileProcessingQueueTKeys.EmptyStateMessage)}
                    </Text>
                }
            </Suspense>
        </Page>
    );
};
