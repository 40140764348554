import React from 'react';
import { Page } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { TranslationFiles, useSafeTranslation, ReportsTKeys } from '../../../hooks/useSafeTranslation';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import './ReportsPage.css';

export const ReportsPage = () => {
    const { t } = useSafeTranslation(TranslationFiles.Reports);

    const reportingUrl = '/reporting';
    const iframe = useUtopiaIFrame(reportingUrl);

    return (
        <Page title={t(ReportsTKeys.ReportsTitle)} icon={{ icon: 'chart-line-up' }}>

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' src={iframe.url} />

            {/* Use the following Tab Panes once we make this page native */}

        </Page>
    );
};
