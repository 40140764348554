import React from 'react';
import { ZeroState } from '../../../../ZeroState';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import zeroStateImg from '../ProfileSideSheetZeroStateImg.png';

export const ProfileSideSheetZeroState: React.FC = () => {

    const { t } = useSafeTranslation(TranslationFiles.Metadata);
    const ZERO_STATE_MAX_IMG_WIDTH = 175;

    return (
        <div className=''>
            <ZeroState
                imageColContent={
                    <div className='mb-3'>
                        <img style={{ 'width': ZERO_STATE_MAX_IMG_WIDTH }} src={zeroStateImg} alt={t(MetadataTKeys.ZeroStateImageAlt)} />
                    </div>}
                textColContent={<Text className='mt-3'> {t(MetadataTKeys.ZeroStateCopy)}</Text >}
                stackColumns={true}
                maxImgWidth={ZERO_STATE_MAX_IMG_WIDTH} />
        </div>
    );
};