import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from '@efilecabinet/nautilus-ui';
import { Text, FullTable, EfcCallToActionInfo, EfcTableColumnInfo } from '@efilecabinet/efc-atlantis-components';
import { Row, Col } from 'reactstrap';
import { MDFieldSideSheetContent } from '../../../_management/_sidesheet/MdFieldSideSheetContent/MdFieldSideSheetContent';
import { MDGroupSideSheetContent } from '../../../_management/_sidesheet/MdGroupSideSheetContent/MdGroupSideSheetContent';
import { MetadataGroupDecorDto, MetadataGroupDto } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { MetadataFieldDto, MDFieldTypes } from '../../../../../api/mdFields/mdFieldApiTypes';
import { useMDFieldApi } from '../../../../../api/mdFields/useMDFieldApi';
import { useMDGroupsApi } from '../../../../../api/mdGroups/useMDGroupsApi';
import { useMDLibraryGroupsApi } from '../../../../../api/mdLibrary/useMDLibraryGroupsApi';
import { useMDLibraryFieldsApi } from '../../../../../api/mdLibrary/useMDLibraryFieldsApi';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useMetadataUtilities } from '../../../../../hooks/useMetadataUtilities';
import { useSafeTranslation, TranslationFiles, MDLibraryModalTKeys } from '../../../../../hooks/useSafeTranslation';
import './MdLibraryModal.css';

export interface MDLibraryModalProps {
    fromGroup: boolean;
    isHiddenState: [boolean, Dispatch<SetStateAction<boolean>>];
    submitGroup: (mdGroup: MetadataGroupDto) => Promise<void>;
}

export const MDLibraryModal = (mdLibraryModalProps: MDLibraryModalProps) => {

    const { authUser, hasAuthUser } = useAuthContext();
    const { getAccountMDFields } = useMDFieldApi();
    const { getAccountMDGroups } = useMDGroupsApi();
    const { queryMDLibraryGroups } = useMDLibraryGroupsApi();
    const { queryMDLibraryFields } = useMDLibraryFieldsApi();
    const { createInitialMDGroup, createInitialMDField } = useMetadataUtilities();
    const { t } = useSafeTranslation(TranslationFiles.MDLibraryModal);

    const [allAccountFieldsLoaded, setAllAccountFieldsLoaded] = useState(false);
    const [allAccountGroupsLoaded, setAllAccountGroupsLoaded] = useState(false);
    const [allLibraryFieldsLoaded, setAllLibraryFieldsLoaded] = useState(false);
    const [allLibraryGroupsLoaded, setAllLibraryGroupsLoaded] = useState(false);
    const [accountMDFields, setAccountMDFields] = useState<MetadataFieldDto[]>([]);
    const [accountMDGroups, setAccountMDGroups] = useState<MetadataGroupDto[]>([]);
    const [mdLibraryFields, setMDLibraryFields] = useState<MetadataFieldDto[]>([]);
    const [mdLibraryGroups, setMDLibraryGroups] = useState<MetadataGroupDto[]>([]);
    const [mdFieldsToAdd, setMDFieldsToAdd] = useState<MetadataFieldDto[]>([]);
    const [mdGroupsToAdd, setMDGroupsToAdd] = useState<MetadataGroupDto[]>([]);
    const [selectedMDLibraryGroup, setSelectedMDLibraryGroup] = useState<MetadataGroupDecorDto>();
    const [selectedMDLibraryField, setSelectedMDLibraryField] = useState<MetadataFieldDto>();
    const [isProcessingAction, setIsProcessingAction] = useState(false);
    const [isModalHiddenState, setIsModalHiddenState] = mdLibraryModalProps.isHiddenState;
    const [refreshMDFieldsSentinalKey, setRefreshMDFieldsSentinalKey] = useState(0);

    const BATCH_SIZE = 100;

    function onSubmit() {
        setIsProcessingAction(true);
        //mdLibraryModalProps.submitGroup(selectedMDGroup)
        //    .then(() => {
        //        setIsModalHiddenState(true); //Might now be needed?
        //        setIsProcessingAction(false);
        //        setSelectedMDLibraryGroup(undefined);
        //        setSelectedMDLibraryField(undefined);
        //    });

        //Todo: remove when implementing submit
        setIsProcessingAction(false);
        setIsModalHiddenState(true);
        setSelectedMDLibraryGroup(undefined);
        setSelectedMDLibraryField(undefined);
    }

    function onClose() {
        setSelectedMDLibraryGroup(undefined);
        setSelectedMDLibraryField(undefined);
    }

    function addToAccount(id: string) {
        if (mdLibraryModalProps.fromGroup) {
            //Todo: add group through API
        } else {
            //Todo: add field through API
        }
    }

    function removeFromAccount(id: string) {
        if (mdLibraryModalProps.fromGroup) {
            //Todo: remove group through API
        } else {
            //Todo: remove field from account through API
        }
    }

    function isInAccount(name: string) {
        if (!!mdLibraryModalProps.fromGroup) {
            return !!accountMDGroups.find(group => group.name === name);
        } else {
            //Todo: Do I need to check all fields in a group too??
            return !!accountMDFields.find(field => field.name === name);
        }
    }


    const getAddToCta = (metadataItem: any): EfcCallToActionInfo => {
        const inAccount = isInAccount(metadataItem.name);

        return mdLibraryModalProps.fromGroup
            ?
            {
                text: inAccount ? t(MDLibraryModalTKeys.RemoveCta) : t(MDLibraryModalTKeys.AddGroupCta),
                emphasis: 'med',
                onClick: () => inAccount ? removeFromAccount(metadataItem.id) : addToAccount(metadataItem.id),
            }
            :
            {
                text: inAccount ? t(MDLibraryModalTKeys.InAccountCta) : t(MDLibraryModalTKeys.AddFieldCta),
                emphasis: 'low',
                onClick: () => inAccount ? removeFromAccount(metadataItem.id) : addToAccount(metadataItem.id),
            };
    };

    const getGroupColumns = (): (string | EfcTableColumnInfo)[] => {
        return [
            { name: 'name', searchBy: true, displayName: t(MDLibraryModalTKeys.NameColumn), widthPct: 30 },
            { name: '#OfFields', displayName: t(MDLibraryModalTKeys.NumOfFieldsColumn), widthPct: 15 },
            { name: 'actions', displayName: t(MDLibraryModalTKeys.AddToAccountColumn), widthPct: 25, useCta: getAddToCta }
        ];
    };

    const getFieldColumns = (): (string | EfcTableColumnInfo)[] => {
        return [
            { name: 'name', searchBy: true, displayName: t(MDLibraryModalTKeys.NameColumn), widthPct: 30 },
            { name: 'type', searchBy: true, isEnum: true, enumValues: Object.keys(MDFieldTypes).filter((o) => isNaN(parseFloat(o))), displayName: t(MDLibraryModalTKeys.TypeColumn), widthPct: 15 },
            { name: 'actions', displayName: t(MDLibraryModalTKeys.AddToAccountColumn), widthPct: 25, useCta: getAddToCta }
        ];
    };

    function onSelectField(selectedList: any[]) {
        const selected = selectedList[0];
        if (!!selected) {
            if (mdLibraryModalProps.fromGroup && selected.id !== selectedMDLibraryGroup?.id) {
                // TODO: get md library group from backend instead of casting to MetadataGroupDecorDto
                setSelectedMDLibraryGroup(mdLibraryGroups.find((f) => f.id === selected.id) as MetadataGroupDecorDto);
            } else if (selected.id !== selectedMDLibraryField?.id) {
                setSelectedMDLibraryField(mdLibraryFields.find((f) => f.id === selected.id) as MetadataFieldDto);
            }
        } else {
            setSelectedMDLibraryGroup(undefined);
            setSelectedMDLibraryField(undefined);
        }
    }

    function handleClose() {
        setIsModalHiddenState(true);
    }

    useEffect(() => {
        let componentUnmounted = false;

        async function loadMDItemsAsync() {
            if ((!allLibraryFieldsLoaded && !allAccountFieldsLoaded) || (!allAccountGroupsLoaded || !allLibraryGroupsLoaded || !allLibraryFieldsLoaded)) {
                if (mdLibraryModalProps.fromGroup) {

                    await getAccountMDGroups(authUser?.accountID as number, accountMDGroups.length, BATCH_SIZE).then((groups) => {
                        if (!componentUnmounted && !!groups) {
                            if (groups.length < BATCH_SIZE) {
                                setAllAccountGroupsLoaded(true);
                            }

                            //Todo: Only loads 'Batch_Size' (100), and then second load will be whatever is 100+
                            // this probably needs to be fixed for all areas loading MD
                            setAccountMDGroups(groups);
                        }
                    });

                    await getAccountMDFields(authUser?.accountID as number, accountMDFields.length, BATCH_SIZE).then((fields) => {
                        if (!componentUnmounted && !!fields) {
                            if (fields.length < BATCH_SIZE) {
                                setAllAccountFieldsLoaded(true);
                            }

                            setAccountMDFields(fields);
                        }
                    });

                    await queryMDLibraryGroups(mdLibraryGroups.length, BATCH_SIZE).then((groups) => {
                        if (!componentUnmounted && !!groups) {
                            if (groups.length < BATCH_SIZE) {
                                setAllLibraryGroupsLoaded(true);
                            }

                            setMDLibraryGroups(groups);
                        }
                    });

                } else {

                    await queryMDLibraryFields(mdLibraryFields.length, BATCH_SIZE).then((fields) => {
                        if (!componentUnmounted && !!fields) {
                            if (fields.length < BATCH_SIZE) {
                                setAllLibraryFieldsLoaded(true);
                            }

                            setMDLibraryFields(fields);
                        }
                    });

                    await getAccountMDFields(authUser?.accountID as number, accountMDFields.length, BATCH_SIZE).then((fields) => {
                        if (!componentUnmounted && !!fields) {
                            if (fields.length < BATCH_SIZE) {
                                setAllAccountFieldsLoaded(true);
                            }

                            setAccountMDFields(fields);
                        }
                    });
                }
            }
        }

        if (!!isModalHiddenState && !!hasAuthUser) {
            loadMDItemsAsync();
        }

        return () => {
            componentUnmounted = true;
        };

    }, [isModalHiddenState, mdLibraryModalProps.fromGroup]);

    return (
        <Modal
            options={{ size: 'modal-xl', modalContentClassName: 'p-0' }}
            header={{
                className: 'p-0',
                children:
                    <div className='modal-header w-100 p-3'>
                        <div className='d-flex flex-wrap align-items-center justify-content-start w-75'>
                            <div className='d-flex align-items-center'>
                                <h1 className='library-modal-header-title'>{t(MDLibraryModalTKeys.ModalTitle) + ' ' + `${mdLibraryModalProps.fromGroup ? t(MDLibraryModalTKeys.Group) : t(MDLibraryModalTKeys.Field)}`}</h1>
                            </div>
                        </div>

                        <button type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose} />

                    </div>
            }}

            body={{
                className: 'p-0',
                children:
                    <div className='library-modal-body modal-body' >
                        <Row className='h-100 px-2'>
                            <div className='h-100 library-modal-body-card-left'>
                                <Row>
                                    <Col>
                                        { /*Todo: Add Icon*/}
                                        <Text className='library-modal-body-title'>{t(MDLibraryModalTKeys.MDLibrary) + ` - ${mdLibraryModalProps.fromGroup ? t(MDLibraryModalTKeys.Group) + 's' : t(MDLibraryModalTKeys.Field) + 's' }`}</Text>
                                    </Col>
                                </Row>

                                <Col className='h-100 library-modal-col px-0'>
                                    <div className='h-100 library-modal-col-scroll'>
                                        <Row className='fields-to-add-col'>
                                            <div>
                                                <FullTable searchable={false}
                                                    filterable={false}
                                                    multiselect={false}
                                                    onSelect={onSelectField}
                                                    data={mdLibraryModalProps.fromGroup ? mdLibraryGroups : mdLibraryFields}
                                                    columns={mdLibraryModalProps.fromGroup ? getGroupColumns() : getFieldColumns()} />
                                            </div>
                                        </Row>
                                    </div>
                                </Col>

                            </div>

                            <div className='h-100 library-modal-body-card-right'>
                                <Col className='h-100 library-modal-col-scroll'>

                                    <Text semibold tag='h5'>{`${mdLibraryModalProps.fromGroup ? t(MDLibraryModalTKeys.Group) : t(MDLibraryModalTKeys.Field)}`+ ' ' + t(MDLibraryModalTKeys.Details)}</Text>

                                    <div className='library-modal-horizontal-divider' />

                                    {!mdLibraryModalProps.fromGroup &&
                                        (!selectedMDLibraryField
                                            ? <div className='library-skeleton-text'>
                                                <Text>{t(MDLibraryModalTKeys.SelectField)}</Text>
                                            </div>
                                            : <>
                                                <div className='library-details-col'>
                                                    <MDFieldSideSheetContent mdField={selectedMDLibraryField as MetadataFieldDto} />
                                                </div>
                                                {!!isInAccount(selectedMDLibraryField.name) &&
                                                    <Text>
                                                        {t(MDLibraryModalTKeys.FieldExists)}
                                                    </Text>
                                                }
                                            </>
                                        )
                                    }

                                    {!!mdLibraryModalProps.fromGroup &&
                                        (!selectedMDLibraryGroup
                                            ? <div className='library-skeleton-text'>
                                                <Text>{t(MDLibraryModalTKeys.SelectGroup)}</Text>
                                            </div>
                                            : <>
                                                <div className='library-details-col'>
                                                    <MDGroupSideSheetContent mdGroup={selectedMDLibraryGroup} refreshMDGroupsSentinalKeyState={[refreshMDFieldsSentinalKey, setRefreshMDFieldsSentinalKey]} />
                                                </div>
                                                {!isInAccount(selectedMDLibraryGroup.name) &&
                                                    <Text>
                                                        {t(MDLibraryModalTKeys.AddingGroupP1)} <strong>{selectedMDLibraryGroup?.name}</strong> {t(MDLibraryModalTKeys.AddingGroupP2)}
                                                    </Text>
                                                }
                                                {!!isInAccount(selectedMDLibraryGroup.name) &&
                                                    <Text>
                                                        {t(MDLibraryModalTKeys.GroupExistsP1)} <strong>{selectedMDLibraryGroup?.name}</strong> {t(MDLibraryModalTKeys.GroupExistsP2)}
                                                    </Text>
                                                }
                                            </>
                                        )
                                    }

                                </Col>
                            </div>
                        </Row>

                    </div>
            }}

            footer={{
                className: 'p-3, library-modal-footer',
                buttons: [
                    {
                        buttonProps: {
                            buttonStyle: 'btn-outline-primary',
                            buttonType: 'textButton',
                            label: 'Cancel',
                            onClick: onClose,
                            dataId: 'btnCloseMDLibraryGroup'
                        }
                    },
                    {
                        buttonProps: {
                            buttonStyle: 'btn-primary',
                            buttonType: 'asyncButton',
                            label: 'Confirm Selection',
                            icon: { icon: ['fas', 'spinner-third'], pulse: true },
                            isProcessingActionState: [isProcessingAction, setIsProcessingAction],
                            width: '150px',
                            onClick: onSubmit,
                            dataId: 'btnConfirmSelection',
                            options: {
                                disabled: false,
                            },
                        },
                        dismissModalOnClick: false,
                    }
                ]
            }}
            elementId={'MDLibraryModal'}
            isHiddenState={[isModalHiddenState, setIsModalHiddenState]}
        />
    );
};