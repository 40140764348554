import React, { useEffect, useState } from 'react';
import { useColors } from '../../../../hooks/useColors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CustomToggle.css';

interface CustomToggleProps {
    isActive: boolean;
}

export const CustomToggle = (props: CustomToggleProps) => {
    const { isActive } = props;
    const colors = useColors();

    const [rotationClass, setRotationClass] = useState('rotate up');

    const toggleRotation = () => {
        if (isActive) {
            setRotationClass('rotate down');
        } else {
            setRotationClass('rotate up');
        }
    };

    useEffect(() => {
        toggleRotation();
    }, [isActive]);

    return (
        <span className={`fa-layers fa-fw mt-2 ${rotationClass}`} style={{ verticalAlign: 'top' }}>
            {!!isActive &&
                <FontAwesomeIcon icon={'circle'} transform='grow-25 down-4' color={colors.atlantisLightFocused} />
            }
            <FontAwesomeIcon icon={'angle-up'} transform={'up-1 right-1'} color={`${isActive ? colors.atlantisEnabled : 'white'}`} />
            <FontAwesomeIcon icon={'equals'} transform={'down-9 right-1'} color={`${isActive ? colors.atlantisEnabled : 'white'}`} />
        </span>
    );
};
