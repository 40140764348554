import React from 'react';
import { EfcCallToActionInfo, Modal } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, DocumentRequestsTKeys } from '../../../hooks/useSafeTranslation';
import { DocumentRequestDTO } from '../../../api/documentRequests/documentRequestApiTypes';
interface ConfirmDocumentRequestDeleteModalProps {
    documentRequestToDelete?: DocumentRequestDTO;
    onClose: () => void;
}
interface DeleteDocumentRequestMessageToUtopia {
    type: string;
    request: DocumentRequestDTO;
}
export const ConfirmDocumentRequestDeleteModal = (props: ConfirmDocumentRequestDeleteModalProps) => {
    const { documentRequestToDelete, onClose } = props;
    const { t } = useSafeTranslation(TranslationFiles.DocumentRequests);
    const handleConfirm = () => {
        const documentRequestIframe = document.querySelector('[data-id="documentRequestIframe"]') as HTMLIFrameElement;
        documentRequestIframe?.contentWindow?.postMessage({ type: 'CONFIRM_DELETE_DOCUMENT_REQUEST', request: documentRequestToDelete } as DeleteDocumentRequestMessageToUtopia, '*');
        onClose();
    };
    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(DocumentRequestsTKeys.Cancel),
            emphasis: 'med',
            color: 'primary',
            onClick: onClose
        },
        {
            text: t(DocumentRequestsTKeys.Confirm),
            emphasis: 'high',
            color: 'danger',
            onClick: handleConfirm
        },
    ];
    return (
        <Modal isOpen={true} toggle={onClose} ctas={ctas} title={t(DocumentRequestsTKeys.ConfirmDelete)} >
            <Modal.Body>
                {t(DocumentRequestsTKeys.ConfirmDeleteBodyText, { documentRequestSubject: ((!!documentRequestToDelete && !!documentRequestToDelete.subject) ? documentRequestToDelete.subject : t(DocumentRequestsTKeys.ConfirmDeleteBodyTextThisRequest)) })}
            </Modal.Body>
        </Modal>
    );
};