import React, { useEffect, useRef, useState } from 'react';
import './DockedBar.css';

interface DockedBarProps {
    children?: React.ReactNode;
    isDocked?: boolean;
    className?: string;
    barClassName?: string;
    dockedParentSelector?: string;
    position?: 'top' | 'bottom';
}

export const DockedBar = (props: DockedBarProps) => {
    const { children, position = 'bottom', className = '', barClassName = 'py-3 px-3', isDocked = false } = props;

    const barRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);
    const [placeholderHeight, setPlaceholderHeight] = useState(0);

    const isDockedClass = isDocked ? 'docked-bar-docked' : 'docked-bar-undocked';
    const borderClass = position == 'bottom' ? 'border-top' : 'border-bottom';

    const placeholderStyle = {
        height: `${placeholderHeight}px`,
    };

    useEffect(() => {
        // when the bar floats above the page, we need to create extra space in its original space to prevent content from being covered
        if (isDocked && barRef.current) {
            const height = barRef.current.offsetHeight;
            setPlaceholderHeight(height);
        }
    }, [isDocked, children]);

    return (
        <div className={`docked-bar ${isDockedClass} docked-bar-${position} ${className}`}>
            {isDocked && <div ref={placeholderRef} className='docked-bar-placeholder' style={placeholderStyle}></div>}
            <div ref={barRef} className={`docked-bar-bar ${barClassName} ${borderClass}`}>
                {children}
            </div>
        </div>
    );
};
