import { useEffect, useState } from 'react';

interface UseGetElementWidthProps {
    elementId: string;
}

export const useGetElementWidth = (props: UseGetElementWidthProps) => {
    const { elementId } = props;

    const [elementWidth, setElementWidth] = useState<number>();

    const getResizeObserver = () => {
        return new ResizeObserver((entries) => {
            setElementWidth(entries[0].contentRect.width);
        });
    };

    useEffect(() => {
        const resizeObserver = getResizeObserver();
        const element = document.getElementById(elementId) as HTMLElement;
        resizeObserver.observe(element);

        return () => {
            resizeObserver.unobserve(element);
        };
    }, []);

    return {
        elementWidth,
    };
};