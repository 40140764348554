import React, { useContext } from 'react';
import { INodePermissionsContext } from './NodePermissionsProvider';

export const NodePermissionsContext = React.createContext<INodePermissionsContext | null>(null);

export const useNodePermissionsContext = (): INodePermissionsContext => {
    const context = useContext(NodePermissionsContext) as INodePermissionsContext;
    if (!context) {
        throw new Error('usePermissionsContext must be used within a NodePermissionsProvider. Did you forget to wrap your component in a NodePermissionsProvider?');
    }
    return context;
};
