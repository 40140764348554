import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { EfcCallToActionInfo, Page } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../_routing';
import { SalesforceTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { IFrame } from '../../../components/IFrame/IFrame';
import { RoutePath, SalesforceRouteParams, SalesforceRoutePath } from '../../_routing/routingTypes';
import { useIFramePathChangeListener } from '../../../hooks/useIFramePathChangeListener';
import './SalesforcePage.css';

const IFrameRoutes: Map<SalesforceRoutePath, string> = new Map([
    [RoutePath.Salesforce, '/admin/salesforceManagement'],
    [RoutePath.SalesforceMappingEdit, `/admin/salesforceManagement/edit/${SalesforceRouteParams.MappingId}`],
    [RoutePath.SalesforceMappingNew, '/admin/salesforceManagement/edit/'],
]);

export const SalesforcePage = () => {

    const { mappingId } = useParams();
    const { routeToSalesforceMappingNew, routeToSalesforce } = useRouting();

    const { t } = useSafeTranslation(TranslationFiles.Salesforce);

    const currentRoute = !!mappingId ? RoutePath.SalesforceMappingEdit : `${location.pathname}` as SalesforceRoutePath;
    const iframe = useUtopiaIFrame(getIFrameUrl(RoutePath.Salesforce));

    function getIFrameUrl(route: SalesforceRoutePath) {
        return IFrameRoutes.get(route)?.replace(SalesforceRouteParams.MappingId, mappingId ?? '') ?? '';
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(SalesforceTKeys.NewMappingButton),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            onClick: () => routeToSalesforceMappingNew()
        }
    ];

    const onIFramePathChange = (newPath: string) => {
        if (currentRoute == RoutePath.SalesforceMappingNew && newPath == IFrameRoutes.get(RoutePath.Salesforce)) {
            routeToSalesforce();
        }
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        iframe.setUrl(getIFrameUrl(currentRoute));
    }, [location.pathname]);

    return (
        <Page title='Salesforce' icon={{ icon: ['far', 'cloud'] }} callsToAction={ctas}>
            <IFrame className='iframe' src={iframe.url} />
        </Page>
    );
};
