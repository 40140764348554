import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useRouting } from '../../_routing';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { useRouteValidator } from '../../../hooks/useRouteValidator';
import { IFrame } from '../../../components/IFrame/IFrame';
import { RoutePath, UserRouteParams, UserRoutePath } from '../../_routing/routingTypes';
import { UserSessionsManagement } from '../UserSessionsManagement/UserSessionsManagement';
import { TranslationFiles, useSafeTranslation, UsersTKeys } from '../../../hooks/useSafeTranslation';
import { useIFramePathChangeListener } from '../../../hooks/useIFramePathChangeListener';
import { AccountFeatureUsageHeader } from '../../../components/AccountFeatureUsageHeader/AccountFeatureUsageHeader';
import { AccountFeatureEnum } from '../../../api/accountFeatures/accountFeatureApiTypes';
import { useLayerContext } from '../../../app/_context/LayerContext/LayerContext';
import { DeleteUserConfirmationModal } from '../DeleteUserConfirmationModal/DeleteUserConfirmationModal';
import './UsersPage.css';

enum UserIFrameRouteParams {
    AccountId = ':accountId',
}

const IFrameRoutes: Map<UserRoutePath, string> = new Map([
    [RoutePath.UserIndex, ''],
    [RoutePath.Users, '/admin/user'],
    [RoutePath.UserNew, '/admin/user/add'],
    [RoutePath.UserEdit, `/admin/user/edit/${UserRouteParams.UserId}`],
    [RoutePath.Groups, '/admin/group'],
    [RoutePath.GroupNew, '/admin/group/add'],
    [RoutePath.GroupEdit, `/admin/group/edit/${UserRouteParams.GroupId}`],
    [RoutePath.UserSessions, `/admin/userSessions/${UserIFrameRouteParams.AccountId}`],
    [RoutePath.AccessLinks, '/admin/accessLinks'],
    [RoutePath.AccessLinkEdit, `/admin/accessLinks/edit/${UserRouteParams.AccessLinkId}`],
    [RoutePath.UserDirectories, `/admin/userDirectories/${UserIFrameRouteParams.AccountId}`],
]);

const HideIFrameRoutes = new Set<UserRoutePath>([
    RoutePath.UserIndex,
    RoutePath.UserSessions,
]);

const GroupTabRoutes = new Set<UserRoutePath>([
    RoutePath.Groups,
    RoutePath.GroupNew,
    RoutePath.GroupEdit,
]);

const UserTabRoutes = new Set<UserRoutePath>([
    RoutePath.UserIndex,
    RoutePath.Users,
    RoutePath.UserNew,
    RoutePath.UserEdit,
]);

const AccessLinksTabRoutes = new Set<UserRoutePath>([
    RoutePath.AccessLinks,
    RoutePath.AccessLinkEdit,
]);

export const UsersPage = () => {
    const { userId, groupId, accessLinkId } = useParams();
    const { routeToUsers, routeToGroups, routeToUserSessions, routeToAccessLinks, routeToUserDirectories, routeToUserNew, routeToGroupNew } = useRouting();
    const { t } = useSafeTranslation(TranslationFiles.Users);

    const { authUser } = useAuthContext();
    const { canGoToRoute } = useRouteValidator();
    const { openModal } = useLayerContext();

    const currentRoute =
        !!userId ? RoutePath.UserEdit :
            !!groupId ? RoutePath.GroupEdit :
                !!accessLinkId ? RoutePath.AccessLinkEdit :
                    `${location.pathname}` as UserRoutePath;
    const iframe = useUtopiaIFrame(getIFrameUrl(currentRoute));

    function getIFrameUrl(route: UserRoutePath) {
        return IFrameRoutes.get(route)
            ?.replace(UserIFrameRouteParams.AccountId, authUser?.accountID.toString() ?? '')
            .replace(UserRouteParams.UserId, userId ?? '')
            .replace(UserRouteParams.GroupId, groupId ?? '')
            .replace(UserRouteParams.AccessLinkId, accessLinkId ?? '') ?? '';
    }

    const ctas: EfcCallToActionInfo[] = [
        {
            text: t(UsersTKeys.AddUserButton),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            dataId: 'btnAddUser',
            onClick: () => routeToUserNew(),
            show: () => currentRoute == RoutePath.Users,
        },
        {
            text: t(UsersTKeys.AddGroupButton),
            emphasis: 'high',
            color: 'primary',
            icon: { icon: ['far', 'circle-plus'] },
            dataId: 'btnAddGroup',
            onClick: () => routeToGroupNew(),
            show: () => currentRoute == RoutePath.Groups,
        },
    ];

    const onIFramePathChange = (newPath: string) => {
        if (currentRoute == RoutePath.UserNew && newPath == IFrameRoutes.get(RoutePath.Users)) {
            routeToUsers();
        } else if (currentRoute == RoutePath.GroupNew && newPath == IFrameRoutes.get(RoutePath.Groups)) {
            routeToGroups();
        }
    };

    const confirmDeleteUser = (e: MessageEvent) => {
        const { type, roles, editSingleRole } = e.data;
        if (type === 'SHOW_CONFIRM_DELETE_USER_MODAL' || type === 'SHOW_CONFIRM_REMOVE_LICENSE_MODAL') {
            openModal((closeModal) => <DeleteUserConfirmationModal onClose={closeModal} roles={roles} type={type} editSingleRole={editSingleRole} />);
        }
    };

    useIFramePathChangeListener({ onIFramePathChange: onIFramePathChange, currentRoute: currentRoute });

    useEffect(() => {
        if (currentRoute == RoutePath.UserIndex) {
            if (canGoToRoute(RoutePath.Users)) {
                routeToUsers();
            } else if (canGoToRoute(RoutePath.Groups)) {
                routeToGroups();
            } else if (canGoToRoute(RoutePath.UserSessions)) {
                routeToUserSessions();
            } else if (canGoToRoute(RoutePath.AccessLinks)) {
                routeToAccessLinks();
            } else if (canGoToRoute(RoutePath.UserDirectories)) {
                routeToUserDirectories();
            }
        } else {
            iframe.setUrl(getIFrameUrl(currentRoute));
        }
    }, [currentRoute]);

    useEffect(() => {
        window.addEventListener('message', confirmDeleteUser);

        return () => {
            window.removeEventListener('message', confirmDeleteUser);
        };
    }, []);

    return (
        <Page title={t(UsersTKeys.UsersTitle)} icon={{ icon: 'user' }} callsToAction={ctas}>
            <AccountFeatureUsageHeader accountFeatureTypes={[AccountFeatureEnum.FullUserLicense, AccountFeatureEnum.EssentialsUserLicense]} />

            {canGoToRoute(RoutePath.Users) && (
                <Tab active={UserTabRoutes.has(currentRoute)} onClick={() => routeToUsers()}>
                    {t(UsersTKeys.UsersTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.Groups) && (
                <Tab active={GroupTabRoutes.has(currentRoute)} onClick={() => routeToGroups()}>
                    {t(UsersTKeys.GroupsTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.UserSessions) && (
                <Tab active={currentRoute === RoutePath.UserSessions} onClick={() => routeToUserSessions()}>
                    {t(UsersTKeys.UserSessionsTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.AccessLinks) && (
                <Tab active={AccessLinksTabRoutes.has(currentRoute)} onClick={() => routeToAccessLinks()}>
                    {t(UsersTKeys.AccessLinksTab)}
                </Tab>
            )}
            {canGoToRoute(RoutePath.UserDirectories) && (
                <Tab active={currentRoute === RoutePath.UserDirectories} onClick={() => routeToUserDirectories()}>
                    {t(UsersTKeys.DirectoriesTab)}
                </Tab>
            )}

            {/* Use the iframe until we make this page native */}

            <IFrame className='iframe' dataId='userIframe' src={iframe.url} hidden={HideIFrameRoutes.has(currentRoute)} />

            {/* Use the following Tab Panes once we make this page native */}

            <Tab.Content>
                <>
                    {currentRoute == RoutePath.UserSessions &&
                        <Tab.Pane>
                            <UserSessionsManagement />
                        </Tab.Pane>
                    }
                </>
            </Tab.Content>
        </Page>
    );
};
