import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { Text, Input, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { useProfileUtilities } from '../../../../../hooks/useProfileUtilities';
import { ProfilesAndProfileItemsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useNodeProfileApi } from '../../../../../api/nodeProfiles/useNodeProfileApi';
import { ActionIcon } from '../../../../ActionIcon/ActionIcon';
import { ProfileItemDTO, ProfileItemTypeEnum } from '../../../../../api/profileItems/profileItemApiTypes';

interface NodeProfileItemValueDisplayProps {
    canViewHiddenText: boolean;
    nodeId: string;
    nodeProfileItem: ProfileItemDTO;
}

export const NodeProfileItemValueDisplay = ({ canViewHiddenText, nodeId, nodeProfileItem }: NodeProfileItemValueDisplayProps) => {

    const DEFAULT_DECIMAL_PLACES = 2;

    const { getMaskedProfileItemValueAsync, getUnmaskedProfileItemValueAsync } = useNodeProfileApi();
    const { t } = useSafeTranslation(TranslationFiles.ProfilesAndProfileItems);
    const { truncateString } = useProfileUtilities();

    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
    const [isToggleMaskingToolTipOpen, setIsToggleMaskingToolTipOpen] = useState<boolean>(false);
    const [showHiddenText, setShowHiddenText] = useState<boolean>(false);
    const [hiddenText, setHiddenText] = useState<string>(nodeProfileItem.value);

    function toggleTooltip() {
        setIsToolTipOpen((prevState) => !prevState);
    }

    function toggleMaskingTooltip() {
        setIsToggleMaskingToolTipOpen((prevState) => !prevState);
    }

    function getPresetValue() {
        const presetValue = _.find(nodeProfileItem.presetValues, { id: nodeProfileItem.presetValueID });
        const value = presetValue ? presetValue.value : nodeProfileItem.value;
        return truncateString(value);
    }

    function isValidDate(date: string): boolean {
        const regex = /^\d{8}$/;
        return regex.test(date);
    }

    function isValidPhoneNumber(phoneNumber: string): boolean {
        const regex = /^\d{10,13}$/;
        return regex.test(phoneNumber);
    }

    useEffect(() => {
        if (nodeProfileItem.type == ProfileItemTypeEnum.HiddenText && !!canViewHiddenText) {
            if (!!showHiddenText) {
                getUnmaskedProfileItemValueAsync(nodeId, nodeProfileItem.id)
                    .then((response) => {
                        setHiddenText(response.value);
                    });
            }
            else {
                getMaskedProfileItemValueAsync(nodeId, nodeProfileItem.id)
                    .then((response) => {
                        setHiddenText(response.value);
                    });
            }
        }
    }, [showHiddenText]);

    const truncatedValue = truncateString(nodeProfileItem.value);
    const showTooltip = nodeProfileItem.value != truncatedValue;

    switch (nodeProfileItem.type) {
        case ProfileItemTypeEnum.Date:
            {
                if (isValidDate(nodeProfileItem.value)) {
                    return <PatternFormat
                        format='##/##/####'
                        displayType='text'
                        value={truncatedValue}
                        renderText={(value) => <Text className='ps-3' size='md'>{value}</Text>} />;
                }
                else {
                    return <Text className='ps-3' size='md'>{truncatedValue}</Text>;
                }
            }
        case ProfileItemTypeEnum.CheckBox:
            return <Input type='checkbox' className='ms-3' checked={truncatedValue === 'true'} disabled />;
        case ProfileItemTypeEnum.Number:
            return (
                <>
                    <div id={`tooltip-target-${nodeProfileItem.id}`}>
                        <NumericFormat
                            value={truncatedValue}
                            displayType='text'
                            thousandSeparator
                            decimalScale={nodeProfileItem.visibleDecimalPlaces ?? DEFAULT_DECIMAL_PLACES}
                            renderText={(value) => <Text className='ps-3' size='md'>{value}</Text>} />
                    </div>
                    {showTooltip &&
                        <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                            {nodeProfileItem.value}
                        </Tooltip>
                    }
                </>
            );
        case ProfileItemTypeEnum.PhoneNumber:
            {
                if (isValidPhoneNumber(nodeProfileItem.value)) {
                    return <PatternFormat
                        format='(###) ###-#### ext. ###'
                        displayType='text'
                        value={truncatedValue}
                        renderText={(value) => <Text className='ps-3' size='md'>{value}</Text>} />;
                }
                else {
                    return <Text className='ps-3' size='md'>{truncatedValue}</Text>;
                }
            }
        case ProfileItemTypeEnum.Currency:
            return (
                <>
                    <div id={`tooltip-target-${nodeProfileItem.id}`}>
                        <NumericFormat
                            value={truncatedValue}
                            displayType='text'
                            thousandSeparator
                            prefix='$ '
                            fixedDecimalScale
                            decimalScale={nodeProfileItem.visibleDecimalPlaces ?? DEFAULT_DECIMAL_PLACES}
                            renderText={(value) => <Text className='ps-3' size='md'>{value}</Text>} />
                    </div>
                    {showTooltip &&
                        <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                            {nodeProfileItem.value}
                        </Tooltip>
                    }
                </>
            );
        case ProfileItemTypeEnum.Percentage:
            return (
                <>
                    <div id={`tooltip-target-${nodeProfileItem.id}`}>
                        <NumericFormat
                            value={truncatedValue}
                            displayType='text'
                            thousandSeparator
                            suffix=' %'
                            fixedDecimalScale
                            decimalScale={nodeProfileItem.visibleDecimalPlaces ?? DEFAULT_DECIMAL_PLACES}
                            renderText={(value) => <Text className='ps-3' size='md'>{value}</Text>} />
                    </div>
                    {showTooltip &&
                        <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                            {nodeProfileItem.value}
                        </Tooltip>
                    }
                </>
            );
        case ProfileItemTypeEnum.PresetValues:
            return (
                <>
                    <div id={`tooltip-target-${nodeProfileItem.id}`}>
                        <Text className='ps-3' size='md'>{getPresetValue()}</Text>
                    </div>
                    {showTooltip &&
                        <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                            {nodeProfileItem.value}
                        </Tooltip>
                    }
                </>
            );
        case ProfileItemTypeEnum.Memo:
            return <textarea className='form-control' rows={3} value={nodeProfileItem.value} readOnly />;
        case ProfileItemTypeEnum.HiddenText:
            {
                const truncatedHiddenTextValue = truncateString(hiddenText);
                const showHiddenTextTooltip = hiddenText != truncatedHiddenTextValue;
                return (
                    <>
                        {!!canViewHiddenText
                            ? (
                                <>
                                <div className='d-flex'>
                                    <ActionIcon id={`tooltip-target-${nodeProfileItem.id}-eyeball`} color='black' className='ps-3' style={{ width: '18px' }} callbackFn={() => setShowHiddenText((prevstate) => !prevstate)} icon={showHiddenText ? 'eye-slash' : 'eye'} ></ActionIcon>

                                    <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}-eyeball`} isOpen={isToggleMaskingToolTipOpen} toggle={toggleMaskingTooltip} placement='bottom'>
                                            {showHiddenText ? t(ProfilesAndProfileItemsTKeys.HideText) : t(ProfilesAndProfileItemsTKeys.ShowHiddenText)}
                                    </Tooltip>
                                    <span id={`tooltip-target-${nodeProfileItem.id}`}><Text className='ps-1' size='md'>{truncatedHiddenTextValue}</Text></span>
                                    {showHiddenTextTooltip &&
                                        <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                                            {hiddenText}
                                        </Tooltip>
                                    }
                                </div>
                                </>
                            )
                            : <Text className='ps-1' size='md'>{truncatedHiddenTextValue}</Text>}
                    </>
                );
            }
        case ProfileItemTypeEnum.Text:
        case ProfileItemTypeEnum.Email:
        default:
            return (
                <>
                    <div id={`tooltip-target-${nodeProfileItem.id}`}> <Text className='ps-3' size='md'>{truncatedValue}</Text> </div>
                    {showTooltip &&
                        <Tooltip className='tooltip-purple' target={`tooltip-target-${nodeProfileItem.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip} placement='bottom'>
                            {nodeProfileItem.value}
                        </Tooltip>
                    }
                </>
            );
    }
};