import React, { useEffect, useState } from 'react';
import { ThemeEnum } from './useColors';

export interface ToastMessage {
    identifier: string;
    message: React.ReactNode;
    color?: ThemeEnum;
    timeout?: number;
}

export const useToastMessages = () => {

    const [toastMessagesQueue, setToastMessageQueue] = useState<ToastMessage[]>([]);
    const [displayedToasts, setDisplayedToasts] = useState<ToastMessage[]>([]);

    const showToastMessage = (message: ToastMessage) => {
        setToastMessageQueue(currentMessages => {
            return currentMessages.concat(message);
        });
    };

    const closeToastMessage = (identifier?: string) => {
        setDisplayedToasts(currentMessages => {
            return currentMessages.filter(x => x.identifier != identifier);
        });
    };

    const rankColor = (color?: ThemeEnum) => {
        switch (color) {
            case ThemeEnum.Danger:
                return 0;
            case ThemeEnum.Warning:
                return 1;
            case ThemeEnum.Success:
                return 2;
            case ThemeEnum.Primary:
                return 3;
            case ThemeEnum.Secondary:
                return 4;
            case ThemeEnum.Light:
            case ThemeEnum.Dark:
                return 5;
            case ThemeEnum.Info:
            default:
                return 6;
        }
    };

    useEffect(() => {
        if (!!toastMessagesQueue && toastMessagesQueue.length > 0) {
            const message = toastMessagesQueue.pop();
            if (!message) {
                return;
            }

            setToastMessageQueue(toastMessagesQueue.filter(x => x.identifier !== message.identifier));

            if (displayedToasts.map(x => x.identifier).includes(message.identifier)) {
                return;
            }

            setDisplayedToasts(currentMessages => {
                return currentMessages.concat(message);
            });

            if (!!message.timeout) {
                setTimeout(() => {
                    closeToastMessage(message.identifier);
                }, message.timeout);
            }
        }
    }, [toastMessagesQueue]);

    useEffect(() => {
        if (!!displayedToasts && displayedToasts.length > 1) {
            setDisplayedToasts(currentToasts => currentToasts.sort((a, b) => rankColor(a.color) - rankColor(b.color)));
        }
    }, [displayedToasts]);

    return {
        displayedToasts,
        showToastMessage,
        closeToastMessage,
    };
};