import React, { useState } from 'react';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import './MdClearAllButton.css';

interface MDClearAllButtonProps {
    onClick: () => void;
}

export const MDClearAllButton = (props: MDClearAllButtonProps) => {
    const { onClick } = props;

    const { t } = useSafeTranslation(TranslationFiles.MDFieldModal);

    const [isConfirming, setIsConfirming] = useState(false);

    const handleClearAll = () => {
        setIsConfirming(true);
    };

    return (
        <>
            {isConfirming ?
                <Button color='danger' onClick={onClick}>
                    {t(MDFieldModalTKeys.ConfirmClearAll)}
                </Button>
                : (
                    <div className='d-flex align-items-center pointer me-2' onClick={handleClearAll}>
                        <div>
                            <FontAwesomeIcon className='trash-icon' icon="trash-can" color='grey' />
                        </div>
                        <span className='ms-1 clear-all'>{t(MDFieldModalTKeys.ClearAll)}</span>
                    </div>
                )}
        </>
    );
};
