export const useSystemLogs = () => {

    const addSystemLog = (windowMessageData: any) => {
        let existingEntries = JSON.parse(localStorage.getItem(windowMessageData.operationName) as string);
        if (existingEntries == null) existingEntries = [];
        existingEntries.push(windowMessageData.exception);

        while (existingEntries.length > 500) {
            existingEntries.pop();
        }

        localStorage.setItem(windowMessageData.operationName, JSON.stringify(existingEntries));
    };

    return {
        addSystemLog,
    };

};