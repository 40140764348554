export enum OrderEnum {
    DefaultAscending = 0,
    DefaultDescending = 1,
    NameAscending = 2,
    NameDescending = 3,
    CreatedByAscending = 4,
    CreatedByDescending = 5,
    CreatedOnAscending = 6,
    CreatedOnDescending = 7,
    TypeAscending = 8,
    TypeDescending = 9,
    DeletedOnAscending = 10,
    DeletedOnDescending = 11,
    UserNameAscending = 12,
    UserNameDescending = 13,
    SizeAscending = 14,
    SizeDescending = 15,
    ModifiedOnAscending = 16,
    ModifiedOnDescending = 17,
}

export enum SystemActionEnum {
    Create = 1,
    Read = 2,
    Update = 3,
    Delete = 4,

    ApplyTemplate = 5,
    Restore = 6,
    Purged = 7,

    Copy = 8,

    Append = 9,

    Expired = 10,
    Failed = 11,
    PreNotify = 12,
    Move = 13,
    Watermark = 14,
    PermissionPushdown = 15,

    FileUpload = 100,
    FileDownload = 101,
    FileCheckOut = 102,
    FileCheckIn = 103,
    FileView = 104,

    NodeLegacyMappingCreated = 204,

    EmailQueued = 300,
    EmailSent = 301,

    Share = 400,

    AddedToAccount = 500,

    PasswordSet = 600,
    PasswordUpdated = 601,
    PasswordRemoved = 602,

    Login = 700,
    FailedLogin = 701,

    Completed = 800,

    WorkflowCurrentStageChanged = 900,
    OnApproval = 901,
    Approved = 902,
    Rejected = 903,
    BeforeTimeframe = 904,
    AfterPastDue = 905,

    SecurityPolicyChanged = 1000,
    ApproveRequest = 1001,
    Assignment = 1002,

    Migrate = 1100,

    Suspended = 1200,
    InProgress = 1201,
}

export enum SystemTypeEnum {
    Node = 0,
    NodeShare = 1,
    Role = 2,
    FileInfo = 3,
    NodeComment = 5,
    Email = 6,
    DocumentRequest = 7,
    TimeTrigger = 8,
    EventTrigger = 9,
    Notification = 10,
    Permission = 11,
    Collection = 12,
    ProgressNotification = 13,
    WorkflowInstance = 14,
    WorkflowInstanceStage = 15,
    WorkflowInstanceStep = 16,
    WorkflowInstanceAsset = 17,
    NodeAnnotation = 18,
    NodeProfile = 19,
    EmailFilesRequest = 20,
    ESignatureTransaction = 21,
    AccessLink = 22,
    FormFill = 23,
    ApiCallout = 24,
    UpdateIsAvailable = 25,
    Account = 26,
}

export interface IBatchItem<T> {
    batchObject: T;
    clientIdentifier: string;
}

export interface IBatchItemResponse<T> extends IBatchItem<T> {
    operationError: UtopiaErrorDTO;
}

export interface UtopiaErrorDTO {
    errorType: ExceptionTypeEnum;
    errorId?: number;   
    message: string;
    title: string;
    operationId: string;
    stackTrace: string;
    hideOperationId: string;
}

export enum ExceptionTypeEnum {
    SystemException = 0,
    General = 1,
    Authentication = 2,
    Unauthorized = 3,
    Template = 4,
    NotImplemented = 5,
    OCR = 6,
    AccountFeatureUsage = 7,
    O365 = 8,
}