import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label, Text } from '@efilecabinet/efc-atlantis-components';
import { PermissionsInheritanceSelectorTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { NodeType } from '../../../../../api/node/nodeApiTypes';
import { NodePermissionDTO, NodePermissionInheritanceEnum } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { useFileUtilities } from '../../../../../hooks/useFileUtilities';
import accountOnly from '../../../../../images/permissions/account-only.png';
import accountAllItems from '../../../../../images/permissions/account-all-items.png';
import cabinetOnly from '../../../../../images/permissions/cabinet-only.png';
import cabinetAllItems from '../../../../../images/permissions/cabinet-all-items.png';
import drawerOnly from '../../../../../images/permissions/drawer-only.png';
import drawerAllItems from '../../../../../images/permissions/drawer-all-items.png';
import folderOnly from '../../../../../images/permissions/folder-only.png';
import folderAllItems from '../../../../../images/permissions/folder-all-items.png';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';

interface PermissionsInheritanceSelectorProps {
    systemType?: NodeType;
}

export const PermissionsInheritanceSelector = ({ systemType }: PermissionsInheritanceSelectorProps) => {
    const { getNodeTypeText } = useFileUtilities();
    const { editedPermission: permission, setEditedPermission } = useNodePermissionsContext();
    const { t } = useSafeTranslation(TranslationFiles.PermissionsInheritanceSelector);

    const [graphicSrc, setGraphicSrc] = useState<string>(folderAllItems);
    const [itemTypeString, setItemTypeString] = useState<string>('folder');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value) as NodePermissionInheritanceEnum;
        setEditedPermission(
            (prevState) =>
                ({
                    ...prevState,
                    childPermission: {
                        ...prevState?.childPermission,
                    },
                    inheritanceBehavior: value,
                    admin: value === NodePermissionInheritanceEnum.Default ? prevState?.admin : false,
                } as NodePermissionDTO)
        );
    };

    const determineGraphic = () => {
        switch (systemType) {
            case NodeType.Account:
                return permission?.inheritanceBehavior === NodePermissionInheritanceEnum.AppliesToSelfOnly ? accountOnly : accountAllItems;
            case NodeType.Cabinet:
                return permission?.inheritanceBehavior === NodePermissionInheritanceEnum.AppliesToSelfOnly ? cabinetOnly : cabinetAllItems;
            case NodeType.Drawer:
                return permission?.inheritanceBehavior === NodePermissionInheritanceEnum.AppliesToSelfOnly ? drawerOnly : drawerAllItems;
            case NodeType.Folder:
            default:
                return permission?.inheritanceBehavior === NodePermissionInheritanceEnum.AppliesToSelfOnly ? folderOnly : folderAllItems;
        }
    };

    useEffect(() => {
        if (!!permission?.inheritanceBehavior !== undefined && !!systemType) {
            setItemTypeString(getNodeTypeText(systemType).toLowerCase());
            setGraphicSrc(determineGraphic());
        }
    }, [permission]);

    return (
        <>
            <Text className='d-block mb-2'>
                {t(PermissionsInheritanceSelectorTKeys.InheritanceInstructions, { itemType: itemTypeString })}
            </Text>
            <div className='d-flex'>
                <img src={graphicSrc} alt={`${itemTypeString} graphic`} />
                <div className='d-flex flex-column ms-5 ps-3'>
                    <FormGroup check>
                        <Label check>
                            <Input
                                dataId={`all-items-inheritance-${permission?.id}`}
                                name='permissions-inheritance'
                                type='radio'
                                checked={permission?.inheritanceBehavior === NodePermissionInheritanceEnum.Default}
                                value={NodePermissionInheritanceEnum.Default}
                                onChange={handleChange}
                            />
                            {t(PermissionsInheritanceSelectorTKeys.AllItems, { itemType: itemTypeString })}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                dataId={`item-only-inheritance-${permission?.id}`}
                                name='permissions-inheritance'
                                type='radio'
                                checked={permission?.inheritanceBehavior === NodePermissionInheritanceEnum.AppliesToSelfOnly}
                                value={NodePermissionInheritanceEnum.AppliesToSelfOnly}
                                onChange={handleChange}
                            />
                            {t(PermissionsInheritanceSelectorTKeys.ItemOnly, { itemType: itemTypeString })}
                        </Label>
                    </FormGroup>
                </div>
            </div>
        </>
    );
};
