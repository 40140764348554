import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { NodePermissionsContext } from './NodePermissionsContext';
import { NodePermissionDTO } from '../../../../api/nodePermissions/nodePermissionApiTypes';
import { useNodePermissionsUtilities } from '../../../../hooks/nodePermissions/useNodePermissionsUtilities';

export interface INodePermissionsContext {
    permissions: NodePermissionDTO[];
    activePermission: NodePermissionDTO | undefined;
    editedPermission: NodePermissionDTO | undefined;
    editedPermissions: NodePermissionDTO[];
    setPermissions: Dispatch<SetStateAction<NodePermissionDTO[]>>;
    setActivePermission: Dispatch<SetStateAction<NodePermissionDTO | undefined>>;
    setEditedPermission: Dispatch<SetStateAction<NodePermissionDTO | undefined>>;
    setEditedPermissions: Dispatch<SetStateAction<NodePermissionDTO[]>>;
    fetchNodePermissions: (nodeId: string) => Promise<void>;
    categorizePermissionChanges: (activeNodeId?: string, individualPermission?: boolean) => {
        permissionsToRemove: NodePermissionDTO[];
        permissionsToUpdate: NodePermissionDTO[];
        permissionsToCreate: NodePermissionDTO[];
    };
}

interface NodePermissionsProviderProps {
    children: ReactNode;
}

export const NodePermissionsProvider = ({ children }: NodePermissionsProviderProps) => {
    const { getNodePermissionsAsync, getEditedPermissions } = useNodePermissionsUtilities();

    const [permissions, setPermissions] = useState<NodePermissionDTO[]>([]);
    const [activePermission, setActivePermission] = useState<NodePermissionDTO>();
    const [editedPermission, setEditedPermission] = useState<NodePermissionDTO>();
    const [editedPermissions, setEditedPermissions] = useState<NodePermissionDTO[]>([]);

    const fetchNodePermissions = async (nodeId: string) => {
        const permissionsResult = await getNodePermissionsAsync(nodeId);
        setPermissions(permissionsResult);
        setEditedPermissions(permissionsResult);
    };

    const categorizePermissionChanges = (activeNodeId?: string, individualPermission = false) => {
        const permissionsToRemove: NodePermissionDTO[] = [];
        const permissionsToUpdate: NodePermissionDTO[] = [];
        const permissionsToCreate: NodePermissionDTO[] = [];

        if (!activeNodeId) {
            return {
                permissionsToRemove,
                permissionsToUpdate,
                permissionsToCreate,
            };
        }

        let permissionsWithChanges: NodePermissionDTO[] = [];

        if (individualPermission && activePermission && editedPermission) {
            permissionsWithChanges = getEditedPermissions([activePermission], [editedPermission]);
        } else {
            permissionsWithChanges = getEditedPermissions(permissions, editedPermissions);
        }

        if (!permissionsWithChanges.length) {
            return {
                permissionsToRemove,
                permissionsToUpdate,
                permissionsToCreate,
            };
        }

        for (const permission of permissionsWithChanges) {
            if (permission.remove && permission.nodeID?.toString() === activeNodeId) {
                // Permission is marked for removal and is not inherited. Can be removed.
                permissionsToRemove.push(permission);
            } else if (permission.remove && permission.nodeID?.toString() !== activeNodeId) {
                // Permission is marked for removal and is inherited. Needs to be updated to have the active node id
                permission.nodeID = +activeNodeId;
                permission.view = false;
                permission.download = false;
                permission.write = false;
                permission.delete = false;
                permission.admin = false;
                permission.uploadFiles = false;
                permission.createDirectories = false;
                permission.childPermission = null;
                permission.restrict = true;
                permission.enforce = true;

                permissionsToUpdate.push(permission);
            } else if (permission.nodeID?.toString() === activeNodeId) {
                // Permission is not inherited and has been edited. Needs to be updated.
                permissionsToUpdate.push(permission);
            } else if (!permission.remove && permission.nodeID?.toString() !== activeNodeId) {
                // Permission is inherited and has been edited. Needs to be created as a new permission.
                permissionsToCreate.push({ ...permission, nodeID: +activeNodeId });
            }
        }

        return {
            permissionsToRemove,
            permissionsToUpdate,
            permissionsToCreate,
        };
    };

    const contextObject: INodePermissionsContext = {
        permissions,
        activePermission,
        editedPermission,
        editedPermissions,
        setPermissions,
        setActivePermission,
        setEditedPermission,
        setEditedPermissions,
        fetchNodePermissions,
        categorizePermissionChanges,
    };

    return <NodePermissionsContext.Provider value={contextObject}>{children}</NodePermissionsContext.Provider>;
};
