import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { AccountBrandingDTO, CnameAvailableDTO } from './brandingApiTypes';

export const useBrandingApi = () => {

    const getBrandingByAccountIdentifier = async (accountIdentifier: string): Promise<AccountBrandingDTO> => {
        const resp: AxiosResponse = await Api.get(`api/branding/accountIdentifier/${accountIdentifier}`);
        return resp.data as AccountBrandingDTO;
    };

    const getBrandingByCname = async (cname: string): Promise<AccountBrandingDTO> => {
        const resp: AxiosResponse = await Api.get(`api/branding/cname/${cname}`);
        return resp.data as AccountBrandingDTO;
    };

    const getCnameAvailable = async (cname: string): Promise<CnameAvailableDTO> => {
        const resp: AxiosResponse = await Api.get(`api/branding/available/${cname}`);
        return resp.data as CnameAvailableDTO;
    };

    const getCnameExists = async (cname: string): Promise<boolean> => {
        const resp: AxiosResponse = await Api.get(`api/branding/exists/${cname}`);
        return resp.data;
    };

    const saveBranding = async (branding: AccountBrandingDTO): Promise<AccountBrandingDTO> => {
        const resp: AxiosResponse = await Api.post('api/branding/saveBranding', branding);
        return resp.data as AccountBrandingDTO;
    };

    return {
        getBrandingByAccountIdentifier,
        getBrandingByCname,
        getCnameAvailable,
        getCnameExists,
        saveBranding,
    };
};
