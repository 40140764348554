import { useLocation } from 'react-router';
import { useAuthContext } from '../../app/_context/AuthContext';
import { DocumentsTKeys, TranslationFiles, useSafeTranslation } from '../useSafeTranslation';
import { useUrlUtilities } from '../useUrlUtilities';
import { useUtopiaIFrameRoutes } from '../useUtopiaIFrameRoutes';
import { DocumentRouteParams, DocumentRoutePath, RoutePath, SelectedDetail } from '../../features/_routing/routingTypes';
import { IDocumentURLDetails, IIFrameURLUpdateDetails, IValidNodesCheckDetails } from './documentsTypes';
import { NodeDTO, NodeType } from '../../api/node/nodeApiTypes';
import { useNodeApi } from '../../api/node/useNodeApi';

export interface DocumentsIFrameState {
    collectionId: string | undefined;
    iFrameUrlPath: string | undefined;
    nodeIds: string | undefined;
    parentId: string | undefined;
    selectedDetail: string | undefined;
    showFullPreview: string | undefined;
}

export const useDocumentsUtilities = () => {
    const { getNodeBatchAsync, getNodeByIdAsync, getRootNodeByAccountIdAsync } = useNodeApi();
    const { getQueryParam } = useUrlUtilities();
    const { user } = useAuthContext();
    const location = useLocation();
    const { IFrameDocumentRoutes } = useUtopiaIFrameRoutes();
    const { t } = useSafeTranslation(TranslationFiles.DocumentLayer);

    const defaultCollectionId = user?.preferences?.defaultCollectionID ?? 0;
    const lastUtopiaHomePathLocalStorageKey = 'lastUtopiaHomeUrl:' + user?.id;
    const externalRootNodeIdLength = 3;

    function determineCurrentRoute(documentURLDetails: IDocumentURLDetails): DocumentRoutePath {
        if (!!documentURLDetails.collectionId) {
            if (!!documentURLDetails.nodeIds) {
                return RoutePath.CollectionNodeDetail;
            } else {
                return RoutePath.Collection;
            }
        } else if (!!documentURLDetails.nodeIds) {
            if (!!documentURLDetails.containerNodeId) {
                return RoutePath.NodeContainer;
            } else if (!!documentURLDetails.selectedDetail) {
                return RoutePath.NodeDetail;
            } else {
                return RoutePath.Node;
            }
        } else {
            return `${location.pathname}` as DocumentRoutePath;
        }
    }

    const getDocumentsIFrameState = (iFrameUrlPath: string | undefined): DocumentsIFrameState => {
        const state = { iFrameUrlPath: iFrameUrlPath } as DocumentsIFrameState;
        const pathParts = state.iFrameUrlPath?.split('/') ?? [];
    
        if (!!state.iFrameUrlPath) {
            if (state.iFrameUrlPath.startsWith('/home/state/data/node/')) {
                // example url: home/state/data/node/267776316/267776316/details/267776316?selectedDetail=profile
                state.nodeIds = pathParts[8].split('?')[0];
                state.parentId = pathParts[6];
                state.selectedDetail = getQueryParam(state.iFrameUrlPath, 'selectedDetail') ?? undefined;
                state.showFullPreview = getQueryParam(state.iFrameUrlPath, 'showFullPreview') ?? undefined;
            } else if (state.iFrameUrlPath.startsWith('/home/state/data/root/')) {
                // example url: home/state/data/root/details/?selectedDetail=profile
                /* do nothing because there are no identifiers in the url */
            } else if (state.iFrameUrlPath.startsWith('/home/state/collections/collection/')) {
                // example urls:
                //    home/state/collections/collection/52668/rootID/nodeID/details?selectedDetail=profile
                //    home/state/collections/collection/52668/rootID/nodeID/details/132859530?selectedDetail=profile
                //    home/state/collections/collection/52668/rootID/253770503/nodeID/253770503/details/253770503?selectedDetail=profile
                state.collectionId = pathParts[5];
            }
        }
    
        return state;
    };

    async function validNodesCheck(nodeIds: string[]): Promise<IValidNodesCheckDetails> {
        const validNodeIds: string[] = [];
        let invalidNodeMessage: string | undefined = undefined;
        try {
            if (nodeIds[0].includes('GD-') || nodeIds[0].includes('OD-')) {
                nodeIds.forEach((nodeId) => validNodeIds.push(nodeId));
            } else if (nodeIds.length > 1) {
                const result: NodeDTO[] = await getNodeBatchAsync(nodeIds);
                if (result.length > 0) {
                    const ids: string[] = result.map(o => o.id);
                    validNodeIds.push(...ids);
                }
                if (nodeIds.length > validNodeIds.length) {
                    invalidNodeMessage = t(DocumentsTKeys.MissingNodePermissionMessage);
                }
            } else {
                const singleResult = await getNodeByIdAsync(nodeIds[0]);
                if (!!singleResult) {
                    validNodeIds.push(singleResult.id);
                } else {
                    invalidNodeMessage = t(DocumentsTKeys.MissingNodePermissionMessage);
                }
            }
        } catch (ex) {
            invalidNodeMessage = t(DocumentsTKeys.InvalidNodeMessage);
        }

        const validNodesCheckDetails: IValidNodesCheckDetails = {
            validNodeIds: validNodeIds,
            invalidNodesMessage: invalidNodeMessage
        };

        return validNodesCheckDetails;
    }

    async function configureFirstSelectedNodeURL(containerNodeId: string | undefined, nodeIds: string[], selectedDetail: string | undefined, routePath: DocumentRoutePath.Node | DocumentRoutePath.NodeDetail | DocumentRoutePath.NodeContainer | DocumentRoutePath.CollectionNodeDetail): Promise<string> {
        let configuredURL = IFrameDocumentRoutes.get(RoutePath.Documents) as string;
        if (nodeIds[0].includes('GD-') || nodeIds[0].includes('OD-')) {
            configuredURL = await configureExternalDriveNodeUrl(nodeIds, selectedDetail, routePath);
        } else {
            configuredURL = await configureStandardNodeUrl(containerNodeId, nodeIds, selectedDetail, routePath);
        }
        return configuredURL;
    }

    async function configureExternalDriveNodeUrl(nodeIds: string[], selectedDetail: string | undefined, routePath: DocumentRoutePath.Node | DocumentRoutePath.NodeDetail | DocumentRoutePath.NodeContainer | DocumentRoutePath.CollectionNodeDetail): Promise<string> {
        const firstSelectedNodeId = nodeIds[0];
        if (firstSelectedNodeId.length === externalRootNodeIdLength) {
            return IFrameDocumentRoutes.get(routePath)
                ?.replace(DocumentRouteParams.RootNodeId, firstSelectedNodeId)
                ?.replace(DocumentRouteParams.ParentId, firstSelectedNodeId)
                ?.replace(DocumentRouteParams.NodeIds, firstSelectedNodeId)
                ?.replace(DocumentRouteParams.SelectedDetail, getSelectedDetailForUtopia(selectedDetail))
                ?.replace(DocumentRouteParams.ShowFullPreview, getShowFullPreviewForUtopia(selectedDetail)) as string;
        } else {
            const firstSelectedNode: NodeDTO = await getNodeByIdAsync(firstSelectedNodeId);
            const rootNodeId: string = firstSelectedNodeId.substring(0, externalRootNodeIdLength);
            let parentId: string;
            if (!!firstSelectedNode.parentID && firstSelectedNode.systemType === NodeType.Folder) {
                parentId = firstSelectedNode.id;
            } else if (!!firstSelectedNode.parentID && firstSelectedNode.parentID.length > externalRootNodeIdLength && !firstSelectedNode.hasChildren) {
                parentId = firstSelectedNode.parentID;
            } else {
                parentId = firstSelectedNodeId.substring(0, externalRootNodeIdLength);
            }

            return IFrameDocumentRoutes.get(routePath)
                ?.replace(DocumentRouteParams.RootNodeId, rootNodeId)
                ?.replace(DocumentRouteParams.ParentId, parentId)
                ?.replace(DocumentRouteParams.NodeIds, nodeIds.toString() ?? '')
                ?.replace(DocumentRouteParams.SelectedDetail, getSelectedDetailForUtopia(selectedDetail))
                ?.replace(DocumentRouteParams.ShowFullPreview, getShowFullPreviewForUtopia(selectedDetail)) as string;
        }
    }

    async function configureStandardNodeUrl(containerNodeId: string | undefined, nodeIds: string[], selectedDetail: string | undefined, routePath: DocumentRoutePath): Promise<string> {
        const firstSelectedNodeId = nodeIds[0];
        const firstSelectedNode: NodeDTO = await getNodeByIdAsync(firstSelectedNodeId);
        const rootNodeId: string = firstSelectedNode.accountID != 0 ? await getRootNodeByAccountIdAsync(firstSelectedNode.accountID).then((node) => node.id) : firstSelectedNode.id;
        let parentId: string = rootNodeId;

        if (containerNodeId == undefined || containerNodeId == 'undefined' || containerNodeId == '0' || isNaN(parseInt(containerNodeId))) {
            if ((firstSelectedNode.systemType == NodeType.File || nodeIds.length > 1) && firstSelectedNode.parentID != undefined) {
                parentId = firstSelectedNode.parentID;
            } else if (firstSelectedNode.id != undefined) {
                parentId = firstSelectedNode.id;
            }
        } else {
            const checkedContainer: NodeDTO = await getNodeByIdAsync(containerNodeId ?? '');
            if (!!checkedContainer) {
                parentId = containerNodeId;
            }
        }

        return IFrameDocumentRoutes.get(routePath)
            ?.replace(DocumentRouteParams.RootNodeId, rootNodeId)
            ?.replace(DocumentRouteParams.ParentId, parentId)
            ?.replace(DocumentRouteParams.NodeIds, nodeIds.toString() ?? '')
            ?.replace(DocumentRouteParams.SelectedDetail, getSelectedDetailForUtopia(selectedDetail))
            ?.replace(DocumentRouteParams.ShowFullPreview, getShowFullPreviewForUtopia(selectedDetail)) as string;
    }

    function getSelectedDetailForUtopia(selectedDetail: string | undefined) {
        let finalSelectedDetail: string = SelectedDetail.Preview;
        if (!!selectedDetail) {
            if (selectedDetail == SelectedDetail.FullPreview) {
                finalSelectedDetail = SelectedDetail.Preview;
            } else {
                finalSelectedDetail = selectedDetail;
            }
        }
        return finalSelectedDetail;
    }

    function getShowFullPreviewForUtopia(selectedDetail: string | undefined) {
        let showFullPreview = 'false';
        if (!!selectedDetail && selectedDetail == SelectedDetail.FullPreview) {
            showFullPreview = 'true';
        }
        return showFullPreview;
    }

    async function getIFrameUrl(documentURLDetails: IDocumentURLDetails): Promise<IIFrameURLUpdateDetails> {
        const currentRoutePath: DocumentRoutePath = determineCurrentRoute(documentURLDetails);
        const iFrameURLUpdateDetails: IIFrameURLUpdateDetails = {
            updatedIFrameURL: IFrameDocumentRoutes.get(RoutePath.Documents) as string,
            invalidNodesMessage: undefined,
        };

        if (currentRoutePath == RoutePath.Documents) {
            const previousState = getDocumentsIFrameState(localStorage.getItem(lastUtopiaHomePathLocalStorageKey) ?? undefined);
            if (!!previousState?.iFrameUrlPath) {
                if (!previousState?.iFrameUrlPath.includes('/home/state/collections/collection/:collectionId/rootID/nodeID/details'
                    || (previousState?.iFrameUrlPath.includes('/home/state/collections/collection/:collectionId/rootID/nodeID/details')
                        && !!previousState.collectionId
                        && previousState.collectionId !== ':collectionId'
                        && !isNaN(parseInt(previousState.collectionId))))) {
                    iFrameURLUpdateDetails.updatedIFrameURL = previousState.iFrameUrlPath;
                } else if (previousState?.iFrameUrlPath.includes('/home/state/collections/collection/:collectionId/rootID/nodeID/details')
                    && !!previousState.collectionId
                    && (previousState.collectionId === ':collectionId'
                        || isNaN(parseInt(previousState.collectionId ?? 'nan'))
                        && defaultCollectionId != 0)) {
                    iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Collections)
                        ?.replace(DocumentRouteParams.DefaultCollectionId, defaultCollectionId.toString()) as string;
                } else {
                    iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Documents) as string;
                }

            }
        } else if (
            currentRoutePath == RoutePath.Node ||
            currentRoutePath == RoutePath.NodeDetail ||
            currentRoutePath == RoutePath.NodeContainer ||
            currentRoutePath == RoutePath.CollectionNodeDetail) {
            try {
                const nodeIdsList = documentURLDetails.nodeIds?.split(',') ?? [];
                const validNodesDetails = await validNodesCheck(nodeIdsList);

                if (validNodesDetails.validNodeIds?.length == 0) {
                    iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Documents) as string;
                    iFrameURLUpdateDetails.invalidNodesMessage = validNodesDetails.invalidNodesMessage ?? '';
                } else {
                    iFrameURLUpdateDetails.updatedIFrameURL = await configureFirstSelectedNodeURL(documentURLDetails.containerNodeId, validNodesDetails.validNodeIds, documentURLDetails.selectedDetail, currentRoutePath);
                    iFrameURLUpdateDetails.invalidNodesMessage = validNodesDetails.invalidNodesMessage ?? '';
                }
            } catch (ex) {
                iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Documents) as string;
                iFrameURLUpdateDetails.invalidNodesMessage = t(DocumentsTKeys.InvalidNodeMessage);
            }
        } else if (currentRoutePath == RoutePath.Collections && defaultCollectionId != 0) {
            iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(currentRoutePath)
                ?.replace(DocumentRouteParams.DefaultCollectionId, defaultCollectionId.toString()) as string;
        } else if (currentRoutePath == RoutePath.Collection && !!documentURLDetails.collectionId?.toString()) {
            if (documentURLDetails.collectionId !== ':collectionId' && !isNaN(parseInt(documentURLDetails.collectionId))) {
                iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(currentRoutePath)
                    ?.replace(DocumentRouteParams.CollectionId, documentURLDetails.collectionId) as string;
            } else if (defaultCollectionId != 0) {
                iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Collections)
                    ?.replace(DocumentRouteParams.DefaultCollectionId, defaultCollectionId.toString()) as string;
            } else {
                iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Documents) as string;
            }
        } else {
            iFrameURLUpdateDetails.updatedIFrameURL = IFrameDocumentRoutes.get(RoutePath.Documents) as string;
        }

        return iFrameURLUpdateDetails;
    }

    return {
        getIFrameUrl,
        getDocumentsIFrameState,
    };
};