import React, { useState } from "react";
import { IUseModalProps } from "./useModal";

// Should only be used by LayerProvider
export const useModalManagement = () => {
    const [modals, setModals] = useState<{ identifier: string, modal: JSX.Element }[]>([]);

    // The modal component will be passed isOpen, toggle, and close props transparently. This is solely
    // to make it easier to migrate from using the useModal hook to using the LayerContext openModal function.
    // Ideally, we want the modal component to call the closeModalFunction passed as parameter to the function 
    // that returns the modal, and we stop cloning the component as well.
    const openModal = (getModalComponent: (closeModalFn: () => void) => JSX.Element) => {
        const identifier = crypto.randomUUID();
        const closeModalFn = getCloseModalFn(identifier);
        const modal = getModalComponent(closeModalFn);
        const wrappedModal = React.cloneElement<IUseModalProps>(modal, { isOpen: true, toggle: closeModalFn, close: closeModalFn });
        setModals((prevModals) => [...prevModals, { identifier, modal: wrappedModal }]);
    };

    const getCloseModalFn = (identifier: string) => {
        return () => setModals((prevModals) => prevModals.filter((modal) => modal.identifier != identifier));
    };

    return {
        modals,
        openModal,
    };
};