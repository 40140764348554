import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { UserFeedbackDTO, SubmitFeedbackDTO } from './feedbackApiTypes';

export const useFeedbackApi = () => {

    const getUserFeedbackInfo = async (userId: number): Promise<UserFeedbackDTO> => {
        const resp: AxiosResponse = await Api.get(`api/Feedback/GetUserFeedbackInfo/${userId}`);
        return resp.data;
    };

    const acknowledgePreviewerFeedback = async (userId: number): Promise<UserFeedbackDTO> => {
        const resp: AxiosResponse = await Api.post(`api/Feedback/AcknowledgePreviewerFeedback/${userId}`);
        return resp.data;
    };

    const submitFeedback = async (form: SubmitFeedbackDTO): Promise<void> => {
        await Api.post('api/Feedback', form);
    };

    const updateSurveyModal = async (userId: number): Promise<void> => {
        await Api.post(`api/Feedback/UpdateSurveyModal/${userId}`);
    };

    const updateSurveyReprompt = async (userId: number): Promise<void> => {
        await Api.post(`api/Feedback/UpdateSurveyReprompt/${userId}`);
    };

    return {
        getUserFeedbackInfo,
        acknowledgePreviewerFeedback,
        submitFeedback,
        updateSurveyModal,
        updateSurveyReprompt,
    };
};
