import React, { useState, useEffect } from 'react';
import { Alert, Fade } from '@efilecabinet/efc-atlantis-components';
import { ThemeEnum } from './useColors';

interface IStatusProps {
    className?: string;
    status: string;
    color: ThemeEnum;
    permanent: boolean;
    timeout?: number | null;
    //The parent component also needs to include the "key" element, which can be incremented to get this component to refresh after being updated with an identical status
}

export const Status = (props: IStatusProps) => {
    const DEFAULT_STATUS_TIMEOUT = 5000;

    const { className, status: statusProp, color, permanent = false, timeout = DEFAULT_STATUS_TIMEOUT, ...otherProps } = props;

    const [status, setStatus] = useState(statusProp);
    const [fadeIn, setFadeIn] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);


    const showStatusMessage = () => {
        if (!fadeIn) {
            setFadeIn(true);
        }
        setShow(true);
    };

    const hideStatusMessage = () => {
        setFadeIn(false);
        setShow(false);
    };

    useEffect(() => {
        setStatus(statusProp);
    }, [statusProp]);

    useEffect(() => {
        if (!!status) {
            showStatusMessage();

            const timeoutValue = timeout ?? DEFAULT_STATUS_TIMEOUT;

            if (timeoutValue !== null && !permanent) {
                const timer = setTimeout(() => {
                    hideStatusMessage();
                    setStatus('');
                }, timeoutValue);
                return () => {
                    clearTimeout(timer);
                };
            }
        }
        else {
            hideStatusMessage();
        }

    }, [status, color, timeout]);

    return (
        <>
            <div>
                {show && (
                    <Fade in={fadeIn} active={show}>
                        <Alert color={color || 'info'} className={className} {...otherProps}>
                            {status}
                        </Alert>
                    </Fade>
                )}
            </div>
        </>
    );
};
