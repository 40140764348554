import React from 'react';
import { EfcCallToActionInfo, NodeSelector as EfcNodeSelector, INode, Modal, SelectorNodeType} from '@efilecabinet/efc-atlantis-components';
import { Col, Row } from 'reactstrap';
import { NodeSelector } from './NodeSelector';
import { NodeSelectorModalTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';

export interface INodeSelectorModalProps {
    activeAccountId: number;
    closeModalFn: () => void;
    onSelect: (selectedNodes: INode[]) => void;
    selectorNodeType: SelectorNodeType;
}   

export const NodeSelectorModal = ({activeAccountId, closeModalFn, onSelect, selectorNodeType }: INodeSelectorModalProps) => {

    const NODE_SELECTOR_HEIGHT = '675px';

    const { t } = useSafeTranslation(TranslationFiles.NodeSelectorModal);
    const [selectedNodes, setSelectedNodes] = React.useState<INode[]>([]);

    function getSelectButtonText(): string {
        switch (selectorNodeType) {
            case SelectorNodeType.Both:
                return t(NodeSelectorModalTKeys.SelectBothButtonText);
            case SelectorNodeType.File:
                return t(NodeSelectorModalTKeys.SelectFileButtonText);
            case SelectorNodeType.Location:
                return t(NodeSelectorModalTKeys.SelectLocationButtonText);
            default: {
                const _exhaustiveCheck: never = selectorNodeType;
                return '';
            }
        }
    }

    function getTitle(): string {
        switch (selectorNodeType) {
            case SelectorNodeType.Both:
                return t(NodeSelectorModalTKeys.SelectBothTitle);
            case SelectorNodeType.File:
                return t(NodeSelectorModalTKeys.SelectFileTitle);
            case SelectorNodeType.Location:
                return t(NodeSelectorModalTKeys.SelectLocationTitle);
            default: {
                const _exhaustiveCheck: never = selectorNodeType;
                return '';
            }
        }
    }

    const ctas: EfcCallToActionInfo[] = [{
        text: getSelectButtonText(),
        emphasis: 'high',
        onClick: () => {
            closeModalFn();
            onSelect(selectedNodes);
        },
        color: 'primary',
        disabled: !selectedNodes.length,
    }];

    return (
        <Modal size='xl' title={getTitle()} toggle={() => closeModalFn()} ctas={ctas} isOpen={true}>
            <Row>
                <Col>
                    <NodeSelector 
                        activeAccountId={activeAccountId} 
                        height={NODE_SELECTOR_HEIGHT} 
                        onSelect={(selectedNodes) => setSelectedNodes(selectedNodes)}
                        selectorNodeType={selectorNodeType} />
                </Col>
            </Row>
        </Modal>
    );
};