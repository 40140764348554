import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import '../Nav/BottomNav/BottomNav.css';

interface IDuoLetterIconProps {
    letter: string;
    primaryColor?: string;
    secondaryColor?: string;
    secondaryOpacity?: number;
    size?: SizeProp;
}

export const DuoLetterIcon = (props: IDuoLetterIconProps) => {

    const { letter, primaryColor, secondaryColor, secondaryOpacity, size } = props;

    return (
        <>
            {letter &&
                <FontAwesomeIcon
                    icon={['fad', `${'circle-' + letter}`] as IconProp}
                    className='me-2'
                    size={size}
                    style={{ '--fa-primary-color': `${primaryColor}`, '--fa-secondary-color': `${secondaryColor}`, '--fa-secondary-opacity': `${secondaryOpacity}` } as React.CSSProperties} />
            }
        </>
    );
};
