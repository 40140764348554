import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { EfcTableColumnInfo, FullTable, Page, Text } from '@efilecabinet/efc-atlantis-components';
import { useIcons } from '../../../hooks/useIcons';
import { TranslationFiles, useSafeTranslation, RecentsTKeys } from '../../../hooks/useSafeTranslation';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { useRouting } from '../../_routing';
import { ZeroState } from '../../../components/ZeroState';
import zeroStateImg from '../../../assets/images/recentsZeroState.svg';
import { RecentNodeTypeEnum, NodeRecentDTO } from '../../../api/recents/recentApiTypes';
import { useRecentsApi } from '../../../api/recents/useRecentsApi';
import { NodeType } from '../../../api/node/nodeApiTypes';
import './RecentsPage.css';

export const RecentsPage = () => {
    const { t } = useSafeTranslation(TranslationFiles.Recents);

    const NUM_RECENT_NODES = 100;
    const ZERO_STATE_MAX_IMG_WIDTH = 200;

    const { getNodeIconProps } = useIcons();
    const { getRecentNodes } = useRecentsApi();
    const { screenSize } = useBootstrapBreakpoints();
    const { getLinkToNode } = useRouting();

    const [recentsPageItems, setRecentsPageItems] = useState<NodeRecentDTO[]>([]);

    const getIcon = (item: NodeRecentDTO): FontAwesomeIconProps => {
        return getNodeIconProps(item.systemType, item.fileInfo?.fileExtension);
    };

    const createColumns = () => {
        const columns: (EfcTableColumnInfo)[] = [
            { name: 'name', displayName: t(RecentsTKeys.NameColumn), widthPct: 47, useIcon: getIcon, linkTo: (node: NodeRecentDTO) => getLinkToNode([node.id]) },
        ];

        if (screenSize > ScreenSize.m) {
            columns.push(
                { name: 'auditLogDate', displayName: t(RecentsTKeys.LastViewedColumn), widthPct: 15, isDate: true },
                { name: 'systemType', displayName: t(RecentsTKeys.TypeColumn), widthPct: 8, searchBy: true, transform: (data: NodeType) => NodeType[data] },
                { name: 'createdOn', displayName: t(RecentsTKeys.CreatedOnColumn), widthPct: 15, isDate: true },
                { name: 'createdBy', displayName: t(RecentsTKeys.CreatedByColumn), widthPct: 15 }
            );
        }

        return columns;
    };

    useEffect(() => {
        getRecentNodes({ recentType: RecentNodeTypeEnum.All, start: 0, count: NUM_RECENT_NODES, })
            .then((items: NodeRecentDTO[]) => {
                setRecentsPageItems(items);
            });
    }, []);

    return (
        <Page title={t(RecentsTKeys.RecentsTitle)} icon={{ icon: 'clock-rotate-left' }}>
            <>
                <div className='my-2'>
                    <FullTable tag={Link} data={recentsPageItems} columns={createColumns()} selectable={false} sortable={false} />
                </div>

                {recentsPageItems.length == 0 && (
                    <ZeroState
                        imageColContent={<img src={zeroStateImg} alt={t(RecentsTKeys.ZeroStateImgAltText)} />}
                        textColContent={
                            <>
                                <Text bold>{t(RecentsTKeys.NoItemsMainText)}</Text>
                                <br />
                                <Text>{t(RecentsTKeys.NoItemsSubText)}</Text>
                            </>
                        }
                        stackColumns={screenSize < ScreenSize.l}
                        maxImgWidth={ZERO_STATE_MAX_IMG_WIDTH} />
                )}
            </>
        </Page>
    );
};
