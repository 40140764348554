import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NotificationStatusEnum, NotificationOrderEnum, UtopiaNotificationDTO, UtopiaTaskCountDTO } from '../notifications/notificationApiTypes';

export const useWorkYouCareAboutApi = () => {
    
    const getUserWorkYouCareAbout = async (notificationStatuses: NotificationStatusEnum[], orderBy: NotificationOrderEnum, start: number, count: number): Promise<UtopiaNotificationDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/WorkYouCareAbout?${notificationStatuses.map((alertStatus) => `notificationStatuses=${alertStatus}&`).join('')}orderBy=${orderBy}&start=${start}&count=${count}`);
        return resp.data;
    };

    const getUserWorkYouCareAboutCount = async (taskStatuses: NotificationStatusEnum[]): Promise<UtopiaTaskCountDTO> => {
        const resp: AxiosResponse = await Api.get(`api/WorkYouCareAbout/Count?${taskStatuses.map((taskStatus) => `taskStatuses=${taskStatus}&`).join('')}`);
        return resp.data;
    };

    return {
        getUserWorkYouCareAbout,
        getUserWorkYouCareAboutCount,
    };
};