import React, { useEffect, useState } from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { Col, Row } from 'reactstrap';
import { Virtuoso } from 'react-virtuoso';
import { ItemTile } from '../ItemTile/ItemTile';
import { ListSelectorItem } from '../ListSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import '../ListSelector.css';
import './GroupTile.css';

export interface GroupTileProps {
    item: ListSelectorItem;
    showIconColumn: boolean;
    showChildren: boolean;
    childrenShowIconColumn: boolean;
    selectedItems: ListSelectorItem[];
    addItem: (item: ListSelectorItem) => void;
    updateShowChildren: (identifier: number, setShowChildren: boolean) => void;
}

export const GroupTile = (props: GroupTileProps) => {

    const { item, showIconColumn, showChildren, childrenShowIconColumn, selectedItems, addItem, updateShowChildren } = props;

    const [isSearchView, setIsSetSearchView] = useState<boolean>(false);

    const [customScrollParent, setCustomScrollParent] = useState<HTMLElement>();

    const handleTileClick = () => {
        if (!isSearchView) {
            updateShowChildren(item.identifier, !showChildren);
        }
        else {
            updateShowChildren(item.identifier, false);
        }
    };

    const findCustomScrollParent = () => document.getElementById('list-selector-infinite-scroll-target');

    useEffect(() => {
        const foundCustomScrollParent = findCustomScrollParent();
        if (!!foundCustomScrollParent) {
            setCustomScrollParent(foundCustomScrollParent);
        }

    }, [!!findCustomScrollParent()]);

    return (
        <>
            <Row className="g-0 group-row-container group-row" onClick={handleTileClick} >
                <Col>
                    <Row className="g-0 group-row">
                        {showIconColumn && (
                            <Col xs="1" className="column-width">
                                <div className="group-icon-wrapper">
                                    {item.icon && <FontAwesomeIcon icon={item.icon as IconName} className='group-icon' />}
                                </div>
                            </Col>
                        )}
                        <Col xs="11">
                            <div className="group-text-wrapper" >
                                <>
                                    <Text size='lg' >{item.name} ({item.childrenItems ? item.childrenItems.length : 0})</Text>
                                    {!showChildren && <FontAwesomeIcon icon='caret-right' size='lg' className="group-dropdown-icon" />}
                                    {showChildren && <FontAwesomeIcon icon='caret-down' size='lg' className="group-dropdown-icon" />}
                                </>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {!!item.childrenItems && item.childrenItems.length > 0 && (
                <>
                    {showChildren && (
                        <Virtuoso
                            data={item.childrenItems}
                            customScrollParent={customScrollParent}
                            itemContent={(index, child) => {
                                return <ItemTile
                                    item={child}
                                    isWithinGroup={true}
                                    topLevelHasIconColumn={showIconColumn}
                                    showIconColumn={childrenShowIconColumn}
                                    selectedItems={selectedItems}
                                    addItem={addItem} />;
                            }}>
                        </Virtuoso>                      
                    )}
                </>
            )}
        </>
    );
};