import React, { FC } from 'react';
import { Text, Dropdown } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './EditOptions.css';

interface EditOptionsProps {
    options: EditOption[];
    className?: string;
    onEditClick: () => void;
}

export interface EditOption {
    onClick: () => void;
    text: string;
}

export const EditOptions: FC<EditOptionsProps> = ({ options, className, onEditClick }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

    const toggleMenuDropdown = () => setIsMenuOpen((prevState) => !prevState);

    return (
        <div className={`d-flex align-items-center ${className}`}>
            <FontAwesomeIcon icon='edit' color='#7514BD' className='pointer' onClick={onEditClick} />
            <Dropdown isOpen={isMenuOpen} toggle={toggleMenuDropdown}>
                <Dropdown.Toggle emphasis='low' icon={{ icon: 'ellipsis-v' }} size='sm' className='metadata-dropdown-toggle p-0 ms-1' />
                <Dropdown.Menu className='p-0'>
                    {options.map((option, index) => (
                        <Dropdown.Item key={index} onClick={option.onClick} className=' ps-2 d-flex align-items-center mb-1 mt-1'>
                            <Text className='ms-1'>{option.text}</Text>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
