import React from 'react';
import { Navbar, RevverIcon } from '@efilecabinet/efc-atlantis-components';
import { IconButton, TextIconButton } from '@efilecabinet/nautilus-ui';
import { useRouting } from '../../_routing';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import './LinkedViewNav.css';

export const LinkedViewNav = () => {

    const { routeToHome } = useRouting();
    const { screenSize } = useBootstrapBreakpoints();

    return (
        <Navbar expand='sm' fixed='top' className={`${'linked-view-nav'}`}>
            <RevverIcon
                color='white'
                className={'linked-view-revver-logo'} />
            {screenSize > ScreenSize.s && 
                <TextIconButton 
                    buttonStyle={'btn-outline-light'}
                    buttonType={'textIconButton'} 
                    icon={{
                        icon: [
                          'fas',
                          'arrow-up-right-from-square'
                        ],
                        pull: 'right'
                      }}
                    label={'Launch Revver'}
                    //TODO: Update to route to document view of current node 
                    onClick={routeToHome} 
                    options={{
                        disableTextWrapping: 'text-nowrap',
                        disabled: false
                      }} 
                    className='launch-revver-spacer' />}

            {screenSize <= ScreenSize.s && 
                <IconButton 
                    buttonStyle={'btn-outline-light'}
                    buttonType={'iconButton'} 
                    icon={{
                        icon: [
                          'fas',
                          'arrow-up-right-from-square'
                        ],
                        pull: 'right'
                      }}
                    padding='p-2'
                    //TODO: Update to route to document view of current node 
                    onClick={routeToHome} 
                    className='launch-revver-spacer' />}
        </Navbar>
    );
};