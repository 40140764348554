import React, { useEffect } from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory, BrowserHistoryOptions } from 'history';
import { useSettingsAndFeatures } from "../../../app/_context/SettingFeaturesContext/SettingsFeaturesContext";
import { useAccountFeatureValidator } from "../../../hooks/useAccountFeatureValidator";
import { useSystemPermissionsValidator } from "../../../hooks/useSystemPermissionsValidators";
import { Route, Routes } from 'react-router';
import { AuthenticatedRoute, LoginPage } from '../../_auth';
import { RoutePath } from '../routingTypes';
import { MainLayoutRoutes } from '../MainLayoutRoutes/MainLayoutRoutes';
import { LinkedViewLayoutRoutes } from '../LinkedViewLayoutRoutes/LinkedViewLayoutRoutes';
import { IAtlantisHtmlDocument } from '../../../IAtlantisHtmlDocument';

export const AppRoutes = () => {

    const { accountFeaturesHaveLoaded } = useAccountFeatureValidator();
    const { systemPermissionsHaveLoaded } = useSystemPermissionsValidator();
    const { settings, hasSettings } = useSettingsAndFeatures();
    const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions);
    const reactPlugin = new ReactPlugin();

    useEffect(() => {
        if (settings?.applicationInsightsConnectionString) {
            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: settings?.applicationInsightsConnectionString,
                    enableAutoRouteTracking: true,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory }
                    }
                }
            });
            appInsights.loadAppInsights();
            (document as IAtlantisHtmlDocument).appInsights = appInsights;
        }
    }, [hasSettings]);

    return (
        <Routes>
            <Route path='/*' element={
                <AuthenticatedRoute redirectTo={RoutePath.Login}>
                    {accountFeaturesHaveLoaded() && systemPermissionsHaveLoaded() &&
                        <MainLayoutRoutes />
                    }
                </AuthenticatedRoute>
            } />
            <Route path='/linkedview' element={
                <AuthenticatedRoute redirectTo={RoutePath.Login}>
                    {accountFeaturesHaveLoaded() && systemPermissionsHaveLoaded() &&
                        <LinkedViewLayoutRoutes />
                    }
                </AuthenticatedRoute>
            } />
            <Route path={RoutePath.Login} element={<LoginPage />} />
            <Route path={RoutePath.LoginOneTimeToken} element={<LoginPage />} />
        </Routes>
    )
}