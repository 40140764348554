export const useSessionStorage = () => {

    const setAccessToken = (token: string) => {
        localStorage.setItem('uid', token);
        sessionStorage.setItem('uid', token);
    };

    const getAccessToken = (): string | null => {
        if (!sessionStorage.getItem('uid'))
            sessionStorage.setItem('uid', localStorage.getItem('uid') as string);
        else
            localStorage.setItem('uid', sessionStorage.getItem('uid') as string);

        return sessionStorage.getItem('uid');
    };

    const removeAccessToken = () => {
        localStorage.removeItem('uid');
        sessionStorage.removeItem('uid');
    };

    return {
        setAccessToken,
        getAccessToken,
        removeAccessToken
    };
};