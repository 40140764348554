import React, { useEffect, useState } from 'react';
import { EfcLink, Text, Button, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { FileVersionLocked } from './FileVersionLocked';
import { FileVersionActions } from './FileVersionActions';
import { FileVersionPrimary } from './FileVersionPrimary';
import { FileVersionDateLink } from './FileVersionDateLink';
import { FileVersionFullNoteModal } from './FileVersionFullNoteModal';
import { FileVersionDTO } from '../../../../../api/fileVersions/fileVersionApiTypes';
import { FileVersionTable } from './FileVersionsTable/FileVersionTable';
import { useNodeDetailsContext } from '../../../_context/NodeDetailsContext/NodeDetailsContext';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';

interface FileVersionProps {
    fileVersion: FileVersionDTO;
}

const FileVersion = ({ fileVersion }: FileVersionProps) => {
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const [tooltipTarget, setTooltipTarget] = useState<string>(`version-notes-${fileVersion.id}`);
    const [isFullNoteModalOpen, setIsFullNoteModalOpen] = useState<boolean>(false);
    const toggleTooltip = () => setIsTooltipOpen((prevState) => !prevState);

    useEffect(() => {
        if (!isFullNoteModalOpen) {
            setIsTooltipOpen(false);
        }
    }, [isFullNoteModalOpen]);

    useEffect(() => {
        const randomId = Math.floor(1000 + Math.random() * 9000);
        setTooltipTarget(`version-notes-${randomId}`);
    }, []);

    return (
        <div>
            <span className='mt-2 ms-1 d-flex align-items-center'>
                {!!fileVersion.isActive ? <FileVersionPrimary fileVersion={fileVersion} /> : !fileVersion.allowPreview ? <FileVersionLocked fileVersion={fileVersion} /> : <span className='file-version-empty-space-not-primary'></span>}
                <FileVersionDateLink fileVersion={fileVersion} className='ms-1' />
                <span className='flex-grow-1' />
                {!!fileVersion.nodeComment && (<Button className='file-version-comment-bubble' emphasis='low' id={tooltipTarget} icon={{ icon: ['fas', 'comment'], color: '#8E8E8E' }} onClick={() => setIsFullNoteModalOpen(true)}></Button>)}
                {!!fileVersion.nodeComment && (<Tooltip target={tooltipTarget} isOpen={isTooltipOpen} toggle={toggleTooltip} placement='bottom'>{t(FileVersionsTKeys.SeeVersionNoteTooltip)}</Tooltip>)}
                <FileVersionActions fileVersion={fileVersion} className='ms-auto' />
            </span>
            {!!isFullNoteModalOpen && (<FileVersionFullNoteModal fileVersion={fileVersion} isFullNoteModalOpen={isFullNoteModalOpen} closeFullNoteModal={() => setIsFullNoteModalOpen(false)} />)}
        </div>
    );
};

export const FileVersions = () => {
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);
    const { isLoadingNode, fileVersions, isFileVersionModalOpen, setIsFileVersionModalOpen } = useNodeDetailsContext();

    const handleSeeAllClick = () => setIsFileVersionModalOpen(true);

    return (
        <>
            {!isLoadingNode && !!fileVersions && (
                <>
                    <div className='d-flex justify-content-between mt-2 ms-2'>
                        <Text className='mb-2'>{t(FileVersionsTKeys.FileVersions)}</Text>
                        {fileVersions.length > 5 && (
                            <EfcLink color='primary' onClick={handleSeeAllClick} dataId='see-all-file-versions'>
                                {t(FileVersionsTKeys.SeeAll, { fileCount: fileVersions.length - 5 })}
                            </EfcLink>
                        )}
                    </div>
                    {fileVersions.slice(0, 5).map((version, index) => <FileVersion key={index} fileVersion={version} />)}
                    {!!isFileVersionModalOpen && <FileVersionTable />}
                </>

            )}
        </>
    );
};
