import React, { useContext } from 'react';
import { SettingsDTO, UtopiaConfigurationDTO } from '../../../api/system/systemApiTypes';

export const SettingsFeaturesContext = React.createContext<ISettingsFeaturesContext | null>(null);

export const useSettingsAndFeatures = (): ISettingsFeaturesContext => {
    return useContext(SettingsFeaturesContext) as ISettingsFeaturesContext;
};

export interface ISettingsFeaturesContext {
    settings?: SettingsDTO;
    hasSettings: boolean;
    utopiaConfig?: UtopiaConfigurationDTO;
    hasUtopiaConfig: boolean;
    featureFlags?: string[];
    hasFeatureFlags: boolean;
    checkFeatureFlag: (flag: string) => boolean;
    hasDesktopApplicationInfo: boolean;
    desktopApplicationInfo?: DesktopApplicationInfo;
}

export interface DesktopApplicationInfo {
    version: string;
}