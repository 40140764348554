import React, { useState } from 'react';
import { Dropdown, Text, EfcCallToActionInfo, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './ContextMenu.css';

export interface ContextMenuProps extends React.HTMLAttributes<HTMLElement> {
    options: ContextMenuOptions[];
    dataId?: string;
    dropdownIcon?: FontAwesomeIconProps;
    emphasis?: 'low' | 'high' | 'med' | undefined;
}

export interface ContextMenuOptions extends Omit<EfcCallToActionInfo, 'text'> {
    type?: ContextMenuOptionType;
    text?: string;
    tooltip?: string;
}

export enum ContextMenuOptionType {
    Header = 'header',
    Divider = 'divider',
}

export const ContextMenu = ({ options, dataId, dropdownIcon = { icon: 'ellipsis' }, emphasis = 'low', ...otherProps }: ContextMenuProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const toggleDropdown = () => setIsMenuOpen((prevState) => !prevState);

    const determineBottomMargin = (i: number) => {
        if (options.length === 2) {
            return i === 0 && 'mb-2';
        } else if (i != 0 && i != options.length - 1) {
            return 'my-2';
        }
    };

    const MenuItem = ({ option, index }: { option: ContextMenuOptions, index: number }) => {
        const itemContent = (
            <Dropdown.Item
                onClick={option.onClick}
                className={`d-flex align-items-center option ${determineBottomMargin(index)}`}
                disabled={option.disabled}
                dataId={option.dataId}
                header={option?.type === ContextMenuOptionType.Header}
                divider={option?.type === ContextMenuOptionType.Divider}
            >
                {option.icon && <FontAwesomeIcon {...option.icon} />}
                {option.text && (
                    <Text className='ms-1' muted={option.disabled}>
                        {option.text}
                    </Text>
                )}
            </Dropdown.Item>
        );

        return option.tooltip ? (
            <TooltipItem key={index} position='left' delayShow={200} id={crypto.randomUUID()} message={option.tooltip}>
                {itemContent}
            </TooltipItem>
        ) : (
            itemContent
        );
    };

    return (
        <Dropdown isOpen={isMenuOpen} toggle={toggleDropdown} dataId={dataId} {...otherProps}>
            <Dropdown.Toggle emphasis={emphasis} icon={dropdownIcon} size='sm' dataId={`${dataId}-toggle`} />
            <Dropdown.Menu dataId={`${dataId}-menu`} className='context-menu'>
                {options.map((option, i) => (
                    <MenuItem key={i} option={option} index={i} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
