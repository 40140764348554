export interface AtlantisTaskDTO{
    taskId: string;
    text: string;
    status: AtlantisTaskStatus;
    createdDate?: string;
    completedDate?: string;
    dismissedDate?: string;
}

export enum AtlantisTaskStatus {
    Assigned = 0,
    Completed = 1,
    Dismissed = 2,
}