import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { UserSearchDTO } from './searchApiTypes';

export const useSearchApi = () => {

    const createUserSearchAsync = async (userSearch: UserSearchDTO) : Promise<UserSearchDTO> => {
        const resp: AxiosResponse = await Api.post('api/userSearch', userSearch);
        return resp.data;
    };

    return {
        createUserSearchAsync,
    };
};
