import React, { useEffect, useState } from 'react';
import { Modal, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { IUseModalProps } from '../../../../hooks/useModal';
import { PreviewTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';

interface PreviewerFormFillSaveModalProps extends IUseModalProps {
    accusoftPreviewerIframeId: string;
    closeAccusoftPreviewerModals: boolean;
    onPreviewerFormFillModalClose: (isConfirmed: boolean) => void;
    onClose: (isConfirmed: boolean) => void;
}

export const PreviewerFormFillSaveModal = (props: PreviewerFormFillSaveModalProps) => {
    const { accusoftPreviewerIframeId, onPreviewerFormFillModalClose, closeAccusoftPreviewerModals, onClose, ...otherProps } = props;

    const { t } = useSafeTranslation(TranslationFiles.Preview);

    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
        onClose(false);
    };

    const handleCloseBoth = () => {
        handleClose();
        onPreviewerFormFillModalClose(false);
    };

    const saveAndCloseModals = () => {
        const documentIframe = document.getElementById(accusoftPreviewerIframeId) as HTMLIFrameElement;

        if (!!documentIframe && !!documentIframe.contentWindow) {
            console.log('Trying to save...');
            documentIframe.contentWindow.postMessage('TriggerAccusoftSave', '*');
        }
        else {
            console.log('Failed to save.');
        }
    };

    const showFormFillSaveCtas: EfcCallToActionInfo[] = [
        { text: t(PreviewTKeys.SaveDiscard), emphasis: 'med', color: 'primary', onClick: handleCloseBoth },
        { text: t(PreviewTKeys.Save), emphasis: 'high', onClick: saveAndCloseModals },
    ];

  
    useEffect(() => {if(!!closeAccusoftPreviewerModals){
        handleCloseBoth();
    } }, [closeAccusoftPreviewerModals]);


    return (
        <Modal isOpen={isOpen} toggle={handleClose} ctas={showFormFillSaveCtas}>
            <Modal.Header toggle={handleClose} >
                {t(PreviewTKeys.SaveHeader)}
            </Modal.Header>
            <Modal.Body>{t(PreviewTKeys.SaveBody)}</Modal.Body>
        </Modal>
    );
};