import React from 'react';
import { useBootstrapBreakpoints } from '../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../hooks/bootstrap/bootstrapTypes';
import { AccountsNavMenuItem } from '../Accounts';
import { RecentsNavMenuItem } from '../Recents';
import { NotificationMenuItem } from '../Notifications';
import { HelpNavMenuItem } from '../Help';
import { FileUploadNavMenuItem } from '../FileUpload';
import { OptOutToggleNavItem } from '../OptOutToggleNavItem';
import '../NavMenu.css';

export const StandardExperienceNavItems = () => {

    const { screenSize } = useBootstrapBreakpoints();

    return (
        <>
            {screenSize > ScreenSize.m && (
                <>
                    <OptOutToggleNavItem />
                    <FileUploadNavMenuItem />
                    <RecentsNavMenuItem />                  
                </>
            )}

            <NotificationMenuItem />

            {screenSize > ScreenSize.m &&
                <HelpNavMenuItem />
            }

            <AccountsNavMenuItem className='ms-2' />
        </>
    );
};
