import { Dispatch, RefObject, SetStateAction } from 'react';
import { LoadDocumentOptions, Previewer, PreviewerInstance } from '@efilecabinet/previewerx';
import { useAuthContext } from '../app/_context/AuthContext';
import { useAuthApi } from '../api/auth/useAuthApi';
import { useSystemPermissionsValidator } from './useSystemPermissionsValidators';
import { usePreviewerContext } from '../app/_context/PreviewerContext/PreviewerContext';
import { SystemPermissionEnum } from '../api/roles/roleApiTypes';

export interface ILoadPreviewerDocumentProps {
    nodeId: string; 
    options: LoadDocumentOptions; 
    previewerDiv: RefObject<HTMLDivElement>; 
    previewerInstance: PreviewerInstance; 
    setIsPreviewerDocumentLoading: Dispatch<SetStateAction<boolean>>;
}

export const usePreviewer = () => {
    const PREVIEWER_LIB = '/pdftronLib';
    const CSS_PATH = '/pdftronLib/previewer.css';

    const { hasPermissionOnAccount } = useSystemPermissionsValidator();
    const { authUser } = useAuthContext();
    const auth = useAuthApi();
    const { utopiaUrl, previewerWebServerUrl, baseAccusoftPreviewUrl } = usePreviewerContext();

    const getAccusoftPreviewerBaseUrl = () => {
        let url = new URL(baseAccusoftPreviewUrl);
        const locationUrl = new URL(location.origin);

        if (!url.port && !locationUrl.port) {
            const subdomain = url.hostname.split('.').slice(0, -2).join('.');
            const baseDomain = location.hostname.split('.').slice(-2).join('.');
            const newUrl = `${url.protocol}//${subdomain}.${baseDomain}`;
            url = new URL(newUrl);
        }

        return url.href;
    };

    const getFullAccusoftPreviewUrl = async (nodeId: string, searchTerm?: string, fileVersionId?: string) => {
        const oneTimeToken = await auth.getOneTimeToken();
        let resultUrl = `${getAccusoftPreviewerBaseUrl()}Previewer?oneTimeToken=${oneTimeToken}`;
        resultUrl += `&nodeId=${nodeId}`;
        if (!!searchTerm) {
            searchTerm = window.encodeURIComponent(searchTerm);
            resultUrl += '&searchTerms=' + searchTerm;
        }
        if (!!fileVersionId) {
            resultUrl += '&fileVersionId=' + fileVersionId;
        }
        resultUrl += '&initialPage=FormDesigner';
        resultUrl += '&designerOnly=false';

        return resultUrl;
    };

    const setupPreviewerInstance = async (previewerDiv: RefObject<HTMLDivElement>, setPreviewerInstance: Dispatch<SetStateAction<PreviewerInstance | undefined>>) => {
        const { accessToken } = authUser ?? {};

        if (!previewerDiv.current || !accessToken) {
            console.log('Missing required data to initialize previewer');
            return;
        }

        try {
            console.log('Setup instance trying to initialize...');

            const instance = Previewer(utopiaUrl, PREVIEWER_LIB, previewerWebServerUrl, CSS_PATH);
            await instance?.InitializePreviewer(previewerDiv.current, accessToken);

            setPreviewerInstance(instance);
            console.log('Setup instance success!');
        } catch (error) {
            console.log({ initializePreviewer: error });
        }
    };

    const loadPreviewerDocument = async (props: ILoadPreviewerDocumentProps) => {
        const { nodeId, options, previewerDiv, previewerInstance, setIsPreviewerDocumentLoading } = props;

        if (!nodeId || !previewerInstance || !previewerDiv.current) {
            return;
        }
        
        try {
            setIsPreviewerDocumentLoading(true);
            const { accountID } = authUser ?? {};
            if (!!accountID) {
                const hasPreviewerToolsPermission = hasPermissionOnAccount(accountID, SystemPermissionEnum.PreviewerTools);
                await previewerInstance.LoadDocument(nodeId, hasPreviewerToolsPermission, options);
            }
        } catch (error) {
            console.log({ loadDocumentError: error });
        } finally {
            setIsPreviewerDocumentLoading(false);
        }
    };

    return {
        setupPreviewerInstance,
        loadPreviewerDocument,
        getFullAccusoftPreviewUrl
    };

};