import React from 'react';
import { NodePermissionLevelEnum, initialPermissionState } from './nodePermissionTypes';
import { NodePermissionDTO } from '../../api/nodePermissions/nodePermissionApiTypes';
import { useNodePermissionsApi } from '../../api/nodePermissions/useNodePermissionsApi';
import { RoleDTO, RoleEnum, UserLicenseEnum } from '../../api/roles/roleApiTypes';
import { IBatchItem, IBatchItemResponse } from '../../api/sharedApiTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUtilities } from '../useUtilities';
import { PermissionCollection } from '../../components/NodeSideSheet/Tabs/Permissions/Permissions';

export const useNodePermissionsUtilities = () => {
    const { getPermissionByNodeIdAsync, updatePermissionsAsync, deletePermissionsAsync, createPermissionsAsync } = useNodePermissionsApi();
    const { areObjectsEqual } = useUtilities();

    function updateNodePermissionLevel(permission?: NodePermissionDTO, permissionLevel?: NodePermissionLevelEnum) {
        if (permission === undefined || permissionLevel === undefined) {
            return initialPermissionState;
        }

        switch (permissionLevel) {
            case NodePermissionLevelEnum.View:
                return {
                    ...permission,
                    view: true,
                    download: false,
                    childPermission: null,
                    write: false,
                    delete: false,
                    admin: false,
                    restrict: false,
                    uploadFiles: false,
                    createDirectories: false,
                };
            case NodePermissionLevelEnum.Download:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    write: false,
                    delete: false,
                    admin: false,
                    restrict: false,
                    uploadFiles: false,
                    createDirectories: false,
                };
            case NodePermissionLevelEnum.UploadFiles:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: {
                        ...permission?.childPermission,
                        view: true,
                        download: true,
                        uploadFiles: true,
                        createDirectories: true,
                        write: true,
                    },
                    uploadFiles: true,
                    createDirectories: false,
                    write: false,
                    delete: false,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.UploadDelete:
                if (!permission?.childPermission?.delete) {
                    return {
                        ...permission,
                        view: true,
                        download: true,
                        childPermission: {
                            ...permission?.childPermission,
                            view: true,
                            download: true,
                            uploadFiles: true,
                            createDirectories: true,
                            write: true,
                            delete: true,
                        },
                        uploadFiles: true,
                    };
                } else {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission?.childPermission,
                            delete: false,
                        },
                    };
                }
            case NodePermissionLevelEnum.CreateDirectories:
                if (!permission?.childPermission?.createDirectories || permission.write) {
                    return {
                        ...permission,
                        view: true,
                        download: true,
                        childPermission: {
                            ...permission?.childPermission,
                            view: true,
                            download: true,
                            uploadFiles: true,
                            createDirectories: true,
                            write: true,
                        },
                        uploadFiles: true,
                        createDirectories: true,
                        write: false,
                        delete: false,
                        admin: false,
                        restrict: false,
                    };
                } else {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission?.childPermission,
                            createDirectories: false,
                        },
                        createDirectories: false,
                        write: false,
                        delete: false,
                        admin: false,
                        restrict: false,
                    };
                }
            case NodePermissionLevelEnum.CreateDirectoriesDelete:
                if (!permission?.childPermission?.delete) {
                    return {
                        ...permission,
                        view: true,
                        download: true,
                        childPermission: {
                            ...permission?.childPermission,
                            view: true,
                            download: true,
                            uploadFiles: true,
                            createDirectories: true,
                            write: true,
                            delete: true,
                        },
                        uploadFiles: true,
                        createDirectories: true,
                    };
                } else {
                    return {
                        ...permission,
                        childPermission: {
                            ...permission?.childPermission,
                            delete: false,
                        },
                    };
                }
            case NodePermissionLevelEnum.Write:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    uploadFiles: true,
                    createDirectories: true,
                    write: true,
                    delete: false,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Delete:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    uploadFiles: true,
                    createDirectories: true,
                    write: true,
                    delete: true,
                    admin: false,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Admin:
                return {
                    ...permission,
                    view: true,
                    download: true,
                    childPermission: null,
                    uploadFiles: true,
                    createDirectories: true,
                    write: true,
                    delete: true,
                    admin: true,
                    restrict: false,
                };
            case NodePermissionLevelEnum.Override:
                if (!permission.enforce) {
                    return {
                        ...permission,
                        enforce: true,
                    };
                } else {
                    return {
                        ...permission,
                        enforce: false,
                        restrict: false,
                    };
                }
            case NodePermissionLevelEnum.RestrictAll:
                if (!permission.restrict) {
                    return {
                        ...permission,
                        view: false,
                        download: false,
                        childPermission: null,
                        uploadFiles: false,
                        createDirectories: false,
                        write: false,
                        delete: false,
                        admin: false,
                        enforce: true,
                        restrict: true,
                    };
                } else {
                    return {
                        ...permission,
                        view: true,
                        enforce: false,
                        restrict: false,
                    };
                }
            case NodePermissionLevelEnum.Remove:
                return {
                    ...permission,
                    remove: true,
                };
            case NodePermissionLevelEnum.Default:
                return initialPermissionState;
            default:
                return {
                    ...permission,
                    childPermission: { ...permission?.childPermission },
                };
        }
    }

    const getNodePermissionsAsync = async (nodeId: string) => {
        return await getPermissionByNodeIdAsync(nodeId);
    };

    const createNodePermissionsAsync = async (permissions: NodePermissionDTO[]) => {
        const batchItems = permissions.map((permission: NodePermissionDTO) => ({ batchObject: { ...permission } } as IBatchItem<NodePermissionDTO>));
        return readBatchResponse(await createPermissionsAsync(batchItems));
    };

    const updateNodePermissionsAsync = async (permissions: NodePermissionDTO[]) => {
        const batchItems = permissions.map((permission: NodePermissionDTO) => ({ batchObject: { ...permission } } as IBatchItem<NodePermissionDTO>));
        return readBatchResponse(await updatePermissionsAsync(batchItems));
    };

    const removeNodePermissionsAsync = async (permissions: NodePermissionDTO[]) => {
        const batchItems = permissions.map((permission: NodePermissionDTO) => ({ batchObject: permission.id } as IBatchItem<number>));
        return readBatchResponse(await deletePermissionsAsync(batchItems));
    };

    const filterNodePermissionsByRoleType = (permissions: NodePermissionDTO[], roleType: RoleEnum) => {
        switch (roleType) {
            case RoleEnum.User:
                return permissions.filter((permission) => permission.roleData.roleType === RoleEnum.User && permission.roleData.license !== UserLicenseEnum.Guest);
            case RoleEnum.Group:
                return permissions.filter((permission) => permission.roleData.roleType === RoleEnum.Group);
            case RoleEnum.Guest:
                return permissions.filter((permission) => permission.roleData.roleType === RoleEnum.User && permission.roleData.license === UserLicenseEnum.Guest);
            default:
                return permissions;
        }
    };

    const filterNodePermissionsByCreatedByUserId = (permissions: NodePermissionDTO[], createdByUserId: string) => {
        // Don't include the user's own permission if they created their own or permissions for anonymous users (access links) since access links have their own section.
        return permissions.filter(
            (permission) =>
                permission.createdByUserID?.toString() === createdByUserId &&
                permission.roleData.userID != permission.createdByUserID &&
                !permission.roleData.anonymous
        );
    };

    const filterOtherNodePermissionsByCreatedByUserId = (permissions: NodePermissionDTO[], createdByUserId: string) => {
        // Don't include permissions for anonymous users (access links) since access links have their own section.
        return permissions.filter((permission) => permission.createdByUserID?.toString() !== createdByUserId && !permission.roleData.anonymous);
    };

    const filterIndividualPermission = (permissions: NodePermissionDTO[], roleId: string) => {
        return permissions.find((permission) => permission.roleID?.toString() === roleId);
    };

    const createCollection = (permissions: NodePermissionDTO[], accessLinks: NodePermissionDTO[]) => {
        const newCollection: PermissionCollection = {
            users: filterNodePermissionsByRoleType(permissions, RoleEnum.User),
            groups: filterNodePermissionsByRoleType(permissions, RoleEnum.Group),
            guests: filterNodePermissionsByRoleType(permissions, RoleEnum.Guest),
            accessLinks,
        };
        return newCollection;
    };

    const getRoleIcon = (role: RoleDTO) => {
        if (role.roleType == RoleEnum.Group) {
            if (role.anonymous) {
                return <FontAwesomeIcon icon='link' />;
            }
            return <FontAwesomeIcon icon='users' />;
        } else if (role.license == UserLicenseEnum.Guest) {
            return <i className={'icon-efc-guest-user ps-1 mx-1'}></i>;
        } else {
            return <FontAwesomeIcon icon='user' className='ms-2' />;
        }
    };

    const readBatchResponse = (response: IBatchItemResponse<NodePermissionDTO>[]) => {
        const permissions: NodePermissionDTO[] = [];
        let errorMessage = '';
        for (const item of response) {
            if (item.operationError) {
                errorMessage += `• ${item.batchObject?.name ?? ''} ${item.operationError.message} \n`;
            }
            if (item.batchObject) {
                permissions.push(item.batchObject as NodePermissionDTO);
            }
        }
        if (errorMessage) {
            throw new Error(errorMessage);
        }
        return permissions;
    };

    const getEditedPermissions = (array1: NodePermissionDTO[], array2: NodePermissionDTO[]): NodePermissionDTO[] => {
        return array2.filter((item2) => {
            return !array1.some((item1) => areObjectsEqual(item1, item2));
        });
    };

    const updateAndCreatePermissions = async (permissionsToUpdate: NodePermissionDTO[], permissionsToCreate: NodePermissionDTO[]) => {
        if (permissionsToUpdate.length) {
            await updateNodePermissionsAsync(permissionsToUpdate);
        }
        if (permissionsToCreate.length) {
            await createNodePermissionsAsync(permissionsToCreate);
        }
    };

    return {
        getNodePermissionsAsync,
        removeNodePermissionsAsync,
        updateNodePermissionLevel,
        filterIndividualPermission,
        filterNodePermissionsByRoleType,
        filterNodePermissionsByCreatedByUserId,
        filterOtherNodePermissionsByCreatedByUserId,
        getRoleIcon,
        getEditedPermissions,
        updateAndCreatePermissions,
        createCollection,
    };
};
