import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SideSheet.css';

interface SideSheetResizerSliderProps {
    onResize: (deltaX: number) => void;
}

export const SideSheetResizerSlider: React.FC<SideSheetResizerSliderProps> = ({ onResize }) => {
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const startX = e.clientX;

        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100vw';
        overlay.style.height = '100vh';
        overlay.style.cursor = 'ew-resize';
        overlay.style.zIndex = '9999';
        overlay.style.opacity = '0';
        document.body.appendChild(overlay);

        const handleMouseMove = (e: MouseEvent) => {
            const deltaX = startX - e.clientX;
            onResize(deltaX);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.body.removeChild(overlay);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <div onMouseDown={handleMouseDown} className='sidesheet-resizer-slider'>
            <FontAwesomeIcon icon={'grip-lines-vertical'} />
        </div>
    );
};
