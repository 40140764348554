export interface UserFeedbackDTO {
    previewerFeedbackAcknowledged: boolean;
    hasSeenSurveyModal: boolean;
    hasSeenSurveyReprompt: boolean;
}

export interface SubmitFeedbackDTO {
    FeedbackType: FeedbackType;
    Message: string;
    Email: string;
    AccountId: number;
    FeedbackRange: number;
    Location: string;
    DesktopVersion?: string;
    AtlantisOnly: boolean;
}

export enum FeedbackType {
    Unspecified = 0,
    Bug = 1,
    NotFound = 2,
    FeatureReq = 3,
    Feedback = 4,
    OptingOut = 5,
    ExperiencedFeedback = 6,
    PreviewerX = 7,
}