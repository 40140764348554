import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { ProfileDTO } from './profileApiTypes';

export const useProfileApi = () => {

    const getAccountProfiles = async (accountId: number): Promise<ProfileDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/profile/account/${accountId}`);
        return resp.data;
    };

    const deleteProfile = async (id: number, accountId: number): Promise<void> => {
        await Api.delete(`api/profile/delete?id=${id}&accountId=${accountId}`);
    };

    return {
        getAccountProfiles,
        deleteProfile,
    };
};