import React, { useState } from 'react';
import _ from 'lodash';
import { Container, Row } from 'reactstrap';
import { Button, Card } from '@efilecabinet/efc-atlantis-components';
import { useProfileUtilities } from '../../../../../../hooks/useProfileUtilities';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../hooks/useSafeTranslation';
import { ProfileSelector } from '../../ProfileSelector/ProfileSelector';
import { NodeProfileFields } from '../../Fields/NodeProfileFields/NodeProfileFields';
import { NodeProfileItemInput } from '../../NodeProfileItemInput/NodeProfileItemInput';
import { NodeProfileItemField } from '../../Fields/NodeProfileItemField/NodeProfileItemField';
import { NodeProfileDTO } from '../../../../../../api/nodeProfiles/nodeProfileApiTypes';
import { ProfileItemDTO } from '../../../../../../api/profileItems/profileItemApiTypes';
import { ProfileDTO } from '../../../../../../api/profiles/profileApiTypes';
import './NodeProfileForm.css';

interface NodeProfileFormProps {
    accountId: number;
    nodeId: string;
    onCanceled: () => void;
    onSaved: (profileItem: NodeProfileDTO) => void;
    originalNodeProfile: NodeProfileDTO;
}

export const NodeProfileForm = ({ accountId, nodeId, onCanceled, onSaved, originalNodeProfile }: NodeProfileFormProps) => {

    const { comparePositionFn } = useProfileUtilities();
    const { t } = useSafeTranslation(TranslationFiles.Metadata);

    const [nodeProfile, setNodeProfile] = useState<NodeProfileDTO>(JSON.parse(JSON.stringify(originalNodeProfile)) /* deep copy */);
    const [errors, setErrors] = useState<Map<number, string>>(new Map());

    function onProfileSelected(profile: ProfileDTO) {
        // Merge the profile items of the selected profile with the existing profile items of the node
        const mergedProfileItems = [..._.values(_.merge(_.keyBy(profile.profileItems.map(x => ({...x})), 'id'), _.keyBy(nodeProfile.profileItems.map(x => ({...x})), 'id')))];

        // If an additional profile item is present on node already, and included with newly selected profile, then update the profileID of that item
        _.forEach(mergedProfileItems, (item) => {
            if (profile.profileItems.some(x => x.id === item.id)) {
                item.profileID = profile.id;
            }

            const profileItemFromProfile = profile.profileItems.find(x => x.id === item.id);
            if (!!profileItemFromProfile) {
                item.position = profileItemFromProfile.position;
            }
        });

        setNodeProfile({ ...nodeProfile, profileID: profile.id, profileName: profile.name, profileItems: mergedProfileItems });
    }

    function getErrorState(profileItem: ProfileItemDTO): [string, ((error: string) => void)] {
        return [
            errors.get(profileItem.id) ?? '',
            (error: string) => {
                setErrors(new Map(errors.set(profileItem.id, error)));
            }
        ];
    }

    function getNodeProfileItemState(profileItem: ProfileItemDTO): [ProfileItemDTO, (nodeProfileItem: ProfileItemDTO) => void] {
        return [
            profileItem,
            (updatedProfileItem) => {
                setNodeProfile({
                    ...nodeProfile,
                    profileItems: nodeProfile.profileItems.map((item) => item.id == updatedProfileItem.id ? { ...updatedProfileItem } : { ...item })
                });
            }
        ];

    }

    const title = !!originalNodeProfile.profileID ? t(MetadataTKeys.EditProfile) : t(MetadataTKeys.AddProfile);
    const isSaveButtonDisabled = Array.from(errors.values()).some(error => !!error);

    return (

        <Card className='mb-3 node-profile-form' color=''>

            <Card.Title className='pt-3 ps-3' size='lg'>{title}</Card.Title>

            <Card.Body className='pb-0 pt-0'>
                {!nodeProfile.profileID
                    ? <ProfileSelector accountId={accountId} onProfileSelected={onProfileSelected} />
                    : (
                        <NodeProfileFields>
                            {nodeProfile.profileItems.filter(x => x.profileID).sort(comparePositionFn).map(profileItem => (
                                <Container key={profileItem.id} className='mb-3'>
                                    <Row className='g-0'>
                                        <NodeProfileItemField nodeProfileItem={profileItem}>
                                            <NodeProfileItemInput errorState={getErrorState(profileItem)} nodeId={nodeId} nodeProfileItemState={getNodeProfileItemState(profileItem)} />
                                        </NodeProfileItemField>
                                    </Row>
                                </Container>
                            ))}
                        </NodeProfileFields>
                    )}
            </Card.Body>

            <Card.Footer className='d-flex justify-content-end'>
                <Button onClick={onCanceled} color='secondary' emphasis='med' className='me-2'>{t(MetadataTKeys.CancelButton)}</Button>
                <Button onClick={() => !!nodeProfile && onSaved(nodeProfile)} color='primary' disabled={isSaveButtonDisabled}>{t(MetadataTKeys.SaveButton)}</Button>
            </Card.Footer>

        </Card>
    );
};
