import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Alert, FormGroup, Input, Label, Text } from '@efilecabinet/efc-atlantis-components';
import { NestedCollapse } from '../../../../NestedCollapse';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useSystemPermissionsValidator } from '../../../../../hooks/useSystemPermissionsValidators';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { PermissionsInheritanceSelector } from '../PermissionsInheritanceSelector/PermissionsInheritanceSelector';
import { NodePermissionInheritanceEnum } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { NodePermissionLevelEnum, PermissionTypes } from '../../../../../hooks/nodePermissions/nodePermissionTypes';
import { RoleEnum, SystemPermissionEnum } from '../../../../../api/roles/roleApiTypes';
import { useFileUtilities } from '../../../../../hooks/useFileUtilities';
import { ThemeEnum } from '@efilecabinet/nautilus-ui/dist/hooks/colors/useColors';
import { NodeDTO } from '../../../../../api/node/nodeApiTypes';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';
import './PermissionsCheckboxList.css';
import '../Permissions.css';

interface PermissionsCheckboxListProps {
    activeNode: NodeDTO | undefined;
    isInherited: boolean;
    disableOverride?: boolean;
}

export const PermissionsCheckboxList = (props: PermissionsCheckboxListProps) => {
    const { disableOverride, activeNode, isInherited } = props;

    const { editedPermission: permission, setEditedPermission } = useNodePermissionsContext();
    const { authUser, hasAuthUser } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { hasPermissionOnAccount } = useSystemPermissionsValidator();
    const { updateNodePermissionLevel } = useNodePermissionsUtilities();
    const { nodeIsContainer, getNodeTypeText } = useFileUtilities();

    const [uploadDeleteDisabled, setUploadDeleteDisabled] = useState<boolean>(false);
    const [createDirectoriesDeleteDisabled, setCreateDirectoriesDeleteDisabled] = useState<boolean>(false);
    const [hasItemPermissionsOverride, setHasItemPermissionsOverride] = useState<boolean>(false);
    const [descriptions, setDescriptions] = useState<{ [key in PermissionTypes]: string }>({
        [PermissionTypes.View]: t(PermissionsTKeys.ViewFileDesc),
        [PermissionTypes.Download]: t(PermissionsTKeys.DownloadFileDesc),
        [PermissionTypes.Upload]: t(PermissionsTKeys.UploadDesc),
        [PermissionTypes.CreateSubitems]: t(PermissionsTKeys.CreateSubitemsDesc),
        [PermissionTypes.Edit]: t(PermissionsTKeys.EditFileDesc),
        [PermissionTypes.Delete]: t(PermissionsTKeys.DeleteFileDesc),
        [PermissionTypes.Admin]: t(PermissionsTKeys.AdminFileDesc),
        [PermissionTypes.Lock]: t(PermissionsTKeys.LockAccessDesc),
        [PermissionTypes.Block]: t(PermissionsTKeys.BlockAccessDesc),
    });

    const isGroup = permission?.roleData?.roleType === RoleEnum.Group;
    const systemType = isInherited ? activeNode?.systemType : permission?.systemType;
    const itemType = !!systemType ? getNodeTypeText(systemType).toLowerCase() : '';
    const isContainer = !!systemType ? nodeIsContainer(systemType) : false;
    const inheritanceSelectorText =
        permission?.inheritanceBehavior === NodePermissionInheritanceEnum.Default
            ? t(PermissionsTKeys.InheritanceSelectorTextDefault, { itemType })
            : t(PermissionsTKeys.InheritanceSelectorTextSelf, { itemType });

    const handleCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        permissionLvl: NodePermissionLevelEnum,
        prevPermissionLvl?: NodePermissionLevelEnum
    ) => {
        if (!e.target.checked && prevPermissionLvl !== undefined) {
            permissionLvl = prevPermissionLvl;
        }
        setEditedPermission((prevState) => updateNodePermissionLevel(prevState, permissionLvl));
    };

    const getPermissionDescriptions = () => {
        if (isContainer) {
            setDescriptions((prevState) => {
                return {
                    ...prevState,
                    [PermissionTypes.View]: t(PermissionsTKeys.ViewDesc, { itemType }),
                    [PermissionTypes.Download]: t(PermissionsTKeys.DownloadDesc, { itemType }),
                    [PermissionTypes.Upload]: t(PermissionsTKeys.UploadDesc, { itemType }),
                    [PermissionTypes.CreateSubitems]: t(PermissionsTKeys.CreateSubitemsDesc, { itemType }),
                    [PermissionTypes.Edit]: t(PermissionsTKeys.EditDesc, { itemType }),
                    [PermissionTypes.Delete]: t(PermissionsTKeys.DeleteDesc, { itemType }),
                    [PermissionTypes.Admin]: t(PermissionsTKeys.AdminDesc),
                };
            });
        }
    };

    useEffect(() => {
        if (!!hasAuthUser && !!authUser?.accountID) {
            setHasItemPermissionsOverride(hasPermissionOnAccount(authUser?.accountID, SystemPermissionEnum.ItemPermissionsOverride));
        }
    }, [hasAuthUser]);

    useEffect(() => {
        if (!!permission) {
            if (permission?.write) {
                setUploadDeleteDisabled(true);
            } else {
                setUploadDeleteDisabled(false);
            }

            if ((!permission?.createDirectories && permission?.childPermission?.delete) || permission?.write) {
                setCreateDirectoriesDeleteDisabled(true);
            } else {
                setCreateDirectoriesDeleteDisabled(false);
            }
        }
    }, [permission]);

    useEffect(() => {
        getPermissionDescriptions();
    }, []);

    return (
        <>
            <Row className='mb-4'>
                <Col xs='3'>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input
                                name='view'
                                dataId={`view-permission-check-${permission?.id}`}
                                type='checkbox'
                                checked={permission?.view}
                                disabled
                            />
                            {t(PermissionsTKeys.ViewCheckboxLabel)}
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs='9'>{descriptions[PermissionTypes.View]}</Col>
            </Row>
            <Row className='mb-4'>
                <Col xs='3'>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input
                                name='download'
                                dataId={`download-permission-check-${permission?.id}`}
                                type='checkbox'
                                checked={permission?.download}
                                onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.Download, NodePermissionLevelEnum.View)}
                            />
                            {t(PermissionsTKeys.DownloadCheckboxLabel)}
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs='9'>{descriptions[PermissionTypes.Download]}</Col>
            </Row>

            {!!isContainer && (
                <>
                    <Row className='mb-4'>
                        <Col xs='3'>
                            <FormGroup check className='pointer'>
                                <Label check>
                                    <Input
                                        name='uploadFiles'
                                        dataId={`upload-permission-check-${permission?.id}`}
                                        type='checkbox'
                                        checked={permission?.uploadFiles}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, NodePermissionLevelEnum.UploadFiles, NodePermissionLevelEnum.Download)
                                        }
                                    />
                                    {t(PermissionsTKeys.UploadCheckboxLabel)}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs='9'>
                            <Text>{descriptions[PermissionTypes.Upload]}</Text>
                            <FormGroup check className='pointer mt-2 pt-1'>
                                <Label check>
                                    <Input
                                        name='upload-delete'
                                        dataId={`upload-delete-permission-check-${permission?.id}`}
                                        type='checkbox'
                                        checked={permission?.childPermission?.delete || permission?.write}
                                        onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.UploadDelete)}
                                        disabled={uploadDeleteDisabled}
                                    />
                                    {t(PermissionsTKeys.UploadDeleteCheckboxLabel)}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs='3'>
                            <FormGroup check className='pointer'>
                                <Label check>
                                    <Input
                                        name='createDirectories'
                                        dataId={`create-subitems-permission-check-${permission?.id}`}
                                        type='checkbox'
                                        checked={permission?.createDirectories}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                NodePermissionLevelEnum.CreateDirectories,
                                                NodePermissionLevelEnum.UploadFiles
                                            )
                                        }
                                    />
                                    {t(PermissionsTKeys.CreateSubitemsCheckboxLabel)}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs='9'>
                            <Text>{descriptions[PermissionTypes.CreateSubitems]}</Text>
                            <FormGroup check className='pointer mt-2 pt-1'>
                                <Label check>
                                    <Input
                                        name='create-subitems-delete'
                                        dataId={`create-subitems-delete-permission-check-${permission?.id}`}
                                        type='checkbox'
                                        checked={permission?.childPermission?.delete || permission?.write}
                                        onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.CreateDirectoriesDelete)}
                                        disabled={createDirectoriesDeleteDisabled}
                                    />
                                    {t(PermissionsTKeys.CreateSubitemsDeleteCheckboxLabel)}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            )}
            <Row className='mb-4'>
                <Col xs='3'>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input
                                name='write'
                                dataId={`write-permission-check-${permission?.id}`}
                                type='checkbox'
                                checked={permission?.write}
                                onChange={(e) =>
                                    handleCheckboxChange(e, NodePermissionLevelEnum.Write, NodePermissionLevelEnum.CreateDirectories)
                                }
                            />
                            {t(PermissionsTKeys.EditCheckboxLabel)}
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs='9'>{descriptions[PermissionTypes.Edit]}</Col>
            </Row>
            <Row className='mb-4'>
                <Col xs='3'>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input
                                name='delete'
                                dataId={`delete-permission-check-${permission?.id}`}
                                type='checkbox'
                                checked={permission?.delete}
                                onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.Delete, NodePermissionLevelEnum.Write)}
                            />
                            {t(PermissionsTKeys.DeleteCheckboxLabel)}
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs='9'>{descriptions[PermissionTypes.Delete]}</Col>
            </Row>
            <Row className='mt-4'>
                <Col xs='3'>
                    <FormGroup check className='pointer'>
                        <Label check>
                            <Input
                                name='admin'
                                dataId={`admin-permission-check-${permission?.id}`}
                                disabled={permission?.inheritanceBehavior === NodePermissionInheritanceEnum.AppliesToSelfOnly}
                                type='checkbox'
                                checked={permission?.admin}
                                onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.Admin, NodePermissionLevelEnum.Delete)}
                            />
                            {t(PermissionsTKeys.AdminCheckboxLabel)}
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs='9'>{descriptions[PermissionTypes.Admin]}</Col>
            </Row>
            {!!isContainer && (
                <NestedCollapse title={inheritanceSelectorText} className='mt-4'>
                    <PermissionsInheritanceSelector systemType={systemType} />
                </NestedCollapse>
            )}
            {!!hasItemPermissionsOverride && (
                <NestedCollapse
                    title={t(PermissionsTKeys.AdvancedPermissions)}
                    className='mt-4'
                    innerClassName='mb-4'
                    openByDefault={permission?.enforce || permission?.restrict}>
                    <Alert color={ThemeEnum.Primary} className='my-4 p-2'>
                        {t(PermissionsTKeys.AdvancedPermissionsInfoBanner)}
                    </Alert>
                    {!isGroup && (
                        <Row className='mt-4'>
                            <Col xs='3'>
                                <FormGroup check className='pointer'>
                                    <Label check className={`${!!disableOverride && 'text-muted'}`}>
                                        <Input
                                            name='enforce'
                                            dataId={`override-permission-check-${permission?.id}`}
                                            type='checkbox'
                                            checked={permission?.enforce}
                                            onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.Override)}
                                            disabled={!!disableOverride}
                                        />
                                        {t(PermissionsTKeys.LockAccessCheckboxLabel)}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col xs='9' className='ps-0'>
                                {descriptions[PermissionTypes.Lock]}
                            </Col>
                        </Row>
                    )}
                    <Row className='mt-4'>
                        <Col xs='3'>
                            <FormGroup check className='pointer'>
                                <Label check>
                                    <Input
                                        name='restrict'
                                        dataId={`restrict-permission-check-${permission?.id}`}
                                        type='checkbox'
                                        checked={permission?.restrict}
                                        onChange={(e) => handleCheckboxChange(e, NodePermissionLevelEnum.RestrictAll)}
                                    />
                                    {t(PermissionsTKeys.BlockAccessCheckboxLabel)}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs='9' className='ps-0'>
                            {descriptions[PermissionTypes.Block]}
                        </Col>
                    </Row>
                </NestedCollapse>
            )}
        </>
    );
};
