import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { Button, Input } from '@efilecabinet/efc-atlantis-components';
import { RoleSelectorTKeys, TranslationFiles, useSafeTranslation } from '../../hooks/useSafeTranslation';
import { useAuthContext } from '../../app/_context/AuthContext';
import { RoleTypeTile } from './RoleTypeTile/RoleTypeTile';
import { RoleChip } from './RoleChip/RoleChip';
import { useRoleApi } from '../../api/roles/useRoleApi';
import { AccountRoleCountsDTO, RoleDTO, RoleEnum, UserLicenseEnum } from '../../api/roles/roleApiTypes';
import './RoleSelector.css';

interface RoleSelectorProps {
    selectedRoles: RoleDTO[];
    setSelectedRoles: React.Dispatch<React.SetStateAction<RoleDTO[]>>;
}

export const RoleSelector = ({ selectedRoles, setSelectedRoles }: RoleSelectorProps) => {
    const { t } = useSafeTranslation(TranslationFiles.RoleSelector);
    const { queryRolesByAccountId, getAccountRoleCounts } = useRoleApi();
    const { authUser } = useAuthContext();

    const DEBOUNCE_TIMER = 500;
    const roleSelectorInputText = t(RoleSelectorTKeys.DefaultText);

    const [roleCounts, setRoleCounts] = useState<AccountRoleCountsDTO | null>(null);
    const [isRoleCountsLoading, setIsRoleCountsLoading] = useState<boolean>(false);
    const [beforeInitialSelect, setBeforeInitialSelect] = useState<boolean>(true);

    const [searchInput, setSearchInput] = useState<string>('');
    const [searchedRolesCache, setSearchedRolesCache] = useState<RoleDTO[]>([]);
    const [isSearchingRoles, setIsSearchingRoles] = useState<boolean>(false);
    const [rolesFromSearchQuery, setRolesFromSearchQuery] = useState<RoleDTO[]>([]);
    const [groupRolesFromSearchQuery, setGroupRolesFromSearchQuery] = useState<RoleDTO[]>([]);
    const [guestRolesFromSearchQuery, setGuestRolesFromSearchQuery] = useState<RoleDTO[]>([]);
    const [userRolesFromSearchQuery, setUserRolesFromSearchQuery] = useState<RoleDTO[]>([]);
    const [defaultSelectedRole, setDefaultSelectedRole] = useState<RoleDTO | null>(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isSelectedRolesChipsOpen, setIsSelectedRolesChipsOpen] = useState<boolean>(false);

    const addRoleToSelectedRoles = (role: RoleDTO) => {
        setBeforeInitialSelect(false);
        setSelectedRoles([...selectedRoles, role]);
        setIsDropdownOpen(false);
    };

    const removeRole = (role: RoleDTO) => {
        setSelectedRoles([...selectedRoles.filter(x => x != role)]);
    };

    const getRoleCounts = async () => {
        setIsRoleCountsLoading(true);

        const result = await getAccountRoleCounts(authUser?.accountID as number);
        setRoleCounts(result);

        setIsRoleCountsLoading(false);
    };

    const searchRoles = async () => {
        setIsDropdownOpen(true);

        const inputRoles = searchedRolesCache.filter(x => x.userName.toLowerCase().includes(searchInput.toLowerCase()) || x.name.toLowerCase().includes(searchInput.toLowerCase()));
        if (inputRoles.length > 0) {
            setRolesFromSearchQuery(inputRoles);
            setIsSearchingRoles(false);
            return;
        }

        const accountRoles = await queryRolesByAccountId({ 
            accountId: authUser?.accountID as number, 
            roleTypes: [RoleEnum.Guest, RoleEnum.User, RoleEnum.Group],
            searchCriteria: searchInput,
        });
        const distinctRoles = [...searchedRolesCache, ...accountRoles].filter((value, index, self) => {
            return self.findIndex(x => x.accountID == value.accountID && x.id == value.id) == index;
        });

        setRolesFromSearchQuery(accountRoles);
        setSearchedRolesCache(distinctRoles);
        setIsSearchingRoles(false);
    };

    const debouncedSearch = debounce(searchRoles, DEBOUNCE_TIMER);

    const isErrorState = () => {
        return !beforeInitialSelect && selectedRoles.length == 0 && !isDropdownOpen;
    };

    const getRoleSelectorInputStyling = () => {
        let styling = 'role-selector-input';
        if (isErrorState()) {
            styling += ' role-selector-input-error';
        }
        else {
            styling += ' role-selector-border-no-error';
        }
        return styling;
    };

    const getRoleSelectorButtonStyling = () => {
        let styling = 'role-selector-button';
        if (isErrorState()) {
            styling += ' role-selector-button-error';
        }
        else {
            styling += ' role-selector-border-no-error';
        }
        return styling;
    };

    const addDefaultSelectedRoleIfEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            addRoleToSelectedRoles(defaultSelectedRole as RoleDTO);
        }
    };

    const findAndSetDefaultSelectedRole = () => {
        let defaultRoleToSet = null;
        if (!defaultRoleToSet && !!userRolesFromSearchQuery) {
            for (let i = 0; i < userRolesFromSearchQuery.length; i++) {
                if (selectedRoles.indexOf(userRolesFromSearchQuery[i]) < 0) {
                    defaultRoleToSet = userRolesFromSearchQuery[i];
                    break;
                }
            }
        }
        if (!defaultRoleToSet && !!guestRolesFromSearchQuery) {
            for (let i = 0; i < guestRolesFromSearchQuery.length; i++) {
                if (selectedRoles.indexOf(guestRolesFromSearchQuery[i]) < 0) {
                    defaultRoleToSet = guestRolesFromSearchQuery[i];
                    break;
                }
            }
        }
        if (!defaultRoleToSet && !!groupRolesFromSearchQuery) {
            for (let i = 0; i < groupRolesFromSearchQuery.length; i++) {
                if (selectedRoles.indexOf(groupRolesFromSearchQuery[i]) < 0) {
                    defaultRoleToSet = groupRolesFromSearchQuery[i];
                    break;
                }
            }
        }
        setDefaultSelectedRole(defaultRoleToSet);
    };

    useEffect(() => {
        if (!!selectedRoles) {
            setIsSelectedRolesChipsOpen(selectedRoles.length > 0);
        }

    }, [selectedRoles]);

    useEffect(() => {
        if (!!searchInput && searchInput.length > 1) {
            setIsSearchingRoles(true);
            debouncedSearch();
        }
        else {
            setRolesFromSearchQuery([]);
        }

    }, [searchInput]);

    useEffect(() => {
        if (!roleCounts && !!authUser) {
            getRoleCounts();
        }

    }, [roleCounts, authUser]);

    useEffect(() => {
        findAndSetDefaultSelectedRole();
    }, [userRolesFromSearchQuery, guestRolesFromSearchQuery, groupRolesFromSearchQuery]);

    useEffect(() => {
        if (!isSearchingRoles && !!rolesFromSearchQuery) {
            setGroupRolesFromSearchQuery(rolesFromSearchQuery.filter(x => x.roleType == RoleEnum.Group));
            setGuestRolesFromSearchQuery(rolesFromSearchQuery.filter(x => x.license == UserLicenseEnum.Guest));
            setUserRolesFromSearchQuery(rolesFromSearchQuery.filter(x => x.roleType == RoleEnum.User && x.license != UserLicenseEnum.Guest));
        }
    }, [rolesFromSearchQuery, isSearchingRoles]);

    return (
        <>
            <div className="role-selector-wrapper" >
                <Row className="g-0">
                    <Col xs="11">
                        <Input
                            name='roleSelectorInput'
                            className={getRoleSelectorInputStyling()}
                            placeholder={roleSelectorInputText}
                            value={searchInput}
                            onChange={e => setSearchInput(e.target.value)}
                            onKeyDown={addDefaultSelectedRoleIfEnter} />
                    </Col>
                    <Col xs="1">
                        <Button
                            emphasis='med'
                            className={getRoleSelectorButtonStyling()}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)} >
                            {!isDropdownOpen &&
                                <FontAwesomeIcon icon='caret-down' />
                            }
                            {isDropdownOpen &&
                                <FontAwesomeIcon icon='caret-up' />
                            }
                        </Button>
                    </Col>
                </Row>
            </div>

            {isErrorState() && (
                <div className='my-2 role-selector-error-message'>
                    <FontAwesomeIcon icon='triangle-exclamation' className='my-0 mx-2' />
                    {t(RoleSelectorTKeys.NoChipsErrorMessage)}
                </div>
            )}

            <Container className="role-selector-container g-0">
                {isSelectedRolesChipsOpen && (
                    <div className="role-selector-container-row role-selector-chip-row mt-2">
                        {selectedRoles.map(role => <RoleChip key={role.id} role={role} accountRoleCounts={roleCounts as AccountRoleCountsDTO} removeRole={removeRole} />)}
                    </div>
                )}

                {isDropdownOpen && (
                    <Row className="role-selector-container-row g-0 role-selector-search-row">
                        <Col>
                            {isSearchingRoles && (
                                <SkeletonTheme enableAnimation height='200px' width='100%'>
                                    <Skeleton count={1} className='mb-3 mx-auto' />
                                </SkeletonTheme>
                            )}

                            {!isSearchingRoles && isRoleCountsLoading && (
                                <SkeletonTheme enableAnimation height='24px' width='100%'>
                                    <Skeleton count={3} className='mb-2 mx-auto' />
                                </SkeletonTheme>
                            )}

                            {!isSearchingRoles && !isRoleCountsLoading && (
                                <div id='role-selector-infinite-scroll-target' className="role-selector-results-container" >
                                    <RoleTypeTile accountId={authUser?.accountID as number} roleType={RoleEnum.User} accountRoleCounts={roleCounts as AccountRoleCountsDTO} rolesFromSearchQuery={userRolesFromSearchQuery} addRole={addRoleToSelectedRoles} selectedRoles={selectedRoles} searchInput={searchInput} defaultSelectedRole={defaultSelectedRole} />
                                    <RoleTypeTile accountId={authUser?.accountID as number} roleType={RoleEnum.Guest} accountRoleCounts={roleCounts as AccountRoleCountsDTO} rolesFromSearchQuery={guestRolesFromSearchQuery} addRole={addRoleToSelectedRoles} selectedRoles={selectedRoles} searchInput={searchInput} defaultSelectedRole={defaultSelectedRole} />
                                    <RoleTypeTile accountId={authUser?.accountID as number} roleType={RoleEnum.Group} accountRoleCounts={roleCounts as AccountRoleCountsDTO} rolesFromSearchQuery={groupRolesFromSearchQuery} addRole={addRoleToSelectedRoles} selectedRoles={selectedRoles} searchInput={searchInput} defaultSelectedRole={defaultSelectedRole} />
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    );
};