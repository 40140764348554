import React, { useEffect, useState } from 'react';
import { Carousel, UncontrolledCarousel, Text } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GuestWelcomePreview.css';

interface GuestWelcomePreviewProps {
    item?: GuestWelcomeCard;
}

export interface GuestWelcomeCard {
    message?: string;
    logoString?: string;
    includeLogo?: boolean;
    accountName?: string;
}

const DefaultMessage = ({ accountName }: { accountName: string | undefined }) => {
    return (
        <Text tag='h6' className='mt-2'>
            You are accessing Revver as a guest of the account, <span className='fw-bolder'>{accountName}</span>.
        </Text>
    );
};

export const GuestWelcomePreview = (prop: GuestWelcomePreviewProps) => {
    const { item } = prop;

    const [ctrlPosition, setCtrlPosition] = useState<number>(209);

    const CTRL_PANEL_WIDTH = 67;

    const centerCarouselControls = () => {
        const previewDiv = document.querySelector('#guest-welcome-preview') as HTMLDivElement | null;
        if (previewDiv) {
            const width = previewDiv.offsetWidth;
            setCtrlPosition((width - CTRL_PANEL_WIDTH) / 2);
        } else {
            setCtrlPosition(ctrlPosition);
        }
    };

    useEffect(() => {
        centerCarouselControls();
        window.addEventListener('resize', centerCarouselControls);

        return () => {
            window.removeEventListener('resize', centerCarouselControls);
        };
    }, []);

    return (
        <>
            <UncontrolledCarousel interval={false} selectorPosition={{ bottom: '-3rem', left: `${ctrlPosition}px` }}>
                <Carousel.Item>
                    <div className='welcome-preview-container modal-preview'>
                        <div className='welcome-modal'>
                            <div className='welcome-header'>
                                <Text tag='h4' className='m-0' semibold>
                                    Welcome!
                                </Text>
                                <FontAwesomeIcon icon={['far', 'xmark']} color='grey' />
                            </div>
                            <DefaultMessage accountName={item?.accountName} />
                            <div className={`message-container ${!item?.message?.length && 'center-logo'}`}>
                                {!!item?.logoString && !!item?.includeLogo && (
                                    <div className={'logo-container'}>
                                        <img className='logo' src={item?.logoString} alt='logo' />
                                    </div>
                                )}
                                <div className='message fw-semibold'>{item?.message}</div>
                            </div>
                            <div className='welcome-preview-footer'>
                                <div className='button'>Close</div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='welcome-preview-container home-preview'>
                        <div className='header'>
                            <div className='search'>
                                <FontAwesomeIcon icon='search' className='me-3' color='gray' />
                                <Text muted>Search</Text>
                            </div>
                        </div>
                        <div className='body'>
                            <div className='message-container'>
                                {!!item?.logoString && !!item?.includeLogo && (
                                    <div className='logo-container'>
                                        <img className='logo' src={item?.logoString} alt='logo' />
                                    </div>
                                )}
                                <div className='message'>{!!item?.message ? item?.message : <DefaultMessage accountName={item?.accountName} />}</div>
                            </div>
                            <div className='to-do-items'>
                                <div className='header'>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon={['far', 'clipboard-check']} className='me-2' />
                                        <Text bold>Your To-Do items</Text>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon={['far', 'circle-plus']} className='me-1' color='gray' />
                                        <Text size='micro' muted semibold>
                                            Add Task
                                        </Text>
                                    </div>
                                </div>
                                <div className='to-do-item'></div>
                                <div className='to-do-item'></div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </UncontrolledCarousel>
        </>
    );
};
