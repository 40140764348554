import React from 'react';
import { Spinner } from '@efilecabinet/efc-atlantis-components';
import { ThemeEnum } from '../../hooks/useColors';
import './FullScreenOverlay.css';

interface FullScreenOverlayProps {
    isVisible: boolean;
    className?: string;
    overlayClassName?: string;
    showSpinner?: boolean;
}

export const FullScreenOverlay = ({ isVisible, className = '', overlayClassName = '', showSpinner = true }: FullScreenOverlayProps) => {
    if (!isVisible) return null;

    return (
        <div className={`full-screen-overlay ${overlayClassName} ${className}`}>
            {showSpinner && (
                <Spinner
                    color={ThemeEnum.Primary}
                    style={{
                        height: '3rem',
                        width: '3rem',
                    }}
                />
            )}
        </div>
    );
};
