import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Modal, Button, Text } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, WelcomeModalTKeys, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { useAccountBranding } from '../../../hooks/useAccountBranding';
import { useBootstrapBreakpoints } from '../../../hooks/bootstrap/useBootstrapBreakpoints';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { ScreenSize } from '../../../hooks/bootstrap/bootstrapTypes';
import { GuestWelcomeWidget } from '../../../features/_guestExperience/GuestWelcomeWidget';
import './AccountGuestWelcomeModal.css';

export interface IGuestWelcomeModalProps {
    toggleWelcome: () => void;
    showWelcomeModal: boolean;
}

export const AccountGuestWelcomeModal = (props: IGuestWelcomeModalProps) => {
    const { toggleWelcome, showWelcomeModal } = props;

    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { authUser, userAccounts, hasCheckedAuthUser } = useAuthContext();
    const { accountBranding } = useAccountBranding();
    const { screenSize } = useBootstrapBreakpoints();

    const [authUserAccountName, setAuthUserAccountName] = useState<string>();

    useEffect(() => {
        if(!!authUser?.accountID && !!userAccounts) {
            const account = userAccounts.get(authUser?.accountID);
            setAuthUserAccountName(account?.accountName);
        }
    }, [hasCheckedAuthUser]);

    return (
        <Modal
            size={`${!!accountBranding?.accountMessage ? 'lg' : 'md'}`}
            title={t(WelcomeModalTKeys.WelcomeTitle)}
            toggle={toggleWelcome}
            isOpen={!!showWelcomeModal && !!accountBranding}
            backdrop='static'
            className={`${screenSize < ScreenSize.s ? 'mobile-modal-spacing' : ''}`}>
            <Modal.Body className='mb-2'>
                <Row>
                    <Col>
                        <Text className='account-guest-welcome-modal-greeting'>{t(WelcomeModalTKeys.GuestWelcomeSubText)} <Text semibold={true} className='account-guest-welcome-modal-greeting'>{authUserAccountName}</Text></Text>
                    </Col>
                </Row>
                {!!accountBranding && (!!accountBranding.logoWithMessage || !!accountBranding.accountMessage) &&
                    <Row>
                        <GuestWelcomeWidget 
                            accountMessage={accountBranding.accountMessage} 
                            accountLogoImgString={accountBranding.logoWithMessage ? accountBranding.accountLogoImgString : undefined} 
                            isModal={true}/>
                    </Row>
                }
                <Row>
                    <Col>
                        <Button color='primary' onClick={toggleWelcome} className='btn-lg float-end'>Close</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
