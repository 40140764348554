import { useEffect, useState } from 'react';
import { useBootstrapBreakpoints } from './bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from './bootstrap/bootstrapTypes';


export const useAllFeaturesStyle = () => {
    const { screenSize } = useBootstrapBreakpoints();
    const [allFeaturesWidthStyle, setAllFeaturesWidthStyle] = useState<{ width: number; }>();

    const styles = {
        allFeaturesSmall: {
            width: 290
        },
        allFeaturesMedium: {
            width: 440
        },
        allFeaturesLarge: {
            width: 890
        }
    };

    useEffect(() => {
        if (screenSize > ScreenSize.l) {
            setAllFeaturesWidthStyle(styles.allFeaturesLarge);
        } else if (screenSize > ScreenSize.m) {
            setAllFeaturesWidthStyle(styles.allFeaturesMedium);
        } else {
            setAllFeaturesWidthStyle(styles.allFeaturesSmall);
        }
    }, [screenSize]);

    return {
        allFeaturesWidthStyle
    };
};