import React, { useEffect } from 'react';
import { NodeSelector as EfcNodeSelector, SelectorNodeType} from '@efilecabinet/efc-atlantis-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNodeApi } from '../../api/node/useNodeApi';
import { NodeDTO } from '../../api/node/nodeApiTypes';

export interface INodeSelectorProps {
    activeAccountId: number;
    height: string;
    onSelect: (selectedNodes: NodeDTO[]) => void;
    showTreeView?: boolean;
    selectorNodeType: SelectorNodeType;
}

export const NodeSelector = ({activeAccountId, height, onSelect, selectorNodeType, showTreeView }: INodeSelectorProps) => {

    const { getAncestorsOnlyAsync, getRootNodesAsync, getNodeChildrenAsync } = useNodeApi();

    const [rootNodes, setRootNodes] = React.useState<NodeDTO[]>([]);

    useEffect(() => {
        getRootNodesAsync().then((nodes) => {
            setRootNodes(nodes);
        });
    }, []);

    return !!rootNodes.length
        ? <EfcNodeSelector 
                activeAccountId={activeAccountId} 
                getAncestors={(nodeId) => getAncestorsOnlyAsync(nodeId)}             
                getNodeChildren={(nodeId: string, start: number, count: number, searchCriteria: string): Promise<NodeDTO[]> => getNodeChildrenAsync(+nodeId, start, count, searchCriteria)} 
                height={height}
                onSelect={(selectedNodes) => onSelect(selectedNodes as NodeDTO[])}
                rootNodes={rootNodes} 
                selectorNodeType={selectorNodeType}
                showTreeView={!!showTreeView}/>
        : (
            <SkeletonTheme enableAnimation>
                <Skeleton height={20} width="200px" count={1} className='my-3' />
                <Skeleton height={50} width="350px" count={1} className='mb-5' />
                <Skeleton height={30} width="100%" count={9} className='mb-4' />
            </SkeletonTheme>
        );
};