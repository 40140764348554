import { useProductUpdatesApi } from '../api/productUpdates/useProductUpdatesApi';
import { useSettingsAndFeatures } from '../app/_context/SettingFeaturesContext/SettingsFeaturesContext';

export const useGoToProductBoard = () => {

    const { getToken } = useProductUpdatesApi();
    const { settings } = useSettingsAndFeatures();

    function openProductBoardUpdatesInNewTab() {
        getToken().then((token:string) => {
            window.open(`${settings?.productUpdatesUrl}?token=${token}`, '_blank');
        }).catch(() => {
            window.open(settings?.productUpdatesUrl, '_blank');
        });
    }

    return { openProductBoardUpdatesInNewTab };
};