import { NodePermissionDTO } from "../nodePermissions/nodePermissionApiTypes";

export interface NodeDetailsDTO {
    templateNames: string[];
    size: number;
    fileCount: number;
    containerCount: number;
}

export interface HasSomeNodeAccessDTO {
    hasSomeNodeAccess: boolean,
}

export interface NodeDTO {
    id: string;
    accountID: number;
    parentID: string;
    name: string;
    size: number;
    systemType: NodeType;
    fileInfo: FileInfoDTO;
    createdOn: string;
    createdBy: string;
    createdByUserId: number;
    modifiedOn: string;
    hasChildren: boolean;
    checkoutInfo: CheckOutInfoDTO;
    instances?: number;
    permission: NodePermissionDTO;
}

export enum NodeType {
    Account = 1,
    Shared = 2,
    Cabinet = 4,
    Drawer = 5,
    Folder = 6,
    File = 7,
    TemplateLibrary = 11,
    Template = 12,
    TemplateFolder = 13,
    TemplateFile = 14,
    PredefinedDocument = 15,
    GDWorkspace = 16,
    ODWorkspace = 17,
    Record = 18,
}

export interface FileInfoDTO {
    accountId: number;
    id: number;
    fileExtension: string;
    sizeInBytes: number;
    createdOn: Date;
    uploadSuccessful: boolean;
    uploadIdentifier: string;
    checkedOutByRoleID?: number;
    formFillDefinitionId?: number;
    generatedFromFileInfoID?: number;
    needsFlattening: boolean;
}

export interface CheckOutInfoDTO {
    nodeId: number;
    checkedOutByRoleID: number;
    checkedOutOn: Date;
    o365ClientLock: string;
    o365ClientLockedOn: Date;
}