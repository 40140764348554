import React from 'react';
import { EfcCallToActionInfo, Modal, Table, Text } from '@efilecabinet/efc-atlantis-components';
import { FileVersionTableRow } from './FileVersionTableRow';
import { useNodeDetailsContext } from '../../../../_context/NodeDetailsContext/NodeDetailsContext';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../../hooks/useSafeTranslation';
import '../FileVersion.css';

export const FileVersionTable = () => {
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);
    const { fileVersions, isFileVersionModalOpen, setIsFileVersionModalOpen } = useNodeDetailsContext();

    const closeModal = () => setIsFileVersionModalOpen(false);

    const ctas: EfcCallToActionInfo[] = [{ text: t(FileVersionsTKeys.Close), emphasis: 'med', color: 'primary', onClick: closeModal, dataId: 'file-version-modal-close' }];

    return (
        <Modal size='lg' isOpen={isFileVersionModalOpen} ctas={ctas} dataId='file-version-modal'>
            <Modal.Header toggle={closeModal} tag='div' className='mx-3'>
                {t(FileVersionsTKeys.ModalTitle, { count: fileVersions.length })}
            </Modal.Header>
            <Modal.Body className='mx-3 file-version-modal' id='file-version-table'>
                <Table borderless dataId='file-version-table'>
                    <thead>
                        <tr>
                            <th scope='col'></th>
                            <th scope='col'>
                                <Text>{t(FileVersionsTKeys.VersionDateColumn)}</Text>
                            </th>
                            <th scope='col' className='d-none d-md-table-cell'>
                                <Text>{t(FileVersionsTKeys.SizeColumn)}</Text>
                            </th>
                            <th scope='col' className='d-none d-sm-table-cell'>
                                <Text>{t(FileVersionsTKeys.CreatedByColumn)}</Text>
                            </th>
                            <th scope='col' className='d-none d-sm-table-cell'>
                                <Text>{t(FileVersionsTKeys.NotesColumn)}</Text>
                            </th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fileVersions.map((fileVersion, index) => (
                            <FileVersionTableRow key={index} fileVersion={fileVersion} />
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
};
