import React, { useEffect, useState } from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { Col, Row } from 'reactstrap';
import { ListSelectorItem } from '../ListSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import './ItemTile.css';
import '../ListSelector.css';

export interface ItemTileProps {
    item: ListSelectorItem;
    isWithinGroup?: boolean;
    topLevelHasIconColumn: boolean;
    showIconColumn: boolean;
    selectedItems: ListSelectorItem[];
    isDisabled?: boolean;
    showDisabledReason?: boolean;
    addItem: (item: ListSelectorItem) => void;
}

export const ItemTile: React.FC<ItemTileProps> = ({ item, isWithinGroup, topLevelHasIconColumn, showIconColumn, selectedItems, isDisabled, showDisabledReason = true, addItem }) => {
    const TRUNCATE_MAX_WIDTH = 25;

    const [canAddItem, setCanAddItem] = useState<boolean>(checkIfCanAddItem());

    function checkIfCanAddItem() {
        if (!!isDisabled) { return false; }

        if (!!selectedItems) {
            const foundItem = selectedItems.filter(x => x.identifier == item.identifier);
            return foundItem.length == 0;
        }
        return true;
    }

    function truncateString(str: string) {
        if (!canAddItem) {
            let addedString = ' (added)';
            if (!showDisabledReason) {
                addedString = '';
            }
            if ((str.length + addedString.length) > TRUNCATE_MAX_WIDTH) {
                return str.substring(0, TRUNCATE_MAX_WIDTH - addedString.length) + `...${addedString} `;
            } else {
                return `${str}${addedString}`;
            }
        }
        else {
            if (str.length > TRUNCATE_MAX_WIDTH) {
                return str.substring(0, TRUNCATE_MAX_WIDTH) + '...';
            } else {
                return str;
            }
        }
    }

    function tryAddItem() {
        if (canAddItem) {
            addItem(item);
        }
    }

    useEffect(() => {
        setCanAddItem(checkIfCanAddItem());
    }, [selectedItems]);

    return (
        <Row className={'g-0 item-row-container ' + (canAddItem ? 'item-row-container-selectable' : 'item-row-container-unselectable')} onClick={tryAddItem} >
            <Col>
                <Row className="g-0 item-row">
                    {isWithinGroup && <Col xs="1" className="column-width" />}
                    {(isWithinGroup && topLevelHasIconColumn) && <Col xs="1" className="column-width" />}

                    {showIconColumn && (<Col xs="1" className="column-width">
                        <div className="item-icon-wrapper">
                            {item.icon && <FontAwesomeIcon icon={item.icon as IconName} className='group-icon' />}
                        </div>
                    </Col>)}

                    <Col xs="9">
                        <div className="item-text-wrapper" >
                            <Text size='lg' color={!canAddItem ? '#D9D9D9' : ''}>{truncateString(item.name)}</Text>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
