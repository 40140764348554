import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodeProfileDTO } from './nodeProfileApiTypes';
import { ProfileItemDTO } from '../profileItems/profileItemApiTypes';

export const useNodeProfileApi = () => {

    const getAllAsync = async (nodeId: number): Promise<NodeProfileDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/nodeProfile/${nodeId}`);
        return resp.data as NodeProfileDTO[];
    };

    const getMaskedProfileItemValueAsync = async (nodeId: string, profileItemId: number): Promise<ProfileItemDTO> => {
        const resp: AxiosResponse = await Api.get(`api/NodeProfile/${nodeId}/Mask/${profileItemId}`);
        return resp.data as ProfileItemDTO;
    };

    const getUnmaskedProfileItemValueAsync = async (nodeId: string, profileItemId: number): Promise<ProfileItemDTO> => {
        const resp: AxiosResponse = await Api.get(`api/NodeProfile/${nodeId}/Unmask/${profileItemId}`);
        return resp.data as ProfileItemDTO;
    };

    const updateNodeProfileAsync = async (nodeProfile: NodeProfileDTO): Promise<NodeProfileDTO> => {
        const resp: AxiosResponse = await Api.post('api/NodeProfile/Update', nodeProfile);
        return resp.data as NodeProfileDTO;
    };

    return {
        getAllAsync,
        getMaskedProfileItemValueAsync,
        getUnmaskedProfileItemValueAsync,
        updateNodeProfileAsync,
    };
};