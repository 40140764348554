import React, { useEffect, useRef } from 'react';
import { Form, FormGroup, Input, Label } from '@efilecabinet/efc-atlantis-components';
import { AddTaskModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { AddTaskFormData } from './UseAddTaskModalForm';

export const AddTaskModalBody = ({ modalElementId, form, onSubmit, isProcessingAction }: AddTaskFormData) => {

    const MAX_INPUT_CHARS = 250;
    
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useSafeTranslation(TranslationFiles.AddTaskModal);

    useEffect(() => {
        
        const focusInput = () => (inputRef?.current as any)?.focus();
        const clearInput = () => form.setObject({ taskText: '' });

        document.getElementById(modalElementId)?.addEventListener('shown.bs.modal', focusInput);
        document.getElementById(modalElementId)?.addEventListener('hidden.bs.modal', clearInput);
        
        return () => {
            document.getElementById(modalElementId)?.removeEventListener('shown.bs.modal', focusInput);
            document.getElementById(modalElementId)?.removeEventListener('hidden.bs.modal', clearInput);
        }

    }, []);

    return (
        <Form>
            <FormGroup>
                <Label for='taskText'>{t(AddTaskModalTKeys.Label)}</Label>
                <Input
                    innerRef={inputRef}
                    disabled={!!isProcessingAction}
                    id='taskText'
                    name='taskText'
                    dataId='txtTaskText'
                    placeholder={t(AddTaskModalTKeys.PlaceHolder)}
                    value={form.model.taskText}
                    maxLength={MAX_INPUT_CHARS}
                    onChange={form.onPropChanged}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            onSubmit();                            
                        }
                    }}
                />
            </FormGroup>
        </Form>
    );
}