import React, { FC, ReactNode } from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';

interface INodeDetailsItemProps {
    titleText: string;
    contentText: string | undefined;
    
}

export const NodeDetailsItem: FC<INodeDetailsItemProps> = ({titleText, contentText}) => {
    return (
        <>
            <Text className='mt-3 ms-2'>{titleText}</Text>
            <Text className='mt-3 ms-4' color='#696969'>
                {contentText}
            </Text>
        </>
    );
};