import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ICheckMark {
    isFinished: boolean;
    showLine: boolean;
}

export const CheckMark = (props: ICheckMark) => {
    const { isFinished, showLine } = props;

    const getFinishedClass = () => {
        return isFinished ? 'icon-finished' : 'icon-unfinished';
    };

    return (
        <>
            <div className='icon-wrapper'>
                <FontAwesomeIcon icon={['fal', 'circle']} className='icon-stacked icon-circle icon-unfinished' />
                <FontAwesomeIcon icon={['far', 'check']} className={`icon-stacked icon-check ${getFinishedClass()}`} />
            </div>
            {!isFinished && showLine && (
                <div className='vertical-line-wrapper'>
                    <div className='vertical-line'></div>
                </div>
            )}
        </>
    );
};
