import { IAtlantisHtmlDocument } from '../../../IAtlantisHtmlDocument';

export const useAppInsights = () => {

    const appInsights = (document as IAtlantisHtmlDocument).appInsights;

    const trackEvent = (eventName: string, eventData: any) => {
        appInsights?.trackEvent({ name: eventName }, eventData);
    };
    return {
        appInsights,
        trackEvent
    };
};