import React from 'react';
import { Col, Row } from 'reactstrap';
import './NodeProfileFields.css';

export interface NodeProfileFieldsProps {
    children: React.ReactNode;
}

/* Puts a purple bar along the left side of the passed in children */
export const NodeProfileFields = ({ children }: NodeProfileFieldsProps) => {
    return (
        <Row className='g-0 ps-2'>
            <Col className='g-0 profile-items-divider-col'><div className='profile-items-divider' /></Col>
            <Col className='g-0 profile-items-content-col'>
                {children}
            </Col>
        </Row>
    );
};
