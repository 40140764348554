import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { AccountFeatureDTO } from './accountFeatureApiTypes';

export const useAccountFeatureApi = () => {

    const getAccountFeatures = async (): Promise<AccountFeatureDTO[]> => {
        const resp: AxiosResponse = await Api.get('api/accountFeature');
        return resp.data;
    };

    return {
        getAccountFeatures,
    };
};
