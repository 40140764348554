import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { IBatchItemResponse, IBatchItem, OrderEnum } from '../sharedApiTypes';
import { RoleDTO, AccountRoleCountsDTO, RoleEnum, UserLicenseEnum } from './roleApiTypes';

export const useRoleApi = () => {

    const updateRole = async (role: RoleDTO): Promise<IBatchItemResponse<RoleDTO>> => {
        const roleBatchItem: IBatchItem<RoleDTO> = { batchObject: role, clientIdentifier: '' };
        const response = await batchUpdateRole([roleBatchItem]);
        return response[0];
    };

    const batchUpdateRole = async (batchItems: IBatchItem<RoleDTO>[]): Promise<IBatchItemResponse<RoleDTO>[]> => {
        const resp: AxiosResponse = await Api.post('api/Role/RoleBatch/Update', batchItems);
        return resp.data;
    };

    const queryRolesByAccountId = async (queryParams: { accountId: number, roleTypes: RoleEnum[], userLicenseTypes?: UserLicenseEnum[], order?: OrderEnum | null, searchCriteria?: string | null, start?: number | null, count?: number | null }): Promise<RoleDTO[]> => {
        const queryString = `?roleTypes=${queryParams.roleTypes.join(',')}&userLicenseTypes=${(queryParams.userLicenseTypes ?? []).join(',')}&orderBy=${queryParams.order ?? ''}&searchCriteria=${queryParams.searchCriteria ?? ''}&start=${queryParams.start ?? ''}&count=${queryParams.count ?? ''}`;
        const resp: AxiosResponse = await Api.get(`api/Role/Account/${queryParams.accountId}` + queryString);
        return resp.data;
    };

    const getAccountRoleCounts = async (accountId: number): Promise<AccountRoleCountsDTO> => {
        const resp: AxiosResponse = await Api.get(`api/Role/GetAccountRoleCounts/${accountId}`);
        return resp.data;
    };

    return {
        updateRole,
        batchUpdateRole,
        queryRolesByAccountId,
        getAccountRoleCounts,
    };
};
