import React from 'react';
import { Col } from 'reactstrap';
import { EfcCallToActionInfo, FormGroup, Input, Label, Modal } from '@efilecabinet/efc-atlantis-components';
import { MetadataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../../hooks/bootstrap/bootstrapTypes';

interface ConfirmDeleteNodeProfileModalProps {
    onClose: (isConfirmed: boolean, deleteSelection?: DeleteOptions) => void;
    closeModalFn: () => void;
}

export type DeleteOptionsKeyStrings = keyof typeof DeleteOptions;
export enum DeleteOptions {
    ProfileOnly = 0,
    DeleteAll,
}

export const ConfirmDeleteNodeProfileModal: React.FC<ConfirmDeleteNodeProfileModalProps> = ({ onClose, closeModalFn }) => {

    const { t } = useSafeTranslation(TranslationFiles.Metadata);
    const { screenSize } = useBootstrapBreakpoints();
    const [deleteSelection, setDeleteSelection] = React.useState<DeleteOptions>(DeleteOptions.ProfileOnly);

    function handleClose() {
        // click x or off canvas to close modal
        onClose(false);
        closeModalFn();
    }

    function handleDeny() {
        // click cta that is med emphasis
        onClose(false);
        closeModalFn();
    }

    function handleConfirm() {
        // click cta that is high emphasis
        onClose(true, deleteSelection);
        closeModalFn();
    }

    function handleOptionChange(key: string) {
        setDeleteSelection(DeleteOptions[key as DeleteOptionsKeyStrings]);
    }

    const getCtas = (): EfcCallToActionInfo[] => {
        return [
            {
                text: t(MetadataTKeys.CancelButton),
                icon: undefined,
                emphasis: 'med',
                color: 'primary',
                disabled: false,
                onClick: handleDeny,
                dataId: 'btnNegativeConfirmation',
            },
            {
                text: t(MetadataTKeys.Confirm),
                icon: undefined,
                emphasis: 'high',
                color: 'danger',
                disabled: false,
                onClick: handleConfirm,
                dataId: 'btnAffirmativeConfirmation',
            }
        ];
    };

    return (
        <Modal
            size='md'
            isOpen={true}
            toggle={handleClose}
            ctas={getCtas()}
            unmountOnClose={true}
            title={t(MetadataTKeys.ConfirmDeleteProfileTitle)}
            className={`${screenSize < ScreenSize.s ? 'md-field-mobile-modal-spacing' : ''}`}>
            <Modal.Body>
                <Col>
                    <FormGroup tag='fieldset'>
                        <FormGroup check key={DeleteOptions[DeleteOptions.ProfileOnly]}>
                            <Input id='radio1' type='radio'
                                checked={deleteSelection === DeleteOptions.ProfileOnly}
                                onChange={() => handleOptionChange(DeleteOptions[DeleteOptions.ProfileOnly])}
                            />
                            &nbsp;
                            <Label check for='radio1'>
                                {t(MetadataTKeys.DeleteProfileNotItems)}
                            </Label>
                        </FormGroup>
                        <FormGroup check key={DeleteOptions[DeleteOptions.DeleteAll]}>
                            <Input id='radio2' type='radio'
                                checked={deleteSelection === DeleteOptions.DeleteAll}
                                onChange={() => handleOptionChange(DeleteOptions[DeleteOptions.DeleteAll])}
                            />
                            &nbsp;
                            <Label check for='radio2' style={{ maxWidth: '80%' }}>
                                {t(MetadataTKeys.DeleteAll)}
                            </Label>
                        </FormGroup>
                    </FormGroup>
                </Col>
            </Modal.Body>
        </Modal>
    );
};