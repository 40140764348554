import React, { ReactNode } from 'react';
import { Container } from 'reactstrap';
import { LinkedViewNav } from '../_linkedViewNav/LinkedViewNav';
import './LinkedViewLayout.css';

interface LinkedViewLayoutProps {
    children: ReactNode;
}

export const LinkedViewLayout = (props: LinkedViewLayoutProps) => {
    const { children } = props;

    return (
        <div className='h-100 w-100' style={{backgroundColor: 'blue', height: '100vh'}}>
            <LinkedViewNav />
            {/* ToDO: Need to fix so it actually shows the children with routing */}
            <Container className='h-50 w-50' style={{backgroundColor: 'pink'}}>
                {children}
            </Container>
        </div>
    );
}