import React from 'react';
import { useIcons } from '../../hooks/useIcons';
import { SettingsFeaturesProvider } from '../_context/SettingFeaturesContext/SettingsFeaturesProvider';
import { AuthProvider } from '../_context/AuthContext';
import { PreviewerProvider } from '../_context/PreviewerContext/PreviewerProvider';
import { LayerProvider } from '../_context/LayerContext/LayerProvider';
import { AppRoutes } from '../../features/_routing/AppRoutes/AppRoutes';
import './App.css';

export const App = () => {

    const { loadIcons } = useIcons();

    loadIcons();

    return (
        <SettingsFeaturesProvider>
            <AuthProvider>
                <PreviewerProvider>
                    <LayerProvider>
                        <AppRoutes/>     
                    </LayerProvider>
                </PreviewerProvider>
            </AuthProvider>
        </SettingsFeaturesProvider>
    );
};