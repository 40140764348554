import React from "react";
import { Route, Routes } from "react-router"
import { PageNotFound } from "../../_errorHandling/PageNotFound/PageNotFound"
import { RoutePath } from "../routingTypes"
import { AuthRoute } from "../../_auth"
import { LinkedViewLayout } from "../../_linkedView/_linkedViewLayout/LinkedViewLayout";
import LinkedView from "../../_linkedView/_linkedView/LinkedView";

export const LinkedViewLayoutRoutes = () => {

    return (
        <LinkedViewLayout>
            <Routes>
                <Route path={RoutePath.LinkedView} element={<AuthRoute path={RoutePath.LinkedView}><LinkedView /></AuthRoute>} />
            </Routes>
        </LinkedViewLayout>
    )
}