import React, { useCallback, useEffect, useState } from 'react';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { NestedCollapse } from '../../../NestedCollapse/NestedCollapse';
import { Button, Text } from '@efilecabinet/efc-atlantis-components';
import { Row } from 'reactstrap';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { PermissionDetailsModal } from './DetailsModal/PermissionDetailsModal';
import { ThemeEnum, useColors } from '../../../../hooks/useColors';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { SidesheetDisplayCard } from '../../../SideSheet/SidesheetDisplayCard';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EditAccessLinkModal } from './EditAccessLinkModal/EditAccessLinkModal';
import { PermissionGrantAccessModal } from './GrantAccessModal/PermissionGrantAccessModal';
import { ContextMenuOptions, ContextMenuProps } from '../../../ContextMenu/ContextMenu';
import { useNodeDetailsContext } from '../../_context/NodeDetailsContext/NodeDetailsContext';
import { initialPermissionState } from '../../../../hooks/nodePermissions/nodePermissionTypes';
import { NodePermissionDTO } from '../../../../api/nodePermissions/nodePermissionApiTypes';
import { AccessLinkDTO } from '../../../../api/accessLinks/accessLinksApiTypes';
import { RoleEnum, UserLicenseEnum } from '../../../../api/roles/roleApiTypes';
import { ExperienceTypeEnum } from '../../../../app/_context/AuthContext/AuthContextTypes';
import { PermissionsSkeletonLoader } from './PermissionsSkeletonLoader';
import { useUtilities } from '../../../../hooks/useUtilities';
import { EditPermissionModal } from './EditPermissionModal/EditPermissionModal';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { DeletePermissionsModal } from './DeletePermissionsModal/DeletePermissionsModal';
import { useNodePermissionsContext } from '../../_context/NodePermissionsContext/NodePermissionsContext';
import { useNodePermissionsUtilities } from '../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { useAccessLinks } from '../../../../hooks/useAccessLinks';

interface PermissionsProps {
    onClose?: () => void;
    nodeId?: string;
}

export interface PermissionCollection {
    users: NodePermissionDTO[];
    groups: NodePermissionDTO[];
    guests: NodePermissionDTO[];
    accessLinks: NodePermissionDTO[];
}

export const Permissions = ({ onClose, nodeId }: PermissionsProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);

    const {
        filterNodePermissionsByCreatedByUserId,
        filterOtherNodePermissionsByCreatedByUserId,
        filterIndividualPermission,
        createCollection,
    } = useNodePermissionsUtilities();
    const { filterNodeAccessLinksByUserId, filterOtherNodeAccessLinksByUserId } = useAccessLinks();

    const { atlantisPrimary } = useColors();
    const { formatDateTime } = useUtilities();
    const { activeNode } = useNodeDetailsContext();
    const { showToastMessage, closeToastMessage } = useLayerContext();
    const { authUser, hasAuthUser, experienceType } = useAuthContext();
    const { permissions, fetchNodePermissions, setEditedPermission, setActivePermission } = useNodePermissionsContext();

    const [userID, setUserID] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [userRoleID, setUserRoleID] = useState<string>('');
    const [isAdminOnNode, setIsAdminOnNode] = useState<boolean>(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [isGrantAccessModalOpen, setIsGrantAccessModalOpen] = useState(false);
    const [myPermission, setMyPermission] = useState<NodePermissionDTO>();
    const [isEditSingleModalOpen, setIsEditSingleModalOpen] = useState(false);
    const [grantedPermissions, setGrantedPermissions] = useState<PermissionCollection>({
        users: [],
        groups: [],
        guests: [],
        accessLinks: [],
    });
    const [otherPermissions, setOtherPermissions] = useState<PermissionCollection>({
        users: [],
        groups: [],
        guests: [],
        accessLinks: [],
    });

    const onViewAllClick = () => setIsDetailsModalOpen(true);
    const closeDetailsModal = () => setIsDetailsModalOpen(false);

    const openEditSingleModal = (permission: NodePermissionDTO) => {
        setActivePermission(permission);
        setEditedPermission(permission);
        setIsEditSingleModalOpen(true);
    };
    const closeEditSingleModal = () => {
        setIsEditSingleModalOpen(false);
        setActivePermission(undefined);
        setEditedPermission(undefined);
    };

    const openRemoveModal = (permission: NodePermissionDTO) => {
        setActivePermission(permission);
        setEditedPermission({ ...permission, remove: true });
        setIsRemoveModalOpen(true);
    };
    const closeRemoveModal = () => {
        setIsRemoveModalOpen(false);
        setActivePermission(undefined);
        setEditedPermission(undefined);
    };

    const openGrantAccessModal = () => {
        const permission = { ...initialPermissionState, nodeID: activeNode?.id ? parseInt(activeNode.id) : 0 };
        setActivePermission(permission);
        setEditedPermission(permission);
        setIsGrantAccessModalOpen(true);
    };
    const closeGrantAccessModal = () => {
        setIsGrantAccessModalOpen(false);
        setActivePermission(undefined);
        setEditedPermission(undefined);
    };

    const createActions = (item: NodePermissionDTO, isAccessLink = false) => {
        if (!isAdminOnNode) return;

        const isInherited = item.nodeID?.toString() !== activeNode?.id;

        const contextActions: ContextMenuProps = {
            options: [
                {
                    text: 'Edit',
                    onClick: () => onEditPermissionClick(item, isAccessLink),
                    dataId: `permissions-menu-edit-${item?.id}`,
                    icon: { icon: 'edit' },
                },
                {
                    text: 'Remove',
                    onClick: () => onRemovePermissionClick(item, isAccessLink),
                    dataId: `permissions-menu-remove-${item?.id}`,
                    icon: { icon: isInherited ? 'lock' : 'trash' },
                    disabled: isInherited,
                    tooltip: isInherited ? t(PermissionsTKeys.RemoveLockedTooltip, { nodeName: item.name }) : undefined,
                },
            ] as ContextMenuOptions[],
            dataId: `permissions-menu-${item?.id}`,
        };
        return contextActions;
    };

    const onRemovePermissionClick = (item: NodePermissionDTO, isAccessLink: boolean) => {
        if (isAccessLink) return; // TODO: Implement logic for removing access links in access link story

        openRemoveModal(item);
    };

    const onEditPermissionClick = (item: NodePermissionDTO | AccessLinkDTO, isAccessLink: boolean) => {
        if (isAccessLink) {
            console.log('Edit Access Link');
        } else {
            openEditSingleModal(item as NodePermissionDTO);
        }
    };
    
    const loadNodePermissions = useCallback(
        async (showSkeletonLoader = true) => {
            if (!nodeId || experienceType !== ExperienceTypeEnum.Standard) return;

            if (showSkeletonLoader) {
                setIsLoading(true);
            }
            try {
                closeToastMessage('error-loading-permissions-toast');
                await fetchNodePermissions(nodeId);
            } catch (error) {
                console.log('Error fetching Permission data:', error);
                showToastMessage({
                    color: ThemeEnum.Danger,
                    identifier: 'error-loading-permissions-toast',
                    message: t(PermissionsTKeys.ErrorLoadingPermissions),
                });
            } finally {
                setIsLoading(false);
            }
        },
        [nodeId]
    );

    const setAllPermissions = (permissions: NodePermissionDTO[]) => {
        setMyPermission(filterIndividualPermission(permissions, userRoleID));

        setGrantedPermissions(createCollection(filterNodePermissionsByCreatedByUserId(permissions, userID), filterNodeAccessLinksByUserId(permissions, userID)));

        if (isAdminOnNode) {
            // Filter out the user's own permission
            const otherPermissions = filterOtherNodePermissionsByCreatedByUserId(permissions, userID).filter((permission) => permission.roleID?.toString() !== userRoleID);
            setOtherPermissions(createCollection(otherPermissions, filterOtherNodeAccessLinksByUserId(permissions, userID)));
        }
    };

    const determinePermissionCount = (collection: PermissionCollection) => {
        return collection.users.length + collection.groups.length + collection.guests.length + collection.accessLinks.length;
    };

    const getInheritanceIcon = (permission: NodePermissionDTO) => {
        const isInherited = permission.nodeID?.toString() !== activeNode?.id;
        return isInherited ? ({ icon: 'sitemap', color: atlantisPrimary } as FontAwesomeIconProps) : undefined;
    };

    const getInheritanceText = (permission: NodePermissionDTO) => {
        const isInherited = permission.nodeID?.toString() !== activeNode?.id;
        return isInherited ? `${t(PermissionsTKeys.InheritedFromTooltip)} ${permission.name}` : undefined;
    };

    const determinePermissionsString = (permission: NodePermissionDTO): string => {
        let permissionString = '';
        if (permission?.admin) {
            permissionString += t(PermissionsTKeys.Admin);
            permissionString = addLockedAndBlocked(permission, permissionString);
            return permissionString;
        }

        if (permission?.view) {
            permissionString += t(PermissionsTKeys.ViewCheckboxLabel);
        }
        if (permission?.download) {
            permissionString += `, ${t(PermissionsTKeys.DownloadCheckboxLabel)}`;
        }
        if (permission?.uploadFiles) {
            permissionString += `, ${t(PermissionsTKeys.UploadCheckboxLabel)}`;
            if (permission?.childPermission?.delete) {
                permissionString += ` ${t(PermissionsTKeys.CanDeleteUploads)}`;
            }
        }
        if (permission?.createDirectories) {
            permissionString += `, ${t(PermissionsTKeys.CreateSubitemsCheckboxLabel)}`;
            if (permission?.childPermission?.delete) {
                permissionString += ` ${t(PermissionsTKeys.CanDeleteSubitems)}`;
            }
        }
        if (permission?.write) {
            permissionString += `, ${t(PermissionsTKeys.EditCheckboxLabel)}`;
        }
        if (permission?.delete) {
            permissionString += `, ${t(PermissionsTKeys.DeleteCheckboxLabel)}`;
        }

        permissionString = addLockedAndBlocked(permission, permissionString);

        return permissionString;
    };

    const addLockedAndBlocked = (permission: NodePermissionDTO, permissionString: string) => {
        if (permission?.enforce) {
            if (permissionString.length) {
                permissionString += `, ${t(PermissionsTKeys.LockedAccess)}`;
            } else {
                permissionString += `${t(PermissionsTKeys.LockedAccess)}`;
            }
        }
        if (permission?.restrict) {
            permissionString += `, ${t(PermissionsTKeys.BlockedAccess)}`;
        }

        return permissionString;
    };

    const determineIcon = (permission: NodePermissionDTO): IconProp => {
        if (permission?.roleData.roleType == RoleEnum.Group) {
            return 'users';
        }

        switch (permission?.roleData.license) {
            case UserLicenseEnum.Guest:
                return 'user-shield';
            case UserLicenseEnum.Full:
            case UserLicenseEnum.Essentials:
            case UserLicenseEnum.Compliance:
                return 'user-alt';
            default:
                return 'user-alt';
        }
    };

    useEffect(() => {
        if (!!activeNode) {
            setIsAdminOnNode(activeNode.permission.admin);
        }
    }, [activeNode]);

    useEffect(() => {
        if (!!hasAuthUser && !!authUser) {
            const userRoleID = authUser?.userRoles.find((i) => i.accountID == authUser?.accountID && i.roleType != RoleEnum.Group)?.id.toString() as string;
            setUserRoleID(userRoleID);

            const userID = authUser?.userID.toString() as string;
            setUserID(userID);
        }
    }, [hasAuthUser]);

    useEffect(() => {
        if (!!nodeId) {
            loadNodePermissions();
        }
    }, [nodeId, loadNodePermissions]);

    useEffect(() => {
        if (permissions.length) {
            setAllPermissions(permissions);
        }
    }, [permissions]);

    return (
        <>
            <SidesheetPage
                title={t(PermissionsTKeys.Title)}
                onClose={onClose}
                dataId='permissions-sideSheet-pane'
                secondaryButton={
                    <>
                        {isAdminOnNode && (
                            <Button emphasis='low' color='primary' onClick={onViewAllClick}>
                                {t(PermissionsTKeys.ViewAllButton)}
                            </Button>
                        )}
                    </>
                }>
                {!!isLoading ? (
                    <PermissionsSkeletonLoader isAdminOnNode={isAdminOnNode} />
                ) : (
                    <>
                        {isAdminOnNode && (
                            <Button emphasis='med' color='primary' className='my-3 w-50 fs-6 fw-bold' onClick={openGrantAccessModal} >
                                {t(PermissionsTKeys.GrantAccessButton)}
                            </Button>
                        )}
                        <Text size='lg' className='my-2'>
                            {t(PermissionsTKeys.PermissionsAndSharingHeader)}
                        </Text>
                        <NestedCollapse
                            title={t(PermissionsTKeys.YourPermissionsHeader)}
                            tooltipMessage={t(PermissionsTKeys.GrantedToolTip)}
                            className='my-2'
                            openByDefault={true}>
                            {!!myPermission && (
                                <SidesheetDisplayCard
                                    icon={{ icon: determineIcon(myPermission) }}
                                    title={myPermission.roleData.name ?? myPermission?.roleData.userName}
                                    subtitles={[determinePermissionsString(myPermission)]}
                                    subtitleIcon={getInheritanceIcon(myPermission)}
                                    subtitleTooltip={getInheritanceText(myPermission)}
                                />
                            )}
                        </NestedCollapse>

                        {determinePermissionCount(grantedPermissions as PermissionCollection) > 0 && (
                            <NestedCollapse
                                title={t(PermissionsTKeys.PermissionsYouGrantedHeader)}
                                subtitle={`(${determinePermissionCount(grantedPermissions as PermissionCollection)})`}
                                openByDefault={true}>
                                <>
                                    {grantedPermissions.users.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.UsersHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.users.map((permission: NodePermissionDTO) => (
                                                <SidesheetDisplayCard
                                                    key={permission.id}
                                                    icon={{ icon: determineIcon(permission) }}
                                                    title={permission?.roleData.name ?? permission?.roleData.userName}
                                                    subtitles={[determinePermissionsString(permission)]}
                                                    actions={createActions(permission)}
                                                    subtitleIcon={getInheritanceIcon(permission)}
                                                    subtitleTooltip={getInheritanceText(permission)}
                                                />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.groups.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.GroupsHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.groups.map((permission: NodePermissionDTO) => (
                                                <SidesheetDisplayCard
                                                    key={permission.id}
                                                    icon={{ icon: determineIcon(permission) }}
                                                    title={permission?.roleData.name ?? permission?.roleData.userName}
                                                    subtitles={[determinePermissionsString(permission)]}
                                                    actions={createActions(permission)}
                                                    subtitleIcon={getInheritanceIcon(permission)}
                                                    subtitleTooltip={getInheritanceText(permission)}
                                                />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.guests.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.GuestUsersHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.guests.map((permission: NodePermissionDTO) => (
                                                <SidesheetDisplayCard
                                                    key={permission.id}
                                                    icon={{ icon: determineIcon(permission) }}
                                                    title={permission?.roleData.name ?? permission?.roleData.userName}
                                                    subtitles={[determinePermissionsString(permission)]}
                                                    actions={createActions(permission)}
                                                    subtitleIcon={getInheritanceIcon(permission)}
                                                    subtitleTooltip={getInheritanceText(permission)}
                                                />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.accessLinks.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.AccessLinksHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.accessLinks.map((accessLink: NodePermissionDTO) => (
                                                <SidesheetDisplayCard
                                                    key={accessLink.id}
                                                    icon={{ icon: 'link' }}
                                                    title={t(PermissionsTKeys.AccessLinkLabel, {
                                                        date: formatDateTime(accessLink.createdOn),
                                                    })}
                                                    subtitles={[determinePermissionsString(accessLink)]}
                                                    actions={createActions(accessLink, true)}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            </NestedCollapse>
                        )}

                        {isAdminOnNode && determinePermissionCount(otherPermissions as PermissionCollection) > 0 && (
                            <>
                                <Row className='my-3 ms-1 opacity-25 square border border-secondary' />

                                <Text size='lg' className='my-2'>
                                    {t(PermissionsTKeys.AllOtherAccessHeader)}
                                </Text>
                                {otherPermissions.users.length > 0 && (
                                    <NestedCollapse
                                        title={t(PermissionsTKeys.UsersHeader)}
                                        subtitle={`(${otherPermissions.users.length})`}
                                        className='my-2'
                                        openByDefault={true}>
                                        {otherPermissions.users.map((permission: NodePermissionDTO) => (
                                            <SidesheetDisplayCard
                                                key={permission.id}
                                                icon={{ icon: determineIcon(permission) }}
                                                title={permission?.roleData.name ?? permission?.roleData.userName}
                                                subtitles={[determinePermissionsString(permission)]}
                                                actions={createActions(permission)}
                                                subtitleIcon={getInheritanceIcon(permission)}
                                                subtitleTooltip={getInheritanceText(permission)}
                                            />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.groups.length > 0 && (
                                    <NestedCollapse
                                        title={t(PermissionsTKeys.GroupsHeader)}
                                        subtitle={`(${otherPermissions.groups.length})`}
                                        className='my-2'
                                        openByDefault={true}>
                                        {otherPermissions.groups.map((permission: NodePermissionDTO) => (
                                            <SidesheetDisplayCard
                                                key={permission.id}
                                                icon={{ icon: determineIcon(permission) }}
                                                title={permission?.roleData.name ?? permission?.roleData.userName}
                                                subtitles={[determinePermissionsString(permission)]}
                                                actions={createActions(permission)}
                                                subtitleIcon={getInheritanceIcon(permission)}
                                                subtitleTooltip={getInheritanceText(permission)}
                                            />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.guests.length > 0 && (
                                    <NestedCollapse
                                        title={t(PermissionsTKeys.GuestUsersHeader)}
                                        subtitle={`(${otherPermissions?.guests.length})`}
                                        className='my-2'
                                        openByDefault={true}>
                                        {otherPermissions.guests.map((permission: NodePermissionDTO) => (
                                            <SidesheetDisplayCard
                                                key={permission.id}
                                                icon={{ icon: determineIcon(permission) }}
                                                title={permission?.roleData.name ?? permission?.roleData.userName}
                                                subtitles={[determinePermissionsString(permission)]}
                                                actions={createActions(permission)}
                                                subtitleIcon={getInheritanceIcon(permission)}
                                                subtitleTooltip={getInheritanceText(permission)}
                                            />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.accessLinks.length > 0 && (
                                    <NestedCollapse
                                        title={t(PermissionsTKeys.AccessLinksHeader)}
                                        subtitle={`(${otherPermissions.accessLinks.length})`}
                                        className='my-2'
                                        openByDefault={true}>
                                        {otherPermissions.accessLinks.map((accessLink: NodePermissionDTO) => (
                                            <SidesheetDisplayCard
                                                key={accessLink.id}
                                                icon={{ icon: 'link' }}
                                                title={t(PermissionsTKeys.AccessLinkLabel, {
                                                    date: formatDateTime(accessLink.createdOn),
                                                })}
                                                subtitles={[determinePermissionsString(accessLink)]}
                                                actions={createActions(accessLink, true)}
                                            />
                                        ))}
                                    </NestedCollapse>
                                )}
                            </>
                        )}
                    </>
                )}
            </SidesheetPage>
            {isDetailsModalOpen && (
                <PermissionDetailsModal
                    isOpen={isDetailsModalOpen}
                    closeModal={closeDetailsModal}
                    editCallback={loadNodePermissions}
                    activeNode={activeNode}
                />
            )}
            {isEditSingleModalOpen && (
                <EditPermissionModal
                    isOpen={isEditSingleModalOpen}
                    closeModal={closeEditSingleModal}
                    activeNode={activeNode}
                    editCallback={loadNodePermissions}
                />
            )}
            {isRemoveModalOpen && (
                <DeletePermissionsModal
                    isOpen={isRemoveModalOpen}
                    closeModal={closeRemoveModal}
                    individualPermission={true}
                    activeNode={activeNode}
                    successCallback={loadNodePermissions}
                />
            )}
            {isGrantAccessModalOpen && (
                <PermissionGrantAccessModal
                    isOpen={isGrantAccessModalOpen}
                    onClose={closeGrantAccessModal}
                    activeNode={activeNode}
                    grantAccessSuccessCallback={loadNodePermissions} />
            )}
        </>
    );
};
