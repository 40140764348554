import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodePermissionDTO } from './nodePermissionApiTypes';
import { IBatchItem, IBatchItemResponse } from '../sharedApiTypes';

export const useNodePermissionsApi = () => {

    const getPermissionByNodeIdAsync = async (nodeId: string): Promise<NodePermissionDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/NodePermissions/NodeId/${nodeId}`);
        return resp.data;
    };

    const updatePermissionsAsync = async (batchItems: IBatchItem<NodePermissionDTO>[]): Promise<IBatchItemResponse<NodePermissionDTO>[]> => {
        const resp: AxiosResponse = await Api.put('api/NodePermissions/Update', batchItems);
        return resp.data;
    };

    const deletePermissionsAsync = async (batchItems: IBatchItem<number>[]): Promise<IBatchItemResponse<NodePermissionDTO>[]> => {
        const resp: AxiosResponse = await Api.post('api/NodePermissions/Delete', batchItems);
        return resp.data;
    };

    const createPermissionsAsync = async (batchItems: IBatchItem<NodePermissionDTO>[]): Promise<IBatchItemResponse<NodePermissionDTO>[]> => {
        const resp: AxiosResponse = await Api.post('api/NodePermissions/Create', batchItems);
        return resp.data;
    };

    return {
        getPermissionByNodeIdAsync,
        updatePermissionsAsync,
        deletePermissionsAsync,
        createPermissionsAsync,
    };
};
