import React, { useEffect, useState } from 'react';
import { EfcCallToActionInfo, Modal } from '@efilecabinet/efc-atlantis-components';
import { useIcons } from '../../../../../hooks/useIcons';
import { useFileVersionApi } from '../../../../../api/fileVersions/useFileVersionApi';
import { useNodeDetailsContext } from '../../../_context/NodeDetailsContext/NodeDetailsContext';
import { TranslationFiles, useSafeTranslation, FileVersionsTKeys } from '../../../../../hooks/useSafeTranslation';
import { FileVersionDTO } from '../../../../../api/fileVersions/fileVersionApiTypes';

interface ConfirmPrimaryModalProps {
    fileVersion?: FileVersionDTO;
    isPrimaryModalOpen: boolean;
    closePrimaryModal: () => void;
}

export const ConfirmPrimaryModal = ({ fileVersion, isPrimaryModalOpen, closePrimaryModal }: ConfirmPrimaryModalProps) => {
    const { spinnerIconProps } = useIcons();
    const { makePrimary } = useFileVersionApi();
    const { fetchNode, fetchFileVersions } = useNodeDetailsContext();
    const { t } = useSafeTranslation(TranslationFiles.FileVersions);

    const [showSpinner, setShowSpinner] = useState(false);
    const [ctas, setCtas] = useState<EfcCallToActionInfo[]>([]);

    const handleConfirm = async () => {
        if (!fileVersion) return;
        try {
            setShowSpinner(true);
            makePrimary(fileVersion);
            fetchFileVersions(fileVersion.nodeId.toString());
            fetchNode(fileVersion.nodeId.toString());
            closePrimaryModal();
        } catch (error) {
            console.log({ error });
        } finally {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        setCtas([
            {
                text: t(FileVersionsTKeys.CancelAction),
                emphasis: 'med',
                onClick: closePrimaryModal,
                dataId: 'file-version-primary-cancel',
            },
            {
                text: t(FileVersionsTKeys.ConfirmPrimaryAction),
                emphasis: 'high',
                color: 'primary',
                onClick: handleConfirm,
                dataId: 'file-version-primary-confirm',
            },
        ]);
    }, []);

    useEffect(() => {
        const updatedCtas = [...ctas];

        const ctaIndex = updatedCtas.findIndex((cta) => cta.text === t(FileVersionsTKeys.ConfirmPrimaryAction));

        if (ctaIndex !== -1) {
            if (!!showSpinner) {
                updatedCtas[ctaIndex].icon = spinnerIconProps;
                updatedCtas[ctaIndex].disabled = true;
            } else {
                delete updatedCtas[ctaIndex].icon;
                updatedCtas[ctaIndex].disabled = false;
            }

            setCtas(updatedCtas);
        }
    }, [showSpinner]);

    return (
        <Modal isOpen={isPrimaryModalOpen} ctas={ctas} dataId='file-version-primary-modal'>
            <Modal.Header toggle={closePrimaryModal} tag='div' className='px-4 pt-4 pb-0'>
                {t(FileVersionsTKeys.ConfirmPrimaryHeader)}
            </Modal.Header>
            <Modal.Body className='p-4'>{t(FileVersionsTKeys.ConfirmPrimaryBody)}</Modal.Body>
        </Modal>
    );
};
