import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuthContext } from '../../../app/_context/AuthContext';

interface IAuthenticatedRouteProps {
    children?: ReactNode;
    redirectTo: string;
}

export const AuthenticatedRoute = (props: IAuthenticatedRouteProps) => {
    const { children, redirectTo } = props;

    const [authenticated, setAuthenticated] = useState(true);

    const { getIsAuthorized } = useAuthContext();
    const location = useLocation();

    useEffect(() => {
        getIsAuthorized().then((isAuth) => {
            setAuthenticated(isAuth);
        });
    }, []);

    return (
        <>
            {(!!authenticated) ?
                <>{children}</>
                : <Navigate to={redirectTo} replace state={{ from: location }} />
            }
        </>
    );
};