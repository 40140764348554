import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { TextRadioButton, EfcTextRadioButtonInfo, Label } from '@efilecabinet/efc-atlantis-components';
import { MDFieldDropdownListModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { DropdownListMDFieldAdvancedFormats } from '../DropdownListMDFiledAdvancedFormats/DropdownListMDFieldAdvancedFormats';
import { DropdownListFormatTypes, ListMetadataFieldDto, MetadataFieldDto } from '../../../../../../../api/mdFields/mdFieldApiTypes';
import { DropdownListFormatTypeKeyStrings } from '../../../../../MetadataFieldTypes';


export interface DropdownListMDFieldFormatsProps {
    mdField: MetadataFieldDto;
    setMDField: Dispatch<SetStateAction<MetadataFieldDto>>;
}

export const DropdownListMDFieldFormats = (props: DropdownListMDFieldFormatsProps) => {
    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.MDFieldDropdownListModal);

    const getButtonInfos = (): EfcTextRadioButtonInfo[] => {
        let singleChecked = true;
        let multiChecked = false;
        if (mdField.dropdownListProps?.dropdownListFormat) {
            singleChecked = mdField.dropdownListProps?.dropdownListFormat as DropdownListFormatTypes === DropdownListFormatTypes.SingleSelect;
            multiChecked = mdField.dropdownListProps?.dropdownListFormat as DropdownListFormatTypes === DropdownListFormatTypes.MultiSelect;
        }

        return [
            { key: DropdownListFormatTypes[DropdownListFormatTypes.SingleSelect], labelName: t(MDFieldDropdownListModalTKeys.SingleSelect), checked: singleChecked },
            { key: DropdownListFormatTypes[DropdownListFormatTypes.MultiSelect], labelName: t(MDFieldDropdownListModalTKeys.MultiSelect), checked: multiChecked },
        ];
    };

    const onChecked = (button: EfcTextRadioButtonInfo) => {
        setMDField({ ...mdField, dropdownListProps: { ...mdField.dropdownListProps as ListMetadataFieldDto, dropdownListFormat: DropdownListFormatTypes[button.key as DropdownListFormatTypeKeyStrings] } });
    };

    useEffect(() => {
        setMDField({ ...mdField, dropdownListProps: { ...mdField.dropdownListProps, dropdownListFormat: mdField.dropdownListProps?.dropdownListFormat ?? DropdownListFormatTypes.SingleSelect, allowNewOptions: mdField.dropdownListProps?.allowNewOptions ?? false } });
    }, []);

    return (
        <>
            <Row className='my-5'>
                <Col>
                    <Label className='mb-3'>{t(MDFieldDropdownListModalTKeys.SelectFormat)}</Label>
                    <TextRadioButton buttonInfos={getButtonInfos()} onChecked={onChecked} />
                </Col>
            </Row>

            <DropdownListMDFieldAdvancedFormats mdField={mdField} setMDField={setMDField} />
        </>
    );
};