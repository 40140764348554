import { Dispatch, SetStateAction } from "react";
import { AccountFeatureDTO } from "../../../api/accountFeatures/accountFeatureApiTypes";
import { AccountBrandingDTO } from "../../../api/branding/brandingApiTypes";
import { UserFeedbackDTO } from "../../../api/feedback/feedbackApiTypes";
import { OptInInfoDTO } from "../../../api/system/systemApiTypes";
import { AuthAccountDTO, AuthUserDTO, UserDTO } from "../../../api/auth/authApiTypes";

export interface IAuthContext {
    accountFeatures: AccountFeatureDTO[];
    authUser: AuthUserDTO | null;
    hasAuthUser: boolean;
    user: UserDTO | null;
    hasUser: boolean;
    handleLoginWithToken: (token: string) => Promise<void>;
    handleLogout: () => void;
    handleSwitchAccounts: (roleId: number, onAccountSwitch?: () => void) => void;
    getIsAuthorized: () => Promise<boolean>;
    hasCheckedAuthUser: boolean;
    userAccounts: Map<number, AuthAccountDTO> | null;
    hasUserAccounts: boolean;
    openAccountSwitchRequiredModal: (accountId: number, onAccountSwitch: () => void) => void;
    optInInfo: OptInInfoDTO | null;
    groupManagerAccounts: number[];
    loginCount: number;
    previewerXSessionCount: number;
    apiOperationCache: Set<any>;
    setApiOperationCache: React.Dispatch<React.SetStateAction<Set<any>>>;
    experienceType: ExperienceTypeEnum;
    userCanViewDocumentRequests: boolean;
    userCanViewDocuments: boolean;
    showAdminAccountOnboarding: boolean;
    isNewUser: boolean;
    updateCurrentAccountBranding: (newBranding: AccountBrandingDTO) => void;
    loginIframeComplete: boolean;
    userFeedbackInfo: UserFeedbackDTO | null;
    handleNewSideSheetToggle: (optIn: boolean) => Promise<void>;
    atlantisSideSheetEnabled: boolean;
    handleMetadataToggle: (optIn: boolean) => void;
    getMetadataToggleStatus: () => boolean;
    hasSeenNodeSideSheetOnBoardingModalState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export interface UserAccount extends AuthAccountDTO {
    branding?: AccountBrandingDTO;
}

export enum ExperienceTypeEnum {
    Standard,
    Guest,
    Anonymous,
}