export interface IAccountOnboardingModalProps {
    toggleWelcome: () => void;
    showWelcomeModal: boolean;
}

export enum AccountOnboardingSteps {
    ShowWelcome = 0,
    SetName = 1,
    SetBranding = 2,
    ShowReviewView = 3,
}

export interface IAccountOnboardingProps {
    currentStep: AccountOnboardingSteps;
    isShowNewExperience: boolean;
    setCurrentStep: (step: AccountOnboardingSteps) => void;
}

export interface IAccountOnboardingBrandingProps {
    currentStep: AccountOnboardingSteps;
    setCurrentStep: (step: AccountOnboardingSteps) => void;
    setTempHideModal: (toggle: boolean) => void;
}

export interface IAccountOnboardingReviewProps {
    currentStep: AccountOnboardingSteps;
    toggleWelcome: () => void;
    dontShowWelcomeModal: () => void;
}
