import { useNavigate } from 'react-router';
import { FolderTemplateRouteParams, RoutePath } from '../routingTypes';

export const useFolderTemplatesRouting = () => {

    const navigate = useNavigate();

    const getLinkToFolderTemplatesIndex = () => {
        return RoutePath.FolderTemplatesIndex;
    };

    const routeToFolderTemplatesIndex = () => {
        navigate(getLinkToFolderTemplatesIndex());
    };

    const getLinkToFolderTemplateLibrary = (libraryId: string) => {
        return RoutePath.FolderTemplateLibrary
            .replace(FolderTemplateRouteParams.LibraryId, libraryId.toString());
    };

    const routeToFolderTemplateLibrary = (libraryId: string) => {
        navigate(getLinkToFolderTemplateLibrary(libraryId));
    };

    const getLinkToFolderTemplate = (libraryId: string, templateId: string) => {
        return RoutePath.FolderTemplateEdit
            .replace(FolderTemplateRouteParams.LibraryId, libraryId)
            .replace(FolderTemplateRouteParams.TemplateId, templateId);
    };

    const routeToFolderTemplate = (libraryId: string, templateId: string) => {
        navigate(getLinkToFolderTemplate(libraryId, templateId));
    };

    return {
        getLinkToFolderTemplatesIndex,
        getLinkToFolderTemplateLibrary,
        getLinkToFolderTemplate,
        routeToFolderTemplatesIndex,
        routeToFolderTemplateLibrary,
        routeToFolderTemplate,
    };
};
