import { NodeDTO } from "../node/nodeApiTypes";
import { UserLicenseEnum, SystemPermissionsDTO, RoleDTO, SystemPermissionDTO } from "../roles/roleApiTypes";

export interface AuthUserDTO {
    accountID: number;
    userID: number;
    accessToken: string;
    refreshToken?: string;
    expiresIn: number;
    mfaToken?: string;
    licenseType: UserLicenseEnum;
    displayName: string;
    systemPermissionsToAccounts: SystemPermissionsDTO[];
    multipleAccounts: boolean;
    userRoles: RoleDTO[];
    samlConfigId: number;
    eSigProduct: ExternalAuthenticationProviderEnum;
    sessionExpirationMismatch: boolean;
}

export interface UserDTO { // These are user settings, and do not have any settings related to the user session or authorization
    id: number;
    securitySettings: UserSecuritySettingsDTO;
    preferences: UserPreferencesDTO;
    emailSettings: EmailSettingDTO[];
    customPreviewerStamps: NodeDTO[];
    userName: string;
    sessionExpire: number;
}

export interface UserSecuritySettingsDTO {
    oldPassword: string;
    newPassword: string;
    mfaIsRequired: boolean;
    mfaType: MfaTypeEnum;
    mfaRequiredDays?: number;
    mobilePhoneNumber: string;
    mobilePhoneCountryCode: string;
}

export interface UserPreferencesDTO {
    activeEmailSettingID?: number;
    doubleClickAction: number;
    alwaysConfirmAppend: boolean;
    deleteAppendedNode: boolean;
    defaultCollectionID?: number;
    timeZoneId: string;
    useStoreArea: boolean;
    searchDefaultByName: boolean;
    searchDefaultByProfileValue: boolean;
    searchDefaultByCreatedByName: boolean;
    searchDefaultByComment: boolean;
    searchDefaultByFullText: boolean;
    searchDefaultByPath: boolean;
    searchDefaultUseContainsWordsIn: boolean;
    skipSSOFullUserPrompt: boolean;
}

export interface EmailSettingDTO {
    id: number;
    accountID?: number;
    emailAddress: string;
    serverAddress: string;
    serverPort: number;
    userName: string;
    password: string;
    enableSSL: boolean;
    settingType: EmailSettingTypeEnum;
    isAccountSetting: boolean;
    failureCount: number;
    lastFailureMessage: string;
    inFailure: boolean;
    authType: EmailAuthTypeEnum;
    externalAuthorizationID?: number;
    externalAuthProviderType: ExternalAuthenticationProviderEnum;
    expiration: number;
    authToken: string;
}

export interface AuthAccountDTO {
    userID: number;
    roleID: number;
    accountID: number;
    accountName: string;
    licenseType: UserLicenseEnum;
    accessToken: string;
    systemPermissions: SystemPermissionDTO[];
    identifier: string;
}

export interface SelectAuthAccountsDTO {
    authAccount: AuthAccountDTO;
    authAccounts: AuthAccountDTO[];
}

export interface IsAuthenticatedResponseDTO {
    isAuthenticated: boolean;
}

export enum MfaTypeEnum {
    App = 0,
    Email = 1,
    Either = 2,
}

export enum EmailAuthTypeEnum {
    UsernameAndPassword = 0,
    OAuth = 1,
}

export enum ExternalAuthenticationProviderEnum {
    GoogleDrive = 1,
    OneDrive = 2,
    Docusign = 3,
    Gmail = 5,
    Outlook = 6,
}

export enum EmailSettingTypeEnum {
    SMTP = 0,
    IMAP = 1,
}