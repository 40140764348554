import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSystemApi } from '../../../api/system/useSystemApi';
import { useUserApi } from '../../../api/user/useUserApi';
import { RoutePath, useRouting } from '../../../features/_routing';
import { AuthContext } from '.';
import { useSettingsAndFeatures } from '../SettingFeaturesContext/SettingsFeaturesContext';
import { useUtopiaRedirect } from '../../../hooks/useUtopiaRedirect';
import { usePreviewerXFeedback } from '../../../hooks/usePreviewerXFeedback';
import { IAtlantisHtmlDocument } from '../../../IAtlantisHtmlDocument';
import { AccountSwitchRequiredModal } from '../../../components/AccountsModal';
import { ExperienceTypeEnum, IAuthContext, UserAccount } from './AuthContextTypes';
import { useAuthApi } from '../../../api/auth/useAuthApi';
import { useAppInsights } from '../../../features/_routing/_hooks/useAppInsights';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { AccountFeatureDTO, AccountFeatureEnum } from '../../../api/accountFeatures/accountFeatureApiTypes';
import { useAccountFeatureApi } from '../../../api/accountFeatures/useAccountFeatureApi';
import { AccountBrandingDTO } from '../../../api/branding/brandingApiTypes';
import { DocumentRequestDTO } from '../../../api/documentRequests/documentRequestApiTypes';
import { useDocumentRequestsApi } from '../../../api/documentRequests/useDocumentRequestApi';
import { UserFeedbackDTO } from '../../../api/feedback/feedbackApiTypes';
import { useFeedbackApi } from '../../../api/feedback/useFeedbackApi';
import { useNodeApi } from '../../../api/node/useNodeApi';
import { SystemPermissionEnum, RoleEnum, UserLicenseEnum } from '../../../api/roles/roleApiTypes';
import { OptInInfoDTO } from '../../../api/system/systemApiTypes';
import { IsGroupManagerResponseDTO } from '../../../api/user/userApiTypes';
import { FeatureFlags } from '../SettingFeaturesContext/settingsAndFeaturesTypes';
import { useUtilities } from '../../../hooks/useUtilities';
import { AuthAccountDTO, AuthUserDTO, SelectAuthAccountsDTO, UserDTO } from '../../../api/auth/authApiTypes';

interface LocationState {
    from: { pathname: string; };
}

interface AuthProviderProps {
    children: ReactNode;
}

interface OpenAccountsModalParams {
    switchToAccountId?: number;
    onAccountSwitch: () => void;
}

declare let clientInterop: any;

export const AuthProvider = (props: AuthProviderProps) => {
    const { children } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as LocationState;
    const [queryParameters] = useSearchParams();

    const [firstLoad, setFirstLoad] = useState(true);

    const [authUser, setAuthUser] = useState<AuthUserDTO | null>(null);
    const [hasAuthUser, setHasAuthUser] = useState<boolean>(false);
    const [hasCheckedAuthUser, setHasCheckedAuthUser] = useState<boolean>(false);
    const [experienceType, setExperienceType] = useState<ExperienceTypeEnum>(ExperienceTypeEnum.Standard);
    const [userCanViewDocumentRequests, setUserCanViewDocumentRequests] = useState<boolean>(true);
    const [userCanViewDocuments, setUserCanViewDocuments] = useState<boolean>(true);

    const [showAdminAccountOnboarding, setShowAdminAccountOnboarding] = useState<boolean>(false);

    const [user, setUser] = useState<UserDTO | null>(null);
    const [hasUser, setHasUser] = useState<boolean>(false);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);

    const [userAccounts, setUserAccounts] = useState<Map<number, UserAccount> | null>(null);
    const [hasUserAccounts, setHasUserAccounts] = useState<boolean>(false);

    const [accountFeatures, setAccountFeatures] = useState<AccountFeatureDTO[]>([]);

    const [groupManagerAccounts, setGroupManagerAccounts] = useState<number[]>([]);

    const [openAccountsModalParams, setOpenAccountsModalParams] = useState<OpenAccountsModalParams | null>(null);

    const [optInInfo, setOptInInfo] = useState<OptInInfoDTO | null>(null);
    const [hasSeenNodeSideSheetOnBoardingModal, setHasSeenNodeSideSheetOnBoardingModal] = useState<boolean>(false);

    const [userFeedbackInfo, setUserFeedbackInfo] = useState<UserFeedbackDTO | null>(null);
    const [atlantisSideSheetEnabled, setAtlantisSideSheetEnabled] = useState<boolean>(false);

    const [authSrc, setAuthSrc] = useState<string>();

    const iframeRef: any = useRef(null);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [loginIframeStarted, setLoginIframeStarted] = useState(false);
    const [loginIframeComplete, setLoginIFrameComplete] = useState(false);

    const [loginCount, setLoginCount] = useState(0);
    const [apiOperationCache, setApiOperationCache] = useState(new Set<any>());

    const auth = useAuthApi();
    const system = useSystemApi();
    const userSessionStorage = useSessionStorage();
    const { getUserFeedbackInfo } = useFeedbackApi();
    const { getAccountFeatures } = useAccountFeatureApi();
    const { getIsGroupManager, toggleNewSideSheet } = useUserApi();
    const { settings, hasSettings, hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();
    const { doNotRedirectToRoute, routeToNode, routeToHome, routeToSearchFilterItemResults, routeToDocuments } = useRouting();
    const { redirectUtopiaUrlToAtlantis } = useUtopiaRedirect();
    const { getUserDocumentRequests } = useDocumentRequestsApi();
    const { previewerXSessionCount, updatePreviewerXSessionCount } = usePreviewerXFeedback();
    const { getHasSomeNodeAccess, getSharedWithAnonymous } = useNodeApi();
    const { trackEvent } = useAppInsights();
    const { areObjectsEqual } = useUtilities();

    let authTimer: NodeJS.Timer | null = null;

    const openAccountSwitchRequiredModal = (switchToAccountId: number, onAccountSwitch: () => void) => {
        setOpenAccountsModalParams({ switchToAccountId, onAccountSwitch });
    };

    const closeAccountsModal = () => {
        setOpenAccountsModalParams(null);
    };

    function handleClientInteropRequests(apiOperation: any) {
        switch (apiOperation.operationName) {
            case 'navigateToNode':
                routeToNode(apiOperation.nodeID);
                break;
            case 'performSearch':
                routeToSearchFilterItemResults(apiOperation.filterItem);
                break;
            case 'addPendingFiles':
                if (!apiOperation.triggedByOnAppLoaded || document.location?.pathname?.startsWith(RoutePath.Documents) || document.location?.pathname?.startsWith(RoutePath.Search) || document.location?.pathname?.startsWith(RoutePath.Login) || document.location?.pathname == RoutePath.Home) {
                    handleDefaultClientInteropRequest(apiOperation);
                }
                break;
            case 'REPORT_UTOPIA_ENDPOINT': // handled in desktop app
            case 'clientOnHostConnectionLost': // handled in desktop app
            case 'utopia-serverSystemLogs': // handled in IFrameComponent
            case 'utopia-clientSystemLogs': // handled in IFrameComponent
                /* should only be handled by desktop app or another listener in Atlantis Front End, not forwarded to Utopia iframe */
                break;
            default:
                handleDefaultClientInteropRequest(apiOperation);
                break;
        }
    }

    function handleDefaultClientInteropRequest(apiOperation: any) {
        setApiOperationCache((oldApiOperationCache) => {
            const newApiOperationCache = new Set(oldApiOperationCache);
            newApiOperationCache.add(apiOperation);
            return newApiOperationCache;
        });
        if (isIFrameLoginComplete() && !document.location.pathname.startsWith(RoutePath.Documents) && !document.location.pathname.startsWith(RoutePath.Search)) {
            routeToDocuments();
        }
    }

    const handleWindowMessageEvent = (e: any) => {
        try {
            if (!(typeof e.data === 'string' || e.data instanceof String) && e.data.operationName) {
                handleClientInteropRequests(e.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLoginWithToken = async (token: string) => {
        const authUser = (await auth.loginWithToken(token).catch((err) => {
            console.error(err);
        })) as AuthUserDTO;

        if (!!authUser?.accessToken) {
            setAuthUser(authUser);
            setHasAuthUser(true);
            setHasCheckedAuthUser(true);

            const token = authUser.accessToken;
            userSessionStorage.setAccessToken(token);

            captureLoginCount(authUser.userID);
            updatePreviewerXSessionCount(authUser.userID);

            getOtherAuthData();

            setLoginIframeStarted(true);

            await getOptInInfo(authUser.userID);
            await getFeedbackInfo(authUser.userID);
        }
    };

    const captureLoginCount = (userID: number) => {
        const countLoginStored = localStorage.getItem(`login-count-${userID}`) ?? '0';

        const loginTotal = +countLoginStored + 1;

        setLoginCount(loginTotal);

        localStorage.setItem(`login-count-${userID}`, `${loginTotal}`);
    };

    const loginToIFrame = async () => {
        const baseUrl = await system.getRubexBaseUrl();

        const oneTimeToken = await auth.getOneTimeToken();

        const redirect = encodeURI('/userSettings/settings');
        const src = `${baseUrl}/#/auth/oneTimeToken/${oneTimeToken}?redirect=${redirect}&hideChrome=true`;

        setIframeLoaded(false);

        setAuthSrc(src);

        setLoginIframeStarted(true);
    };

    const getOtherAuthData = () => {
        const isSystemCall = true;
        auth.getCurrentUser(isSystemCall).then((currUser: UserDTO) => {
            if (user == null || currUser == null || !areObjectsEqual(user, currUser, ['sessionExpire'])) {
                setUser(currUser);
            }
            setHasUser(true);
        });

        auth.getUserAccounts().then((accounts) => {
            const accts = new Map<number, AuthAccountDTO>();
            if (accounts.authAccounts != undefined && accounts.authAccounts.length > 0) {
                (accounts as SelectAuthAccountsDTO).authAccounts.map((acct) => accts.set(acct.accountID, acct));
            } else if (accounts.authAccount != undefined) {
                accts.set(accounts.authAccount.accountID, accounts.authAccount);
            }

            setUserAccounts(accts);
            setHasUserAccounts(true);
        });

        getAccountFeatures().then((accountFeatures: AccountFeatureDTO[]) => {
            setAccountFeatures(accountFeatures);
        });

        getIsGroupManager().then((response: IsGroupManagerResponseDTO) => {
            setGroupManagerAccounts(response.accountsWhereManager ?? []);
        });
    };

    const getOptInInfo = async (userId: number) => {
        const optInInfo: OptInInfoDTO = await system.getOptInInfo(userId);
        setOptInInfo(optInInfo);
    };

    const getFeedbackInfo = async (userId: number) => {
        const feedbackInfo: UserFeedbackDTO = await getUserFeedbackInfo(userId);
        setUserFeedbackInfo(feedbackInfo);
    };

    const handleAccountOnboarding = (accountFeatures: AccountFeatureDTO[]) => {
        if (!authUser) {
            setShowAdminAccountOnboarding(false);
        }

        const currAccountPermissions = authUser?.systemPermissionsToAccounts.find((x) => x.accountID === authUser.accountID);
        const validPermissions = currAccountPermissions?.permissionsList.filter((x) => x.systemPermissionType === SystemPermissionEnum.UserManagement || x.systemPermissionType === SystemPermissionEnum.SystemSettings);
        const brandingAccountFeature = accountFeatures.find((x) => x.featureType == AccountFeatureEnum.Branding);
        const isAccountOnboarding = !!hasSettings && !!hasFeatureFlags && checkFeatureFlag(FeatureFlags.AccountOnboarding);

        if (validPermissions?.length === 2 && !!brandingAccountFeature && isAccountOnboarding) {
            setShowAdminAccountOnboarding(true);
        } else {
            setShowAdminAccountOnboarding(false);
        }
    };

    const handleLogout = async () => {
        const err = new Error();
        const stack = err.stack?.split('\n').map(function (line) {
            return line.trim();
        });

        trackEvent('Atlantis Logout Event', {
            LogType: 'Logout Event',
            UserID: authUser?.userID,
            AccountID: authUser?.accountID,
            ExpiresIn: authUser?.expiresIn,
            DisplayName: authUser?.displayName,
            SamlConfigId: authUser?.samlConfigId,
            StackTrace: stack?.splice(1), // Removing the 'Error' line in the Stacktrace
        });

        if (!!authTimer) {
            clearInterval(authTimer);
        }

        setAuthUser(null);
        setHasAuthUser(false);
        setHasCheckedAuthUser(false);

        if (await isAuthenticatedAsync()) {
            await auth.logout();
        }

        userSessionStorage.removeAccessToken();

        navigate(RoutePath.Login, { state: { from: location } });
    };

    const isAuthenticatedAsync = async () => {
        return !!userSessionStorage.getAccessToken() && !!(await auth.getIsAuthenticatedAsync()).isAuthenticated;
    };

    const handleSwitchAccounts = async (roleId: number, onAccountSwitch?: () => void) => {
        await auth.switchToAccount(roleId);
        if (!!onAccountSwitch) {
            onAccountSwitch();
        }
        window.location.reload();
    };

    const getIsAuthorized = async (): Promise<boolean> => {
        const token = userSessionStorage.getAccessToken();

        if (!!token) {
            const theAuthUser = await auth.getAuthenticatedUser();

            if (!!theAuthUser) {
                setAuthUser(theAuthUser as AuthUserDTO);
                setHasAuthUser(true);
                setHasCheckedAuthUser(true);

                getOtherAuthData();

                setLoginIframeStarted(true);

                await getOptInInfo((theAuthUser as AuthUserDTO).userID);
                await getFeedbackInfo((theAuthUser as AuthUserDTO).userID);

                return true;
            } else {
                handleLogout();

                return false;
            }
        }

        setHasCheckedAuthUser(true);
        return false;
    };

    const updateCurrentAccountBranding = (newBranding: AccountBrandingDTO) => {
        if (!!authUser?.accountID) {
            const currentAccountId = userAccounts?.get(authUser?.accountID)?.accountID;

            if (!!currentAccountId) {
                setUserAccounts((currentAccounts) => {
                    currentAccounts?.forEach((account, accountId) => {
                        if (accountId === currentAccountId) {
                            account.branding = newBranding;
                        }
                    });

                    return currentAccounts;
                });
            }
        }
    };

    const onIFrameLoginComplete = () => {
        authTimer = setInterval(getIsAuthorized, 60000);

        const goTo = queryParameters.get('goTo');
        const redirect = queryParameters.get('redirect');

        if (!!goTo) {
            if (doNotRedirectToRoute(goTo)) {
                routeToHome();
            } else {
                navigate(goTo);
            }
        } else if (!!redirect) {
            routeToHome();
            redirectUtopiaUrlToAtlantis(redirect, authContext);
        } else if (!!apiOperationCache.size) {
            routeToDocuments();
        } else {
            if (window.location.pathname.startsWith(RoutePath.Login)) {
                const origin = locationState?.from?.pathname || '/';
                navigate(origin);
            }
        }
    };

    const isIFrameLoginComplete = (): boolean => {
        return iframeLoaded && loginIframeStarted && hasCheckedAuthUser;
    };

    const checkCanUserViewDocumentRequests = () => {
        const docRequestQueryParams = {
            start: 0,
            count: 1,
            orderBy: 0,
        };
        getUserDocumentRequests(docRequestQueryParams)
            .then((documentRequest: DocumentRequestDTO[]) => {
                if (documentRequest.length > 0) {
                    setUserCanViewDocumentRequests(true);
                }
            })
            .catch(() => {
                setUserCanViewDocumentRequests(false);
            });
    };

    const checkCanAnonymousUserViewDocuments = () => {
        getSharedWithAnonymous(0, 1).then((children) => {
            if (children.length > 0) {
                setUserCanViewDocuments(true);
            }
        });
    };

    const isGuestUser = (): boolean => {
        return !!authUser && (authUser?.userRoles ?? []).length > 0 && (authUser?.userRoles ?? []).filter((i) => i.roleType == RoleEnum.User).some((o) => o.accountID == authUser?.accountID && o.userID == authUser.userID && o.license == UserLicenseEnum.Guest);
    };

    const isAnonymousUser = (): boolean => {
        return !!authUser && (authUser?.userRoles ?? []).length > 0 && (authUser?.userRoles ?? []).filter((i) => i.roleType == RoleEnum.User).some((o) => o.accountID == authUser?.accountID && o.userID == authUser.userID && o.anonymous == true);
    };

    const handleIfIFrameLoadedEvent = (windowMessage: any): void => {
        if (windowMessage?.data == 'clientOnAppLoaded' && iframeRef?.current?.contentWindow == windowMessage?.source) {
            setTimeout(() => {
                setIframeLoaded(true);
            }, settings?.utopiaAuthIframeLoadDelayInMs ?? 500);
        }
    };

    async function handleNewSideSheetToggle(optIn: boolean) {
        if (!!authUser) {
            const userId = authUser.userID;
            try {
                await toggleNewSideSheet(userId, optIn);
                await getOptInInfo(userId);
            } catch (error) {
                console.error('Error toggling side sheet', error);
            }
        }
    }

    function getMetadataEnabledFromLocalStorage(): boolean {
        switch (localStorage.getItem(`optInMetadata_userId_${authUser?.userID}`)) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return false;
        }
    }

    function handleMetadataToggle(optIn: boolean) {
        if (!!authUser) {
            try {
                localStorage.setItem(`optInMetadata_userId_${authUser.userID}`, optIn.toString());
            } catch (error) {
                console.error('Error toggling metadata', error);
            }
        }
    }

    function getMetadataToggleStatus() {
        if (!!authUser) {
            return getMetadataEnabledFromLocalStorage();
        }
        return false;
    }


    useEffect(() => {
        if (!!loginIframeStarted) {
            loginToIFrame();
        }
    }, [loginIframeStarted]);

    useEffect(() => {
        window.addEventListener('message', handleIfIFrameLoadedEvent);
        return () => {
            window.removeEventListener('message', handleIfIFrameLoadedEvent);
        };
    }, []);

    useEffect(() => {
        if (isIFrameLoginComplete()) {
            setLoginIFrameComplete(true);
            onIFrameLoginComplete();
        }
    }, [iframeLoaded, loginIframeStarted, hasCheckedAuthUser]);

    useEffect(() => {
        if (isAnonymousUser()) {
            setExperienceType(ExperienceTypeEnum.Anonymous);
            setUserCanViewDocumentRequests(false);
            setUserCanViewDocuments(false);
            checkCanUserViewDocumentRequests();
            checkCanAnonymousUserViewDocuments();
        } else if (isGuestUser()) {
            setExperienceType(ExperienceTypeEnum.Guest);
            setUserCanViewDocumentRequests(false);
            setUserCanViewDocuments(false);
            checkCanUserViewDocumentRequests();
            getHasSomeNodeAccess(authUser?.accountID ?? 0).then((response) => setUserCanViewDocuments(response.hasSomeNodeAccess));
        }
    }, [hasCheckedAuthUser]);

    useEffect(() => {
        if (!!authUser) {
            (document as IAtlantisHtmlDocument).appInsights?.setAuthenticatedUserContext(authUser.userID.toString(), authUser.accountID.toString());
        }

        if (typeof clientInterop !== 'undefined') {
            if (!!authUser) {
                if (firstLoad) {
                    setFirstLoad(false);
                    clientInterop.onSuccessfulLoginFn(
                        JSON.stringify({
                            utopia_url: settings?.utopiaUrl,
                            access_token: authUser?.accessToken,
                            refresh_token: authUser?.refreshToken,
                            expires_in: authUser?.expiresIn,
                        })
                    );
                }
                window.addEventListener('message', handleWindowMessageEvent);
                window.parent.postMessage('clientOnAppLoaded', '*');
            } else {
                setFirstLoad(true);
                clientInterop.onLogOut();
            }
        }

        return () => {
            window.removeEventListener('message', handleWindowMessageEvent);
        };
    }, [authUser]);

    useEffect(() => {
        if (!!accountFeatures) {
            handleAccountOnboarding(accountFeatures);
        }
    }, [accountFeatures]);

    useEffect(() => {
        if (!!hasFeatureFlags && !!optInInfo) {
            if (optInInfo.optInAtlantisSideSheet || checkFeatureFlag(FeatureFlags.SideSheetEnabled)) {
                setAtlantisSideSheetEnabled(true);
            } else {
                setAtlantisSideSheetEnabled(false);
            }
        }
    }, [hasFeatureFlags, optInInfo]);

    const authContext: IAuthContext = {
        accountFeatures,
        hasAuthUser,
        authUser,
        hasUser,
        user,
        handleLoginWithToken,
        handleLogout,
        handleSwitchAccounts,
        getIsAuthorized,
        userAccounts,
        hasUserAccounts,
        openAccountSwitchRequiredModal,
        optInInfo,
        groupManagerAccounts,
        loginCount,
        previewerXSessionCount,
        apiOperationCache,
        setApiOperationCache,
        hasCheckedAuthUser,
        experienceType,
        userCanViewDocumentRequests,
        userCanViewDocuments,
        updateCurrentAccountBranding,
        loginIframeComplete,
        userFeedbackInfo,
        showAdminAccountOnboarding,
        isNewUser,
        handleNewSideSheetToggle,
        atlantisSideSheetEnabled,
        handleMetadataToggle,
        getMetadataToggleStatus,
        hasSeenNodeSideSheetOnBoardingModalState: [hasSeenNodeSideSheetOnBoardingModal, setHasSeenNodeSideSheetOnBoardingModal],
    };

    return (
        <AuthContext.Provider value={authContext}>
            <>
                <iframe ref={iframeRef} src={authSrc} width='10%' height='10%' title='hidden' hidden />
                {children}
                <AccountSwitchRequiredModal isOpen={!!openAccountsModalParams} closeModal={closeAccountsModal} switchToAccountId={openAccountsModalParams?.switchToAccountId} onAccountSwitch={openAccountsModalParams?.onAccountSwitch as () => void} />
            </>
        </AuthContext.Provider>
    );
};
