import { useAccessLinksApi } from '../api/accessLinks/useAccessLinksApi';
import { NodePermissionDTO } from '../api/nodePermissions/nodePermissionApiTypes';

export const useAccessLinks = () => {
    const { getAccessLinksByNodeIdAsync } = useAccessLinksApi();

    const getNodeAccessLinksAsync = async (accountId: string, nodeId: string) => {
        return await getAccessLinksByNodeIdAsync(accountId, nodeId);
    };

    const filterNodeAccessLinksByUserId = (permissions: NodePermissionDTO[], createdByUserId: string) => {
        return permissions.filter((permission) => permission.createdByUserID?.toString() === createdByUserId && permission.roleData.anonymous);
    };

    const filterOtherNodeAccessLinksByUserId = (permissions: NodePermissionDTO[], createdByUserId: string) => {
        return permissions.filter((permission) => permission.createdByUserID?.toString() !== createdByUserId && permission.roleData.anonymous);
    };

    return {
        getNodeAccessLinksAsync,
        filterNodeAccessLinksByUserId,
        filterOtherNodeAccessLinksByUserId,
    };
};
