import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useRecycleBinRouting = () => {

    const navigate = useNavigate();

    const getLinkToRecycleBin = () => {
        return RoutePath.RecycleBin;
    };

    const routeToRecycleBin = () => {
        navigate(getLinkToRecycleBin());
    };

    return {
        getLinkToRecycleBin,
        routeToRecycleBin,
    };
};
