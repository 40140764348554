import React, { useEffect, useState } from 'react';
import { EfcCallToActionInfo, Form, FormGroup, Modal } from '@efilecabinet/efc-atlantis-components';
import { IUseModalProps } from '../../../../hooks/useModal';
import { useESignatureTransactionApi } from '../../../../api/eSignatureTransactions/useESignatureTransactionApi';
import { TranslationFiles, useSafeTranslation, ESignatureModalTKeys } from '../../../../hooks/useSafeTranslation';
import { UtopiaESignatureTaskDTO } from '../../../../api/notifications/notificationApiTypes';
import { ESignatureExternalTypeEnum } from '../../../../api/eSignatureTransactions/eSignatureTransactionApiTypes';

interface ESignatureTaskModalProps extends IUseModalProps {
    utopiaESignatureTask: UtopiaESignatureTaskDTO;
}

export const ESignatureTaskModal = (props: ESignatureTaskModalProps) => {
    const { isOpen, close, utopiaESignatureTask } = props;

    const [emailWasResent, setEmailWasResent] = useState<boolean>(false);

    const { t } = useSafeTranslation(TranslationFiles.ESignatureModal);
    const { resendEmailAsync } = useESignatureTransactionApi();

    const externalType = utopiaESignatureTask.externalType;
    const transactionId = utopiaESignatureTask.transactionId;

    const handleClose = () => {
        !!close && close();
    };

    const reSendEmail = () => {
        setEmailWasResent(true);
        resendEmailAsync(transactionId);
    };

    const getCtas = () => {
        const ctas: EfcCallToActionInfo[] = [{ text: t(ESignatureModalTKeys.CtaText), emphasis: 'high', color: 'primary', onClick: handleClose }];
        if (!emailWasResent) {
            ctas.unshift({ text: t(ESignatureModalTKeys.ResendCtaText), emphasis: 'med', color: 'primary', onClick: reSendEmail });
        }
        return ctas;
    };

    const externalTypeEmail: string = externalType == ESignatureExternalTypeEnum.Docusign ? 'dse@docusign.com' : 'noreply@signixmail.com';
    const externalTypeName: string = externalType == ESignatureExternalTypeEnum.Docusign ? t(ESignatureModalTKeys.NameDocuSign) : t(ESignatureModalTKeys.NameSignix);
    const externalTypeTitle: string = !emailWasResent ? (externalType == ESignatureExternalTypeEnum.Docusign ? t(ESignatureModalTKeys.TitleDocuSign) : t(ESignatureModalTKeys.TitleSignix)) : t(ESignatureModalTKeys.TitleEmailResent);
    const externalTypeSenderName: string = externalType == ESignatureExternalTypeEnum.Docusign ? t(ESignatureModalTKeys.SenderNameDocuSign) : t(ESignatureModalTKeys.SenderNameSignix);
    const reSendEmailCopy: string = externalType == ESignatureExternalTypeEnum.Docusign ? t(ESignatureModalTKeys.EmailCopyDocuSign) : t(ESignatureModalTKeys.EmailCopySignix);

    useEffect(() => {
        if (isOpen) {
            setEmailWasResent(false);
        }
    }, [isOpen]);

    return (
        <Modal title={externalTypeTitle} toggle={close} isOpen={isOpen} ctas={getCtas()} unmountOnClose={true}>
            <Modal.Body>
                {!emailWasResent ? (
                    <Form>
                        <FormGroup>
                            <p>
                                {t(ESignatureModalTKeys.ModalTextLine1, { externalTypeName: externalTypeName })}
                                <br />
                                {t(ESignatureModalTKeys.ModalTextLine2, { externalTypeSenderName: externalTypeSenderName, externalTypeEmail: externalTypeEmail, reSendEmailCopy: reSendEmailCopy })}
                            </p>
                        </FormGroup>
                    </Form>
                ) : (
                    <Form>
                        <FormGroup>
                            <p>{t(ESignatureModalTKeys.ModalTextLine3)}</p>
                        </FormGroup>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};
