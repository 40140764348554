import React, { ReactNode, useState } from 'react';
import { useAtlantisTours } from '../../../hooks/useAtlantisTours';
import { useToastMessages } from '../../../hooks/useToastMessages';
import { usePreviewerXFeedback } from '../../../hooks/usePreviewerXFeedback';
import { ILayerContext, LayerContext } from './LayerContext';
import { useModalManagement } from '../../../hooks/useModalManagement';

interface LayerProviderProps {
    children: ReactNode;
}

export const LayerProvider = (props: LayerProviderProps) => {
    const { children } = props;

    const { displayedToasts, showToastMessage, closeToastMessage } = useToastMessages();
    const { toggleTour, isTourOpen, isTourMenuOpen, toggleTourMenu } = useAtlantisTours();
    const { docViewCount, updateDocViewCount } = usePreviewerXFeedback();

    const { modals, openModal } = useModalManagement();

    const contextObject: ILayerContext = {
        toastMessages: displayedToasts,
        showToastMessage: showToastMessage,
        closeToastMessage: closeToastMessage,

        isTourMenuOpen: isTourMenuOpen,
        toggleTourMenu: toggleTourMenu,

        isTourOpen: isTourOpen,
        toggleTour: toggleTour,

        previewerXDocViewCount: docViewCount,
        updatePreviewerXDocViewCount: updateDocViewCount,

        openModal: openModal,
    };

    return (
        <LayerContext.Provider value={contextObject}>
            <>
                {children}
                {modals.map((modalData, index) => (
                    <React.Fragment key={index}>
                        {modalData.modal}
                    </React.Fragment>))}
            </>
        </LayerContext.Provider>);
};
