import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { DocumentRequestDTO } from './documentRequestApiTypes';

export const useDocumentRequestsApi = () => {

    const getUserDocumentRequests = async (query: { start?: number, count?: number, orderBy?: number, accountId?: number}): Promise<DocumentRequestDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/documentRequest?start=${query.start}&count=${query.count}&orderBy=${query.orderBy}`);
        return resp.data as DocumentRequestDTO[];
    };

    const queryTemplates = async (query: { start?: number, count?: number, orderBy?: number, accountId?: number}): Promise<DocumentRequestDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/documentRequest/Template?start=${query.start}&count=${query.count}&orderBy=${query.orderBy}`);
        return resp.data as DocumentRequestDTO[];
    };

    const deleteDocumentRequest = async (id: string) => {
        await Api.delete(`api/documentRequest/${id}`);
    };

    return {
        getUserDocumentRequests,
        queryTemplates,
        deleteDocumentRequest,
    };
};
