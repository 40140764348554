import React from 'react';
import { AllFeaturesPanel } from '../../components/AllFeatureGroups/AllFeaturesPanel';

interface AllFeaturesSideNavFlyoutProps {
    closeTray: boolean;
    onClick?: () => void; // set by SideNav.Link if closeTray is true
}

export const AllFeaturesSlideout = (props: AllFeaturesSideNavFlyoutProps) => {
    return <AllFeaturesPanel collapseAllFeatures={() => !!props.onClick && props.onClick()}/>;
};