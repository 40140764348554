import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, SearchInput, Table } from '@efilecabinet/efc-atlantis-components';
import { PermissionDetailsTableRow } from './PermissionDetailsTableRow';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { PermissionDetailsTableHeader } from './PermissionDetailsTableHeader';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useSystemPermissionsValidator } from '../../../../../hooks/useSystemPermissionsValidators';
import { SystemPermissionEnum } from '../../../../../api/roles/roleApiTypes';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { NodePermissionLevelEnum } from '../../../../../hooks/nodePermissions/nodePermissionTypes';
import { NodeDTO } from '../../../../../api/node/nodeApiTypes';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';
import './PermissionDetailsTable.css';

interface PermissionDetailsTableProps {
    itemType: string;
    isEditMode: boolean;
    isContainer: boolean;
    editCallback?: () => void;
    activeNode: NodeDTO | undefined;
    onGrantAccess?: () => void;
}

export const PermissionDetailsTable = (props: PermissionDetailsTableProps) => {
    const { itemType, isEditMode, isContainer, editCallback, activeNode, onGrantAccess } = props;

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { permissions, editedPermissions, setEditedPermissions } = useNodePermissionsContext();
    const { updateNodePermissionLevel } = useNodePermissionsUtilities();
    const { hasAuthUser, authUser } = useAuthContext();
    const { hasPermissionOnAccount } = useSystemPermissionsValidator();

    const [hasItemPermissionsControl, setHasItemPermissionsControl] = useState<boolean>(false);

    const filteredPermissions = isEditMode ? editedPermissions : permissions;

    function onEdit() {
        !!editCallback && editCallback();
    }

    function onCheckboxClick(
        e: ChangeEvent<HTMLInputElement>,
        permissionId: number,
        permissionLvl: NodePermissionLevelEnum,
        prevPermissionLvl?: NodePermissionLevelEnum
    ) {
        setEditedPermissions((prevState) =>
            prevState.map((p) => {
                if (!e.target.checked && prevPermissionLvl !== undefined) {
                    permissionLvl = prevPermissionLvl;
                }
                return p.id === permissionId ? { ...updateNodePermissionLevel(p, permissionLvl) } : p;
            })
        );
    }

    const onDeleteClick = (permissionId: number) => {
        setEditedPermissions((prevState) =>
            prevState.map((p) =>
                p.id === permissionId
                    ? {
                          ...updateNodePermissionLevel(p, NodePermissionLevelEnum.Remove),
                      }
                    : p
            )
        );
    };

    useEffect(() => {
        if (!!hasAuthUser && !!authUser?.accountID) {
            setHasItemPermissionsControl(hasPermissionOnAccount(authUser?.accountID, SystemPermissionEnum.ItemPermissionsControl));
        }
    }, [hasAuthUser]);

    return (
        <>
            <Row className='mb-4'>
                <Col sm='7' lg='4' className='order-2 order-sm-1'>
                    <SearchInput />
                </Col>
                {hasItemPermissionsControl && !isEditMode && (
                    <Col sm='5' lg='8' className='d-flex justify-content-end order-1 order-sm-2 mb-3 mb-sm-0'>
                        <Button
                            emphasis='med'
                            icon={{ icon: 'edit' }}
                            onClick={onEdit}
                            dataId='permissions-details-edit'>
                            {t(PermissionsTKeys.EditButton)}
                        </Button>
                        <Button
                            emphasis='high'
                            onClick={onGrantAccess}
                            className='ms-2'
                            dataId='permissions-details-grant-access'>
                            {t(PermissionsTKeys.GrantAccessButton)}
                        </Button>
                    </Col>
                )}
            </Row>

            <div className='table-responsive'>
                <Table className='table-nowrap'>
                    <thead>
                        <PermissionDetailsTableHeader itemType={itemType} isContainer={isContainer} />
                    </thead>
                    <tbody>
                        {filteredPermissions
                            .filter((permission) => !permission.remove)
                            .map((permission: NodePermissionDTO) => (
                                <PermissionDetailsTableRow
                                    key={permission.id}
                                    permission={permission}
                                    isEditMode={isEditMode}
                                    onCheckboxClick={onCheckboxClick}
                                    onDeleteClick={onDeleteClick}
                                    activeNode={activeNode}
                                />
                            ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
