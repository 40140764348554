import { FormFillDefinitionDTO } from "../forms/formApiTypes";
import { NodeDTO } from "../node/nodeApiTypes";
import { RoleDTO } from "../roles/roleApiTypes";

export interface DocumentRequestDTO {
    id: string;
    accountId: number;
    createdByUserId: number;
    createdByUserName: string;
    createdOn: string;
    subject: string;
    message: string;
    daysTillExpiration?: number;
    calculatedExpirationDate?: string;
    notifyOnCompletion: boolean;
    completed: boolean;
    isTemplate: boolean;
    recipientRoles: RoleDTO[];
    destinationNode: NodeDTO;
    attachmentNodes: NodeDTO[];
    documentRequestContainers: DocumentRequestContainerDTO[];
    createAccessLinks: boolean;
    isAnonymous: boolean;
    accessLinkToken?: string;
    expires?: string;
}

export interface DocumentRequestContainerDTO {
    id: number;
    documentRequestID: string;
    containerName: string;
    isMultiple: boolean;
    required: boolean;
    rename: boolean;
    fileCountMinimum: number;
    fileCountMaximum: number;
    files: NodeDTO[];
    uploadedFileCount: number;
    order: number;
    formFillDefinition: FormFillDefinitionDTO;
    formFillFileInfoId?: number;
    formFillNode: NodeDTO;
    accountId: number;
}

export enum DocumentRequestType {
    NotFound = 0,
    Regular = 1,
    UploadLink = 2
}