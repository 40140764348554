export interface FilePasswordOperationDTO {
    nodeID: number;
    currentPassword: string;
    newPassword?: string;
    operation: FilePasswordOperationEnum;
}

export enum FilePasswordOperationEnum {
    CheckPassword = 0,
    ApplyPassword = 1,
    UpdatePassword = 2,
    DeletePassword = 3,
}

export interface FilePasswordOperationResponseDTO {
    result: FilePasswordOperationResultEnum;
    errorMessage: string;
}

export enum FilePasswordOperationResultEnum
{
    Completed = 0,
    Failed = 1,
    ValidPassword = 2,
    InvalidPassword = 3,
}