import React from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { ListSelectorItem } from '../ListSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import './Chip.css';

export interface ChipProps {
    item: ListSelectorItem;
    removeItem: (item: ListSelectorItem) => void;
}

export const Chip = (props: ChipProps) => {
    const TRUNCATE_MAX_WIDTH = 16;

    const { item, removeItem } = props;

    const truncateString = (str: string) => {
        if (str.length > TRUNCATE_MAX_WIDTH) {
            return str.substring(0, TRUNCATE_MAX_WIDTH) + '...';
        } else {
            return str;
        }
    };

    const getChipText = (str: string) => {
        return truncateString(str);
    };

    return (
        <div className="chip-wrapper" >
            {item.icon && <FontAwesomeIcon icon={item.icon as IconName} className="chip-icon" />}
            <Text size='md'>{getChipText(item.name)}</Text>
            <FontAwesomeIcon icon="xmark" className="chip-icon chip-close-icon" onClick={() => { removeItem(item); }} />
        </div>
    );
};