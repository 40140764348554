import React, { ReactNode } from 'react';
import { Button, Card, Text } from '@efilecabinet/efc-atlantis-components';

interface SidesheetPageProps {
    title: string;
    secondaryButton?: ReactNode;
    hideSecondaryButton?: boolean;
    onClose?: () => void;
    children?: ReactNode;
    dataId?: string;
    hidden?: boolean;
    contentNeedsFullWidth?: boolean;
}
export const SidesheetPage = (props: SidesheetPageProps) => {

    const { title, secondaryButton, onClose, children, dataId, hidden, contentNeedsFullWidth, hideSecondaryButton = false } = props;

    const getCardStyling = () => {
        let styling = 'h-100';
        if (!!secondaryButton) {
            styling += ' sidesheet-with-secondary-button-alternate-padding';
        }
        if (!!contentNeedsFullWidth) {
            styling += ' sidesheet-content-full-width';
        }
        return styling;
    };

    const getTitleStyling = () => {
        let styling = 'd-flex justify-content-between align-items-center sidesheet-tab-title';
        if (!!contentNeedsFullWidth) {
            styling += ' sidesheet-content-full-width-title-only';
        }
        return styling;
    };

    return (
        <>
            <Card body fluid borderless className={getCardStyling()} dataId={dataId} hidden={hidden}>
                <Card.Title className={getTitleStyling()} >
                    <Text className='fw-bold' tag='h5'>{title}</Text>

                    {!!secondaryButton && !hideSecondaryButton && (
                        <div className='sidesheet-secondary-button'>
                            {secondaryButton}
                        </div>
                    )}
                    {!!onClose &&
                        <Button onClick={onClose} close dataId='close-sideSheet-btn' className='sidesheet-close-button' />
                    }
                </Card.Title>

                {children}
            </Card>
        </>
    );
};