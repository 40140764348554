import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { CheckedOutFileDTO } from './checkOutApiTypes';

export const useCheckedOutApi = () => {

    const getAccountCheckedOut = async (accountId: number): Promise<CheckedOutFileDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/checkedOut/account/${accountId}`);
        return resp.data as CheckedOutFileDTO[];
    };

    const getMyCheckedOut = async (): Promise<CheckedOutFileDTO[]> => {
        const resp: AxiosResponse = await Api.get('api/checkedOut');
        return resp.data as CheckedOutFileDTO[];
    };

    const forceCheckIn = async (nodeIds: string[]): Promise<void> => {        
        await Api.post('api/checkedOut/forceCheckIn', nodeIds);
    };

    return {
        getAccountCheckedOut,
        getMyCheckedOut,
        forceCheckIn,
    };
};
