import { AccountFeatureDTO, AccountFeatureEnum } from '../api/accountFeatures/accountFeatureApiTypes';
import { useAuthContext } from '../app/_context/AuthContext';
import { ExternalAuthenticationProviderEnum } from '../api/auth/authApiTypes';

export const useAccountFeatureValidator = () => {

    const { accountFeatures, authUser } = useAuthContext();

    const accountFeaturesByAccountLookup = createAccountFeaturesLookup();

    function createAccountFeaturesLookup(): Map<number, Map<AccountFeatureEnum, AccountFeatureDTO>> {
        const accountFeaturesLookup = new Map<number, Map<AccountFeatureEnum, AccountFeatureDTO>>();
        accountFeatures.forEach(i => {
            if (!accountFeaturesLookup.has(i.utopiaAccountId)) {
                accountFeaturesLookup.set(i.utopiaAccountId, new Map<AccountFeatureEnum, AccountFeatureDTO>());
            }
            accountFeaturesLookup.get(i.utopiaAccountId)?.set(i.featureType, i);
        });
        return accountFeaturesLookup;
    }

    const accountFeaturesHaveLoaded = (): boolean => {
        return accountFeatures?.some(() => true) ?? false;
    };

    const hasFeatureOnAccount = (accountId: number, ...accountFeatureTypes: AccountFeatureEnum[]): boolean => {
        return accountFeatureTypes.every(i => accountFeaturesByAccountLookup.get(accountId)?.has(i) ?? false);
    };

    const hasFeatureOnSomeAccount = (...accountFeatureTypes: AccountFeatureEnum[]): boolean => {
        return accountFeatureTypes.every(i => Array.from(accountFeaturesByAccountLookup.values()).some(features => features.has(i)));
    };

    const isAuthenticatedToDocusign = () => {
        return authUser?.eSigProduct == ExternalAuthenticationProviderEnum.Docusign.valueOf();
    };

    const isESignatureFeatureAvailable = (accountId: number) => {
        return hasFeatureOnAccount(accountId, AccountFeatureEnum.ESignature) || isAuthenticatedToDocusign();
    };

    return {
        accountFeaturesHaveLoaded,
        hasFeatureOnAccount,
        hasFeatureOnSomeAccount,
        isESignatureFeatureAvailable,
    };
};