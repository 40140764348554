import React from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, UploadLinkTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import ShareableLink from '../../../../components/ShareableLink/ShareableLink';

interface CreatedUploadLinkProps {
    nodeName?: string;
    uploadLinkURL: string;
}

export const CreatedUploadLink = ({ nodeName, uploadLinkURL }: CreatedUploadLinkProps) => {
    const { t } = useSafeTranslation(TranslationFiles.UploadLinkModal);
    return (
        <>
            <ShareableLink link={uploadLinkURL} label={t(UploadLinkTKeys.LinkLabel, { nodeName })} />
            <Text className='d-block mt-4 mb-75r'>{t(UploadLinkTKeys.CreatedDescription)}</Text>
        </>
    );
};
