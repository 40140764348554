import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { NodeType, Text } from '@efilecabinet/efc-atlantis-components';
import { useColors } from '../../../../../hooks/useColors';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { NodeDTO } from '../../../../../api/node/nodeApiTypes';
import { useFileUtilities } from '../../../../../hooks/useFileUtilities';
import { NodeIcon } from '../../../../Icons/NodeIcon/NodeIcon';

interface PermissionDetailsModalHeaderProps {
    isEditMode: boolean;
    activeNode: NodeDTO | undefined;
}

export const PermissionDetailsModalHeader = ({ isEditMode, activeNode }: PermissionDetailsModalHeaderProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { atlantisPrimary, atlantisDefault } = useColors();
    const { getFileExtension } = useFileUtilities();

    function getHeaderText() {
        return isEditMode ? t(PermissionsTKeys.EditDetailsModalHeader) : t(PermissionsTKeys.ViewDetailsModalHeader);
    }

    const style = activeNode?.systemType === NodeType.Cabinet ? 'cabinet-dark-node-icon' : activeNode?.systemType === NodeType.Drawer ? 'drawer-dark-node-icon' : '';

    return (
        <>
            {activeNode && (
                <Row>
                    <Col className='d-flex align-items-center'>
                        {isEditMode && <FontAwesomeIcon icon={['far', 'edit']} color={atlantisPrimary} className='me-2' />}
                        <Text semibold>{getHeaderText()}</Text>
                        <NodeIcon nodeType={activeNode.systemType} fileExtension={getFileExtension(activeNode.name)} customIconClassName={style} fontAwesomeColor={atlantisDefault} />
                        <Text semibold>{activeNode.name}</Text>
                    </Col>
                </Row>
            )}
        </>
    );
};
