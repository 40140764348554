import React, { useEffect, useRef, useState } from 'react';
import { EfcCallToActionInfo, EfcLink, Input, Modal, Text } from '@efilecabinet/efc-atlantis-components';
import { useIcons } from '../../../hooks/useIcons';
import { BrandingImageEditor } from '../_subComponents/BrandingImageEditor/BrandingImageEditor';
import { Status } from '../../../hooks/useStatusMessage';
import { ThemeEnum } from '../../../hooks/useColors';
import { useSafeTranslation, TranslationFiles, BrandingImageModalTKeys } from '../../../hooks/useSafeTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BrandingImageModal.css';

interface BrandingImageEditProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    base64Image: string;
    setBase64Image: (logo: string) => void;
    clearFile: boolean;
    setClearFile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BrandingImageModal = (props: BrandingImageEditProps) => {
    const { isOpen, setIsOpen, base64Image, setBase64Image, clearFile, setClearFile } = props;

    const { t } = useSafeTranslation(TranslationFiles.BrandingImageModal);
    const { spinnerIconProps } = useIcons();

    const [file, setFile] = useState<File | null>(null);
    const [url, setUrl] = useState<string | null>(base64Image ?? null);
    const [saved, setIsSaved] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [ctas, setCtas] = useState<EfcCallToActionInfo[]>([]);

    const [statusMessage, setStatusMessage] = useState('');
    const [statusColor, setStatusColor] = useState(ThemeEnum.Success);
    const [statusKey, setStatusKey] = useState(0);

    const setStatus = (status: string, color: ThemeEnum) => {
        setStatusMessage(status);
        setStatusColor(color);
        setStatusKey(prevKey => prevKey + 1);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const MAX_SIZE = 5; // 5 MB
    const MAX_SIZE_IN_BYTES = MAX_SIZE * 1024 * 1024; // 5 MB in bytes

    const captureFileUrl = (newFile: File) => {
        if (!newFile.type.startsWith('image/')) {
            setStatus(t(BrandingImageModalTKeys.OnlyImageFiles), ThemeEnum.Danger);
        } else if (newFile.size > MAX_SIZE_IN_BYTES) {
            setStatus(t(BrandingImageModalTKeys.FileSizeExceedsLimit), ThemeEnum.Danger);
        } else {
            setStatus('', ThemeEnum.Success);
            const newUrl = URL.createObjectURL(newFile);
            setFile(newFile);
            setUrl(newUrl);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            captureFileUrl(event.target.files[0]);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files) as File[];

        captureFileUrl(files[0]);
    };

    const handleSaveImage = () => {
        setShowSpinner(true);
        setIsSaved(true);
    };

    const getTitle = () => {
        if (!!url) {
            return t(BrandingImageModalTKeys.SizeYourLogo);
        }

        return t(BrandingImageModalTKeys.UploadYourLogo);
    };

    const getMessage = () => {
        if (!!url) {
            return t(BrandingImageModalTKeys.SizeYourLogoMessage);
        }

        return t(BrandingImageModalTKeys.UploadYourLogoMessage);
    };

    const cancelClicked = () => {
        if (!base64Image) {
            setClearFile(true);
        }

        setIsOpen(false);
    };

    useEffect(() => {
        setCtas([
            { text: t(BrandingImageModalTKeys.Cancel), color: 'primary', emphasis: 'med', onClick: cancelClicked },
            { text: t(BrandingImageModalTKeys.SaveImage), color: 'primary', emphasis: 'high', disabled: !file && !url, onClick: handleSaveImage },
        ]);
    }, []);

    useEffect(() => {
        const updatedCtas = [...ctas];

        const ctaIndex = updatedCtas.findIndex((cta) => cta.text === t(BrandingImageModalTKeys.SaveImage));

        if (ctaIndex !== -1) {
            if (!!showSpinner) {
                updatedCtas[ctaIndex].icon = spinnerIconProps;
            } else {
                delete updatedCtas[ctaIndex].icon;
            }

            setCtas(updatedCtas);
        }
    }, [showSpinner]);

    useEffect(() => {
        const updatedCtas = [...ctas];

        const ctaIndex = updatedCtas.findIndex((cta) => cta.text === t(BrandingImageModalTKeys.SaveImage));

        if (ctaIndex !== -1) {
            if (!file) {
                updatedCtas[ctaIndex].disabled = true;
            } else {
                updatedCtas[ctaIndex].disabled = false;
            }

            setCtas(updatedCtas);
        }
    }, [file]);

    useEffect(() => {
        if (!isOpen) {
            setIsSaved(false);
        } else if (!!clearFile) {
            setFile(null);
            setUrl(null);
            setClearFile(false);
        }
    }, [isOpen]);

    return (
        <Modal title={getTitle()} isOpen={isOpen} toggle={cancelClicked} ctas={ctas} size='lg' backdrop='static' className='branding-image-modal'>
            <div className='px-3 branding-image-modal-content'>
                <Text semibold>{getMessage()}</Text>
                {!url && (
                    <>
                        <div className='file-input-area mt-4 mb-2' onDragOver={handleDragOver} onDrop={handleDrop}>
                            <div className='file-input-container'>
                                <div className='file-icon-container mx-auto'>
                                    <FontAwesomeIcon icon={['fal', 'file']} size='5x' />
                                </div>
                                <div className='mt-3 text-center px-2'>
                                    <Text semibold>
                                        <EfcLink color='primary' onClick={() => inputRef.current?.click()}>
                                            {t(BrandingImageModalTKeys.ClickToUpload)}
                                        </EfcLink>
                                        {t(BrandingImageModalTKeys.OrDragAndDrop)}
                                    </Text>
                                    <Text className='d-block mt-3' size='sm'>
                                        {t(BrandingImageModalTKeys.SizeTips)} <br />
                                        {t(BrandingImageModalTKeys.MaxFileSize, { limit: MAX_SIZE })}
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <Status className='logo-file-status' status={statusMessage} color={statusColor} key={statusKey} permanent={true} />
                    </>
                )}
                {!!url && <BrandingImageEditor url={url} saved={saved} setBase64Image={setBase64Image} setShowSpinner={setShowSpinner} />}
            </div>

            <Input type='file' accept='image/*' onChange={handleChange} hidden innerRef={inputRef} />
        </Modal>
    );
};
