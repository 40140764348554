import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useWorkYouCareRouting = () => {

    const navigate = useNavigate();

    const getLinkToWorkYouCareAbout = () => {
        return RoutePath.WorkYouCareAbout;
    };

    const routeToWorkYouCareAbout = () => {
        navigate(getLinkToWorkYouCareAbout());
    };

    return {
        getLinkToWorkYouCareAbout,
        routeToWorkYouCareAbout,
    };
};
