import React, { ReactNode } from 'react';
import { Col } from 'reactstrap';

interface IZeroStateProps {
    imageColContent: ReactNode;
    textColContent: ReactNode;
    stackColumns: boolean;
    maxImgWidth?: number;
    maxTextWidth?: number | 'auto';
    style?: React.CSSProperties;
}

export const ZeroState = (props: IZeroStateProps) => {
    const { imageColContent, textColContent, stackColumns, maxImgWidth = 125, maxTextWidth = 'auto', style } = props;
    const getOrientationClassName = () => {
        const className = 'd-flex align-items-center justify-content-center';
        return stackColumns ? className + ' flex-column' : className;
    };

    const getTextAlignmentClassName = () => {
        const className = 'px-3 py-3';
        return stackColumns ? className + ' text-center' : className;
    };

    const maxImgWidthStyle = {
        maxWidth: maxImgWidth,
    };

    const maxTextWidthStyle = {
        maxWidth: maxTextWidth,
    };

    return (
        <div className={getOrientationClassName()} style={style}>
            <Col style={maxImgWidthStyle}>
                {imageColContent}
            </Col>
            <Col className={getTextAlignmentClassName()} style={maxTextWidthStyle}>
                {textColContent}
            </Col>
        </div>
    );
};