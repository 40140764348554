import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { IsGroupManagerResponseDTO } from './userApiTypes';

export const useUserApi = () => {

    const getIsGroupManager = async (): Promise<IsGroupManagerResponseDTO> => {
        const resp: AxiosResponse = await Api.get('api/user/IsGroupManager');
        return resp.data;
    };

    const toggleNewSideSheet = async (userId: number, value: boolean): Promise<void> => {
        await Api.put(`api/user/${userId}/toggleNewSideSheet`, { optInSideSheet: value });
    };

    const getHasSeenNodeSideSheetOnBoardingModal = async (userId: number): Promise<boolean> => {
        const resp: AxiosResponse = await Api.get(`api/user/${userId}/nodeSideSheetOnBoardingInfo`);
        return resp.data;
    };

    const updateHasSeenNodeSideSheetOnBoardingModal = async (userId: number): Promise<void> => {
        await Api.put(`api/user/${userId}/nodeSideSheetOnBoardingInfo`);
    };

    return {
        getIsGroupManager,
        toggleNewSideSheet,
        getHasSeenNodeSideSheetOnBoardingModal,
        updateHasSeenNodeSideSheetOnBoardingModal,
    };
};
