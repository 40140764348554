import React, { FC } from 'react';
import { Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';

interface MDDisplayPropertyProps {
    label: string;
    value: string[];
}

export const MDDisplayProperty: FC<MDDisplayPropertyProps> = ({ label, value }) => {
    return (
        <Row>
            <Row className='pb-2'>
                <Text color='#8E8E8E' size='md' muted>{label}</Text>
            </Row>
            {value.map((val, index) => (
                <Row key={index} className='pb-2'>
                    <Text className='ms-3 fw-bold'>{val}</Text>
                </Row>
            ))}
        </Row>
    );
};