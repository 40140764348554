import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useSystemLogsRouting = () => {

    const navigate = useNavigate();

    const getLinkToSystemLogsServer = () => {
        return RoutePath.SystemLogsServer;
    };

    const routeToSystemLogsServer = () => {
        navigate(getLinkToSystemLogsServer());
    };

    const getLinkToSystemLogsClient = () => {
        return RoutePath.SystemLogsClient;
    };

    const routeToSystemLogsClient = () => {
        navigate(getLinkToSystemLogsClient());
    };

    return {
        getLinkToSystemLogsServer,
        routeToSystemLogsServer,
        getLinkToSystemLogsClient,
        routeToSystemLogsClient,
    };
};