import React from 'react';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface SideSheetTabButtonProps {
    isDisabled?: boolean;
    icon: FontAwesomeIconProps;
    isActive?: boolean;
    onClick?: () => void;
    dataId?: string;
}

export const SideSheetTabButton = (props: SideSheetTabButtonProps) => {
    const { isDisabled = false, icon, isActive = false, onClick, dataId } = props;

    return <Button disabled={isDisabled} emphasis='low' icon={{ ...icon, fixedWidth: true, size: 'lg', className: `tab-icon me-1 ${!!isActive ? 'active' : ''}` }} className='tab-button' active={isActive} onClick={onClick} dataId={dataId} />;
};
