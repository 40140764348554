import React, { useState, useEffect } from 'react';
import { Modal } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, WelcomeModalTKeys, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useBootstrapBreakpoints } from '../../../../hooks/bootstrap/useBootstrapBreakpoints';
import { ScreenSize } from '../../../../hooks/bootstrap/bootstrapTypes';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { useOptInApi } from '../../../../api/optIn/useOptInApi';
import { useSettingsAndFeatures } from '../../../../app/_context/SettingFeaturesContext/SettingsFeaturesContext';
import { IAccountOnboardingModalProps, AccountOnboardingSteps } from '../AccountOnboardingTypes';
import { AccountOnboardingName } from '../AccountOnboardingModalComponents/AccountOnboardingName';
import { AdminExistingFirstTimeUserWelcomeModal } from '../AccountOnboardingModalComponents/AdminFirstTimeToAtlantisWelcome';
import { AccountOnboardingBranding } from '../AccountOnboardingModalComponents/AccountOnboardingBranding';
import { AccountOnboardingReview } from '../AccountOnboardingModalComponents/AccountOnboardingReview';
import { FeatureFlags } from '../../../../app/_context/SettingFeaturesContext/settingsAndFeaturesTypes';
import './AdminNewExperienceWelcomeModal.css';

export const AdminNewExperienceWelcomeModal = (props: IAccountOnboardingModalProps) => {
    const { toggleWelcome, showWelcomeModal } = props;

    const { optInInfo, authUser } = useAuthContext();
    const { t } = useSafeTranslation(TranslationFiles.WelcomeModal);
    const { screenSize } = useBootstrapBreakpoints();
    const { hideWelcomeModal } = useOptInApi();

    const { hasSettings, hasFeatureFlags, checkFeatureFlag } = useSettingsAndFeatures();

    const [isShowNewExperience, setIsShowNewExperience] = useState<boolean | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState<AccountOnboardingSteps | undefined>(undefined);
    const [tempHideModal, setTempHideModal] = useState<boolean>(false);

    const determineIsShowNewExperience = () => {
        const isPublicOptOut = !!hasSettings && !!hasFeatureFlags && checkFeatureFlag(FeatureFlags.PublicOptOut);
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

        if (isPublicOptOut && (optInInfo?.optOutAtlantisDateTime == undefined || threeMonthsAgo < optInInfo?.optOutAtlantisDateTime)) {
            return true;
        }

        return false;
    };

    const dontShowWelcomeModal = async () => {
        if (authUser?.userID !== undefined) {
            await hideWelcomeModal(authUser.userID);
        }
    };

    const getModalHeaderText = () => {
        return isShowNewExperience ? t(WelcomeModalTKeys.AdminFirstTimeUserAccountOnboardingTitle) : t(WelcomeModalTKeys.ExistingUserAccountOnboardingTitle);
    };

    const onModalHeaderButtonClick = () => {
        dontShowWelcomeModal();
        toggleWelcome();
    };

    const getModalHeaderButton = () => {
        return currentStep === AccountOnboardingSteps.ShowReviewView ? <button type='button' className={'btn-close close-button'} aria-label='Close' onClick={onModalHeaderButtonClick}></button> : null;
    };

    useEffect(() => {
        if (isShowNewExperience === undefined) {
            setIsShowNewExperience(determineIsShowNewExperience());
        }

        if (isShowNewExperience !== undefined) {
            if (currentStep === undefined) {
                const countLoginStored = localStorage.getItem(`admin-welcome-seen-${authUser?.userID}`) ?? 'false';
                if (countLoginStored === 'false') {
                    setCurrentStep(!!isShowNewExperience ? AccountOnboardingSteps.ShowWelcome : AccountOnboardingSteps.SetName);
                } else {
                    setCurrentStep(AccountOnboardingSteps.ShowReviewView);
                }
            } else if (currentStep === AccountOnboardingSteps.ShowReviewView) {
                localStorage.setItem(`admin-welcome-seen-${authUser?.userID}`, 'true');
            }
        }
    }, [currentStep, isShowNewExperience]);

    return (
        <Modal size='lg' isOpen={showWelcomeModal} backdrop='static' className={`${screenSize < ScreenSize.s ? 'new-experience-mobile-modal-spacing' : ''} ${tempHideModal ? 'temp-hide-modal' : ''} hello`}>
            <Modal.Header close={getModalHeaderButton()} className='admin-welcome-modal-header m-4 mb-3 p-0'>
                {getModalHeaderText()}
            </Modal.Header>
            <Modal.Body className='m-4 mt-0 ml-2'>
                {isShowNewExperience && <AdminExistingFirstTimeUserWelcomeModal currentStep={currentStep as AccountOnboardingSteps} setCurrentStep={setCurrentStep} />}
                <AccountOnboardingName currentStep={currentStep as AccountOnboardingSteps} setCurrentStep={setCurrentStep} isShowNewExperience={!!isShowNewExperience} />
                <AccountOnboardingBranding currentStep={currentStep as AccountOnboardingSteps} setTempHideModal={setTempHideModal} setCurrentStep={setCurrentStep} />
                <AccountOnboardingReview currentStep={currentStep as AccountOnboardingSteps} toggleWelcome={toggleWelcome} dontShowWelcomeModal={dontShowWelcomeModal} />
            </Modal.Body>
        </Modal>
    );
};
