import { useNavigate } from 'react-router';
import { DocumentRouteParams, RoutePath } from '../routingTypes';

export const useDocumentsRouting = () => {

    const navigate = useNavigate();

    // Documents Index

    const getLinkToDocuments = () => {
        return RoutePath.Documents;
    };

    const routeToDocuments = (isIFrameSrcChange?: boolean) => {
        navigate(getLinkToDocuments(), { state: { isIFrameSrcChange: isIFrameSrcChange } });
    };

    // Folder View Tab

    const getLinkToNode = (nodeIds: string[]) => {
        return RoutePath.Node.replace(DocumentRouteParams.NodeIds, nodeIds.toString());
    };

    const routeToNode = (nodeIds: string[]) => {
        navigate(getLinkToNode(nodeIds));
    };

    const getLinkToNodeDetail = (nodeIds: string[], selectedDetail: string) => {
        return RoutePath.NodeDetail
            .replace(DocumentRouteParams.NodeIds, nodeIds.toString())
            .replace(DocumentRouteParams.SelectedDetail, selectedDetail);
    };

    const routeToNodeDetail = (nodeIds: string[], selectedDetail: string) => {
        navigate(getLinkToNodeDetail(nodeIds, selectedDetail));
    };

    const getLinkToNodeContainer = (containerNodeId: string, nodeIds: string[], selectedDetail: string) => {
        return RoutePath.NodeDetail
            .replace(DocumentRouteParams.ContainerNodeId, containerNodeId)
            .replace(DocumentRouteParams.NodeIds, nodeIds.toString())
            .replace(DocumentRouteParams.SelectedDetail, selectedDetail);
    };

    const routeToNodeContainer = (containerNodeId: string, nodeIds: string[], selectedDetail: string, isIFrameSrcChange?: boolean) => {
        navigate(getLinkToNodeContainer(containerNodeId, nodeIds, selectedDetail), { state: { isIFrameSrcChange: isIFrameSrcChange } });
    };

    // Collections Tab

    const getLinkToCollections = () => {
        return RoutePath.Collections;
    };

    const routeToCollections = () => {
        navigate(getLinkToCollections());
    };

    const getLinkToCollection = (collectionId: string) => {
        return RoutePath.Collection.replace(DocumentRouteParams.CollectionId, collectionId);
    };
    
    const routeToCollection = (collectionId: string, isIFrameSrcChange?: boolean) => {
        navigate(getLinkToCollection(collectionId), { state: { isIFrameSrcChange: isIFrameSrcChange } });
    };

    const getLinkToCollectionNodeDetail = (collectionId: string, nodeIds: string[], selectedDetail?: string) => {
        return RoutePath.CollectionNodeDetail
            .replace(DocumentRouteParams.CollectionId, collectionId)
            .replace(DocumentRouteParams.NodeIds, nodeIds.toString())
            .replace(DocumentRouteParams.SelectedDetail, selectedDetail ?? '');
    };

    const routeToCollectionNodeDetail = (collectionId: string, nodeIds: string[], selectedDetail?: string) => {
        navigate(getLinkToCollectionNodeDetail(collectionId, nodeIds, selectedDetail));
    };

    return {
        getLinkToDocuments,
        getLinkToNode,
        getLinkToNodeDetail,
        getLinkToNodeContainer,
        getLinkToCollections,
        getLinkToCollection,
        getLinkToCollectionNodeDetail,
        routeToDocuments,
        routeToNode,
        routeToNodeDetail,
        routeToNodeContainer,
        routeToCollections,
        routeToCollection,
        routeToCollectionNodeDetail,
    };
};