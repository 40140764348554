import React from 'react';
import { CallToAction, Text } from '@efilecabinet/efc-atlantis-components';
import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNodeSidesheetUtilities } from '../useNodeSidesheetUtilities';
import './SidesheetOnboardingModal.css';
import './SidesheetOnboardingModalSm.css';

interface SidesheetOnboardingModalSmProps {
    isOpen: boolean;
    destroyModal: () => void;
}

export const SidesheetOnboardingModalSm = ({ isOpen, destroyModal }: SidesheetOnboardingModalSmProps) => {

    const { goToSidesheetHelpArticle } = useNodeSidesheetUtilities();

    // TODO: The small modal is properly centered on small screens, but when the mobile breakpoint is hit, it appears off-center.
    // Dark Mode has plans to rework the node sidesheet mobile view, so this will be addressed then.
    return (
        <Modal
            isOpen={isOpen}
            toggle={destroyModal}
            className='sidesheet-onboarding-modal-wrapper'
            contentClassName={'sidesheet-onboarding-modal sidesheet-onboarding-modal-sm'}>

            <div className='modal-header sidesheet-onboarding-modal-header'>
                <div className='w-100 d-flex justify-content-end'>
                    <FontAwesomeIcon icon='close' color='white' onClick={destroyModal} type='button' data-bs-dismiss='modal' aria-label='Close' />
                </div>
            </div>

            <div className='modal-body sidesheet-onboarding-modal-sm-body d-flex align-items-end justify-content-end'>
                <div className='m-1 p-4 pointer' onClick={goToSidesheetHelpArticle}>
                    <div>
                        <Text className='me-1' size='lg'  color='white' >
                            Learn more about these
                        </Text>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Text className='me-1' size='lg' color='white' >
                            improvements
                        </Text>
                        <FontAwesomeIcon icon='arrow-up-right-from-square' color='white' />
                    </div>
                </div>
            </div>

            <div className='modal-footer sidesheet-onboarding-modal-footer d-flex align-items-end justify-content-end'>
                <CallToAction text='Close' color='primaryDark' emphasis='med' onClick={destroyModal} />
            </div>

        </Modal>
    );
};
