import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Text } from '@efilecabinet/efc-atlantis-components';
import { ActionIcon } from '../../../../ActionIcon/ActionIcon';
import { ConfirmationDeleteModal } from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { NotesTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useLayerContext } from '../../../../../app/_context/LayerContext/LayerContext';
import { useNodeDetailsContext } from '../../../_context/NodeDetailsContext/NodeDetailsContext';
import { useNodeNotesApi } from '../../../../../api/nodeNotes/useNodeNotesApi';
import { NodeNoteDTO } from '../../../../../api/nodeNotes/nodeNoteApiTypes';

interface NoteProps {
    note: NodeNoteDTO;
    onNoteDelete: (noteId: number) => void;
    onNoteEdit: (note: NodeNoteDTO) => void;
}

export const Note: React.FC<NoteProps> = ({ note, onNoteDelete, onNoteEdit }: NoteProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Notes);

    const { authUser } = useAuthContext();
    const { activeNode } = useNodeDetailsContext();
    const { openModal } = useLayerContext();
    const { updateNodeNoteAsync, deleteNodeNoteAsync } = useNodeNotesApi();

    const [isEditing, setIsEditing] = useState(false);
    const [noteText, setNoteText] = useState(note.text);
    const noteTextRef = useRef<HTMLDivElement>(null);

    const authUserId = authUser?.userID ?? -1;

    const getDisplayDate = (dateString: string) => {
        return dayjs(dateString).format('DD MMM YYYY h:mm A');
    };

    const submitMessageIfEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            save();
        }
    };

    const deleteNote = (note: NodeNoteDTO) => {
        openModal((closeModalFn) => <ConfirmationDeleteModal note={note} closeModalFn={closeModalFn} onClose={onConfirmDeleteClose} />);
    };

    const onConfirmDeleteClose = (isConfirmed: boolean, noteId: number) => {
        if (isConfirmed) {
            onNoteDelete(noteId);
            deleteNodeNoteAsync(noteId);
        }
    };

    const save = async () => {
        setNoteText(noteTextRef.current?.innerText ?? '');

        onNoteEdit(await updateNodeNoteAsync({ ...note, text: noteTextRef.current?.innerText ?? '' }));
        setIsEditing(false);
    };

    return (
        <div key={note.id} className={'my-3' + (authUserId === note.createdByUserID ? ' note-card-self' : ' note-card-other')}>
            <div className='mb-2'>
                <div className='mb-2'>
                    <Text bold={true}>{note.createdBy}</Text>
                </div>
                <div className='d-flex align-items-center'>
                    <Text color='#696969'>{getDisplayDate(note?.createdOn as string)} {(note.lastModifiedOn !== note.createdOn) && '(edited)'}</Text>
                    <div className='mx-2 d-flex align-items-center'>
                        {authUserId === note.createdByUserID &&
                            <ActionIcon icon='pen-to-square' color='#7514BD' callbackFn={() => setIsEditing(true)} />
                        }
                        {(authUserId === note.createdByUserID || !!activeNode?.permission.admin) &&
                            <ActionIcon icon='trash-can' color='#292929' callbackFn={() => deleteNote(note)} />
                        }
                    </div>
                </div>
            </div>
            <div className={'card p-3 note-text ' + (!!isEditing && 'editing')}>
                {!isEditing && <div className='display-note-text'>{noteText}</div>}
                {!!isEditing &&
                    <>
                        <div contentEditable={isEditing} className={isEditing ? 'note-box editing' : 'note-box'} onKeyDown={submitMessageIfEnter} ref={noteTextRef} suppressContentEditableWarning={true}>
                            {noteText}
                        </div>
                        <div className='d-flex justify-content-end mt-2'>
                            <Button className='mx-2' color='primary' emphasis='med' onClick={() => setIsEditing(false)}>{t(NotesTKeys.Cancel)}</Button>
                            <Button color='primary' emphasis='high' onClick={save}>{t(NotesTKeys.Save)}</Button>
                        </div>
                    </>
                }
            </div>
        </div >
    );
};