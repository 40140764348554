import { useEffect } from 'react';

interface UtopiaMessageData {
    key: string;
    value: string;
}

interface UseIFramePathChangeListenerProps {
    onIFramePathChange: (newPath: string) => void;
    currentRoute: string;
}

export const useIFramePathChangeListener = (props: UseIFramePathChangeListenerProps) => {

    const { onIFramePathChange, currentRoute } = props;

    const handleMessage = (message: MessageEvent<UtopiaMessageData>) => {
        if (!!message.data?.key && !!message.data?.value && message.data.key == 'utopiaUrlChanged') {
            onIFramePathChange(message.data.value);
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [currentRoute]);
};
