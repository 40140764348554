import React, { useState } from 'react';
import { Alert, Modal, Text, Button } from '@efilecabinet/efc-atlantis-components';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { ThemeEnum } from '../../../../../hooks/useColors';
import { useIcons } from '../../../../../hooks/useIcons';
import { useUtilities } from '../../../../../hooks/useUtilities';
import { RoleEnum } from '../../../../../api/roles/roleApiTypes';
import { useNodePermissionsContext } from '../../../_context/NodePermissionsContext/NodePermissionsContext';
import { NodeDTO } from '../../../../../api/node/nodeApiTypes';

interface DeletePermissionsModalProps {
    isOpen: boolean;
    onCancel?: () => void;
    closeModal: () => void;
    individualPermission?: boolean;
    activeNode: NodeDTO | undefined;
    successCallback?: (showSkeletonLoader?: boolean) => Promise<void>;
}

export const DeletePermissionsModal = (props: DeletePermissionsModalProps) => {
    const { isOpen, onCancel, closeModal, activeNode, successCallback, individualPermission = false } = props;

    const { spinnerIconProps } = useIcons();
    const { formatDateTime } = useUtilities();
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { categorizePermissionChanges, permissions, setEditedPermissions } = useNodePermissionsContext();
    const { getRoleIcon, removeNodePermissionsAsync, updateAndCreatePermissions } = useNodePermissionsUtilities();

    const [errorStr, setErrorStr] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { permissionsToRemove, permissionsToCreate, permissionsToUpdate } = categorizePermissionChanges(activeNode?.id, individualPermission);
    const isGroup = permissionsToRemove[0]?.roleData?.roleType === RoleEnum.Group;
    const roleName = permissionsToRemove[0]?.roleData.name;

    const handleCancel = () => {
        onCancel && onCancel();
        closeModal();
        setEditedPermissions(permissions);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        setErrorStr('');

        try {
            await removeNodePermissionsAsync(permissionsToRemove);

            if (permissionsToUpdate.length || permissionsToCreate.length) {
                await updateAndCreatePermissions(permissionsToUpdate, permissionsToCreate);
            }

            closeModal();
            successCallback && await successCallback();
        } catch (error: any) {
            console.log(error);
            setErrorStr(t(PermissionsTKeys.EditError, { error: error?.message }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} background='static' title={t(PermissionsTKeys.DeleteConfirmModalHeader)} dataId='delete-permissions-confirm-modal'>
            <Modal.Body className='mt-2'>
                {errorStr && <Alert color='danger'>{errorStr}</Alert>}
                {!individualPermission ? (
                    <>
                        <Text>{t(PermissionsTKeys.DeleteConfirmModalDescription)}</Text>
                        <div className='mt-4'>
                            {permissionsToRemove.map((p, i) => (
                                <div className={`d-flex ${i == permissionsToRemove.length - 1 ? 'mb-2 pb-1' : 'mb-3'}`} key={p.id}>
                                    {getRoleIcon(p.roleData)}
                                    {p.roleData.anonymous ? (
                                        <Text className='ms-1'>{t(PermissionsTKeys.AccessLinkLabel, { date: formatDateTime(p.createdOn) })}</Text>
                                    ) : (
                                        <Text className='ms-1'>{p.role}</Text>
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <Text>{isGroup ? t(PermissionsTKeys.DeleteGroupDesc, { roleName }) : t(PermissionsTKeys.DeleteUserDesc, { roleName })}</Text>
                        <Alert color={ThemeEnum.Primary} className='mt-3'>
                            {isGroup ? t(PermissionsTKeys.DeleteGroupInfo) : t(PermissionsTKeys.DeleteUserInfo)}
                        </Alert>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCancel} emphasis='med' dataId='delete-permissions-cancel'>
                    {t(PermissionsTKeys.CancelButton)}
                </Button>
                <Button
                    onClick={handleDelete}
                    emphasis='high'
                    color={ThemeEnum.Danger}
                    dataId='delete-permissions-confirm'
                    icon={isLoading ? spinnerIconProps : undefined}>
                    {t(PermissionsTKeys.ConfirmButton)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
