import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { Text, Tooltip, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import { useGetElementWidth } from '../../../hooks/useGetElementWidth';
import { ContextMenu, ContextMenuProps } from '../../ContextMenu/ContextMenu';
import { useColors } from '../../../hooks/useColors';
import './SidesheetDisplayCard.css';

interface SidesheetDisplayCardProps {
    icon?: FontAwesomeIconProps;
    title: string;
    titleTooltip?: string;
    subtitles?: string[];
    subtitleIcon?: FontAwesomeIconProps;
    subtitleTooltip?: string;
    actions?: ContextMenuProps;
    className?: string;
}

export const SidesheetDisplayCard = (props: SidesheetDisplayCardProps) => {
    const { icon, title, titleTooltip, subtitles, className, actions, subtitleIcon, subtitleTooltip } = props;

    const { elementWidth: sidesheetWidth } = useGetElementWidth({ elementId: 'sidesheet' });
    const { atlantisMuted } = useColors();

    const BOOTSTRAP_1_COLUMN_WIDTH = '1';
    const BOOTSTRAP_2_COLUMN_WIDTH = '2';
    const BOOTSTRAP_FULL_COLUMN_WIDTH = '12';

    const [iconColumnWidth, setIconColumnWidth] = useState<string>(BOOTSTRAP_2_COLUMN_WIDTH);
    const [tooltipId, setTooltipId] = useState<string>(crypto.randomUUID());
    const [subtitleTooltipId, setSubtitleTooltipId] = useState<string>(crypto.randomUUID());

    const getTitleColumnWidth = () => {
        return (+BOOTSTRAP_FULL_COLUMN_WIDTH - 2 * +iconColumnWidth).toString();
    };

    const getIcon = (icon: FontAwesomeIconProps) => {
        if (icon.icon === 'user-shield') {
            return <i className={'icon-efc-guest-user ps-1 mx-1'}></i>;
        } else {
            return <FontAwesomeIcon {...icon} fixedWidth />;
        }
    };

    useEffect(() => {
        if (!!sidesheetWidth && sidesheetWidth < 450 && iconColumnWidth === BOOTSTRAP_1_COLUMN_WIDTH) {
            setIconColumnWidth(BOOTSTRAP_2_COLUMN_WIDTH);
        } else if (!!sidesheetWidth && sidesheetWidth >= 450 && iconColumnWidth === BOOTSTRAP_2_COLUMN_WIDTH) {
            setIconColumnWidth(BOOTSTRAP_1_COLUMN_WIDTH);
        }
    }, [sidesheetWidth]);

    return (
        <>
            <Row className={`mx-0 my-2 py-2 ${className || ''} border rounded sidesheet-display-card`} style={{ cursor: 'pointer' }}>
                <Col>
                    <Row className='d-flex align-items-center'>
                        <Col xs={iconColumnWidth} className='px-2 d-flex align-items-center justify-content-center'>
                            {!!icon && getIcon(icon)}
                        </Col>
                        <Col xs={getTitleColumnWidth()} className='ps-0 me-auto text-truncate'>
                            <TooltipItem id={tooltipId} message={!!titleTooltip ? titleTooltip : title}>
                                <Text>{title}</Text>
                            </TooltipItem>
                        </Col>
                        {!!actions && (
                            <Col xs={iconColumnWidth} className='d-flex align-items-center justify-content-center'>
                                <ContextMenu {...actions} />
                            </Col>
                        )}
                    </Row>

                    {!!subtitles &&
                        subtitles.map((subtitle, index) => (
                            <Row key={`${subtitle}${index}`} className='d-flex align-items-center'>
                                <Col xs={iconColumnWidth} className='px-2 d-flex align-items-center'>
                                    <FontAwesomeIcon icon='user' fixedWidth style={{ visibility: 'hidden' }} />
                                </Col>
                                <Col xs={getTitleColumnWidth()} className='ps-0 d-flex align-items-center'>
                                    <Text color={atlantisMuted}>{subtitle}</Text>
                                    {subtitleIcon && subtitleTooltip ? (
                                        <TooltipItem delayShow={200} id={subtitleTooltipId} message={subtitleTooltip}>
                                            <FontAwesomeIcon {...subtitleIcon} className='ms-1' />
                                        </TooltipItem>
                                    ) : subtitleIcon ? (
                                        <FontAwesomeIcon {...subtitleIcon} className='ms-1' />
                                    ) : null}
                                </Col>
                            </Row>
                        ))}
                </Col>
            </Row>
        </>
    );
};
