import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodeRecentDTO, RecentNodeTypeEnum } from './recentApiTypes';

export const useRecentsApi = () => {

    const getRecentNodes = async (queryParams: { recentType: RecentNodeTypeEnum, start: number, count: number, }): Promise<NodeRecentDTO[]> => {
        const recentNodeType: string = RecentNodeTypeEnum[queryParams.recentType];
        const resp: AxiosResponse = await Api.get(`api/recentNodes/RecentNodes/${recentNodeType}/${queryParams.start}/${queryParams.count}`);
        return resp.data;
    };

    return {
        getRecentNodes,
    };
};
