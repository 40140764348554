import React, { useState } from 'react';
import { Dropdown, useDropdown } from '@efilecabinet/efc-atlantis-components';
import { HelpNavMenuItemTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { FeedbackModal } from './Feedback/FeedbackModal';
import { HelpCard } from './HelpCard/HelpCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './HelpNavMenuItem.css';

export const HelpNavMenuItem = () => {
    const { t } = useSafeTranslation(TranslationFiles.HelpNavMenuItem);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const { toggleDropdown, dropdownIsOpen } = useDropdown();

    const toggleFeedback = () => {

        if (!showFeedbackModal) {
            toggleDropdown();
        }

        setShowFeedbackModal(!showFeedbackModal);
    };

    return (
        <>
            <Dropdown isOpen={dropdownIsOpen} toggle={toggleDropdown} dataId='navMenuHelpIcon'>
                <Dropdown.Toggle className='nav-link d-flex align-items-center' tag='a'>
                    <FontAwesomeIcon icon={['far', 'circle-question']} className='mx-2' size='lg' title={t(HelpNavMenuItemTKeys.Help)} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='help-navmenu'>
                    <HelpCard toggleFeedback={toggleFeedback} />
                </Dropdown.Menu>
            </Dropdown>
            <FeedbackModal showFeedbackModal={showFeedbackModal} toggleFeedback={toggleFeedback} />
        </>
    );
};

