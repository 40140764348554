import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './ActionIcon.css';

interface ActionIconProps extends FontAwesomeIconProps { // Fix the interface declaration
    id?: string;
    callbackFn: () => void;
}

export const ActionIcon = ({ icon, color = '#7514BD', id, callbackFn }: ActionIconProps) => {
    return (
        <div id={id} className='pointer IconShadow d-flex justify-content-center'>
            <FontAwesomeIcon color={color} className='icon' icon={icon} onClick={callbackFn} />
        </div>
    );
};