export const useUrlUtilities = () => {

    const getValidUrl = (url: string): URL => {
        try {
            return new URL(url);
        } catch {
            return new URL(url, 'https://.com');
        }
    };

    const getFragment = (url: string): string => {
        return getValidUrl(url).hash.slice(1);
    };
    
    const hasQueryParams = (url: string): boolean => {
        try {
            return !!Array.from(getValidUrl(url)?.searchParams ?? []).length;
        }
        catch {
            console.error(`Failed to check url path for query parameters: ${url}`);
            return false;
        }
    };

    const getQueryParam = (url: string, paramKey: string): string | null => {
        return getValidUrl(url).searchParams.get(paramKey);
    };

    const getQueryParams = (url: string): URLSearchParams => {
        return getValidUrl(url).searchParams;
    };

    const getQueryParamDelimiter = (url: string): string => {
        return hasQueryParams(url) ? '&' : '?';
    };

    const removeQueryParams = (url: string, queryParams: string[]): string => {
        try {
            const newURL = getValidUrl(url);
            queryParams.forEach((param: string) => {
                newURL.searchParams.delete(param);
            });
            return newURL.pathname + newURL.search;
        }
        catch {
            console.error(`Failed to remove required query parameters for url path: ${url}`);
            return url;
        }
    };

    const removeOrigin = (url: string): string => {
        return getValidUrl(url).pathname;
    };

    return {
        getValidUrl,
        getFragment,
        hasQueryParams,
        getQueryParam,
        getQueryParams,
        getQueryParamDelimiter,
        removeQueryParams,
        removeOrigin,
    };
};