import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { OptInInfoDTO, SettingsDTO, UtopiaConfigurationDTO } from './systemApiTypes';

export const useSystemApi = () => {

    const getRubexBaseUrl = async (): Promise<string> => {
        const resp: AxiosResponse = await Api.get('api/system/RubexBaseUrl');
        return resp.data;
    };

    const getRubexConfiguration = async (): Promise<UtopiaConfigurationDTO> => {
        const resp: AxiosResponse = await Api.get('api/system/RubexConfiguration');
        return resp.data;
    };

    const getSettings = async (): Promise<SettingsDTO> => {
        const resp: AxiosResponse = await Api.get('api/system/Settings');
        return resp.data;
    };

    const getFeatureFlags = async (): Promise<string[]> => {
        const resp: AxiosResponse = await Api.get('api/system/FeatureFlags');
        return resp.data;
    };

    const getOptInInfo = async (userId: number): Promise<OptInInfoDTO> => {
        const resp: AxiosResponse = await Api.get(`api/system/OptInInfo/${userId}`);
        return resp.data;
    };

    return {
        getSettings,
        getRubexConfiguration,
        getRubexBaseUrl,
        getFeatureFlags,
        getOptInInfo
    };
};
