import React from 'react';
import { Page } from '@efilecabinet/efc-atlantis-components';
import { IFrame } from '../../../components/IFrame/IFrame';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import './AuditLogsPage.css';

export const AuditLogsPage = () => {

    const auditLogUrl = '/admin/auditLog';
    const iframe = useUtopiaIFrame(auditLogUrl);

    return (
        <Page title='Audit Logs' icon={{ icon: 'clock-rotate-left' }}>
            <IFrame className='iframe' src={iframe.url} />
        </Page>
    );
};
