import React, {useState } from 'react';
import { Button, Modal } from '@efilecabinet/efc-atlantis-components';
import { FilePasswordTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { IUseModalProps } from '../../../../hooks/useModal';

interface FullScreenPreviewerModalProps extends IUseModalProps {
    onClose: (isConfirmed: boolean) => void;
    onDeleteConfirm: () => void;
    onCancel: () => void;
}

export const FilePasswordRemoveConfirmModal = (props: FullScreenPreviewerModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.FilePassword);
    const { onClose, onDeleteConfirm, onCancel, ...otherProps } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
        onClose(false);
    };

    const performCancel = () => {
        onCancel();
        handleClose();
    };

    const onConfirm = () => {
        onDeleteConfirm();
        handleClose();
    };

    return (
        <Modal title={t(FilePasswordTKeys.ConfirmPasswordRemoveTitle)} toggle={handleClose} isOpen={isOpen} {...otherProps}>
            <Modal.Body>
                {t(FilePasswordTKeys.ConfirmPasswordRemoveText)}
            </Modal.Body>
            <Modal.Footer>
            <Button dataId='revversAmazingFilePasswordRemoveConfirmModalCancelButtonDataIdAttributeTag' color='primary' onClick={performCancel} emphasis='med'>{t(FilePasswordTKeys.Cancel)}</Button>
            <Button dataId='revversAmazingFilePasswordRemoveConfirmModalConfirmButtonDataIdAttributeTag' color='danger' onClick={onConfirm}>{t(FilePasswordTKeys.Confirm)}</Button>
            </Modal.Footer>
        </Modal>
    );
};
