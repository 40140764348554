import { DocumentRouteParams, DocumentRoutePath, LinkedViewRouteParams, LinkedViewRoutePath, RoutePath } from '../features/_routing/routingTypes';

export const useUtopiaIFrameRoutes = () => {

    const IFrameDocumentRoutes: Map<DocumentRoutePath, string> = new Map([
        [RoutePath.Documents, '/home/state/data/root/details/'],
        [RoutePath.Node, `/home/state/data/node/${DocumentRouteParams.RootNodeId}/${DocumentRouteParams.ParentId}/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}&showFullPreview=${DocumentRouteParams.ShowFullPreview}`],
        [RoutePath.NodeDetail, `/home/state/data/node/${DocumentRouteParams.RootNodeId}/${DocumentRouteParams.ParentId}/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}&showFullPreview=${DocumentRouteParams.ShowFullPreview}`],
        [RoutePath.NodeContainer, `/home/state/data/node/${DocumentRouteParams.RootNodeId}/${DocumentRouteParams.ParentId}/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}`],
        [RoutePath.Collections, `/home/state/collections/collection/${DocumentRouteParams.DefaultCollectionId}/rootID/nodeID/details`],
        [RoutePath.Collection, `/home/state/collections/collection/${DocumentRouteParams.CollectionId}/rootID/nodeID/details`],
        [RoutePath.CollectionNodeDetail, `/home/state/collections/collection/${DocumentRouteParams.CollectionId}/rootID/nodeID/details/${DocumentRouteParams.NodeIds}?selectedDetail=${DocumentRouteParams.SelectedDetail}`],
    ]);

    const IFrameLinkedViewRoutes: Map<LinkedViewRoutePath, string> = new Map([
        [RoutePath.LinkedNode, `/home/state/data/node/${LinkedViewRouteParams.LinkedNodeId}/${LinkedViewRouteParams.LinkedNodeId}/details/${LinkedViewRouteParams.LinkedNodeId}`],
        [RoutePath.LinkedNodeContainer, `/home/state/data/node/${LinkedViewRouteParams.LinkedNodeId}/${LinkedViewRouteParams.ContainerNodeId}/details/${LinkedViewRouteParams.ContainerNodeId}?selectedDetail=${LinkedViewRouteParams.SelectedDetail}`],
        [RoutePath.LinkedNodeDetail, `/home/state/data/node/${LinkedViewRouteParams.LinkedNodeId}/${LinkedViewRouteParams.ContainerNodeId}/details/${LinkedViewRouteParams.DetailsNodeIds}?selectedDetail=${LinkedViewRouteParams.SelectedDetail}&showFullPreview=${LinkedViewRouteParams.ShowFullPreview}`],
    ]);

    return {
        IFrameDocumentRoutes,
        IFrameLinkedViewRoutes
    };
};